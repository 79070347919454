/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { Segment } from 'semantic-ui-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CustomTooltip } from '../../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { putActivity } from '../../../store/actions';
import { get, isEmpty } from '../../../utils/lodash';
import './activity.scss';
import { isParentProgramActivity } from '../../../utils/activity';

class DocumentNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeInput(value, type) {
    this.setState({ [type]: value });
  }

  onBlurInput(type) {
    const { putActivity } = this.props;
    putActivity('projectNotes', { [type]: this.state[type] });
  }

  render() {
    const { currentActivity, user, helpTips, pageDisabled } = this.props;
    const { evaluationPlans = [], isAnalysisField } = currentActivity;

    const normalEvaluation = evaluationPlans.filter((item) => {
      return item.methodId !== 9;
    });

    const { analysis, reflection, notes } = this.state;
    return (
      <Segment className="document-note-seg" disabled={pageDisabled}>
        <div className="document-note-div">
          <AvForm className="">
            {isAnalysisField &&
            (isParentProgramActivity(currentActivity.activityPlanTypeId) ||
              !isEmpty(normalEvaluation)) ? (
              <AvField
                rows="8"
                type="textarea"
                name="analysis"
                id="analysis"
                label={
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '219',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <div>Evaluation Results Analysis</div>
                  </CustomTooltip>
                }
                className="notes-textarea"
                placeholder="What do the evaluation results tell you about participant outcomes? What factors were significant to outcomes?"
                value={analysis ? analysis : currentActivity.analysis}
                onChange={(e) => this.onChangeInput(e.target.value, 'analysis')}
                onBlur={() => this.onBlurInput('analysis')}
                disabled={
                  currentActivity.statusId == 3 || pageDisabled ? true : false
                }
              />
            ) : null}

            <AvField
              rows="8"
              type="textarea"
              name="reflection"
              id="reflection"
              label={
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '81',
                  }}
                  position="top left"
                  wide="very"
                >
                  <div>Reflection</div>
                </CustomTooltip>
              }
              className="notes-textarea"
              placeholder="Consider the activity as a whole and what you would like to pass on to readers of the activity report. Was the activity successful in achieving its intended outcomes? Was your theory of change confirmed? What did you learn? Were there any significant hindrances or problems? "
              value={reflection ? reflection : currentActivity.reflection}
              onChange={(e) => this.onChangeInput(e.target.value, 'reflection')}
              onBlur={() => this.onBlurInput('reflection')}
              disabled={
                currentActivity.statusId == 3 || pageDisabled ? true : false
              }
            />
            {user.role === 'guest' && currentActivity.parentId ? null : (
              <AvField
                rows="6"
                type="textarea"
                name="notes"
                id="notes"
                label={
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '82',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <div>Unpublished Note</div>
                  </CustomTooltip>
                }
                className="notes-textarea"
                helpMessage="This will not be visible on the activity report or export."
                value={notes ? notes : currentActivity.notes}
                onChange={(e) => this.onChangeInput(e.target.value, 'notes')}
                onBlur={() => this.onBlurInput('notes')}
                disabled={
                  currentActivity.statusId == 3 || pageDisabled ? true : false
                }
              />
            )}
          </AvForm>
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
    },
    dispatch
  );
};

DocumentNotes = connect(mapStateToProps, mapDispatchToProps)(DocumentNotes);

class FinalActivityDescription extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeInput(value, type) {
    this.setState({ [type]: value });
  }

  onBlurInput(type) {
    const { putActivity } = this.props;
    if (!isEmpty(this.state[type]))
      putActivity('projectNotes', { [type]: this.state[type] });
  }
  render() {
    const { currentActivity, user, helpTips, pageDisabled } = this.props;

    const { summary } = this.state;

    return (
      <div>
        {' '}
        <Segment className="document-note-seg" disabled={pageDisabled}>
          <div className="document-note-div">
            <AvForm className="">
              <AvField
                rows="6"
                type="textarea"
                name="summary"
                id="summary"
                label={
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '80',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <div>Activity Description </div>
                  </CustomTooltip>
                }
                className="notes-textarea"
                value={summary ? summary : currentActivity.summary}
                onChange={(e) => this.onChangeInput(e.target.value, 'summary')}
                onBlur={() => this.onBlurInput('summary')}
                disabled={
                  currentActivity.statusId == 3 || pageDisabled ? true : false
                }
              />
            </AvForm>
          </div>
        </Segment>
      </div>
    );
  }
}

FinalActivityDescription = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalActivityDescription);

export { DocumentNotes, FinalActivityDescription };

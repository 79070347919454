import axios from 'axios';
import { FullStoryAPI } from 'react-fullstory';
import { NotificationManager } from 'react-notifications';

import {
  getAuthenticatedToken,
  removePersist,
  setAuthenticatedToken,
  setRefreshToken,
} from '../../utils/cacheStore';
import history from '../../utils/history';
import { cloneDeep, get } from '../../utils/lodash';
import { authMsg, getCommonError, userAuth } from '../../utils';

const CLEAR_AUTH = 'CLEAR_AUTH';
const LOGIN_USER_LOADING = 'LOGIN_USER_LOADING';
const GET_USER_LOADING = 'GET_USER_LOADING';
const GET_USER = 'GET_USER';
const USER_LOGIN_INCORRECT = 'USER_LOGIN_INCORRECT';
const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
const FORGOT_USER_LOADING = 'FORGOT_USER_LOADING';
const RESET_USER_LOADING = 'RESET_USER_LOADING';
const REGISTER_LOADING = 'REGISTER_LOADING';
const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
const SET_REG_REQUEST_USER = 'SET_REG_REQUEST_USER';
const USER_MAIL_VERIFICATION_LOADING = 'USER_MAIL_VERIFICATION_LOADING';
const USER_MAIL_VERIFICATION = 'USER_MAIL_VERIFICATION';
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
const SET_ORGANIZATION = 'SET_ORGANIZATION';
const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';
const ERROR_HANDLING = 'ERROR_HANDLING';

const initialState = {
  user: {},
  userGetLoading: false,
  userLoginLoading: false,
  userLogoutLoading: false,
  userLoginIncorrect: false,
  updatePasswordLoading: false,
  forgotPassLoading: false,
  resetPassLoading: false,
  registerTokenLoading: false,
  registerLoading: false,
  requestedRegUser: {},
  emailVerifyLoading: false,
  authToken: null,
  updatingUser: false,
  userSignupData: {},
  errorHandling: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_AUTH:
      return initialState;
    case LOGIN_USER_LOADING:
      return { ...state, userLoginLoading: action.payload };
    case USER_LOGIN_INCORRECT:
      return { ...state, userLoginIncorrect: action.payload };
    case GET_USER:
      return { ...state, user: action.payload || {} };
    case GET_USER_LOADING:
      return { ...state, userGetLoading: action.payload };
    case UPDATE_PASSWORD:
      return { ...state, updatePasswordLoading: action.payload };
    case FORGOT_USER_LOADING:
      return { ...state, forgotPassLoading: action.payload };
    case RESET_USER_LOADING:
      return { ...state, resetPassLoading: action.payload };
    case REGISTER_USER_LOADING:
      return { ...state, registerLoading: action.payload };
    case REGISTER_LOADING:
      return { ...state, registerTokenLoading: action.payload };
    case SET_REG_REQUEST_USER:
      return { ...state, requestedRegUser: action.payload };
    case USER_MAIL_VERIFICATION_LOADING:
      return { ...state, emailVerifyLoading: action.payload };
    case USER_MAIL_VERIFICATION:
      return { ...state, emailVerify: action.payload };
    case SET_AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case UPDATE_USER_LOADING:
      return { ...state, updatingUser: action.payload };
    case SET_ORGANIZATION:
      return { ...state, archiveCoreData: action.payload };
    case SET_SIGNUP_DATA:
      return { ...state, userSignupData: action.payload };
    case ERROR_HANDLING:
      return { ...state, errorHandling: action.payload };
    // case CLEAR_SIGNUP_DATA:
    //   return { ...state, userSignupData: {} };
    default:
      return state;
  }
}

export function signUpCache(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_SIGNUP_DATA, payload: values });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function clearSignUpCache() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_SIGNUP_DATA, payload: {} });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function initializedApp() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const authToken = await getAuthenticatedToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
}

export function clearAuth() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_AUTH });
  };
}

export function loginUser(form) {
  return async (dispatch) => {
    dispatch({ type: LOGIN_USER_LOADING, payload: true });
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.post(`/auth/login`, form, {
          withCredentials: true,
        });
        await dispatch({ type: USER_LOGIN_INCORRECT, payload: false });
        await dispatch({ type: LOGIN_USER_LOADING, payload: false });
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        await setAuthenticatedToken(data.token);
        await setRefreshToken(data.refreshToken);
        await dispatch(initializedApp());
        resolve();
      } catch (error) {
        dispatch({ type: LOGIN_USER_LOADING, payload: false });
        dispatch({ type: GET_USER, payload: {} });
        //dispatch({ type: ERROR_HANDLING, payload: getCommonError(error) });
        // NotificationManager.error(getCommonError(error));
        reject(getCommonError(error));
      }
    });
  };
}

export function logoutUser(navigate = null) {
  return async (dispatch) => {
    try {
      await axios.get('/auth/logout', { withCredentials: true });
      await userAuth.signout();

      navigate && navigate('/');

      await dispatch({ type: 'RESET' });
      removePersist();
    } catch (error) {
      await userAuth.signout();
      navigate && navigate('/');
      await dispatch({ type: 'RESET' });
      removePersist();
    }
  };
}

export function getUserProfile() {
  return async (dispatch) => {
    dispatch({ type: GET_USER_LOADING, payload: true });
    try {
      const res = await axios.get(`/user/current`);
      dispatch({ type: GET_USER, payload: res.data });
      FullStoryAPI('identify', res.data.id, {
        displayName: res.data.name,
        email: res.data.email,
        organisationId: get(res.data, 'organisationId', null),
        organisationName: get(res.data, 'organisation.name', null),
        role: res.data.role,
      });
      dispatch({ type: GET_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      dispatch(logoutUser());
    }
  };
}

export function getUserProfileOnly() {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/user/current`);
      dispatch({ type: GET_USER, payload: res.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateUser(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_LOADING, payload: true });
      await axios.put('/user', body);
      authMsg('success', ['Updated your profile', 'Success']);
      dispatch({ type: UPDATE_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_USER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function changePassword(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PASSWORD, payload: true });
      await axios.put(`/user/password`, body);
      NotificationManager.success('Updated your password', 'Success');
      dispatch({ type: UPDATE_PASSWORD, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_PASSWORD, payload: false });
      NotificationManager.error(getCommonError(error));
    }
  };
}

export function setOrgGoalDocument(data) {
  return async (dispatch, getState) => {
    try {
      const user = cloneDeep(getState().authentication.user);
      Object.assign(user.organisation, data);
      dispatch({ type: GET_USER, payload: user });
    } catch (error) {
      // console.error('setOrgGoalDocument -> error', error);
      // NotificationManager.error(getCommonError(error))
    }
  };
}

export function helpTips(data) {
  return async (dispatch, getState) => {
    try {
      const user = cloneDeep(getState().authentication.user);
      const options = Object.assign(user.options, data);
      const response = await axios.put(`/user/settings`, { options });
      dispatch({ type: GET_USER, payload: response.data });
    } catch (error) {
      // NotificationManager.error(getCommonError(error));
    }
  };
}

export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch({ type: FORGOT_USER_LOADING, payload: true });
    try {
      await axios.post(`/auth/forgot-password`, {
        username: email,
      });
      dispatch({ type: FORGOT_USER_LOADING, payload: false });
      NotificationManager.success('Password reset email sent', 'Success');
      return true;
    } catch (error) {
      NotificationManager.error(getCommonError(error));
      dispatch({ type: FORGOT_USER_LOADING, payload: false });
    }
  };
}

export function getForgotVerification(token) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.get('/auth/verify', {
          params: {
            token,
          },
        });
        resolve();
      } catch (error) {
        history.push('/password-link-expired');
        reject(error);
      }
    });
  };
}

export function checkMailVerified(token) {
  return async (dispatch) => {
    dispatch({ type: USER_MAIL_VERIFICATION_LOADING, payload: true });
    try {
      await axios.get('/auth/verify-mail', {
        params: {
          token,
        },
      });
      dispatch({ type: USER_MAIL_VERIFICATION_LOADING, payload: false });
      dispatch({ type: USER_MAIL_VERIFICATION, payload: true });
    } catch (error) {
      dispatch({ type: USER_MAIL_VERIFICATION, payload: false });
      dispatch({ type: USER_MAIL_VERIFICATION_LOADING, payload: false });
    }
  };
}

export function resetPassword(form) {
  return async (dispatch) => {
    dispatch({ type: RESET_USER_LOADING, payload: true });
    try {
      await axios.post(`/auth/reset-password`, form);
      dispatch({ type: RESET_USER_LOADING, payload: false });
      history.push('/password-changed');
      NotificationManager.success('Password reset success', 'Success');
    } catch (error) {
      NotificationManager.error(getCommonError(error));
      dispatch({ type: RESET_USER_LOADING, payload: false });
    }
  };
}

export function registerRequest(token = null) {
  return async (dispatch) => {
    dispatch({ type: REGISTER_LOADING, payload: true });
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.get('/register/request', {
          params: {
            token,
          },
        });
        dispatch({
          type: SET_REG_REQUEST_USER,
          payload: response.data,
        });
        dispatch({ type: REGISTER_LOADING, payload: false });
        resolve();
      } catch (error) {
        history.push('/link-expired');
        dispatch({ type: REGISTER_LOADING, payload: false });
        reject(getCommonError(error));
      }
    });
  };
}

export function userRegister(form) {
  return async (dispatch) => {
    dispatch({ type: REGISTER_USER_LOADING, payload: true });
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          '/register/submit-invite-registration',
          form
        );
        dispatch({ type: REGISTER_USER_LOADING, payload: false });
        history.push(`/account/verification/${response.data.token}`);
        resolve();
      } catch (error) {
        dispatch({ type: REGISTER_USER_LOADING, payload: false });
        //dispatch({ type: ERROR_HANDLING, payload: getCommonError(error) });
        history.push('/link-expired');
        reject(getCommonError(error));
      }
    });
  };
}

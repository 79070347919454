import React, { useState } from 'react';
import EmailResponseFormExistingUser from './emailResFormExistUser';
import EmailResponseFormGuestUser from './emailResFormGuestUser';
import { EmailVerification } from '../../../../../../components/form';
import { get, isEmpty } from '../../../../../../utils/lodash';
import { getLinkActivityFeatureName } from '../../../../../../utils/activity/linkActivity';

// TODO: Implement only get data when confirmed the button
// separate response panel
/**
 * Only get data when confirmed the button
 */
export default function CheckUserEmail(props) {
  const {
    checkChangeAllow = null,
    activityFeatureRequired = null,
    activityManager = null,
    changeOwnershipPayload = {
      fromOrganisationId: null,
      fromUserId: null,
      // toOrganisationId: null,
      parentActOwnerOrgId: null,
    },
    setFieldValue,
  } = props;

  // assign founded user from verifying
  const [foundedUser, setFoundedUser] = useState(null);

  // assign user after confirmed
  const [attachedUser, setAttachedUser] = useState(activityManager);

  // TODO: need to investigate this is not working Formik issue
  // const RenderResponsePanel = props => {
  //   if (attachedUser) {
  //     return <EmailResponseFormGuestUser {...props} />;
  //   } else {
  //     if (foundedUser && foundedUser.isExitingUser) {
  //       const { data } = foundedUser;
  //       console.log(
  //         '🚀 ~ file: checkUserEmail.js:41 ~ RenderResponsePanel ~ data:',
  //         data
  //       );
  //       const isFeatureMet =
  //         !activityFeatureRequired ||
  //         (activityFeatureRequired &&
  //           !isEmpty(data.userFeatures) &&
  //           data.userFeatures.includes(activityFeatureRequired));

  //       const toOrganisationId = data.organisationId;
  //       const {
  //         fromOrganisationId = null,
  //         // toOrganisationId = null,
  //         parentActOwnerOrgId = null,
  //       } = changeOwnershipPayload;

  //       const isChangAllowedMet =
  //         !checkChangeAllow ||
  //         (checkChangeAllow &&
  //           (fromOrganisationId === toOrganisationId ||
  //             toOrganisationId === parentActOwnerOrgId ||
  //             fromOrganisationId === parentActOwnerOrgId));

  //       if (!isChangAllowedMet) {
  //         return (
  //           <>
  //             <div className="no-feature-warning-box w-100">
  //               <div className="padding-issue ">
  //                 You can not not invite this user to this activity. This
  //                 organisation is not matched
  //               </div>
  //             </div>
  //           </>
  //         );
  //       } else if (!isFeatureMet) {
  //         return (
  //           <>
  //             <div className="no-feature-warning-box w-100">
  //               <div className="padding-issue ">
  //                 Invitation to this activity is not possible for the user in
  //                 question as the activity type is not accessible or available
  //                 to them
  //               </div>
  //             </div>
  //           </>
  //         );
  //       } else if (isChangAllowedMet && isFeatureMet) {
  //         return <EmailResponseFormExistingUser />;
  //       }
  //     } else if (foundedUser && !foundedUser.isExitingUser) {
  //       return (
  //         <>
  //           <span className="not-verified-email">
  //             This email does not match an existing account in Takso. Enter
  //             details below to create a guest account for this person
  //           </span>

  //           <CustomInput
  //             id="firstName"
  //             name="firstName"
  //             placeholder="Enter First Name 1"
  //             labelText="First Name"
  //             requiredStar
  //           />
  //           <EmailResponseFormGuestUser {...props} />
  //         </>
  //       );
  //     }
  //   }
  //   return <></>;
  // };

  const checkChangeAllowMet = (_toOrganisationId) =>
    !checkChangeAllow ||
    (checkChangeAllow &&
      ((fromOrganisationId !== null &&
        fromOrganisationId === _toOrganisationId) ||
        _toOrganisationId === parentActOwnerOrgId ||
        fromOrganisationId === parentActOwnerOrgId));

  let data = null;
  if (foundedUser) {
    let { data: userData } = foundedUser;
    data = userData;
  }

  const isFeatureMet =
    !activityFeatureRequired ||
    (activityFeatureRequired &&
      ((typeof foundedUser === 'object' &&
        isEmpty(foundedUser) &&
        activityFeatureRequired === getLinkActivityFeatureName(1)) ||
        (typeof foundedUser === 'object' &&
          !isEmpty(foundedUser) &&
          !foundedUser.isExitingUser &&
          activityFeatureRequired === getLinkActivityFeatureName(1)) ||
        (foundedUser &&
          !isEmpty(data.userFeatures) &&
          get(data, 'userFeatures', []).includes(activityFeatureRequired))));

  const toOrganisationId = foundedUser
    ? get(data, 'organisation.id', null)
    : null;
  const {
    fromOrganisationId = null,
    fromUserId = null,
    // toOrganisationId = null,
    parentActOwnerOrgId = null,
  } = changeOwnershipPayload;

  //check if the allow to make change the email
  const isChangAllowedMet = checkChangeAllowMet(toOrganisationId);

  const resetUserDefaultValues = () => {
    setFieldValue('firstName', '');
    setFieldValue('surname', '');
    setFieldValue('entityName', '');
    setFieldValue('address1', '');
    setFieldValue('address2', '');
    setFieldValue('phone', '');
    setFieldValue('suburb', '');
    setFieldValue('state', '');
    setFieldValue('postcode', '');
  };

  const onChangeUserData = (userData) => {
    setFoundedUser(userData);
    setAttachedUser(null);
    resetUserDefaultValues();
    setFieldValue(
      'isExistingUser',
      !isEmpty(userData) ? userData.isExitingUser : false
    );

    // if existing user
    const _userFeatures = get(userData, 'data.userFeatures', []);
    const _isFeatureMet =
      !activityFeatureRequired ||
      (activityFeatureRequired &&
        ((typeof userData === 'object' &&
          isEmpty(userData) &&
          activityFeatureRequired === getLinkActivityFeatureName(1)) ||
          (typeof userData === 'object' &&
            !isEmpty(userData) &&
            !userData.isExitingUser &&
            activityFeatureRequired === getLinkActivityFeatureName(1)) ||
          (userData && _userFeatures.includes(activityFeatureRequired))));

    if (checkChangeAllow) {
      const toOrganisationId = userData
        ? get(userData, 'data.organisation.id', null)
        : null;

      // check change ability
      if (checkChangeAllowMet(toOrganisationId)) {
        // if new user allow to change
        if (userData && !userData.isExitingUser) {
          setFieldValue('isChangeAllowed', true);
        } else {
          const toUserId = get(userData, 'data.id', null);
          // check same user is truing to add as new user
          if (_isFeatureMet && fromUserId !== toUserId) {
            setFieldValue('isChangeAllowed', userData && !isEmpty(userData));
          } else {
            setFieldValue('isChangeAllowed', false);
          }
        }
      } else {
        setFieldValue('isChangeAllowed', false);
      }
    } else {
      if (_isFeatureMet) {
        setFieldValue('isChangeAllowed', userData && !isEmpty(userData));
      } else {
        setFieldValue('isChangeAllowed', false);
      }
    }
    setFieldValue('isConfirmedUser', userData && !isEmpty(userData));
    // to get organisation
    setFieldValue(
      'organisationId',
      get(userData, 'data.organisation.id', null)
    );
  };

  return (
    <>
      <EmailVerification
        id="email"
        name="email"
        getData={onChangeUserData}
        {...props}
      />

      {/* Render Response */}
      {/* attached user data coming for editing || setting default values */}
      {attachedUser ? (
        <>
          {get(attachedUser, 'isExistingUser', false) ? (
            <EmailResponseFormExistingUser data={attachedUser} />
          ) : (
            <EmailResponseFormGuestUser {...props} />
          )}
        </>
      ) : foundedUser && checkChangeAllow && !isChangAllowedMet ? (
        <>
          <div className="no-feature-warning-box w-100">
            <div className="padding-issue ">
              You can not invite this user to this activity. This organisation
              is not matched
            </div>
          </div>
        </>
      ) : foundedUser &&
        checkChangeAllow &&
        isChangAllowedMet &&
        fromUserId === get(foundedUser, 'data.id', null) ? (
        <div className="no-feature-warning-box w-100">
          <div className="padding-issue ">
            You can not not invite to same user again
          </div>
        </div>
      ) : !isFeatureMet && foundedUser ? (
        <>
          <div className="no-feature-warning-box w-100">
            <div className="padding-issue ">
              Invitation to this activity is not possible for the user in
              question as the activity type is not accessible or available to
              them
            </div>
          </div>
        </>
      ) : isChangAllowedMet && isFeatureMet ? (
        foundedUser && !foundedUser.isExitingUser ? (
          <>
            <span className="not-verified-email">
              This email does not match an existing account in Takso. Enter
              details below to create a guest account for this person
            </span>

            <EmailResponseFormGuestUser {...props} />
          </>
        ) : foundedUser && foundedUser.isExitingUser ? (
          <EmailResponseFormExistingUser
            data={get(foundedUser, 'data', null)}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

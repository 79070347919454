import React, { Fragment } from 'react';

import _ from 'lodash';
import { Pagination, Table, Icon } from 'semantic-ui-react';

import { isEmpty, map } from '../utils/lodash';
import { EmptyContainer } from '../components';

export class CustomTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: [],
      isOpen: false,
    };
  }

  toggleRow(rowId) {
    const { expandedRows } = this.state;
    const row = expandedRows.find((r) => r.id === rowId);
    if (row) {
      row.isOpen = !row.isOpen;
      this.setState({
        expandedRows: [...expandedRows],
      });
    } else {
      this.setState({
        expandedRows: [...expandedRows, { id: rowId, isOpen: true }],
      });
    }
  }

  render() {
    const {
      parentHeaders,
      header,
      columns,
      data,
      customClass,
      pagination,
      total = null,
      page,
      footerName = '',
      handlePaginationChange,
      noOfPages,
      emptyMessage,
      customHeaderClass = '',
      height,
      headerFixed = false,
      keyExtractor,
      currencySymbol = '$',
    } = this.props;

    return (
      <Table
        basic="very"
        stackable
        structured={false}
        className={customClass ? customClass : 'home-activity'}
      >
        {header ? (
          <Table.Header className={customHeaderClass} fullWidth>
            {parentHeaders ? (
              <>
                <Table.Row>
                  {map(parentHeaders, (column, i) => (
                    <Table.HeaderCell
                      key={`table_header_parent_header_cell_${i}`}
                      textAlign={column.textAlign ? column.textAlign : 'center'}
                      colSpan={column.colSpan ? column.colSpan : '1'}
                      className={column.className ? column.className : ''}
                    >
                      {column.title ? column.title : ''}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </>
            ) : null}
            <Table.Row className={headerFixed ? 'header-fixed' : ''}>
              {map(columns, (column, i) => (
                <Table.HeaderCell
                  key={`table_header_header_cell_${i}`}
                  textAlign={column.textAlign ? column.textAlign : 'left'}
                  className={column.className ? column.className : ''}
                  width={column.width ? column.width : null}
                >
                  {column.title ? column.title : ''}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
        ) : null}

        <Table.Body>
          {
            //set data here
            _.isEmpty(data) ? (
              <Table.Row className="empty-row">
                <Table.Cell colSpan={columns.length}>
                  <EmptyContainer
                    classes={`no-data ${height ? 'more-height' : ''}`}
                    msg={emptyMessage ? emptyMessage : 'No records found..'}
                  />
                </Table.Cell>
              </Table.Row>
            ) : (
              map(data, (item, i) => {
                if (isEmpty(item)) return null;
                const rowKey = keyExtractor && keyExtractor(item, i);

                const checkRow = this.state.expandedRows.find(
                  (r) => r.id === item.id
                );
                let isOpen = false;
                if (!isEmpty(checkRow)) {
                  isOpen = checkRow.isOpen;
                }

                return (
                  <Fragment key={`table_body_${rowKey || i}`}>
                    <Table.Row
                      key={`table_body_row${rowKey || i}`}
                      onClick={() => item.children && this.toggleRow(item.id)}
                    >
                      {map(columns, (column, c) => (
                        <Table.Cell
                          key={`table_body_row_cell_${c}`}
                          verticalAlign={
                            column.verticalAlign ? column.verticalAlign : 'top'
                          }
                          textAlign={
                            column.textAlign ? column.textAlign : 'left'
                          }
                          width={column.width ? column.width : null}
                        >
                          {c === 0 && !isEmpty(item.children) ? (
                            <div className="arrow-first-column">
                              <Icon
                                name={
                                  isOpen ? 'triangle down' : 'triangle right'
                                }
                              />
                              {column.render(item)}
                            </div>
                          ) : (
                            column.render(item)
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                    {/* data format should come with a objected called children
                  data = [
                      {
                        id: 1,
                        name: 'Parent 1',
                        children: [
                          { id: 2, name: 'Child 1' },
                          { id: 3, name: 'Child 2' },
                        ],
                      }
                  ] */}
                    {item.children &&
                      isOpen &&
                      item.children.map((child) => (
                        <Table.Row
                          key={`table_body_children_row_${rowKey || i}`}
                          className="sub-data-table"
                        >
                          {map(columns, (column, c) => (
                            <Table.Cell
                              key={`table_body_children_row_cell_${c}`}
                              verticalAlign={
                                column.verticalAlign
                                  ? column.verticalAlign
                                  : 'top'
                              }
                              textAlign={
                                column.textAlign ? column.textAlign : 'left'
                              }
                              width={column.width ? column.width : null}
                            >
                              {c === 0 ? (
                                <div className="sub-first-column">
                                  {column.renderSubColumn(child)}
                                </div>
                              ) : (
                                column.renderSubColumn(child)
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                  </Fragment>
                );
              })
            )
          }
        </Table.Body>
        {total !== null ? (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <b className="partner-total-column">{footerName}</b>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                <b className="partner-total-column">
                  {currencySymbol}
                  {parseFloat(total).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    useGrouping: true,
                  })}
                </b>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        ) : null}
        {pagination && !isEmpty(data) ? (
          <Table.Footer>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan={columns ? columns.length : 1}>
                <Pagination
                  className="table-pagination"
                  size="tiny"
                  activePage={page ? page : 1}
                  onPageChange={(e, { activePage }) =>
                    handlePaginationChange
                      ? handlePaginationChange(activePage)
                      : false
                  }
                  totalPages={noOfPages ? noOfPages : 1}
                  pointing
                  secondary
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  stackable
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        ) : null}
      </Table>
    );
  }
}

import axios from 'axios';
import {
  getIndustrySectorTypes,
  getOutcomes,
  getMethods,
  getOrgVenuesTypes,
  setMethods,
} from './extra';
import {
  getDepartmentsLists,
  getOrganization,
  getOrganizationGoals,
} from './organization';
import { cloneDeep, uniqBy, pick } from '../../utils/lodash';
import { getCommonError, authMsg } from '../../utils';
import {
  LIMIT,
  ORGANISATION_REPORT_LOADING,
  PROGRAM_REPORT_LOADING,
} from '../../utils/constant';
import { isParentProgramActivity } from '../../utils/activity';

/*** TYPES ***/
const REPORT_LOADING = 'REPORT_LOADING';

const GET_SYSTEM_OVERVIEW_SUMMARY = 'GET_SYSTEM_OVERVIEW_SUMMARY';
const GET_ACTIVITY_SUMMARY = 'GET_ACTIVITY_SUMMARY';
const GET_INPUT_OUTPUT_DATA = 'GET_INPUT_OUTPUT_DATA';
const GET_PARTICIPANT_RESPONCES = 'GET_PARTICIPANT_RESPONCES';
const GET_PARTICIPANT_RESPONCES_CHANGE = 'GET_PARTICIPANT_RESPONCES_CHANGE';
const GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST =
  'GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST';
const GET_PARTICIPANT_OUTCOMES = 'GET_PARTICIPANT_OUTCOMES';
const SET_MANAGEMENT_SUMMARY = 'SET_STRATEGIC_PLAN';
const GET_EXPORT_ORG_SUMMARY = 'GET_EXPORT_ORG_SUMMARY';
const SET_EXPORT_ORG_SUMMARY_LOADING = 'SET_EXPORT_ORG_SUMMARY_LOADING';
const GET_PROJECT_RESPONCES = 'GET_PROJECT_RESPONCES';
const PROJECT_REPORT_LOADING = 'PROJECT_REPORT_LOADING';
const SET_EVALUATION_PLAN_LIST = 'SET_EVALUATION_PLAN_LIST';
const SET_OUTCOME_SUMMARY_GOALS = 'SET_OUTCOME_SUMMARY_GOALS';
const SET_OUTCOME_SUMMARY_REFERENCES = 'SET_OUTCOME_SUMMARY_REFERENCES';
const GET_ACTIVITY_SUMMARY_VENUE = 'GET_ACTIVITY_SUMMARY_VENUE';

const GET_OUTCOME_SUMMARY_CHART = 'GET_OUTCOME_SUMMARY_CHART';
const MAP_DATA_LOADING = 'MAP_DATA_LOADING';
const CONTRACTOR_DATA = 'CONTRACTOR_DATA';
const MAP_DATA_ACTIVITIES_LOADING = 'MAP_DATA_ACTIVITIES_LOADING';
const ACTIVITIES_MAP_DATA = 'ACTIVITIES_MAP_DATA';
const MAP_DATA_ORGANISATION_LOADING = 'MAP_DATA_ORGANISATION_LOADING';
const ORGANISATION_MAP_DATA = 'ORGANISATION_MAP_DATA';

const PROJECT_CONTRACTOR_MAP_DATA = 'PROJECT_CONTRACTOR_MAP_DATA';
const PROJECT_VENUE_MAP_DATA = 'PROJECT_VENUE_MAP_DATA';
const PROJECT_ORGANIZATION_MAP_DATA = 'PROJECT_ORGANIZATION_MAP_DATA';

const REPORT_FILTERS = 'REPORT_FILTERS';
const ADVANCED_FILTER_LOADING = 'ADVANCED_FILTER_LOADING';

const GET_SUMMARY_PARTICIPANTS = 'GET_SUMMARY_PARTICIPANTS';
const ADVANCED_FILTER_TOGGLE = 'ADVANCED_FILTER_TOGGLE';
const SET_START_DATE_REPORT = 'SET_START_DATE_REPORT';
const SET_END_DATE_REPORT = 'SET_END_DATE_REPORT';
const GET_CROSS_REFERENCE = 'GET_CROSS_REFERENCE';
const GET_ACTIVITY_SUMMARY_PARTICIPANTS = 'GET_ACTIVITY_SUMMARY_PARTICIPANTS';
const GET_ACTIVITY_STATS = 'GET_ACTIVITY_STATS';
//report loading
//activity summary
const REPORT_ACTIVITY_SUMMARY_LOADING = 'REPORT_ACTIVITY_SUMMARY_LOADING';
const REPORT_ACTIVITY_STATS_LOADING = 'REPORT_ACTIVITY_STATS_LOADING';
const REPORT_ACTIVITY_VENUE_LOADING = 'REPORT_ACTIVITY_VENUE_LOADING';
const REPORT_ACTIVITY_PARTICIPANTS_LOADING =
  'REPORT_ACTIVITY_PARTICIPANTS_LOADING';
const REPORT_MAP_ORGANISATION_LOADING = 'REPORT_MAP_ORGANISATION_LOADING';
const REPORT_MAP_ACTIVITY_VENUES_LOADING = 'REPORT_MAP_ACTIVITY_VENUES_LOADING';
const REPORT_MAP_CONTRACTOR_LOADING = 'REPORT_MAP_CONTRACTOR_LOADING';
//outcome summary
const REPORT_OUTCOME_SUMMARY_LOADING = 'REPORT_OUTCOME_SUMMARY_LOADING';
//input
const REPORT_INPUT_OUTPUT_LOADING = 'REPORT_INPUT_OUTPUT_LOADING';
//management
const REPORT_MANAGEMENT_LOADING = 'REPORT_MANAGEMENT_LOADING';
//outcome reported
const REPORT_POST_PRE_LOADING = 'REPORT_POST_PRE_LOADING';
const REPORT_POST_LOADING = 'REPORT_POST_LOADING';
const REPORT_INDIVIDUAL_RATE_REPORT_LOADING =
  'REPORT_INDIVIDUAL_RATE_REPORT_LOADING';

const SET_OUTCOME_PAGE = 'SET_OUTCOME_PAGE';
/*** REDUCERS ***/

const initialState = {
  //activity report loading
  activityStatsLoading: false,
  activityReportLoading: {},
  venueReportLoading: {},
  participantReportLoading: {},
  mapLoading: {},
  orgMapLoading: {},
  actVenueMapLoading: {},
  contractorMapLoading: {},
  //end of activity report loading
  outcomeSummaryReportLoading: {},
  inputOutputReportLoading: {},
  managementSummaryLoading: {},

  //outcome reported
  postPreReportLoading: {},
  postReportLoading: {},
  individualRateReportLoading: {},

  systemOverviewSummary: {},
  actSummary: {},
  activityStats: {},
  reportLoading: {},
  inputOutputData: {},
  participants: [],
  participantsChange: [],
  participantsChangePrePost: [],
  loading: false,
  projectLoading: false,
  participantsOutcomes: [],
  managementSummaryReport: [],
  exportOrg: {},
  exportLoading: false,
  evaluationPlanList: {},
  outcomeSummaryGoals: [],
  outcomeSummaryReferences: [],
  venueChart: {},
  contractorMapData: {},
  mapDataLoading: false,
  actMapData: {},
  activitiesMapDataLoading: false,
  orgMapData: {},
  orgMapDataLoading: false,
  participantSummary: {},
  outcomeSummaryChart: [],
  projectContractorMapData: {},
  projectVenueMapData: {},
  projectOrganizationMapData: {},

  reportFilterOptions: {
    startDate: null,
    endDate: null,
    statuses: [], // activity status ids [1 - in development, 2 - active ,3 - completed]
    activityTypes: [],
    venues: [],
    participants: [],
    outcome: null,
    outcomes: [],
    method: 'all', // method use for only outcome report
    goals: [],
    crossReferences: [],
    departments: [],
    units: [],
    sort: null,
    order: 'asc',
    outcomeOnly: true,
    activityOutcomeOnly: true,
    venueOutcomeOnly: true,
    participantOutcomeOnly: true,
    outcomeAddressedOnly: true,
    page: 1,
    limit: LIMIT,
    organisationLevel: 'all',
    renderOutcomeChart: false,

    //subOptions
    subVenues: [],
  },

  advancedFilterLoading: {},
  advancedToggle: false,
  startDateReport: null,
  endDateReport: null,
  participantsData: {},
  isOutcomeReportedPage: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SYSTEM_OVERVIEW_SUMMARY:
      return {
        ...state,
        systemOverviewSummary: action.payload,
        participants: action.payload.participants,
        participantsChangePrePost: action.payload.participantsChangePrePost,
      };
    case GET_ACTIVITY_SUMMARY:
      return { ...state, actSummary: action.payload };
    case GET_ACTIVITY_STATS:
      return { ...state, activityStats: action.payload };

    case GET_INPUT_OUTPUT_DATA:
      return { ...state, inputOutputData: action.payload };
    case GET_PARTICIPANT_RESPONCES:
      return { ...state, participants: action.payload };
    case GET_PARTICIPANT_RESPONCES_CHANGE:
      return { ...state, participantsChange: action.payload };
    case GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST:
      return { ...state, participantsChangePrePost: action.payload };
    case GET_OUTCOME_SUMMARY_CHART:
      return { ...state, outcomeSummaryChart: action.payload };

    case REPORT_LOADING:
      return {
        ...state,
        reportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    //activity report loading
    case REPORT_ACTIVITY_STATS_LOADING:
      return {
        ...state,
        activityStatsLoading: {
          [action.payload.type]: action.payload.status,
        },
      };
    case REPORT_ACTIVITY_SUMMARY_LOADING:
      return {
        ...state,
        activityReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_ACTIVITY_VENUE_LOADING:
      return {
        ...state,
        venueReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_ACTIVITY_PARTICIPANTS_LOADING:
      return {
        ...state,
        participantReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    //map
    case REPORT_MAP_ORGANISATION_LOADING:
      return {
        ...state,
        orgMapLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_MAP_ACTIVITY_VENUES_LOADING:
      return {
        ...state,
        actVenueMapLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_MAP_CONTRACTOR_LOADING:
      return {
        ...state,
        contractorMapLoading: {
          [action.payload.type]: action.payload.status,
        },
      };
    //map end

    //end of activity loading
    case REPORT_OUTCOME_SUMMARY_LOADING:
      return {
        ...state,
        outcomeSummaryReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_INPUT_OUTPUT_LOADING:
      return {
        ...state,
        inputOutputReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };
    case REPORT_MANAGEMENT_LOADING:
      return {
        ...state,
        managementSummaryLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_POST_PRE_LOADING:
      return {
        ...state,
        postPreReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case REPORT_POST_LOADING:
      return {
        ...state,
        postReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };
    case REPORT_INDIVIDUAL_RATE_REPORT_LOADING:
      return {
        ...state,
        individualRateReportLoading: {
          [action.payload.type]: action.payload.status,
        },
      };

    case GET_PARTICIPANT_OUTCOMES:
      return { ...state, participantsOutcomes: action.payload };
    case SET_MANAGEMENT_SUMMARY:
      return { ...state, managementSummaryReport: action.payload };
    case GET_EXPORT_ORG_SUMMARY:
      return { ...state, exportOrg: action.payload };
    case SET_EXPORT_ORG_SUMMARY_LOADING:
      return { ...state, exportLoading: action.payload };
    case PROJECT_REPORT_LOADING:
      return { ...state, projectLoading: action.payload };
    case SET_EVALUATION_PLAN_LIST:
      return { ...state, evaluationPlanList: action.payload };
    case GET_PROJECT_RESPONCES:
      return {
        ...state,
        participants: action.payload.participantResponses,
        participantsChange: action.payload.participantResponsesChange,
        participantsChangePrePost:
          action.payload.participantResponsesChangePostPre,
      };
    case SET_OUTCOME_SUMMARY_GOALS:
      return { ...state, outcomeSummaryGoals: action.payload };
    case SET_OUTCOME_SUMMARY_REFERENCES:
      return { ...state, outcomeSummaryReferences: action.payload };
    case GET_ACTIVITY_SUMMARY_VENUE:
      return { ...state, venueChart: action.payload };
    case MAP_DATA_LOADING:
      return { ...state, mapDataLoading: action.payload };
    case CONTRACTOR_DATA:
      return { ...state, contractorMapData: action.payload };
    case MAP_DATA_ACTIVITIES_LOADING:
      return { ...state, activitiesMapDataLoading: action.payload };
    case ACTIVITIES_MAP_DATA:
      return { ...state, actMapData: action.payload };
    case MAP_DATA_ORGANISATION_LOADING:
      return { ...state, orgMapDataLoading: action.payload };
    case ORGANISATION_MAP_DATA:
      return { ...state, orgMapData: action.payload };
    case GET_SUMMARY_PARTICIPANTS:
      return { ...state, participantSummary: action.payload };

    case PROJECT_CONTRACTOR_MAP_DATA:
      return { ...state, projectContractorMapData: action.payload };
    case PROJECT_VENUE_MAP_DATA:
      return { ...state, projectVenueMapData: action.payload };
    case PROJECT_ORGANIZATION_MAP_DATA:
      return { ...state, projectOrganizationMapData: action.payload };

    case REPORT_FILTERS:
      return { ...state, reportFilterOptions: action.payload };

    case ADVANCED_FILTER_LOADING:
      return { ...state, advancedFilterLoading: action.payload };
    case ADVANCED_FILTER_TOGGLE:
      return { ...state, advancedToggle: action.payload };

    case SET_START_DATE_REPORT:
      return { ...state, startDateReport: action.payload };
    case SET_END_DATE_REPORT:
      return { ...state, endDateReport: action.payload };
    case GET_CROSS_REFERENCE:
      return { ...state, filterCrossReferences: action.payload };
    case GET_ACTIVITY_SUMMARY_PARTICIPANTS:
      return { ...state, participantsData: action.payload };
    case SET_OUTCOME_PAGE:
      return { ...state, isOutcomeReportedPage: action.payload };

    default:
      return state;
  }
}

export function setOutcomePage(isSelected) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_OUTCOME_PAGE, payload: isSelected });
  };
}

/*
 * ----------------------------- Advanced Filter Utils -----------------------------------
 */
export function advancedFilterOpen(status) {
  return async (dispatch, getState) => {
    dispatch({ type: ADVANCED_FILTER_TOGGLE, payload: status });
  };
}

export function advancedFilterAllReset(
  reportType,
  isProgramReport = false,
  projectId = ''
) {
  return async (dispatch, getState) => {
    let { reportFilterOptions } = getState().report;
    reportFilterOptions.organisationLevel = 'all';
    reportFilterOptions.statuses = [];
    reportFilterOptions.activityTypes = [];
    reportFilterOptions.venues = [];
    reportFilterOptions.subVenues = [];
    reportFilterOptions.participants = [];
    reportFilterOptions.outcomes = [];
    reportFilterOptions.methods = [];
    reportFilterOptions.goals = [];
    reportFilterOptions.crossReferences = [];
    reportFilterOptions.departments = [];
    reportFilterOptions.units = [];
    reportFilterOptions.activityOutcomeOnly = true;
    reportFilterOptions.venueOutcomeOnly = true;
    reportFilterOptions.participantOutcomeOnly = true;
    // if this advanced filter come from program
    if (isProgramReport) {
      await dispatch(
        getProgrammeReportDataByActivityType(
          reportType,
          reportFilterOptions,
          projectId
        )
      );
    } else {
      await dispatch(
        getReportDataByActivityType(reportType, reportFilterOptions)
      );
    }
    await dispatch({
      type: REPORT_FILTERS,
      payload: reportFilterOptions,
    });
  };
}

export function setAdvancedFilterValue(type, value) {
  return async (dispatch, getState) => {
    let { reportFilterOptions } = getState().report;

    reportFilterOptions = { ...reportFilterOptions, [type]: value };

    await dispatch({
      type: REPORT_FILTERS,
      payload: reportFilterOptions,
    });
  };
}

export function advancedFilterReset(
  reportType,
  filedName,
  isProgramReport = false,
  projectId = ''
) {
  return async (dispatch, getState) => {
    let { reportFilterOptions } = getState().report;
    switch (filedName) {
      case 'organisationLevel':
        reportFilterOptions.organisationLevel = 'all';
        break;
      case 'statuses':
        reportFilterOptions.statuses = [];
        break;
      case 'activityTypes':
        reportFilterOptions.activityTypes = [];
        break;
      case 'venues':
        reportFilterOptions.venues = [];
        reportFilterOptions.subVenues = [];
        break;
      case 'participants':
        reportFilterOptions.participants = [];
        break;
      case 'outcomes':
        reportFilterOptions.outcomes = [];
        break;
      case 'methods':
        reportFilterOptions.methods = [];
        break;
      case 'outcome':
        reportFilterOptions.outcome = null;
        reportFilterOptions.method = 'all';
        break;
      case 'goals':
        reportFilterOptions.goals = [];
        break;
      case 'crossReferences':
        reportFilterOptions.crossReferences = [];
        break;
      case 'departments':
        reportFilterOptions.departments = [];
        break;
      case 'units':
        reportFilterOptions.units = [];
        break;
      default:
        reportFilterOptions.organisationLevel = [];
        reportFilterOptions.statuses = [];
        reportFilterOptions.activityTypes = [];
        reportFilterOptions.venues = [];
        reportFilterOptions.subVenues = [];
        reportFilterOptions.participants = [];
        reportFilterOptions.outcomes = [];
        reportFilterOptions.methods = [];
        reportFilterOptions.goals = [];
        reportFilterOptions.crossReferences = [];
        reportFilterOptions.departments = [];
        reportFilterOptions.units = [];
        reportFilterOptions.activityOutcomeOnly = true;
        reportFilterOptions.venueOutcomeOnly = true;
        reportFilterOptions.participantOutcomeOnly = true;
        reportFilterOptions.outcomeOnly = true;
        reportFilterOptions.page = 1;
    }

    // reset all dataset when there is no outcome selected for outcomes reported
    if (filedName === 'outcome' && reportType === 'outcomes-reported') {
      Promise.all([
        dispatch({
          type: GET_PARTICIPANT_RESPONCES,
          payload: [],
        }),
        dispatch({
          type: GET_PARTICIPANT_RESPONCES_CHANGE,
          payload: [],
        }),
        dispatch({
          type: GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST,
          payload: [],
        }),
      ]);
    } else {
      if (isProgramReport) {
        await dispatch(
          getProgrammeReportDataByActivityType(
            reportType,
            reportFilterOptions,
            projectId
          )
        );
      } else {
        await dispatch(
          getReportDataByActivityType(reportType, reportFilterOptions)
        );
      }
    }

    await dispatch({
      type: REPORT_FILTERS,
      payload: reportFilterOptions,
    });
  };
}

/*
 * ----------------------------- Report API Requests -----------------------------------
 */
export function getContractorMapData(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_CONTRACTOR_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(`/organisation/contractors-map`, {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: CONTRACTOR_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_MAP_CONTRACTOR_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_CONTRACTOR_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivitiesMapData(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(`/organisation/activities-map`, {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: ACTIVITIES_MAP_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganisationMapData(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_ORGANISATION_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.ORGANISATION_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(`/organisation/locations-map`, {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: ORGANISATION_MAP_DATA,
        payload: response.data,
      });

      dispatch({
        type: REPORT_MAP_ORGANISATION_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ORGANISATION_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_ORGANISATION_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ORGANISATION_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllReportData() {
  return (dispatch) =>
    Promise.all([
      dispatch(getOutcomes()),
      dispatch(getMethods()),
      dispatch(getIndustrySectorTypes()),
      dispatch(getOrganization()),
    ]);
}

export function getAllAdvancedFilterRelatedApi(orgId) {
  return (dispatch) =>
    Promise.all([
      dispatch(getOrgVenuesTypes()),
      dispatch(getOrganizationGoals()),
      dispatch(getDepartmentsLists()),
    ]);
}

export function getAllActivitySummary(filter) {
  return async (dispatch, getState) => {
    const promises = [
      dispatch(getActivityStats(filter)),
      dispatch(getActivitySummary(filter)),
      dispatch(getActivitySummaryVenues(filter)),
      dispatch(getActivitySummaryParticipants(filter)),
      dispatch(getActivitiesMapData(filter)),
      dispatch(getOrganisationMapData(filter)),
      dispatch(getContractorMapData(filter)),
    ];
    return Promise.all(promises);
  };
}

export function getAllProjectActivitySummary(id, filter) {
  return (dispatch) =>
    Promise.all([
      dispatch(getProjectActivityStats(id, filter)),
      dispatch(getProjectActivitySummary(id, filter)),
      dispatch(getProjectActivitySummaryVenues(id, filter)),
      dispatch(getProjectActivitySummaryParticipants(id, filter)),
      //map
      dispatch(getProjectOrgMapData(id, filter)),
      dispatch(getProjectVenueMapData(id, filter)),
      dispatch(getProjectContractorMapData(id, filter)),
    ]);
}

export function getProjectActivityStats(projectId, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_STATS_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.ACTIVITY_STATS_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${projectId}/report/activity-stats`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_STATS,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_STATS_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_STATS_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_STATS_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_STATS_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectActivitySummary(projectId, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_SUMMARY_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${projectId}/report/activity-summary`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_SUMMARY,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_SUMMARY_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_SUMMARY_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectActivitySummaryVenues(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_VENUE_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.VENUE_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${id}/report/activity-summary-venues`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_SUMMARY_VENUE,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_VENUE_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.VENUE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_VENUE_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.VENUE_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectActivitySummaryParticipants(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${id}/report/activity-summary-participants`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_SUMMARY_PARTICIPANTS,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectOrgMapData(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_ORGANISATION_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.ORGANISATION_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${id}/report/linked-activity/owner-organisation-location`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
          },
        }
      );
      dispatch({
        type: PROJECT_ORGANIZATION_MAP_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_MAP_ORGANISATION_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ORGANISATION_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_ORGANISATION_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ORGANISATION_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectVenueMapData(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(`/project/${id}/report/venues-map`, {
        params: {
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: PROJECT_VENUE_MAP_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_ACTIVITY_VENUES_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.ACTIVITY_VENUE_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectContractorMapData(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_MAP_CONTRACTOR_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/project/${id}/report/contractors-map`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: PROJECT_CONTRACTOR_MAP_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_MAP_CONTRACTOR_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MAP_CONTRACTOR_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.CONTRACTOR_MAP_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivitySummaryVenues(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_VENUE_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.VENUE_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/organisation/activity-summary-venues/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.venueOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_SUMMARY_VENUE,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_VENUE_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.VENUE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_VENUE_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.VENUE_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivityStats(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_STATS_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.ACTIVITY_STATS_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/organisation/report/activities-stats`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_STATS,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_STATS_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_STATS_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_STATS_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_STATS_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivitySummary(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_SUMMARY_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/organisation/activity-summary/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.activityOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),

            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_ACTIVITY_SUMMARY,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_SUMMARY_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_SUMMARY_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.ACTIVITY_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getActivitySummaryParticipants(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/organisation/activity-summary-participants/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            outcomeOnly: JSON.stringify(params.participantOutcomeOnly),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_SUMMARY_PARTICIPANTS,
        payload: response.data,
      });
      dispatch({
        type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_ACTIVITY_PARTICIPANTS_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.PARTICIPANT_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getInputOutputDataReport(params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_INPUT_OUTPUT_LOADING,
      payload: {
        type: ORGANISATION_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(
        `/organisation/input-output-data/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcomes: JSON.stringify(params.outcomes),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_INPUT_OUTPUT_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_INPUT_OUTPUT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_INPUT_OUTPUT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllPrePostChartData(filters) {
  return (dispatch) => {
    try {
      // individual chart does not have self assessed and intercept
      if (filters.method === 12) {
        dispatch(getPostPreResults(filters));
      } else {
        Promise.all([
          dispatch(getIndividualRateResults(filters)),
          dispatch(getPostPreResults(filters)),
        ]);
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getPostDataResults(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(`/organisation/post-results/report`, {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcome: JSON.stringify(params.outcome),
          method: JSON.stringify(params.method),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: GET_PARTICIPANT_RESPONCES,
        payload: response.data,
      });
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getIndividualRateResults(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(
        `/organisation/individual-rate-change/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcome: JSON.stringify(params.outcome),
            method: JSON.stringify(params.method),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE,
        payload: response.data,
      });
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getPostPreResults(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(
        `/organisation/post-pre-results/report`,
        {
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcome: JSON.stringify(params.outcome),
            method: JSON.stringify(params.method),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST,
        payload: response.data,
      });
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: false,
        },
      });
    }
  };
}

export function getManagementSummaryReport(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_MANAGEMENT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.MANAGEMENT_SUMMARY_LOADING,
          status: true,
        },
      });
      const updatedParams = {
        startDate: params.startDate,
        endDate: params.endDate,
        statuses: JSON.stringify(params.statuses),
        activityTypes: JSON.stringify(params.activityTypes),
        venues: JSON.stringify(params.venues),
        participants: JSON.stringify(params.participants),
        outcomes: JSON.stringify(params.outcomes),
        goals: JSON.stringify(params.goals),
        crossReferences: JSON.stringify(params.crossReferences),
        departments: JSON.stringify(params.departments),
        organisationLevel: JSON.stringify(params.organisationLevel),

        units: JSON.stringify(params.units),
        order: params.order,
        limit: LIMIT,
        page: params.page,
        //subOptions
        subVenues: JSON.stringify(params.subVenues),
      };
      if (params.sort !== null) {
        updatedParams.sort = JSON.stringify(params.sort);
      }
      const response = await axios.get(
        `/organisation/report/management-summary`,
        {
          params: updatedParams,
        }
      );
      dispatch({ type: SET_MANAGEMENT_SUMMARY, payload: response.data });
      dispatch({
        type: REPORT_MANAGEMENT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.MANAGEMENT_SUMMARY_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_MANAGEMENT_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.MANAGEMENT_SUMMARY_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function strategicPlanExport() {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: true });

        let { reportFilterOptions: params } = getState().report;

        const formattedParams = {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          organisationLevel: JSON.stringify(params.organisationLevel),
          units: JSON.stringify(params.units),
          order: params.order,
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        };

        const response = await axios.get(
          `/organisation/management-summary/export`,
          {
            params: formattedParams,
          }
        );

        dispatch({
          type: GET_EXPORT_ORG_SUMMARY,
          payload: response.data,
        });

        dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: false });

        if (response && response.data) {
          resolve(response.data);
        }
      } catch (error) {
        dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: false });
        authMsg('error', getCommonError(error));
      }
    });
  };
}

/** Parent-child calls */
export function getParentActivitySummary(parentId) {
  return async (dispatch, getState) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      if (isParentProgramActivity(currentActivity.activityPlanTypeId)) {
        const response = await axios.get(
          `/project/${parentId}/activity-summary/report`
        );
        dispatch({
          type: GET_ACTIVITY_SUMMARY,
          payload: response.data,
        });
      }
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

// export function getParentHeadlineData(parentId) {
//   return async (dispatch, getState) => {
//     dispatch({ type: REPORT_LOADING, payload: true });
//     try {
//       const state = getState();
//       const currentActivity = cloneDeep(state.activity.currentActivity);
//       if (isParentProgramActivity(currentActivity.activityPlanTypeId)) {
//         const response = await axios.get(
//           `/project/${parentId}/headline/report`
//         );
//         dispatch({
//           type: GET_INPUT_OUTPUT_DATA,
//           payload: response.data,
//         });
//       }
//       dispatch({ type: REPORT_LOADING, payload: false });
//     } catch (error) {
//       dispatch({ type: REPORT_LOADING, payload: false });
//       authMsg('error', getCommonError(error));
//     }
//   };
// }

export function getAllParentChartPreviewData(parentId) {
  return (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    Promise.all([
      dispatch(getParentParticipantResponces(parentId)),
      dispatch(getParentParticipantResponcesChangePrePost(parentId)),
    ])
      .then(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      })
      .catch(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      });
  };
}

export function getAllParentChartData(parentId) {
  return (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    Promise.all([
      dispatch(
        getParentParticipantResponces(parentId, {
          preview: false,
        })
      ),
      dispatch(getParentParticipantResponcesChange(parentId)),
      dispatch(
        getParentParticipantResponcesChangePrePost(parentId, {
          preview: false,
        })
      ),
    ])
      .then(() => {
        dispatch(getOutcomeSummary());
      })
      .then(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      })
      .catch(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      });
  };
}

export function getParentParticipantResponces(parentId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/project/${parentId}/participant-responses/report`
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getParentParticipantResponcesChange(parentId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/project/${parentId}/participant-responses-change/report`
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getParentParticipantResponcesChangePrePost(parentId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/project/${parentId}/participant-responses-change-pre-post/report`
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

/** Super admin report calls */

export function adminAllReportData() {
  return (dispatch) =>
    Promise.all([dispatch(getOutcomes()), dispatch(getMethods())]);
}

export function adminActivitySummary(startDate, endDate, activityType) {
  return async (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    try {
      const response = await axios.get(`/activity-summary/report`, {
        params: { startDate, endDate, activityType },
      });
      dispatch({
        type: GET_ACTIVITY_SUMMARY,
        payload: response.data,
      });
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function adminHeadlineData(startDate, endDate, calendarType) {
  return async (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    try {
      const response = await axios.get(`/headline/report`, {
        params: { startDate, endDate, calendarType },
      });
      dispatch({
        type: GET_INPUT_OUTPUT_DATA,
        payload: response.data,
      });
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('Error', getCommonError(error));
    }
  };
}

export function adminAllChartData(
  startDate,
  endDate,
  activityType,
  goalType,
  referenceType,
  outcomeType
) {
  return (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    Promise.all([
      dispatch(
        adminParticipantResponces(
          startDate,
          endDate,
          activityType,
          goalType,
          referenceType
        )
      ),
      dispatch(
        adminParticipantResponcesChange(
          startDate,
          endDate,
          activityType,
          goalType,
          referenceType
        )
      ),
      dispatch(
        adminParticipantResponcesChangePrePost(
          startDate,
          endDate,
          activityType,
          goalType,
          referenceType
        )
      ),
    ])
      .then(() => {
        !outcomeType && dispatch(getOutcomeSummary());
      })
      .then(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      })
      .catch(() => {
        dispatch({ type: REPORT_LOADING, payload: false });
      });
  };
}

export function adminParticipantResponces(
  startDate,
  endDate,
  activityType,
  goalType,
  referenceType
) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/participant-responses/report`, {
        params: { startDate, endDate, activityType, goalType, referenceType },
      });
      dispatch({
        type: GET_PARTICIPANT_RESPONCES,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function adminParticipantResponcesChange(
  startDate,
  endDate,
  activityType,
  goalType,
  referenceType
) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/participant-responses-change/report`, {
        params: { startDate, endDate, activityType, goalType, referenceType },
      });
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function adminParticipantResponcesChangePrePost(
  startDate,
  endDate,
  activityType,
  goalType,
  referenceType
) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/participant-responses-change-pre-post/report`,
        {
          params: { startDate, endDate, activityType, goalType, referenceType },
        }
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function adminManagementSummaryReport(
  startDate,
  endDate,
  status,
  sort,
  order
) {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_LOADING, payload: true });
      const response = await axios.get(`/management-summary/report`, {
        params: { startDate, endDate, status, sort, order },
      });
      dispatch({ type: SET_MANAGEMENT_SUMMARY, payload: response.data });
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function adminStrategicPlanExport(
  startDate,
  endDate,
  status,
  sort,
  order
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: true });
      const response = await axios.get(`/management-summary/export`, {
        params: { startDate, endDate, status, sort, order },
      });
      dispatch({
        type: GET_EXPORT_ORG_SUMMARY,
        payload: response.data,
      });
      dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_EXPORT_ORG_SUMMARY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

/** Helpers */
export function getOutcomeSummary() {
  return async (dispatch, getState) => {
    try {
      let { participants, participantsChange, participantsChangePrePost } =
        getState().report;
      participants = participants.map((value) => value.name);
      participantsChange = participantsChange.map((value) => value.name);
      participantsChangePrePost = participantsChangePrePost.map(
        (value) => value.name
      );
      const outcomes = uniqBy([
        ...participants,
        ...participantsChange,
        ...participantsChangePrePost,
      ]);
      dispatch({
        type: GET_PARTICIPANT_OUTCOMES,
        payload: outcomes,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectOutcomeSummary(projectId, isSeries = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: PROJECT_REPORT_LOADING, payload: true });

      const response = await axios.get(
        `/project/${projectId}/outcome-summary`,
        {
          params: { isSeries },
        }
      );
      dispatch({
        type: GET_PROJECT_RESPONCES,
        payload: response.data,
      });
      dispatch({ type: PROJECT_REPORT_LOADING, payload: false });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({ type: PROJECT_REPORT_LOADING, payload: false });
    }
  };
}

export function adminSystemOverview(
  startDate,
  endDate,
  outcome = 'all',
  sectorTypeId = 'all',
  activityType
) {
  return async (dispatch) => {
    dispatch({ type: REPORT_LOADING, payload: true });
    let params = { startDate, endDate };
    if (sectorTypeId !== 'all') {
      params.sectorTypeId = sectorTypeId;
    }
    if (outcome !== 'all') {
      params.outcome = outcome;
    }
    params.activityType = activityType;

    try {
      const response = await axios.get(`/system-overview/report`, {
        params,
      });
      dispatch({
        type: GET_SYSTEM_OVERVIEW_SUMMARY,
        payload: response.data,
      });
      dispatch(getOutcomeSummary());
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

/**
 * @param  {} startDate
 * @param  {} endDate
 * @param  {} activityType
 * @description get all goal and references in outcome summary chart (system reports)
 */
export function getOutcomeSummaryGoals(
  startDate,
  endDate,
  activityType,
  outcomeType,
  organisationId
) {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_LOADING, payload: true });
      let params = {
        startDate,
        endDate,
        activityType,
        outcomeType,
        organisationId,
      };

      const response = await axios.get(`/outcome-summary/goals`, {
        params,
      });

      dispatch({
        type: SET_OUTCOME_SUMMARY_GOALS,
        payload: response.data,
      });
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

/**
 * @param  {} startDate
 * @param  {} endDate
 * @param  {} activityType
 * @param  {} outcomeType
 * @param  {} goalType
 * @description get all goal and references in outcome summary chart (system reports)
 */
export function getOutcomeSummaryReferences(
  startDate,
  endDate,
  activityType,
  outcomeType,
  goalType,
  organisationId
) {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_LOADING, payload: true });
      const params = {
        startDate,
        endDate,
        activityType,
        outcomeType,
        goalType,
        organisationId,
      };

      if (goalType === 'all') {
        dispatch({
          type: SET_OUTCOME_SUMMARY_REFERENCES,
          payload: [],
        });
      } else {
        const response = await axios.get(`/outcome-summary/cross-references`, {
          params,
        });

        dispatch({
          type: SET_OUTCOME_SUMMARY_REFERENCES,
          payload: response.data,
        });
      }

      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REPORT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

/* get organisation report data for organisation reports */
export function getOrganizationReportData(reportType, filterObj = []) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADVANCED_FILTER_LOADING, payload: true });

      let { reportFilterOptions } = getState().report;

      const reportFilterPayload = {
        ...reportFilterOptions,
        ...filterObj,
      };

      if (reportType !== 'outcomes-reported') {
        const methods = getState().extra.methods;
        reportFilterPayload.method = 'all';
        dispatch(setMethods(methods));
      }

      Promise.all([
        dispatch(getReportDataByActivityType(reportType, reportFilterPayload)),
        dispatch({
          type: REPORT_FILTERS,
          payload: reportFilterPayload,
        }),
      ]);

      dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getReportDataByActivityType(reportType, reportFilter) {
  return async (dispatch, getState) => {
    try {
      const { outcomes } = getState().extra;
      switch (reportType) {
        case 'activities':
          dispatch(getAllActivitySummary(reportFilter));
          break;
        case 'input-output-data':
          dispatch(getInputOutputDataReport(reportFilter));
          break;

        case 'management-summary':
          dispatch(getManagementSummaryReport(reportFilter));
          break;

        case 'outcomes-addressed':
          dispatch(getOutcomeSummeryChart(reportFilter));
          break;
        case 'outcomes-reported':
          if (reportFilter.outcome !== null) {
            const item = outcomes.find(
              (item) => item.id === reportFilter.outcome
            );

            if (item && item.prePost === 0) {
              dispatch(getPostDataResults(reportFilter));
            } else {
              dispatch(getAllPrePostChartData(reportFilter));
            }
          }
          break;
        default:
          dispatch(getAllActivitySummary(reportFilter));
          break;
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function setOutcomeReportData(
  selectedOutcome,
  reportType,
  renderOutcomeChart
) {
  return async (dispatch, getState) => {
    try {
      const { outcomes } = getState().extra;
      let { reportFilterOptions } = getState().report;

      for (const item of outcomes) {
        if (item.title === selectedOutcome) {
          const outcomeId = { outcome: item.id };

          const updatedReportFilter = {
            ...reportFilterOptions,
            ...outcomeId,
            ...renderOutcomeChart,
          };

          if (reportType !== 'outcomes-reported') {
            const methods = getState().extra.methods;
            updatedReportFilter.method = 'all';
            dispatch(setMethods(methods));
          }

          if (item && item.prePost === 0) {
            dispatch(getPostDataResults(updatedReportFilter));
          } else {
            dispatch(getAllPrePostChartData(updatedReportFilter));
          }

          dispatch({
            type: REPORT_FILTERS,
            payload: updatedReportFilter,
          });
        }
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProgrammeReportData(reportType, id, filterObj = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADVANCED_FILTER_LOADING, payload: true });

      let { reportFilterOptions } = getState().report;

      const reportFilterPayload = {
        ...reportFilterOptions,
        ...filterObj,
      };

      if (reportType !== 'outcomes-reported') {
        const methods = getState().extra.methods;
        reportFilterPayload.method = 'all';
        dispatch(setMethods(methods));
      }

      await Promise.all([
        dispatch(
          getProgrammeReportDataByActivityType(
            reportType,
            reportFilterPayload,
            id
          )
        ),
        dispatch({
          type: REPORT_FILTERS,
          payload: reportFilterPayload,
        }),
      ]);

      dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function setProgrammeOutcomeData(reportType, selectedOutcome) {
  return async (dispatch, getState) => {
    try {
      const { outcomes } = getState().extra;
      for (const item of outcomes) {
        if (item.title === selectedOutcome) {
          const filterObj = {
            outcome: item.id,
            page: 1,
          };

          dispatch({ type: ADVANCED_FILTER_LOADING, payload: true });

          let { reportFilterOptions } = getState().report;
          const reportFilterPayload = {
            ...reportFilterOptions,
            ...filterObj,
          };
          if (reportType !== 'outcomes-reported') {
            const methods = getState().extra.methods;
            reportFilterPayload.method = 'all';
            dispatch(setMethods(methods));
          }
          dispatch({
            type: REPORT_FILTERS,
            payload: reportFilterPayload,
          });
          dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
        }
      }
    } catch (error) {
      dispatch({ type: ADVANCED_FILTER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProgrammeReportDataByActivityType(
  reportType,
  reportFilter,
  id
) {
  return async (dispatch, getState) => {
    try {
      const { outcomes } = getState().extra;
      let filters = pick(reportFilter, ['startDate', 'endDate']);
      switch (reportType) {
        case 'activities':
          dispatch(getAllProjectActivitySummary(id, reportFilter));
          break;
        case 'outcomes-addressed':
          dispatch(getProjectOutcomeAddressed(id, reportFilter));
          break;
        case 'input-output-data':
          dispatch(getProjectInputOutputDataReport(id, reportFilter));
          break;
        case 'outcomes-reported':
          if (reportFilter.outcome !== null) {
            const item = outcomes.find(
              (item) => item.id === reportFilter.outcome
            );
            if (item && item.prePost === 0) {
              dispatch(getProjectPostDataResults(id, reportFilter));
            } else {
              dispatch(getProjectAllPrePostChartData(id, reportFilter));
            }
          }
          break;

        default:
          dispatch(getAllActivitySummary(filters));
          break;
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectInputOutputDataReport(id, params) {
  return async (dispatch) => {
    dispatch({
      type: REPORT_INPUT_OUTPUT_LOADING,
      payload: {
        type: PROGRAM_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
        status: true,
      },
    });
    try {
      const response = await axios.get(`/project/${id}/report/input-output`, {
        params: {
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcomes: JSON.stringify(params.outcomes),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: GET_INPUT_OUTPUT_DATA,
        payload: response.data,
      });
      dispatch({
        type: REPORT_INPUT_OUTPUT_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_INPUT_OUTPUT_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.INPUT_OUTPUT_SUMMARY_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectAllPrePostChartData(id, filters) {
  return (dispatch) => {
    try {
      // individual chart does not have self assessed and intercept
      if (filters.method === 12) {
        dispatch(getProjectPostPreResults(id, filters));
      } else {
        Promise.all([
          dispatch(getProjectIndividualRateResults(id, filters)),
          dispatch(getProjectPostPreResults(id, filters)),
        ]);
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectIndividualRateResults(id, params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(
        `/project/${id}/report/individual-rate-change`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            outcome: JSON.stringify(params.outcome),
            method: JSON.stringify(params.method),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE,
        payload: response.data,
      });
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_INDIVIDUAL_RATE_REPORT_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectPostPreResults(id, params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(`/project/${id}/report/post-pre`, {
        params: {
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcome: JSON.stringify(params.outcome),
          method: JSON.stringify(params.method),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: GET_PARTICIPANT_RESPONCES_CHANGE_PRE_POST,
        payload: response.data,
      });
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_POST_PRE_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_PRE_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectPostDataResults(id, params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_REPORT_LOADING,
          status: true,
        },
      });
      const response = await axios.get(`/project/${id}/report/post-results`, {
        params: {
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          outcome: JSON.stringify(params.outcome),
          method: JSON.stringify(params.method),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: GET_PARTICIPANT_RESPONCES,
        payload: response.data,
      });
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_REPORT_LOADING,
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: REPORT_POST_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.POST_REPORT_LOADING,
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectOutcomeAddressed(id, params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: true,
        },
      });
      const response = await axios.get(
        `/project/${id}/report/outcome-addressed`,
        {
          params: {
            statuses: JSON.stringify(params.statuses),
            activityTypes: JSON.stringify(params.activityTypes),
            outcomes: JSON.stringify(params.outcomes),
            venues: JSON.stringify(params.venues),
            participants: JSON.stringify(params.participants),
            goals: JSON.stringify(params.goals),
            crossReferences: JSON.stringify(params.crossReferences),
            departments: JSON.stringify(params.departments),
            units: JSON.stringify(params.units),
            organisationLevel: JSON.stringify(params.organisationLevel),
            outcomeOnly: JSON.stringify(params.outcomeOnly),
            //subOptions
            subVenues: JSON.stringify(params.subVenues),
          },
        }
      );
      dispatch({
        type: GET_OUTCOME_SUMMARY_CHART,
        payload: response.data,
      });
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: false,
        },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: PROGRAM_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: false,
        },
      });
    }
  };
}

export function getOutcomeSummeryChart(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: true,
        },
      });

      const response = await axios.get(`/organisation/outcome/report`, {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          statuses: JSON.stringify(params.statuses),
          activityTypes: JSON.stringify(params.activityTypes),
          outcomes: JSON.stringify(params.outcomes),
          venues: JSON.stringify(params.venues),
          participants: JSON.stringify(params.participants),
          goals: JSON.stringify(params.goals),
          crossReferences: JSON.stringify(params.crossReferences),
          departments: JSON.stringify(params.departments),
          units: JSON.stringify(params.units),
          outcomeOnly: JSON.stringify(params.outcomeAddressedOnly),
          organisationLevel: JSON.stringify(params.organisationLevel),
          //subOptions
          subVenues: JSON.stringify(params.subVenues),
        },
      });
      dispatch({
        type: GET_OUTCOME_SUMMARY_CHART,
        payload: response.data,
      });
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: false,
        },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: REPORT_OUTCOME_SUMMARY_LOADING,
        payload: {
          type: ORGANISATION_REPORT_LOADING.OUTCOME_ADDRESS_LOADING,
          status: false,
        },
      });
    }
  };
}

export function getCrossReferenceByGoal(goals) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/organisation/report/cross-references-by-goals`,
        { params: { goals: JSON.stringify(goals) } }
      );
      dispatch({ type: GET_CROSS_REFERENCE, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

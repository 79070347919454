import './activity.scss';

import React from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Loader, Segment, Button, Modal, Icon } from 'semantic-ui-react';

import { CustomSegment, CustomTooltip } from '../../../components';
import { filter, get, isEmpty, map, find } from '../../../utils/lodash';
import { putActivity } from '../../../store/actions';
import { getPageDisable, getActivityTypeToc } from '../../../utils';
import { ActivityParentGoalAndObjective } from './selectedGAndO';

class ActivityParentTheoryCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        projectTocKnown: props.currentActivity
          ? props.currentActivity.projectTocKnown
          : '',
        projectTocDo: props.currentActivity
          ? props.currentActivity.projectTocDo
          : '',
      },
      openPreviewModal: false,
    };
  }

  onChange(value, type) {
    let { form } = this.state;
    form[type] = value;
    this.setState({ form });
  }

  onBlurInput(type) {
    const { putActivity } = this.props;
    const { form } = this.state;
    try {
      putActivity('activityData', { [type]: form[type] });
    } catch (error) {
      NotificationManager.error('Something went wrong');
    }
  }

  openPreviewModal() {
    this.setState((prevState) => ({
      openPreviewModal: !prevState.openPreviewModal,
    }));
  }

  getWeWillText(defaultValues, currentActivity) {
    let weWillText = '';
    if (isEmpty(defaultValues.projectTocDo)) {
      weWillText = (
        <span className="toc-knowing">
          [Describe how this {getActivityTypeToc(currentActivity)} program will
          be structured to achieve the objectives]
        </span>
      );
    } else {
      weWillText = defaultValues.projectTocDo;
    }
    return weWillText;
  }

  render() {
    let {
      currentActivity,
      user,
      helpTips,
      KnowingTipKey,
      weWillTipKey,
      currentState,
      extra,
    } = this.props;

    let objectives = [];
    if (extra.outcomes) {
      objectives = map(currentActivity.parentOutcomes, (outcome) => {
        outcome.main = find(extra.outcomes, (o) => {
          return o.id === outcome.outcomeId;
        });
        return outcome;
      });
    }

    const defaultValues = {
      projectTocKnown: currentActivity.projectTocKnown,
      projectTocDo: currentActivity.projectTocDo,
    };

    let domains = [];
    domains = filter(
      currentActivity.parentDomains,
      (i) => currentActivity.primaryPolicyId !== i.policy.id
    );
    if (!isEmpty(domains)) domains = map(domains, (d) => d.policy.policyName);

    return (
      <>
        <Modal
          size="tiny"
          open={this.state.openPreviewModal}
          closeIcon={
            <Icon
              onClick={() => this.openPreviewModal()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>
            <label className="modal-toc-preview-header">Theory of Change</label>
          </Modal.Header>
          <Modal.Content>
            <div className="modal-toc-content">
              <div>
                <strong>Aiming for </strong> outcomes in the{' '}
                {isEmpty(objectives) ? (
                  <span className="toc-knowing">[Selected domains]</span>
                ) : currentActivity.primaryPolicy ? (
                  `${get(currentActivity, 'primaryPolicy.policyName', '')}`
                ) : (
                  ''
                )}
                {!isEmpty(objectives) && currentActivity.secondaryPolicy
                  ? ` and ${get(
                      currentActivity,
                      'secondaryPolicy.policyName',
                      ''
                    )}`
                  : !isEmpty(objectives) && !isEmpty(domains)
                    ? ', '
                    : ''}
                {!isEmpty(objectives) && !isEmpty(domains)
                  ? domains.join(', ')
                  : ''}
                {!isEmpty(objectives) && ' domains'}
                <strong> and knowing </strong>
                {isEmpty(defaultValues.projectTocKnown) ? (
                  <span className="toc-knowing">
                    [Summarise the strongest points of evidence that leads to
                    the activity]
                  </span>
                ) : (
                  defaultValues.projectTocKnown
                )}
                <strong> we will </strong>{' '}
                {this.getWeWillText(defaultValues, currentActivity)}
              </div>
            </div>
          </Modal.Content>
        </Modal>
        <AvForm className="activity-form" model={defaultValues}>
          <ActivityParentGoalAndObjective theory />
          <Segment className="content-segment" attached>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={9}>
                  <div className="theory-textarea-container-grants">
                    <AvField
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: KnowingTipKey,
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div>
                            <strong>Aiming for</strong> outcomes in the{' '}
                            <label>
                              {isEmpty(objectives)
                                ? '[Selected domains]'
                                : currentActivity.primaryPolicy
                                  ? `${get(
                                      currentActivity,
                                      'primaryPolicy.policyName',
                                      ''
                                    )}`
                                  : ''}
                              {!isEmpty(objectives) &&
                              currentActivity.secondaryPolicy
                                ? ` and ${get(
                                    currentActivity,
                                    'secondaryPolicy.policyName',
                                    ''
                                  )}`
                                : !isEmpty(objectives) && !isEmpty(domains)
                                  ? ', '
                                  : ''}
                              {!isEmpty(objectives) && !isEmpty(domains)
                                ? domains.join(', ')
                                : ''}{' '}
                              {!isEmpty(objectives) && ' domains'}
                            </label>
                            <strong> and knowing</strong>
                          </div>
                        </CustomTooltip>
                      }
                      className="theory-textarea"
                      placeholder="Summarise the strongest points of evidence that leads to the activity"
                      name="projectTocKnown"
                      type="textarea"
                      onChange={(e) =>
                        this.onChange(e.target.value, 'projectTocKnown')
                      }
                      onBlur={() => this.onBlurInput('projectTocKnown')}
                      disabled={getPageDisable(
                        currentActivity,
                        user,
                        currentState
                      )}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={9}>
                  <div className="theory-textarea-container-grants">
                    <AvField
                      label={
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: weWillTipKey,
                          }}
                          position="top left"
                          wide="very"
                        >
                          <strong>we will</strong>
                        </CustomTooltip>
                      }
                      className="theory-textarea"
                      name="projectTocDo"
                      type="textarea"
                      placeholder={`Describe how this ${getActivityTypeToc(
                        currentActivity
                      )} program will be structured to achieve the objectives`}
                      onChange={(e) =>
                        this.onChange(e.target.value, 'projectTocDo')
                      }
                      onBlur={() => this.onBlurInput('projectTocDo')}
                      disabled={getPageDisable(
                        currentActivity,
                        user,
                        currentState
                      )}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column
                  className="theory-textarea-container-single"
                  mobile={8}
                  tablet={8}
                  computer={8}
                >
                  <div className="preview-toc-button">
                    <Button
                      className="Primary-Button"
                      type="button"
                      onClick={() => this.openPreviewModal()}
                    >
                      Preview Theory of Change
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </AvForm>
      </>
    );
  }
}

class ActivityParentTheory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CustomSegment
        className="create-theory-of-change"
        title={`Create theory of change - ${
          this.props.currentActivity.goal.split('-')[0]
        }`}
        children={
          <ActivityParentTheoryCard
            theory="One"
            KnowingTipKey="105"
            weWillTipKey="106"
            currentState="project.edit.theoryOfChange"
            {...this.props}
          />
        }
        rightHeader={
          <Loader
            active={
              this.props.actPut.theoryOne ? this.props.actPut.theoryOne : false
            }
            inline
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    extra: state.extra,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
    },
    dispatch
  );
};

ActivityParentTheory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParentTheory);

export { ActivityParentTheory };

/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/*eslint-disable no-loop-func */

import React from 'react';
import { Grid, Loader, Radio, Segment, Icon } from 'semantic-ui-react';
import { ChartComponent, makeChartObject } from '../reportHome/activitySummary';
import { useDispatch, useSelector } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

import { isEmpty, find, get } from '../../../utils/lodash';
import '../reports.scss';

import {
  EmptyContainer,
  CustomNotification,
  CustomSegment,
} from '../../../components';
import {
  advancedFilterAllReset,
  getOrganizationReportData,
  getPolicies,
  setOutcomePage,
  setOutcomeReportData,
} from '../../../store/actions';
import history from '../../../utils/history';
import { ORGANISATION_REPORT_LOADING } from '../../../utils/constant';
import { OutcomeChart } from '../../../components/chartsWidgets';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const OutcomeAddressed = (props) => {
  const dispatch = useDispatch();
  const [participantsResponses, setParticipantsResponses] = React.useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      barColor: [],
      size: [],
    },
  });
  const [participantsResponsesOnly, setParticipantsResponsesOnly] =
    React.useState(true);

  const [showOutcomesReportChart, setShowOutcomesReportChart] =
    React.useState(false);

  const isOutcomeReportedPage = useSelector(
    (state) => state.report.isOutcomeReportedPage
  );

  React.useEffect(() => {
    dispatch(getPolicies());
  }, [dispatch]);

  const policies = useSelector((state) => state.extra.policies);

  const reportFilter = useSelector((state) => state.report.reportFilterOptions);

  const outcomeSummaryChart = useSelector(
    (state) => state.report.outcomeSummaryChart
  );
  const organization = useSelector((state) => state.organization.organization);

  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );

  const reportInitialState = useSelector((state) => state.report);
  const outcomeReportLoader = get(
    reportInitialState,
    `outcomeSummaryReportLoading.${ORGANISATION_REPORT_LOADING.OUTCOME_ADDRESS_LOADING}`,
    false
  );

  React.useEffect(() => {
    if (!isEmpty(outcomeSummaryChart)) {
      setParticipantsResponses({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          barColor: [],
          size: [],
        },
      });

      makeChartObject(
        outcomeSummaryChart,
        setParticipantsResponses,
        reportFilter
      );
    }
  }, [outcomeSummaryChart, participantsResponsesOnly, reportFilter]);

  const cultural = (
    outcomeSummaryChart.find((item) => item.policyId === 1) || {}
  ).totalActivities;

  const social = (outcomeSummaryChart.find((item) => item.policyId === 2) || {})
    .totalActivities;

  const economic = (
    outcomeSummaryChart.find((item) => item.policyId === 3) || {}
  ).totalActivities;

  const governance = (
    outcomeSummaryChart.find((item) => item.policyId === 4) || {}
  ).totalActivities;

  const environmental = (
    outcomeSummaryChart.find((item) => item.policyId === 5) || {}
  ).totalActivities;

  //make the condition
  // const condition =
  //   (cultural !== undefined && cultural > 0) ||
  //   (social !== undefined && social > 0) ||
  //   (economic !== undefined && economic > 0) ||
  //   (governance !== undefined && governance > 0) ||
  //   (environmental !== undefined && environmental > 0);
  const outcomes = policies.map((policy) => policy.policyName);

  const isActivityCollection = outcomes.some((outcome) => {
    const totalActivities = (
      outcomeSummaryChart.find((item) => item.mainTitle === outcome) || {}
    ).totalActivities;
    return totalActivities !== undefined && totalActivities > 0;
  });

  const onChangeParticipantsResponsesOnly = () => {
    setParticipantsResponsesOnly(!participantsResponsesOnly);
    dispatch(
      getOrganizationReportData('outcomes-addressed', {
        outcomeAddressedOnly: !participantsResponsesOnly,
      })
    );
  };

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id == organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const getHeight = (dataLength) => `${5 * dataLength.length}rem`;

  const renderHeaderItems = () => {
    if (outcomeReportLoader) {
      return <Loader active size="medium" />;
    }

    return (
      <>
        <div className="activity-name">
          <div
            className={
              cultural == undefined && cultural <= 0
                ? `report-circle circle-bottom-null reports-cultural`
                : `report-circle circle-bottom reports-cultural`
            }
          />
          <div className="col-wise">
            <div>
              <label>Cultural</label>
            </div>
            <div className="number-centre">{cultural || 0}</div>
          </div>
        </div>

        <div className="activity-name">
          <div
            className={
              social == undefined && social <= 0
                ? `report-circle circle-bottom-null reports-social`
                : `report-circle circle-bottom reports-social`
            }
          />
          <div className="col-wise">
            <div>
              <label>Social</label>
            </div>
            <div className="number-centre">{social || 0}</div>
          </div>
        </div>
        <div className="activity-name">
          <div
            className={
              economic == undefined && economic <= 0
                ? `report-circle circle-bottom-null reports-economic`
                : `report-circle circle-bottom reports-economic`
            }
          />
          <div className="col-wise">
            <div>
              <label>Economic</label>
            </div>
            <div className="number-centre">{economic || 0}</div>
          </div>
        </div>
        <div className="activity-name">
          <div
            className={
              governance == undefined && governance <= 0
                ? `report-circle circle-bottom-null reports-governance`
                : `report-circle circle-bottom reports-governance`
            }
          />
          <div className="col-wise">
            <div>
              <label>Governance</label>
            </div>
            <div className="number-centre">{governance || 0}</div>
          </div>
        </div>
        <div className="activity-name">
          <div
            className={
              environmental == undefined && environmental <= 0
                ? `report-circle circle-bottom-null reports-environmental`
                : `report-circle circle-bottom reports-environmental`
            }
          />
          <div className="col-wise">
            <div>
              <label>Environmental</label>
            </div>
            <div className="number-centre">{environmental || 0}</div>
          </div>
        </div>
      </>
    );
  };

  const renderOutcomeReport = () => {
    return (
      <Segment loading={outcomeReportLoader}>
        {isActivityCollection ? (
          <div
            style={{
              height: getHeight(get(participantsResponses, 'dataset', 0)),
              width: 'auto',
              background: 'white',
            }}
          >
            <ChartComponent
              toggle={participantsResponsesOnly}
              data={participantsResponses}
              chartType={'activitiesOnly'}
              status={reportFilter.statuses}
              dynamicBarColor={true}
              props={props}
              changeOutcomeInterface={changeOutcomeInterface}
              reportType={props.reportType}
            />
          </div>
        ) : (
          <EmptyContainer msg="No Data Available" />
        )}
      </Segment>
    );
  };

  const renderHeaderSection = () => {
    return (
      <CustomSegment title="Outcome Overview">
        <div className="activity-count-info">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div>
                  {props.dateRangeChosenLabel !== 'Since the Start' && (
                    <CustomNotification
                      messageBanner={
                        'In development and Active figures reflect status on final day of the search period. Completed figure reflects activities completed in this period.  See help page for more information.'
                      }
                    />
                  )}
                </div>
                <Grid className="activity-summary-grid" padded>
                  {outcomeReportLoader ? (
                    <Loader active size="medium" />
                  ) : (
                    <>
                      <div className="org-down">
                        <Grid.Row>
                          <label>Organisation Type</label>
                        </Grid.Row>
                        <Grid.Row>
                          <h6>{getOrganizationSectorType()}</h6>
                        </Grid.Row>
                      </div>

                      <div className="left-align">{renderHeaderItems()}</div>
                    </>
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </CustomSegment>
    );
  };

  const changeOutcomeInterface = (reportType, selectedOutcome) => {
    dispatch(setOutcomePage(!isOutcomeReportedPage));
    dispatch(
      setOutcomeReportData(selectedOutcome, reportType, {
        renderOutcomeChart: !isOutcomeReportedPage,
      })
    );
    if (reportType === 'outcomes-addressed') {
      dispatch(
        getOrganizationReportData(reportType, {
          outcomes: isEmpty(reportFilter.outcomes) ? [] : reportFilter.outcomes,
        })
      );
    }
  };

  return (
    <div>
      {isOutcomeReportedPage ? (
        <div>
          <div className="outcome-address-back">
            <label
              className="view-container-goals"
              onClick={() => changeOutcomeInterface('outcomes-addressed')}
            >
              <Icon name="arrow left" className="outcome-address-back-arrow" />
              Back to Outcomes Addressed
            </label>
          </div>
          <OutcomeChart />
        </div>
      ) : (
        <div>
          {renderHeaderSection()}
          <br />
          <CustomSegment
            title="Outcomes Addressed"
            rightHeader={
              <div style={{ display: ' flex', flexDirection: 'row' }}>
                Used Outcomes Only: &nbsp;
                <Radio
                  toggle
                  className="report-filter"
                  checked={participantsResponsesOnly}
                  onChange={() => onChangeParticipantsResponsesOnly()}
                />
              </div>
            }
          >
            {renderOutcomeReport()}
          </CustomSegment>{' '}
        </div>
      )}
    </div>
  );
};

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllParentChartPreviewData,
  updateProjectAggregateImage,
  getProgramIncludedReportCharts,
  getAllParentChartData,
} from '../../../store/actions';
import { map, find, isEmpty, get, size, uniqBy } from '../../../utils/lodash';
import './activity.scss';
import { CustomSegment } from '../../../components';
import {
  PostPreTrendSurvey,
  PostSurveyChart,
  SelfAssessedPostTrendChart,
  SelfAssessedPostPreTrend,
  PostTrendSurveyChart,
  PostPreSurvey,
} from '../../reports/reportHome/outcomesSummary';
import { Segment, Grid, Tab } from 'semantic-ui-react';

import { cloneDeep, filter, flatten, sum } from 'lodash';
import { setParentDomains } from '../../../utils';

class OutcomeCharts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateBase64Img = async (imageData) => {
    const {
      currentActivity,
      updateProjectAggregateImage,
      chart,
      getAllImages,
    } = this.props;
    await getAllImages({ getAllGraphImages: false, reportLoaded: true });
    // if (currentActivity.statusId < 3) {
    await updateProjectAggregateImage(currentActivity.id, {
      outcomeId: chart.id,
      policyValue: chart.policyValue,
      imageData,
    });
    await getAllImages({ getAllGraphImages: false, reportLoaded: false });
  };

  // get allowed reports for given project
  async getProgramIncludedReportCharts() {
    const { currentActivity, getProgramIncludedReportCharts } = this.props;
    const programIncludedReportCharts = await getProgramIncludedReportCharts(
      currentActivity.id
    );
    return programIncludedReportCharts;
  }

  // check allowed reports for given outcome chart
  isProgramIncludedReportCharts = (type) => {
    const { programIncludedReports, chart } = this.props;
    let filterData = filter(
      programIncludedReports,
      (item) => item.type === type && item.outcomeId === chart.id
    );
    let isProgramIncluded = filterData.length > 0;
    return isProgramIncluded;
  };

  render() {
    const {
      chart,
      isInterceptAvailable,
      isSelfAssessedAvailable,
      isMethodAll,
    } = this.props;

    return (
      <Segment className="content-segment padding-outcomes">
        <div className="report-activity-seg">
          <Grid className="report-activity-div">
            {(isMethodAll && this.isProgramIncludedReportCharts('TREND')) ||
            this.isProgramIncludedReportCharts('AGGREGATE') ? (
              <>
                <Grid.Row>
                  <Grid.Column className="chart-column">
                    <div className="report-method">
                      <div className="method">Method</div>
                      <div className="method-type">All</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column className="chart-column">
                    {size(chart.data) === 2 &&
                    this.isProgramIncludedReportCharts('TREND') ? (
                      <PostPreTrendSurvey
                        participantsChangePrePost={[chart]}
                        type={chart.name}
                        method="all"
                        updateBase64Img={(image) => this.updateBase64Img(image)}
                        grantReportHeader={true}
                        outcome={get(chart, 'name', '-')}
                      />
                    ) : this.isProgramIncludedReportCharts('AGGREGATE') ? (
                      <PostSurveyChart
                        participants={[chart]}
                        type={chart.name}
                        method="all"
                        updateBase64Img={(image) => this.updateBase64Img(image)}
                        outcome={get(chart, 'name', '-')}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : null}

            {this.isProgramIncludedReportCharts('SINGLE_TREND') && (
              <>
                <Grid.Row>
                  <Grid.Column className="chart-column mt-5">
                    <div className="report-method">
                      <div className="method">Chart Type</div>
                      <div className="method-type">Trend - Rate of change</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="chart-column">
                    <Tab.Pane className="individual-rate-heatmap">
                      <div className="pre-post-chart-div">
                        <PostPreSurvey
                          participantsChange={this.props.participantsChange}
                          type={chart.name}
                          method="all"
                        />
                      </div>
                    </Tab.Pane>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {this.isProgramIncludedReportCharts('SEPERATE') && (
              <>
                <Grid.Row>
                  <Grid.Column className="chart-column mt-5">
                    <div className="report-method">
                      <div className="method">Chart Type</div>
                      <div className="method-type">Separated</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="chart-column">
                    <PostTrendSurveyChart
                      className="w-100"
                      participants={[chart]}
                      type={chart.name}
                      method="all"
                      updateBase64Img={(image) => this.updateBase64Img(image)}
                      grantReportHeader={true}
                      outcome={get(chart, 'name', '-')}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {!isEmpty(isSelfAssessedAvailable) &&
            (this.isProgramIncludedReportCharts('SELF-ASSESSED-POST') ||
              this.isProgramIncludedReportCharts('SELF-ASSESSED-PRE')) ? (
              <>
                <Grid.Row className="charts-method-row">
                  <Grid.Column className="method-type-self-assessed">
                    <div className="report-method">
                      <div className="method">Method</div>
                      <div className="method-type">Self Assessed</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="chart-column">
                    {size(chart.data) === 2 ? (
                      <SelfAssessedPostPreTrend
                        participantsChangePrePost={[chart]}
                        type={chart.name}
                        method="9"
                        updateBase64Img={(image) => this.updateBase64Img(image)}
                        outcome={get(chart, 'name', '-')}
                      />
                    ) : (
                      <SelfAssessedPostTrendChart
                        participants={[chart]}
                        type={chart.name}
                        method="9"
                        updateBase64Img={(image) => this.updateBase64Img(image)}
                        outcome={get(chart, 'name', '-')}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : null}

            {!isEmpty(isInterceptAvailable) &&
            (this.isProgramIncludedReportCharts('INTERCEPT_SINGLE') ||
              this.isProgramIncludedReportCharts('INTERCEPT_AGGREGATE')) ? (
              <>
                <Grid.Row>
                  <Grid.Column className="method-type-self-assessed">
                    <div className="report-method">
                      <div className="method">Method</div>
                      <div className="method-type">
                        CC Intercept - Participants
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="chart-column">
                    <PostSurveyChart
                      participants={[chart]}
                      type={chart.name}
                      method="12"
                      updateBase64Img={(image) => this.updateBase64Img(image)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : null}
          </Grid>
        </div>
      </Segment>
    );
  }
}

class ParentOutcomeCharts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      const {
        getAllParentChartPreviewData,
        currentActivity,
        getAllImages,
        getProgramIncludedReportCharts,
        getAllParentChartData,
      } = this.props;
      await getProgramIncludedReportCharts(currentActivity.id);
      await getAllImages({ getAllGraphImages: true, reportLoaded: true });
      await getAllParentChartPreviewData(currentActivity.id); // outcome call
      await getAllParentChartData(currentActivity.id);
      await getAllImages({
        getAllGraphImages: false,
        reportLoaded: false,
      });
    } catch (error) {
      // console.error("ParentOutcomeCharts -> componentDidMount -> error", error)
    }
  }

  isChartDataAvailableForIncludedReport(outcome, programIncludedReports) {
    let dataResult,
      allDataResult,
      isAvailable = false;

    const SelfAssessed = find(outcome.byMethod, (d) => d.methodId === 9);
    const isIntercept = find(outcome.byMethod, (d) => d.methodId === 12);

    for (const chart of programIncludedReports) {
      if (isAvailable) break;
      if (outcome.data.length === 2) {
        dataResult = map(outcome.data, (s) => sum(s));
        switch (chart.type) {
          case 'TREND':
            isAvailable = !dataResult.includes(0);
            break;
          case 'SELF-ASSESSED-PRE':
            isAvailable = !isEmpty(SelfAssessed);
            break;
          case 'INTERCEPT_AGGREGATE' || 'INTERCEPT_SINGLE':
            isAvailable = !isEmpty(isIntercept);
            break;
          default:
            break;
        }
      } else {
        dataResult = sum(outcome.data);
        allDataResult = map(outcome.dataAll, (s) => sum(s.data));
        switch (chart.type) {
          case 'AGGREGATE':
            isAvailable = dataResult !== 0;
            break;
          case 'SEPERATE':
            isAvailable = !allDataResult.includes(0);
            break;
          case 'SELF-ASSESSED-POST':
            isAvailable = !isEmpty(SelfAssessed);
            break;
          case 'INTERCEPT_AGGREGATE' || 'INTERCEPT_SINGLE':
            isAvailable = !isEmpty(isIntercept);
            break;
          default:
            break;
        }
      }
    }
    return isAvailable;
  }

  render() {
    const {
      currentActivity,
      outcomes,
      participantsChangePrePost,
      participants,
      programIncludedReports,
    } = this.props;

    let changePrePost = cloneDeep(participantsChangePrePost);

    let newParticipants = [],
      byMethodIntercept = [];
    for (const item of participants) {
      let getIntercept = find(item.byMethod, (o) => {
        return o.methodId === 12;
      });

      if (getIntercept !== undefined) {
        byMethodIntercept.push(getIntercept);
      }

      let sameOutcomes = find(participantsChangePrePost, (o) => {
        return o.id === item.id;
      });

      if (sameOutcomes === undefined) {
        newParticipants.push(item);
      }
    }

    if (!isEmpty(byMethodIntercept)) {
      byMethodIntercept = uniqBy(byMethodIntercept, (o) => {
        return o.methodId;
      });
    }

    for (const changePre of changePrePost) {
      changePre.byMethod.push(...byMethodIntercept);
    }

    let newDataSet = newParticipants.concat(changePrePost);

    if (
      !isEmpty(currentActivity) &&
      !isEmpty(outcomes) &&
      !isEmpty(flatten(newDataSet))
    ) {
      map(newDataSet, (data) => {
        const getOutcome = find(outcomes, (o) => {
          return o.title === data.name;
        });

        if (!isEmpty(getOutcome)) {
          const domain = find(setParentDomains(currentActivity), (o) => {
            return o.policyId === getOutcome.policyId;
          });
          if (domain && domain.policy) {
            data.domain = domain.policy.policyName;
            data.policyValue = domain.policy.id;
          }
        }
      });
    }

    return (
      <Fragment>
        {isEmpty(newDataSet) ? (
          <></>
        ) : (
          map(newDataSet, (outcome, i) => {
            const isSelfAssessedAvailable = find(
              outcome.byMethod,
              (d) => d.methodId === 9
            );
            const isInterceptAvailable = find(
              outcome.byMethod,
              (d) => d.methodId === 12
            );

            let isMethodAll = false;

            if (outcome.activityCount > 0) {
              isMethodAll = true;
            }

            if (
              isSelfAssessedAvailable === undefined &&
              isInterceptAvailable === undefined &&
              !isMethodAll
            )
              return null;

            const filteredOutcomeCharts = filter(
              programIncludedReports,
              (item) => item.outcomeId === outcome.id
            );

            if (isEmpty(filteredOutcomeCharts)) return null;
            if (
              !this.isChartDataAvailableForIncludedReport(
                outcome,
                programIncludedReports
              )
            )
              return null;

            const outcomeName = get(outcome, 'name', '-');
            const outcomeDomain = get(outcome, 'domain', '-');

            return (
              <CustomSegment
                key={i}
                title={`Actual Outcomes - ${outcomeName} (${outcomeDomain})`}
                children={
                  <OutcomeCharts
                    chart={outcome}
                    {...this.props}
                    isMethodAll={isMethodAll}
                    isInterceptAvailable={isInterceptAvailable}
                    isSelfAssessedAvailable={isSelfAssessedAvailable}
                    programIncludedReports={programIncludedReports}
                  />
                }
                extraMargin
                toggle={false}
                attached
              />
            );
          })
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { activity } = state;
  return {
    currentActivity: activity.currentActivity,
    actLoading: state.report.loading,
    participants: state.report.participants,
    participantsChangePrePost: state.report.participantsChangePrePost,
    chartData: [
      ...state.report.participants,
      ...state.report.participantsChangePrePost,
    ],
    outcomes: state.extra.outcomes,
    programIncludedReports: activity.programIncludedReports,
    participantsChange: state.report.participantsChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllParentChartPreviewData,
      updateProjectAggregateImage,
      getProgramIncludedReportCharts,
      getAllParentChartData,
    },
    dispatch
  );
};

ParentOutcomeCharts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentOutcomeCharts);

export { ParentOutcomeCharts };

import axios from 'axios';
import { authMsg, getCommonError } from '../../utils';
import history from '../../utils/history';
import { NotificationManager } from 'react-notifications';
import { LIMIT } from '../../utils/constant';
import { getOrgVenuesTypes } from '../../store/actions';

import {
  getOutcomes,
  getMethods,
  getEventDetails,
  getHelpPages,
  getActivityHelpTips,
} from '../actions';
import { getInvoices } from './organization';

/*** TYPES ***/
const SET_FEATURES_LOADING = 'SET_FEATURES_LOADING';
const GET_FEATURES_FOR_ORGANISATION = 'GET_FEATURES_FOR_ORGANISATION';
const GET_FEATURES = 'GET_FEATURES';
const GET_FEATURES_LOADING = 'GET_FEATURES_LOADING';
const SET_FEATURE_UPDATE_LOADING = 'SET_FEATURE_UPDATE_LOADING';

const SET_USER_TYPE_LOADING = 'SET_USER_TYPE_LOADING';

const SET_OUTCOME_LOADING = 'SET_OUTCOME_LOADING';
const SET_OUTCOME_CULTURAL = 'SET_OUTCOME_CULTURAL';
const GET_USERS = 'GET_USERS';

const SET_ORGANISATION_POST = 'SET_ORGANISATION_POST';

const SET_QUESTIONS_LOADING = 'SET_QUESTIONS_LOADING';
const SET_QUESTIONS = 'SET_QUESTIONS';

const SET_VENUE_TYPE_LOADING = 'SET_VENUE_TYPE_LOADING';
const SET_OUTCOME_UPDATE_LOADING = 'SET_OUTCOME_UPDATE_LOADING';

const SET_ALL_SYSTEM_LOG_LOADING = 'SET_ALL_SYSTEM_LOG_LOADING';
const SET_SYSTEM_LOG_LIST = 'SET_SYSTEM_LOG_LIST';

const SET_VENUE_TYPE = 'SET_VENUE_TYPE';
const GET_VENUE_TYPE_LOADING = 'GET_VENUE_TYPE_LOADING';
const GET_VENUE_TYPE = 'GET_VENUE_TYPE';

const SET_ACTIVITY_TYPE_LOADING = 'SET_ACTIVITY_TYPE_LOADING';
const SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE';
const SET_METHOD_LOADING = 'SET_METHOD_LOADING';
const SET_METHODS = 'SET_METHODS';
const GET_DOMAINS = 'GET_DOMAINS';

const SET_ACTIVITY_UPDATE_LOADING = 'SET_ACTIVITY_UPDATE_LOADING';

const SET_HELP_PAGE_LOAD = 'SET_HELP_PAGE_LOAD';
const SET_HELP_PAGE = 'SET_HELP_PAGE';
const SET_HELP_TIP_LOAD = 'SET_HELP_TIP_LOAD';
const SET_HELP_TIP = 'SET_HELP_TIP';
const SET_UPDATE_HELP_PAGE_LOADING = 'SET_UPDATE_HELP_PAGE_LOADING';
const SET_HELP_TIP_LOADING = 'SET_HELP_TIP_LOADING';
const SET_INDUSTRY_LOADING = 'SET_INDUSTRY_LOADING';
const SET_INDUSTRY_SECTOR = 'SET_INDUSTRY_SECTOR';
const GET_INDUSTRY_ALL_LOADING = 'GET_INDUSTRY_ALL_LOADING';
const GET_INDUSTRY_ALL_SECTOR = 'GET_INDUSTRY_ALL_SECTOR';
const GET_QUESTIONS = 'GET_QUESTIONS';
const GET_QUESTIONS_LOADING = 'GET_QUESTIONS_LOADING';
const SET_ENABLE_LOADING = 'SET_ENABLE_LOADING';
const GET_OUTPUT_FORMAT = 'GET_OUTPUT_FORMAT';
const GET_OUTPUT_FORMAT_GROUP = 'GET_OUTPUT_FORMAT_GROUP';
const GET_OUTPUT_FORMAT_TYPES = 'GET_OUTPUT_FORMAT_TYPES';
const SET_OUTPUT_FORMATTER_LOADING = 'SET_OUTPUT_FORMATTER_LOADING';
const OUTPUT_FORMATTER_UPDATE_LOADING = 'OUTPUT_FORMATTER_UPDATE_LOADING';
const SET_ALL_ORG_USERS_LOADING = 'SET_ALL_ORG_USERS_LOADING';
const SET_ALL_ORG_USERS_LIST = 'SET_ALL_ORG_USERS_LIST';
const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
const GET_ORG_SELECTED_USER = 'GET_ORG_SELECTED_USER';
const SET_SELECTED_PROJECT_USER = 'SET_SELECTED_PROJECT_USER';
const SET_ALL_ORG_LIST_LOADING = 'SET_ALL_ORG_LIST_LOADING';
const SET_ALL_ORG_LIST = 'SET_ALL_ORG_LIST';
const GET_SYSTEM_LOG_TYPES = 'GET_SYSTEM_LOG_TYPES';
const SET_ORGANISATION_POST_LOADING = 'SET_ORGANISATION_POST_LOADING';
const SET_ORG_UPDATE_LOADING = 'SET_ORG_UPDATE_LOADING';
const SET_ORGANIZATION_UPDATE = 'SET_ORGANIZATION_UPDATE';
const SET_USER_POST_LOADING = 'SET_USER_POST_LOADING';
const GET_PROJECTS = 'GET_PROJECTS';
const SET_USER_REMOVE_LOADING = 'SET_USER_REMOVE_LOADING';
const GET_ORG_FEATURES = 'GET_ORG_FEATURES';
const GET_ORG_FEATURES_LOADING = 'GET_ORG_FEATURES_LOADING';
const GET_SELECTED_USERS_CSV = 'GET_SELECTED_USERS_CSV';
const GET_INVOICES = 'GET_INVOICES';
const GET_INVOICES_LOADING = 'GET_INVOICES_LOADING';
const SET_MARK_BY_LOADING = 'SET_MARK_BY_LOADING';
const SET_SUB_ORGANISATION_POST_LOADING = 'SET_SUB_ORGANISATION_POST_LOADING';
const SET_POST_PERMISSION_GROUPS_LOADING = 'SET_POST_PERMISSION_GROUPS_LOADING';

const GET_PERMISSION_GRP_LOADING = 'GET_PERMISSION_GRP_LOADING';
const GET_PERMISSION_GRP = 'GET_PERMISSION_GRP';
const SET_PERMISSION_UPDATE_LOADING = 'SET_PERMISSION_UPDATE_LOADING';
const PERMISSION_GROUP_DELETE_LOADING = 'PERMISSION_GROUP_DELETE_LOADING';

/*** REDUCERS ***/
const initialState = {
  listLoading: false,
  industryLoading: false,
  outcomeCultural: {},
  industryList: {},
  domains: [],
  industryAll: [],
  getIndustryAllLoading: false,
  outcomeLoading: false,
  questions: [],
  questionLoading: false,
  enableLoading: false,
  evalQs: {},
  venueTypeLoading: false,
  venueType: {},
  venueTypeLimit: {},
  vLoading: false,
  methodLoading: false,
  methods: {},
  orgAllLoading: false,
  allUserList: {},
  organisationsAll: {},
  selectedUser: {},
  activityUserLoading: false,
  allOrgLoading: false,
  allOrganisations: {},
  outcomeUpdateLoading: false,
  systemLogLoading: false,
  systemLogs: [],
  systemLogType: [],
  hPageLoading: false,
  hTipLoading: false,
  helpPagePost: {},
  helpTipsPost: {},
  updatePageLoading: false,
  updateTipLoading: false,
  postOrgLoading: false,
  updateOrgLoading: false,
  org: {},
  activityLoading: false,
  activityType: {},
  activityUpdateLoading: false,
  featureLoading: false,
  features: [],
  getfeatureloading: false,
  featureUpdateLoading: false,
  outputFormatGroupsList: [],
  outputFormatTypes: [],
  outputFormatsList: {},
  outLoading: false,
  outputFormatterUpdateLoading: false,
  users: [],
  projects: [],
  userPostLoading: false,
  featuresList: [],
  userRemoveLoading: false,
  orgFeaturesLoading: false,
  orgFeatures: [],
  selectedUserCsvLink: {},
  userTypeChangeLoading: false,
  orgPost: {},
  invoices: {},
  invoicesLoading: false,
  markByLoading: false,
  postSubOrgLoading: false,
  postPermissionGroupLoading: false,
  getPermissionLoading: false,
  getPermissionGroup: {},
  updatePermissionGroupLoading: false,
  deletePermissionLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_OUTCOME_LOADING:
      return { ...state, outcomeLoading: action.payload };

    case SET_OUTCOME_CULTURAL:
      return { ...state, outcomeCultural: action.payload };

    case SET_OUTCOME_UPDATE_LOADING:
      return { ...state, outcomeUpdateLoading: action.payload };
    case GET_DOMAINS:
      return { ...state, domains: action.payload };

    case SET_INDUSTRY_LOADING:
      return { ...state, industryLoading: action.payload };
    case SET_INDUSTRY_SECTOR:
      return { ...state, industryList: action.payload };

    case GET_INDUSTRY_ALL_LOADING:
      return { ...state, getIndustryAllLoading: action.payload };
    case GET_INDUSTRY_ALL_SECTOR:
      return { ...state, industryAll: action.payload };

    case GET_QUESTIONS_LOADING:
      return { ...state, listLoading: action.payload };

    case GET_QUESTIONS:
      return { ...state, questions: action.payload };

    case SET_QUESTIONS_LOADING:
      return { ...state, questionLoading: action.payload };
    case SET_QUESTIONS:
      return { ...state, evalQs: action.payload };

    case SET_ENABLE_LOADING:
      return { ...state, enableLoading: action.payload };

    case SET_VENUE_TYPE_LOADING:
      return { ...state, venueTypeLoading: action.payload };
    case SET_VENUE_TYPE:
      return { ...state, venueType: action.payload };

    case SET_METHOD_LOADING:
      return { ...state, methodLoading: action.payload };
    case SET_METHODS:
      return { ...state, methods: action.payload };
    case GET_USERS:
      return { ...state, users: action.payload };

    case GET_VENUE_TYPE_LOADING:
      return { ...state, vLoading: action.payload };
    case GET_VENUE_TYPE:
      return { ...state, venueTypeLimit: action.payload };
    case SET_ALL_ORG_USERS_LOADING:
      return { ...state, orgAllLoading: action.payload };
    case SET_ALL_ORG_USERS_LIST:
      return { ...state, allUserList: action.payload };

    case GET_ORGANISATIONS:
      return { ...state, organisationsAll: action.payload };
    case SET_SELECTED_PROJECT_USER:
      return { ...state, selectedUser: action.payload };
    case GET_ORG_SELECTED_USER:
      return { ...state, activityUserLoading: action.payload };

    case SET_ALL_ORG_LIST_LOADING:
      return { ...state, allOrgLoading: action.payload };
    case SET_ALL_ORG_LIST:
      return { ...state, allOrganisations: action.payload };

    case SET_HELP_PAGE_LOAD:
      return { ...state, hPageLoading: action.payload };
    case SET_HELP_PAGE:
      return { ...state, helpPagePost: action.payload };

    case SET_HELP_TIP_LOAD:
      return { ...state, hTipLoading: action.payload };
    case SET_HELP_TIP:
      return { ...state, helpTipsPost: action.payload };
    case SET_UPDATE_HELP_PAGE_LOADING:
      return { ...state, updatePageLoading: action.payload };
    case SET_HELP_TIP_LOADING:
      return { ...state, updateTipLoading: action.payload };

    case SET_ORGANISATION_POST_LOADING:
      return { ...state, postOrgLoading: action.payload };
    case SET_SUB_ORGANISATION_POST_LOADING:
      return { ...state, postSubOrgLoading: action.payload };
    case SET_ORGANIZATION_UPDATE:
      return { ...state, org: action.payload };

    case SET_ORG_UPDATE_LOADING:
      return { ...state, updateOrgLoading: action.payload };

    case SET_ACTIVITY_TYPE_LOADING:
      return { ...state, activityLoading: action.payload };
    case SET_ACTIVITY_TYPE:
      return { ...state, activityType: action.payload };
    case SET_ACTIVITY_UPDATE_LOADING:
      return { ...state, activityUpdateLoading: action.payload };
    case SET_OUTPUT_FORMATTER_LOADING:
      return { ...state, outLoading: action.payload };
    case GET_OUTPUT_FORMAT:
      return { ...state, outputFormatsList: action.payload };
    case GET_OUTPUT_FORMAT_GROUP:
      return { ...state, outputFormatGroupsList: action.payload };
    case GET_OUTPUT_FORMAT_TYPES:
      return { ...state, outputFormatTypes: action.payload };
    case GET_FEATURES_FOR_ORGANISATION:
      return { ...state, featuresList: action.payload };
    case OUTPUT_FORMATTER_UPDATE_LOADING:
      return { ...state, outputFormatterUpdateLoading: action.payload };

    case SET_ALL_SYSTEM_LOG_LOADING:
      return { ...state, systemLogLoading: action.payload };
    case SET_SYSTEM_LOG_LIST:
      return { ...state, systemLogs: action.payload };
    case GET_SYSTEM_LOG_TYPES:
      return { ...state, systemLogType: action.payload };
    case GET_PROJECTS:
      return { ...state, projects: action.payload };

    case SET_USER_REMOVE_LOADING:
      return { ...state, userRemoveLoading: action.payload };

    case GET_SELECTED_USERS_CSV:
      return { ...state, selectedUserCsvLink: action.payload };

    case SET_USER_POST_LOADING:
      return { ...state, userPostLoading: action.payload };

    case GET_ORG_FEATURES:
      return { ...state, orgFeatures: action.payload };

    case GET_ORG_FEATURES_LOADING:
      return { ...state, orgFeaturesLoading: action.payload };

    case SET_FEATURES_LOADING:
      return { ...state, featureLoading: action.payload };
    case GET_FEATURES:
      return { ...state, features: action.payload };
    case GET_FEATURES_LOADING:
      return { ...state, getfeatureLoading: action.payload };
    case SET_FEATURE_UPDATE_LOADING:
      return { ...state, featureUpdateLoading: action.payload };
    case SET_USER_TYPE_LOADING:
      return { ...state, userTypeChangeLoading: action.payload };
    case SET_ORGANISATION_POST:
      return { ...state, orgPost: action.payload };
    case GET_INVOICES:
      return { ...state, invoices: action.payload };
    case GET_INVOICES_LOADING:
      return { ...state, invoicesLoading: action.payload };

    case SET_MARK_BY_LOADING:
      return { ...state, markByLoading: action.payload };
    case SET_POST_PERMISSION_GROUPS_LOADING:
      return { ...state, postPermissionGroupLoading: action.payload };

    case GET_PERMISSION_GRP:
      return { ...state, getPermissionGroup: action.payload };
    case GET_PERMISSION_GRP_LOADING:
      return { ...state, getPermissionLoading: action.payload };
    case SET_PERMISSION_UPDATE_LOADING:
      return { ...state, updatePermissionGroupLoading: action.payload };

    case PERMISSION_GROUP_DELETE_LOADING:
      return { ...state, deletePermissionLoading: action.payload };

    default:
      return state;
  }
}

/*** ACTIONS ***/

export function getDomains() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/domains`);
      dispatch({ type: GET_DOMAINS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getFeaturesForOrganisation() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/features`);
      dispatch({
        type: GET_FEATURES_FOR_ORGANISATION,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getFeatures(page = 1, limit = 15, keyword) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_FEATURES_LOADING,
        payload: true,
      });
      let params = {
        limit: 0,
        page: 0,
      };

      if (page !== 0) {
        params = {
          limit,
          page,
        };
      }

      if (keyword) params.keyword = keyword;

      const response = await axios.get(`/get-feature-data`, {
        params,
      });
      dispatch({ type: GET_FEATURES, payload: response.data });
      dispatch({
        type: GET_FEATURES_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_FEATURES_LOADING,
        payload: false,
      });
    }
  };
}

export function exportSelectedUser(search) {
  return async (dispatch) => {
    try {
      let params = {};
      if (search) {
        params.organisationId = search.organisationId;
        params.userKeyword = search.keyword;
      }
      const response = await axios.get(`/system/users/export`, {
        params,
      });
      dispatch({ type: GET_SELECTED_USERS_CSV, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectsForSystemLog() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/projects`);
      dispatch({ type: GET_PROJECTS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getUsers() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/org/users`);
      dispatch({ type: GET_USERS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getSystemLogTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/system-log-types`);
      dispatch({ type: GET_SYSTEM_LOG_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllQuestions(page = 1, outputId) {
  return async (dispatch) => {
    try {
      let limit = 12;

      dispatch({
        type: GET_QUESTIONS_LOADING,
        payload: true,
      });
      const params = {
        limit,
        page,
        outputId,
      };
      const response = await axios.get(`/get-question`, {
        params,
      });

      dispatch({ type: GET_QUESTIONS, payload: response.data });
      dispatch({
        type: GET_QUESTIONS_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_QUESTIONS_LOADING,
        payload: false,
      });
    }
  };
}

export function postOutcomes(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_OUTCOME_LOADING, payload: true });
      await axios.post(`/add-outcome`, values);
      authMsg('success', ['Outcome Added Successfully!', 'Outcome']);
      dispatch(getOutcomes());
      dispatch({ type: SET_OUTCOME_LOADING, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_OUTCOME_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOutcome(id, policyId, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_OUTCOME_UPDATE_LOADING, payload: true });
      await axios.put(`/outcomes/${id}/policy/${policyId}`, values);
      authMsg('success', ['Outcome has been successfully Updated!', 'Outcome']);
      dispatch({ type: SET_OUTCOME_UPDATE_LOADING, payload: false });
      dispatch(getOutcomes(id));
    } catch (error) {
      dispatch({ type: SET_OUTCOME_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateMarkBy(id, orgId, values, status) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_MARK_BY_LOADING, payload: true });
      await axios.put(`/organisation/${orgId}/invoice/${id}`, values);
      authMsg('success', [
        'Invoices Status has been successfully Updated!',
        'Invoices',
      ]);
      dispatch({ type: SET_MARK_BY_LOADING, payload: false });
      dispatch(
        getInvoices(orgId, {
          status,
        })
      );
    } catch (error) {
      dispatch({ type: SET_MARK_BY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateQuestions(outputId, methodId, values, currentPage) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_QUESTIONS_LOADING, payload: true });
      await axios.put(`/eval/${outputId}/questions/${methodId}`, values);
      authMsg('success', [
        'Evaluation question has been successfully Updated!',
        'Evaluation question ',
      ]);
      dispatch({ type: SET_QUESTIONS_LOADING, payload: false });
      dispatch(getAllQuestions(currentPage, outputId));
    } catch (error) {
      dispatch({ type: SET_QUESTIONS_LOADING, payload: false });
    }
  };
}

export function postIndustry(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_INDUSTRY_LOADING, payload: true });
      await axios.post(`/industry-sector-types`, values);
      authMsg('success', ['Industry Added Successfully!', 'Industry']);
      dispatch(getIndustryAll());
      dispatch({ type: SET_INDUSTRY_LOADING, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_INDUSTRY_LOADING, payload: false });
    }
  };
}

export function postVenueTypes(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: true });
      await axios.post(`/venue_types`, values);
      authMsg('success', ['Venue Type Added Successfully!', 'Venue Type ']);
      dispatch(getOrgVenuesTypes());
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function updateIndustry(id, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_INDUSTRY_LOADING, payload: true });
      await axios.put(`/industry-sector-types/${id}`, values);
      authMsg('success', [
        'Industry has been successfully Updated!',
        'Industry',
      ]);
      dispatch({ type: SET_INDUSTRY_LOADING, payload: false });
      dispatch(getIndustryAll());
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_INDUSTRY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateVenueType(id, form) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: true });
      await axios.put(`/venue_types/${id}`, form);
      authMsg('success', [
        'Venue Type has been successfully Updated!',
        'Venue Type',
      ]);
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: false });
      dispatch(getOrgVenuesTypes());
    } catch (error) {
      dispatch({ type: SET_VENUE_TYPE_LOADING, payload: false });
    }
  };
}

export function getIndustryAll(page = 1, limit = LIMIT, type) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_INDUSTRY_ALL_LOADING,
        payload: true,
      });
      const params = {
        limit,
        page,
      };
      const response = await axios.get(`/settings/industry-sector-types`, {
        params,
      });
      dispatch({ type: GET_INDUSTRY_ALL_SECTOR, payload: response.data });
      dispatch({
        type: GET_INDUSTRY_ALL_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_INDUSTRY_ALL_LOADING,
        payload: false,
      });
    }
  };
}

export function getPermissionGroups(page = 1, limit = 15, keyword) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PERMISSION_GRP_LOADING,
        payload: true,
      });
      let params = {};

      // eslint-disable-next-line
      if (page != 0) {
        params = {
          limit,
          page,
        };
      }

      if (keyword) params.keyword = keyword;

      const response = await axios.get(`/permission-groups-all`, {
        params,
      });
      dispatch({ type: GET_PERMISSION_GRP, payload: response.data });
      dispatch({
        type: GET_PERMISSION_GRP_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_PERMISSION_GRP_LOADING,
        payload: false,
      });
    }
  };
}

export function postPermissionGroups(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_POST_PERMISSION_GROUPS_LOADING,
        payload: true,
      });
      await axios.post(`/permission-groups`, values);
      authMsg('success', [
        'Permission Group Added Successfully!',
        'Permission Group',
      ]);
      dispatch(getPermissionGroups());
      dispatch({
        type: SET_POST_PERMISSION_GROUPS_LOADING,
        payload: false,
      });
    } catch (error) {
      console.error('error :', error);
      dispatch({
        type: SET_POST_PERMISSION_GROUPS_LOADING,
        payload: false,
      });
    }
  };
}

export function postAccountPermissionGroups(values) {
  return async (dispatch) => {
    try {
      await axios.post(`/account-permission-groups`, values);
      authMsg('success', [
        'Account Permission Group Added Successfully!',
        'Account Permission',
      ]);
    } catch (error) {
      console.error('error :', error);
    }
  };
}

export function updatePermissionGroup(id, values, page) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_PERMISSION_UPDATE_LOADING, payload: true });
      await axios.put(`/permission-group/${id}`, values);
      authMsg('success', [
        'Permission Group has been successfully Updated!',
        'Permission',
      ]);
      dispatch({ type: SET_PERMISSION_UPDATE_LOADING, payload: false });
      dispatch(getPermissionGroups(page));
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_PERMISSION_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateMethods(id, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_METHOD_LOADING, payload: true });
      await axios.put(`/methods/${id}`, values);
      authMsg('success', ['Method has been successfully Updated!', 'Method']);
      dispatch({ type: SET_METHOD_LOADING, payload: false });
      dispatch(getMethods(id));
    } catch (error) {
      dispatch({ type: SET_METHOD_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function updateEnableQuestion(outcomeId, methodId, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_ENABLE_LOADING, payload: true });
      await axios.put(`/questions/${outcomeId}/enable/${methodId}`, values);
      authMsg('success', [
        'Question Enable/ disable has been successfully Updated!',
        'Question',
      ]);
      dispatch({ type: SET_ENABLE_LOADING, payload: false });
      dispatch(getAllQuestions());
    } catch (error) {
      dispatch({ type: SET_ENABLE_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function postActivityType(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACTIVITY_TYPE_LOADING, payload: true });
      await axios.post(`/event-details`, values);
      authMsg('success', [
        'Activity Type Added Successfully!',
        'Activity Type',
      ]);
      dispatch(getEventDetails({ all: true }));
      dispatch({ type: SET_ACTIVITY_TYPE_LOADING, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_ACTIVITY_TYPE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateActivityType(id, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_ACTIVITY_UPDATE_LOADING, payload: true });
      await axios.put(`/event-details/${id}`, values);
      authMsg('success', [
        'Activity type has been successfully Updated!',
        'Activity type',
      ]);
      dispatch({ type: SET_ACTIVITY_UPDATE_LOADING, payload: false });
      dispatch(getEventDetails({ all: true }));
    } catch (error) {
      dispatch({ type: SET_ACTIVITY_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateFeatures(id, values, currentPage) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_FEATURE_UPDATE_LOADING, payload: true });
      await axios.put(`/features/${id}`, values);
      authMsg('success', ['Feature has been successfully Updated!', 'Feature']);
      dispatch({ type: SET_FEATURE_UPDATE_LOADING, payload: false });
      dispatch(getFeatures(currentPage));
    } catch (error) {
      dispatch({ type: SET_FEATURE_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOutputFormatTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/enum/output-format-item-types`);
      dispatch({ type: GET_OUTPUT_FORMAT_TYPES, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOutputFormatGroups(page = 1) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        limit: 10,
      };
      const response = await axios.get(`/output-format-group`, {
        params,
      });
      dispatch({ type: GET_OUTPUT_FORMAT_GROUP, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function postOutputFormatGroup(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: true });
      await axios.post(`/output-format-group`, values);
      authMsg('success', [
        'Output Format is Added Successfully!',
        'Output Format',
      ]);
      dispatch(getOutputFormatGroups());
      dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOutputFormats(page = 1) {
  return async (dispatch) => {
    try {
      let params = {
        page,
        limit: 10,
      };

      const response = await axios.get(`/output-format`, {
        params,
      });
      dispatch({ type: GET_OUTPUT_FORMAT, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function postOutputFormat(values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: true });
        await axios.post(`/output-format`, values);
        authMsg('success', [
          'Output Format is Added Successfully!',
          'Output Format',
        ]);
        dispatch(getOutputFormats());
        dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: false });
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_OUTPUT_FORMATTER_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function getAllUsersOrg(page = 1, search, disabled = 'all', limit = 15) {
  return async (dispatch) => {
    try {
      let order = 'asc';
      dispatch({ type: SET_ALL_ORG_USERS_LOADING, payload: true });
      const params = {
        limit,
        page,
        order,
        disabled,
      };
      if (search) {
        params.keyword = search.keyword;
        params.organisationId = search.organisationId;
      }
      const response = await axios.get(`/org/all-users-list`, {
        params,
      });
      dispatch({ type: SET_ALL_ORG_USERS_LIST, payload: response.data });
      dispatch({ type: SET_ALL_ORG_USERS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ALL_ORG_USERS_LOADING, payload: false });
    }
  };
}
export function postHelpPage(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_HELP_PAGE_LOAD, payload: true });
      await axios.post(`/help-pages`, values);
      authMsg('success', ['Help Page Added Successfully!', 'Help Page']);
      dispatch(getHelpPages());
      dispatch({ type: SET_HELP_PAGE_LOAD, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_HELP_PAGE_LOAD, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgAll(
  page = 1,
  accType = 'all',
  statusType = 'active',
  keyword = '',
  limit = 15
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ALL_ORG_LIST_LOADING, payload: true });
      let params = {
        limit,
        page,
        order: 'asc',
        accType,
        statusType,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const response = await axios.get(`/organisations-list`, {
        params,
      });
      dispatch({ type: SET_ALL_ORG_LIST, payload: response.data });
      dispatch({ type: SET_ALL_ORG_LIST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ALL_ORG_LIST_LOADING, payload: false });
    }
  };
}

export function updateHelpPage(id, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_UPDATE_HELP_PAGE_LOADING, payload: true });
      await axios.put(`/help-pages/${id}`, values);
      authMsg('success', [
        'Help page has been successfully Updated!',
        'Help Page',
      ]);
      dispatch({ type: SET_UPDATE_HELP_PAGE_LOADING, payload: false });
      dispatch(getHelpPages());
    } catch (error) {
      dispatch({ type: SET_UPDATE_HELP_PAGE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postFeatures(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_FEATURES_LOADING, payload: true });
      await axios.post(`/features`, values);
      authMsg('success', ['Feature Added Successfully!', 'Feature']);
      dispatch(getFeatures());
      dispatch({ type: SET_FEATURES_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_FEATURES_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}
export function getAllOrganisations() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/org/organisations`);
      dispatch({ type: GET_ORGANISATIONS, payload: response.data });
    } catch (error) {}
  };
}

export function postHelpTips(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_HELP_TIP_LOAD, payload: true });
      await axios.post(`/help-tips`, values);
      authMsg('success', ['Help Tip Added Successfully!', 'Help Tip']);
      dispatch(getActivityHelpTips());
      dispatch({ type: SET_HELP_TIP_LOAD, payload: false });
    } catch (error) {
      console.error('error :', error);
      dispatch({ type: SET_HELP_TIP_LOAD, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOutputFormatGroup(values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: true });
      await axios.put(`/output-format-group`, values);
      authMsg('success', [
        'Output Format Group has been successfully Updated!',
        'Output Format Group',
      ]);
      dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: false });
      dispatch(getOutputFormatGroups());
    } catch (error) {
      dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOutputFormat(id, values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: true });
        await axios.put(`/output-format/${id}`, values);
        authMsg('success', [
          'Output Formatter has been successfully Updated!',
          'Output Formatter',
        ]);
        dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: false });
        dispatch(getOutputFormats());
        resolve(true);
      } catch (error) {
        dispatch({ type: OUTPUT_FORMATTER_UPDATE_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function getOrgUser(orgId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORG_SELECTED_USER, payload: true });
      const response = await axios.get(`/organisation/${orgId}/users`, {});
      dispatch({
        type: SET_SELECTED_PROJECT_USER,
        payload: response.data,
      });
      dispatch({ type: GET_ORG_SELECTED_USER, payload: false });
    } catch (error) {
      dispatch({ type: GET_ORG_SELECTED_USER, payload: false });
    }
  };
}
export function putHelpTips(id, values) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_HELP_TIP_LOADING, payload: true });
      await axios.put(`/help-tips/${id}`, values);
      authMsg('success', [
        'Help Tips has been successfully Updated!',
        'Help Tips',
      ]);
      dispatch({ type: SET_HELP_TIP_LOADING, payload: false });
      dispatch(getActivityHelpTips());
    } catch (error) {
      dispatch({ type: SET_HELP_TIP_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOutputFormatter(id, type) {
  return async (dispatch) => {
    try {
      await axios.delete(`/output-formatter/${type}/${id}`);
      authMsg('success', [
        'Output Format is Deleted Successfully!',
        'Output Format',
      ]);
      if (type === 'group') {
        await dispatch(getOutputFormatGroups());
      } else {
        await dispatch(getOutputFormats());
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getSystemLog(page = 1, limit = LIMIT, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ALL_SYSTEM_LOG_LOADING, payload: true });
      const params = {
        limit,
        page,
      };
      if (values) {
        params.startDate = values.startDate;
        params.endDate = values.endDate;
        params.type = values.type;
        params.organisation = values.organisation;
        params.activity = values.activity;
        params.user = values.user;
        params.keyword = values.keyword;
      }

      const response = await axios.get(`/system-logs`, {
        params,
      });

      dispatch({ type: SET_SYSTEM_LOG_LIST, payload: response.data });
      dispatch({ type: SET_ALL_SYSTEM_LOG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ALL_SYSTEM_LOG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postOrganisation(values, page, accType, keyword) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: true });
        const response = await axios.post(`/organisation`, values);
        dispatch(getOrgAll(page, accType, keyword));
        authMsg('success', ['Account Added Successfully!', 'Account']);
        dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: false });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function postSignup(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: true });
      await axios.post(`/signup`, values);
      NotificationManager.success(
        'Account Created Successfully!',
        'Individual'
      );
      history.push('/verification');
      dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORGANISATION_POST_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
    }
  };
}

export function postSignupRequest(values, form) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_SUB_ORGANISATION_POST_LOADING,
        payload: true,
      });
      await axios.post(`/signup/request`, values);
      NotificationManager.success('Email Sent Successfully!', 'Organisation');
      dispatch({
        type: SET_SUB_ORGANISATION_POST_LOADING,
        payload: false,
      });
      form && form.resetForm();
    } catch (error) {
      dispatch({
        type: SET_SUB_ORGANISATION_POST_LOADING,
        payload: false,
      });
      NotificationManager.error(getCommonError(error));
    }
  };
}

export function updateOrganisationDetails(orgId, orgData, page, accType) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_UPDATE_LOADING, payload: true });
      const response = await axios.put(`/organisation-admin/${orgId}`, orgData);
      dispatch({ type: SET_ORGANIZATION_UPDATE, payload: response.data });
      dispatch(getOrgAll(page, accType));
      authMsg('success', [
        'Your Acccount Info has been successfully updated!',
        'Account Info',
      ]);
      dispatch({ type: SET_ORG_UPDATE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_UPDATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createNewUsers(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_USER_POST_LOADING, payload: true });
      await axios.post(`/organisation/user`, formData);
      authMsg('success', ['User has been successfully created!', 'New User']);
      await dispatch(getAllUsersOrg());
      dispatch({ type: SET_USER_POST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_USER_POST_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteUser(userId, isWithdrawnActivities = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_USER_REMOVE_LOADING, payload: true });

      await axios.delete(`/organisation/user/${userId}`);

      if (isWithdrawnActivities) {
        authMsg('success', [
          'user all invited activities has been withdrawn!',
          'User',
        ]);
      }
      authMsg('success', ['User has been successfully deleted!', 'User']);

      !isWithdrawnActivities && (await dispatch(getAllUsersOrg()));
      dispatch({ type: SET_USER_REMOVE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_USER_REMOVE_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganisationFeature(orgId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ORG_FEATURES_LOADING,
        payload: true,
      });
      const response = await axios.get(`/organisation/${orgId}/feature`);
      dispatch({ type: GET_ORG_FEATURES, payload: response.data });
      dispatch({
        type: GET_ORG_FEATURES_LOADING,
        payload: false,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: GET_ORG_FEATURES_LOADING,
        payload: false,
      });
    }
  };
}

export function updateFromSuperAdminUserType(orgId, userId, formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_USER_TYPE_LOADING, payload: true });
      await axios.put(`/organisation/${orgId}/user/${userId}/role`, formData);
      authMsg('success', ['User type has been successfully updated!', 'User']);
      await dispatch(getAllUsersOrg());
      dispatch({ type: SET_USER_TYPE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_USER_TYPE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

import React, { useState, useEffect, Fragment } from 'react';
import {
  isEmpty,
  map,
  get,
  find,
  flattenDeep,
  sum,
} from '../../../../utils/lodash';
import '../report.scss';
import { GoogleMap } from '../../../../components/googleMap';
import { Radio, Grid, Segment, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { ChartComponent } from '../activitySummary';
import { useDispatch } from 'react-redux';

import {
  getProjectActivitySummary,
  getProjectActivitySummaryVenues,
  getProjectActivitySummaryParticipants,
} from '../../../../store/actions';
import { CustomSegment, EmptyContainer } from '../../../../components';
import { PROGRAM_REPORT_LOADING } from '../../../../utils/constant';
import { getActivityReportLoadingAttributes } from '../../../../utils/activity';

export const ProgrammeActivity = ({
  dateRangeChosenLabel,
  parent = false,
  superAdmin = false,
}) => {
  const background = '#E0E0E0';
  const gray = '#757575';
  const black = '#000000';
  const white = '#ffff';

  const [activityType, setActivityType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [venueType, setVenueType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [participantsType, setParticipantsType] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      size: [],
    },
  });

  const [activitiesOnly, setActivitiesOnly] = useState(true);
  const [venuesOnly, setVenuesOnly] = useState(true);
  const [participantsOnly, setParticipantsOnly] = useState(true);

  const venueChart = useSelector((state) => state.report.venueChart);
  const participantsData = useSelector(
    (state) => state.report.participantsData
  );

  const actSummary = useSelector((state) => state.report.actSummary);
  const outcomes = get(actSummary, 'outcomes', {});

  const stats = useSelector((state) => state.report.activityStats);

  //loaders
  const reportInitialState = useSelector((state) => state.report);
  const loadingValues = getActivityReportLoadingAttributes(
    PROGRAM_REPORT_LOADING,
    reportInitialState
  );
  const {
    activityStatsLoading,
    activityReportLoading,
    venueReportLoading,
    participantLoading,
    // organisationMapLoading, // commented out
    activityMapLoading,
    contractorMapLoading,
  } = loadingValues;

  const statuses = useSelector(
    (state) => state.report.reportFilterOptions.statuses
  );

  const report = useSelector((state) => state.report);

  const advancedFilters = useSelector(
    (state) => state.report.reportFilterOptions
  );
  const currentActivity = useSelector(
    (state) => state.activity.currentActivity
  );

  const appendByStatusValue = (value, type, setArrayType) => {
    value.data.byStatus[type].map((data, i) =>
      setArrayType((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], data],
      }))
    );
  };

  const makeChartObject = (chartType, setArrayType) => {
    if (!isEmpty(chartType)) {
      chartType.map((chartTitle) => {
        setArrayType((prevState) => ({
          ...prevState,
          mainTitle: [...prevState.mainTitle, chartTitle.mainTitle],
          dataset: [...prevState.dataset, null],
          styles: {
            size: [...prevState.styles.size, 16],
            color: [...prevState.styles.color, gray],
            weight: [...prevState.styles.weight, '600'],
            highlight: [...prevState.styles.highlight, background],
          },
        }));
        if (statuses.includes(1))
          setArrayType((prevState) => ({
            ...prevState,
            development: [...prevState.development, null],
          }));
        if (statuses.includes(2))
          setArrayType((prevState) => ({
            ...prevState,
            active: [...prevState.active, null],
          }));
        if (statuses.includes(3))
          setArrayType((prevState) => ({
            ...prevState,
            completed: [...prevState.completed, null],
          }));

        chartTitle.data.label.map((label, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            mainTitle: [...prevState.mainTitle, label],
            styles: {
              size: [...prevState.styles.size, 14],
              color: [...prevState.styles.color, black],
              weight: [...prevState.styles.weight, '400'],
              highlight: [...prevState.styles.highlight, white],
            },
          }))
        );

        chartTitle.data.dataset.map((data, i) =>
          setArrayType((prevState) => ({
            ...prevState,
            dataset: [...prevState.dataset, data],
          }))
        );

        if (statuses.includes(1))
          appendByStatusValue(chartTitle, 'development', setArrayType);
        if (statuses.includes(2))
          appendByStatusValue(chartTitle, 'active', setArrayType);
        if (statuses.includes(3))
          appendByStatusValue(chartTitle, 'completed', setArrayType);
        return null;
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(venueChart)) {
      setVenueType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(venueChart, setVenueType, venuesOnly);
    }
  }, [venueChart, venuesOnly, statuses]);

  useEffect(() => {
    if (!isEmpty(outcomes)) {
      setActivityType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(outcomes, setActivityType, activitiesOnly);
    }
  }, [outcomes, activitiesOnly, statuses]);

  useEffect(() => {
    if (!isEmpty(participantsData)) {
      setParticipantsType({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          size: [],
        },
      });
      makeChartObject(participantsData, setParticipantsType, participantsOnly);
    }
  }, [participantsData, activitiesOnly, statuses]);

  const dispatch = useDispatch();

  const onChangeActivitiesOnly = () => {
    setActivitiesOnly(!activitiesOnly);
    advancedFilters.activityOutcomeOnly = !activitiesOnly;
    dispatch(getProjectActivitySummary(currentActivity.id, advancedFilters));
  };

  const onChangeVenuesOnly = () => {
    setVenuesOnly(!venuesOnly);
    advancedFilters.venueOutcomeOnly = !venuesOnly;
    dispatch(
      getProjectActivitySummaryVenues(currentActivity.id, advancedFilters)
    );
  };

  const onChangeParticipantsOnly = () => {
    setParticipantsOnly(!participantsOnly);
    advancedFilters.participantOutcomeOnly = !participantsOnly;
    dispatch(
      getProjectActivitySummaryParticipants(currentActivity.id, advancedFilters)
    );
  };

  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );
  const organization = useSelector((state) => state.organization.organization);

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id === organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const getHeight = (data) => {
    return `${5 * data.length}rem`;
  };

  const renderMaps = () => {
    const mapData = [
      // {
      //   data: report.projectOrganizationMapData,
      //   title: 'Organisation',
      //   isEmptyData: isEmpty(report.projectOrganizationMapData),
      // loading:orgMapDataLoading
      // },
      {
        data: report.projectVenueMapData,
        title: 'Activities',
        isEmptyData: isEmpty(report.projectVenueMapData),
        loading: activityMapLoading,
      },
      {
        data: report.projectContractorMapData,
        title: 'Contractor',
        isEmptyData: isEmpty(report.projectContractorMapData),
        loading: contractorMapLoading,
      },
    ];

    return (
      <Segment>
        {map(mapData, (item, key) => {
          return (
            <Fragment key={`programme_activity_${key}`}>
              {/* <br /> */}
              <div className="programme-activity-heading">
                <div className="content">
                  <label>{item.title}</label>
                </div>
              </div>
              <div className="programme-map">
                <CustomSegment title={item.title} className="activities">
                  <Segment loading={report.orgMapDataLoading}>
                    {!item.isEmptyData ? (
                      <GoogleMap data={item.data} type={item.title} />
                    ) : (
                      <EmptyContainer msg="No data found." />
                    )}
                  </Segment>
                </CustomSegment>
              </div>
            </Fragment>
          );
        })}
      </Segment>
    );
  };

  const renderHeader = () => {
    return (
      <Grid>
        <Grid.Row className="programme-activity">
          <Grid.Column width={16}>
            <Grid className="activity-summary-grid" padded>
              {parent || superAdmin ? null : (
                <div className="org-down">
                  <Grid.Row>
                    <label>Organisation Type</label>
                  </Grid.Row>
                  <Grid.Row>
                    <h6>{getOrganizationSectorType()}</h6>
                  </Grid.Row>
                </div>
              )}
              {activityStatsLoading ? (
                <Loader active size="medium" indeterminate />
              ) : (
                <div className="left-align">
                  <div className="activity-name">
                    <div className="circle circle-bottom activity-warning" />
                    <div className="col-wise">
                      <div>
                        <label>In-development</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalDevelopmentCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="circle circle-bottom activity-success" />
                    <div className="col-wise">
                      <div>
                        <label>Active</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalActiveCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="circle circle-bottom activity-completed" />
                    <div className="col-wise">
                      <div>
                        <label>Completed</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalCompletedCount}
                      </div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="col-wise">
                      <div>
                        <label>Total Activities</label>
                      </div>
                      <div className="number-centre">
                        {stats.totalActivityCount}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderActivitySummary = () => {
    return (
      <Segment>
        <div className="activity-count-info">{renderHeader()}</div>
        <Segment loading={activityReportLoading}>
          <br />

          <div className="programme-activity-heading">
            <div className="content">
              <label>Activity Type</label>
              <div
                className="mr-3"
                style={{ display: ' flex', flexDirection: 'row' }}
              >
                Reportable Activities Only: &nbsp;
                <div className="two-step-radio-container-admin">
                  <Radio
                    toggle
                    checked={activitiesOnly}
                    onChange={() => onChangeActivitiesOnly()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            id="activityType"
            style={{
              height: !isEmpty(get(activityType, 'dataset', null))
                ? getHeight(get(activityType, 'dataset', 0))
                : 'auto',
              width: 'auto',
            }}
            className={isEmpty(activityType.dataset) ? 'programme-map' : null}
          >
            {isEmpty(get(activityType, 'dataset', null)) ? (
              <EmptyContainer msg="No data found." />
            ) : (
              <ChartComponent
                toggle={activitiesOnly}
                data={
                  !isEmpty(get(actSummary, 'outcomes', [])) &&
                  !isEmpty(activityType.dataset) &&
                  sum(flattenDeep(activityType.dataset)) !== 0
                    ? activityType
                    : {
                        ...activityType,
                        dataset: [],
                        development: [],
                        active: [],
                        completed: [],
                      }
                }
                chartType={'activitiesOnly'}
                status={statuses}
              />
            )}
          </div>
        </Segment>
      </Segment>
    );
  };

  const renderActivityVenues = () => {
    return (
      <Segment loading={venueReportLoading}>
        <div className="programme-activity-heading">
          <div className="content">
            <label>Activity Venues</label>
            <div
              className="mr-3"
              style={{ display: ' flex', flexDirection: 'row' }}
            >
              Reportable Activities Only: &nbsp;
              <div className="two-step-radio-container-admin">
                <Radio
                  toggle
                  checked={venuesOnly}
                  onChange={() => onChangeVenuesOnly()}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="venueType"
          style={{
            height: !isEmpty(get(venueType, 'dataset', null))
              ? getHeight(get(venueType, 'dataset', 0))
              : 'auto',
            width: 'auto',
            background: 'white',
          }}
          className={isEmpty(venueType.dataset) ? 'programme-map' : null}
        >
          {isEmpty(get(venueType, 'dataset', null)) ? (
            <EmptyContainer msg="No data found." />
          ) : (
            <ChartComponent
              toggle={venuesOnly}
              // data={!isEmpty(venueType) && venueType}
              data={
                !isEmpty(venueChart) &&
                !isEmpty(venueType.dataset) &&
                sum(flattenDeep(venueType.dataset)) !== 0
                  ? venueType
                  : {
                      ...venueType,
                      dataset: [],
                      development: [],
                      active: [],
                      completed: [],
                    }
              }
              chartType={'venuesOnly'}
              status={statuses}
            />
          )}
        </div>
      </Segment>
    );
  };

  const renderActivityParticipants = () => {
    return (
      <Segment loading={participantLoading}>
        <div className="programme-activity-heading">
          <div className="content">
            <label>Activity Participants</label>
            <div
              className="mr-3"
              style={{ display: ' flex', flexDirection: 'row' }}
            >
              Reportable Activities Only: &nbsp;
              <div className="two-step-radio-container-admin">
                <Radio
                  toggle
                  checked={participantsOnly}
                  onChange={() => onChangeParticipantsOnly()}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="participantsType"
          style={{
            height: !isEmpty(get(participantsType, 'dataset', null))
              ? getHeight(get(participantsType, 'dataset', 0))
              : 'auto',
            width: 'auto',
            background: 'white',
          }}
          className={isEmpty(participantsType.dataset) ? 'programme-map' : null}
        >
          {isEmpty(get(participantsType, 'dataset', null)) ? (
            <EmptyContainer msg="No data found." />
          ) : (
            <ChartComponent
              toggle={participantsOnly}
              // data={!isEmpty(participantsType) && participantsType}
              data={
                !isEmpty(participantsData) &&
                !isEmpty(participantsType.dataset) &&
                sum(flattenDeep(participantsType.dataset)) !== 0
                  ? participantsType
                  : {
                      ...participantsType,
                      dataset: [],
                      development: [],
                      active: [],
                      completed: [],
                    }
              }
              chartType={'participantsOnly'}
              status={statuses}
            />
          )}
        </div>
      </Segment>
    );
  };

  return (
    <>
      {renderActivitySummary()}
      {renderActivityVenues()}
      {renderActivityParticipants()}
      {renderMaps()}
    </>
  );
};

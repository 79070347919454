import './activity.scss';

/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';

import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Grid, Modal, Icon, Loader } from 'semantic-ui-react';

import {
  removeCachedActivitiesSteps,
  setCachedActivitiesSteps,
} from '../../../utils/cacheStore';
import {
  getReports,
  putActivity,
  setUserOnboardingOption,
} from '../../../store/actions';

import infoIcon from '../../../assets/info-outline.svg';
import { isParentProgramActivity } from '../../../utils/activity';
import { checkMemberPermission } from '../../../utils/activity/userPermission';
import WHITELIST_USERS from '../../../utils/activity/specialPermissionUsers';
// import ReactToPrint from 'react-to-print';

class ArchiveActivity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async closeActivity() {
    try {
      const { modal } = this.state;
      const { currentActivity, closable, putActivity, user } = this.props;
      if (!currentActivity.isLinkedActivity && user.role === 'guest') {
        NotificationManager.warning(
          'Guest user do not have permission to execute this action.',
          'Takso Access'
        );
      } else {
        await putActivity('closeActivity', {
          statusId: closable === '2' || closable === 2 ? 3 : 2,
        });
      }
      if (modal) {
        this.toggle();
      }
    } catch (error) {
      // console.error('ActivitiesContainer -> closeActivity -> error', error);
    }
  }

  render() {
    const { modal } = this.state;
    const { closable, currentActivity, user, validationOutput } = this.props;
    const reportPermission = checkMemberPermission(
      user,
      currentActivity.members,
      'Report'
    );

    const isWhitelistOwner = WHITELIST_USERS.includes(user.email);

    return currentActivity.parentId && currentActivity.statusId === 3 ? null : (
      <Fragment>
        <Button
          loading={
            this.props.actPut.closeActivity
              ? this.props.actPut.closeActivity
              : false
          }
          disabled={
            (this.props.actPut.closeActivity || !validationOutput
              ? true
              : false) ||
            (user.id != currentActivity.ownerId &&
              !reportPermission.write &&
              !isWhitelistOwner)
          }
          className={`close-button ${
            closable === 2 || closable === '2'
              ? validationOutput
                ? ' close-valid-btn'
                : ''
              : ' close-reopen-btn'
          }`}
          onClick={() =>
            currentActivity.parentId && currentActivity.statusId === 2
              ? this.toggle()
              : this.closeActivity()
          }
        >
          {closable === 2 || closable === '2'
            ? 'Archive Activity'
            : 'Reopen Activity'}
        </Button>
        <Modal size="tiny" open={modal} onClose={() => this.toggle()}>
          <Modal.Header>Complete activity record</Modal.Header>
          <Modal.Content>
            <p className="complete-act-info">
              Thank you for completing the activity. From this point you will no
              be able to edit this activity. The Program manager will review
              your activity report and you will be notified when it has been
              accepted. If there are any issues the program manager will contact
              you directly.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="modal-cancel" onClick={() => this.toggle()}>
              Cancel
            </Button>
            <Button
              className="modal-proceed"
              onClick={() => this.closeActivity()}
              loading={
                this.props.actPut.closeActivity
                  ? this.props.actPut.closeActivity
                  : false
              }
              disabled={
                (this.props.actPut.closeActivity || !validationOutput
                  ? true
                  : false) ||
                (user.id != currentActivity.ownerId && !reportPermission.write)
              }
            >
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}
class ActivitiesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      infoSteps: false,
      waitExportLoading: true,
      modal: false,
    };
  }

  async componentDidMount() {
    try {
      const { user } = this.props;
      if (!user.onboardingOption.activityFormTips) {
        this.setState({
          infoSteps: true,
        });
      }

      setTimeout(() => {
        this.setState({
          waitExportLoading: false,
        });
      }, 5000);
    } catch (error) {
      removeCachedActivitiesSteps();
    }
  }

  getJoyRide(data) {
    if (data.type === 'tour:end') {
      this.setState(
        {
          infoSteps: false,
        },
        () => {
          setCachedActivitiesSteps(true);
        }
      );
    }
  }

  async repeatInfo() {
    try {
      this.setState({
        infoSteps: true,
      });
    } catch (error) {
      this.setState({
        infoSteps: false,
      });
    }
  }

  async showInfoModal() {
    this.setState({
      modal: true,
    });
  }

  async onExport() {
    const { downloadLink, getReports } = this.props;
    getReports(downloadLink, 'reportExport');
  }

  render() {
    const { modal } = this.state;
    const {
      children,
      title,
      previous,
      next,
      closable,
      currentActivity,
      currentTab,
      getReportsLoading,
      isEvaluationPageOpen = false,
      // reportAllImagesLoaded = true,
      getReports,
      exportJsonLink,
      downloadLink,
      user,
    } = this.props;

    return (
      <div className="full">
        {/* ref={el => (this.componentRef = el)} */}
        <div
          className={`act-content-layer ${
            currentActivity &&
            isParentProgramActivity(currentActivity.activityPlanTypeId)
              ? ' parent-act'
              : ''
          }`}
        >
          <div className="content-container">
            <Grid>
              {title === 'REPORT' ? (
                <Grid.Row className="act-row no-print" textAlign="right">
                  <Grid.Column
                    // className="report-graph-col"
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="export-dropdown-btn"
                  >
                    {
                      // reportAllImagesLoaded
                      this.state.waitExportLoading ? (
                        <Button
                          size="small"
                          className="Primary-Button"
                          icon
                          labelPosition="left"
                          // disabled={true}
                        >
                          <Icon>
                            <Loader active size="tiny" indeterminate />
                          </Icon>
                          Preparing Graphs...
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          className="Primary-Button export"
                          icon
                          labelPosition="left"
                          loading={
                            getReportsLoading['reportExport'] ? true : false
                          }
                          disabled={
                            getReportsLoading['reportExport'] ? true : false
                          }
                        >
                          <Dropdown
                            disabled={getReportsLoading['reportExport']}
                            text={'Export'}
                            direction="left"
                            //inline
                            selectOnBlur={false}
                            icon="angle down"
                            //className="export-dropdown-btn"
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="dropdown-customItem"
                                onClick={() => {
                                  getReports(downloadLink, 'reportExport').then(
                                    (status) => {
                                      if (status) {
                                        this.showInfoModal();
                                      }
                                    }
                                  );
                                }}
                                children={
                                  <label
                                    style={{
                                      color: 'black',
                                    }}
                                  >
                                    Download Report
                                  </label>
                                }
                              />
                              {user.role === 'admin' ? (
                                <Dropdown.Item
                                  className="dropdown-customItem"
                                  onClick={() => {
                                    getReports(
                                      exportJsonLink,
                                      'reportExport'
                                    ).then((status) => {
                                      if (status) {
                                        this.showInfoModal();
                                      }
                                    });
                                  }}
                                  children={
                                    <label
                                      style={{
                                        color: 'black',
                                      }}
                                    >
                                      Download JSON
                                    </label>
                                  }
                                />
                              ) : null}
                              {/* <ReactToPrint
                              trigger={() => {
                                return (
                                  <Dropdown.Item
                                    className="dropdown-customItem"
                                    onClick={() => window.print()}
                                  >
                                    <label
                                      style={{
                                        color: 'black',
                                      }}
                                    >
                                      Print Report
                                    </label>
                                  </Dropdown.Item>
                                );
                              }}
                              content={() => this.componentRef}
                            /> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Button>
                      )
                    }
                  </Grid.Column>
                </Grid.Row>
              ) : null}
              <Grid.Row className="act-row">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="activity-sub-column"
                >
                  {children}
                </Grid.Column>
              </Grid.Row>

              {/* <div className="extra-height-container" /> */}
              {isEvaluationPageOpen ? null : (
                <>
                  {title === 'ERROR' ? null : (
                    <Grid.Row className="act-footer" textAlign="right">
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="footer-buttons">
                          {currentTab && currentTab !== 0 ? (
                            <Button
                              className="Secondary-Button"
                              onClick={() => previous()}
                            >
                              Previous
                            </Button>
                          ) : null}
                          {title === 'REPORT' ||
                          (title === 'GREEN LIGHT' &&
                            this.props.currentActivity.statusId !==
                              2) ? null : (
                            <Button
                              className="Primary-Button"
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )}
                          {title === 'REPORT' && closable > 1 ? (
                            <ArchiveActivity {...this.props} />
                          ) : null}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </>
              )}
            </Grid>
          </div>
        </div>
        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          size={'mini'} //  : 'small'
        >
          <Modal.Content>
            <div className="align-self-center justify-content-center text-center my-3">
              <img
                className="modal-report-error-img"
                alt="greenRight"
                src={infoIcon}
              />{' '}
            </div>

            <p className="text-center font-weight-bold my-3 modal-report-error-header">
              Report Downloaded! <br />
              Having Trouble? Try These Tips
            </p>
            <p className="text-center modal-report-error-paragraph">
              If there are any issues with the report, please download again
              without reloading the page. If problems persist, please check the
              help page for more advice.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <div className="align-self-center justify-content-center text-center">
              <Button
                className="modal-proceed px-5"
                content="Got it"
                onClick={() => this.setState({ modal: false })}
              />
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    currentActivity: state.activity.currentActivity,
    getReportsLoading: state.activity.getReportsLoading,
    actPut: state.activity.actPut,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
      getReports,
      setUserOnboardingOption,
    },
    dispatch
  );
};

ActivitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesContainer);

export { ActivitiesContainer };

import './activities.scss';

/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { PermissionDenied } from '../../components/errorStatus';
import {
  Button,
  Dropdown,
  Icon,
  Loader,
  Menu,
  Tab,
  Modal,
  Radio,
} from 'semantic-ui-react';
import Joyride from 'react-joyride';

import { CustomSegment, EmptyContainer, IssuesBox } from '../../components';
import {
  ACTIVITY_TABS,
  APP_NAME,
  PARENT_ACTIVITY_TABS,
} from '../../utils/constant';
import {
  compact,
  find,
  get,
  isEmpty,
  isString,
  map,
  remove,
  size,
} from '../../utils/lodash';
import {
  getAllActivityData,
  getReports,
  putActivityStatus,
  setUserOnboardingOption,
} from '../../store/actions';
import {
  getActivityType,
  getEvidenceTitle,
  getIssuesInProject,
  getPageDisable,
  isTabDeactivated,
} from '../../utils';
import {
  ActivitiesContainer,
  ActivityEvidence,
  ActivityGoal,
  ActivityObjective,
  ActivityParentEvidence,
  ActivityParentObjective,
  ActivityParentTheory,
  ActivityTheory,
  ActualOutcomes,
  ActualOutput,
  CustomItem,
  GreenLightComponent,
  MainEvalPlan,
  Notes,
  ParentOutcomeCharts,
  ReportActivity,
  TheoryOfChange,
} from './form';
import { BeaconComponent, Tooltip } from '../../components';
import Documentation from './form/documentation';
import ActivityPlan from './form/activityPlan';
import { EvaluationPlanSection } from './form/evaluation/evaluationPlanSection';
import { OutcomeSummarySections } from './form/evaluation/outcomeSummarySections';
import { getGrantTrialDefinedTexts } from '../../utils/pageText';
import { isParentProgramActivity } from '../../utils/activity';
import { checkPermissionDenied } from '../../utils/activity/userPermission';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    target: '.activity-goal',
    content:
      'You will be asked to nominate the organisation goal that this activity will address. Once selected the planning has commenced, it cannot be changed.',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.ui.pointing.secondary.menu',
    content: (
      <p>
        The steps between <b>Objective</b> and <b>Evaluation</b> can be changed
        and edited as often as required, until the activity has got the{' '}
        <b>Green Light</b> to proceed
      </p>
    ),
    placement: 'bottom',
  },
  {
    target: '.activity-report',
    content: (
      <p>
        The <b>Report page</b> is a real-time summary of the activity as it is
        being built and can be viewed, printed or exported at any time.
      </p>
    ),
    placement: 'bottom-end',
  },
];

const ActivitySteps = (props) => {
  const { match } = props;
  const [state, setState] = useState({
    panes: [],
    currentTab: 0,
    disabled: ['project.edit.documentation', 'project.edit.report'],
    approvalMethod: 'allowSelf',
    authorisationCode: null,
    approvalView: false,
    confirmModal: false,
    single: false,
    reportAllImagesLoaded: true,
    isEvaluationPageOpen: false,
    infoSteps: false,
    isSeries: false,
  });

  const dispatch = useDispatch();
  const selectors = useSelector((state) => state);
  const {
    activity,
    authentication: { user },
    extra: { evidenceTypes, helpTips, parentOutcomes, festivalTypes },
  } = selectors;
  const navigate = useNavigate();
  const { currentActivity, getReportsLoading } = activity;
  props = { ...props, ...activity, user };

  useEffect(() => {
    const fetchData = async () => {
      document.title = `Activities - Flow | ${APP_NAME}`;
      try {
        const _approvalMethod =
          currentActivity.approvalMethod === 1 ? 'ppmsCode' : 'allowSelf';
        const _authorisationCode = currentActivity.codeLabel
          ? currentActivity.codeLabel
          : null;

        setState((prevState) => ({
          ...prevState,
          approvalMethod: _approvalMethod,
          authorisationCode: _authorisationCode,
        }));

        if (_authorisationCode || _approvalMethod !== 'allowSelf') {
          setState((prevState) => ({
            ...prevState,
            approvalView: true,
          }));
        }

        if (match.params) {
          dispatch(getAllActivityData(match.params.id));
          changeRoute(match.params.type);
        }

        window.scrollTo(0, 0);

        if (!user.onboardingOption.activityFormTips) {
          setState((prevState) => ({
            ...prevState,
            infoSteps: true,
          }));
        }
      } catch (error) {
        console.error('ActivitySteps -> useEffect -> error', error);
      }
    };

    fetchData();
  }, []);

  async function changeRoute(value) {
    try {
      const panes = generateTabs();

      const tab = currentActivity
        ? find(
            isParentProgramActivity(currentActivity.activityPlanTypeId)
              ? PARENT_ACTIVITY_TABS
              : ACTIVITY_TABS,
            (o) => {
              return o.id === value || o.route === value;
            }
          )
        : null;

      if (!tab) return;

      const _isTabDeactivated = isTabDeactivated(
        'CURRENT',
        currentActivity,
        panes,
        tab.id
      );

      if (_isTabDeactivated) {
        setState((prevState) => ({
          ...prevState,

          currentTab: 0,
        }));

        navigate(`/activities/${match.params.id}/goal`);
      } else {
        setState((prevState) => ({
          ...prevState,

          currentTab: tab.id,
        }));
        navigate(`/activities/${match.params.id}/${tab.route}`);
      }
    } catch (error) {
      return false;
    }
  }

  const getAllImages = async (value) => {
    return new Promise((resolve, reject) => {
      try {
        let getAllGraphImages = value['getAllGraphImages'];
        let reportLoaded = value['reportLoaded'];
        let reportAllImagesLoadedLocal = false;

        if (getAllGraphImages || reportLoaded) {
          reportAllImagesLoadedLocal = true;
        } else {
          reportAllImagesLoadedLocal = false;
        }

        setState((prevState) => ({
          ...prevState,
          reportAllImagesLoaded: reportAllImagesLoadedLocal,
        }));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  function generateTabs() {
    try {
      let completionProgress = null;
      let statusId = null;
      // const {
      //   user,
      //   currentActivity,
      //   getReports,
      //   getReportsLoading,
      //   festivalTypes,
      // } = props;

      const { reportAllImagesLoaded, isSeries } = state;

      const member = find(currentActivity.members, (member) => {
        return Number(member.userId) === Number(user.id);
      });
      // const primaryPolicy = currentActivity.primaryPolicy
      //   ? currentActivity.primaryPolicy.policyName
      //   : '';
      // const secondaryPolicy = currentActivity.secondaryPolicy
      //   ? currentActivity.secondaryPolicy.policyName
      //   : '';
      if (currentActivity && currentActivity.completionProgress) {
        completionProgress = currentActivity.completionProgress;
        statusId = currentActivity.statusId;
      }

      // const [evaluation1, evaluation2] = currentActivity.evaluation;
      let validationOutput = null;

      const domains = map(
        currentActivity.parentDomains,
        (i) => i.policy.policyName
      );

      try {
        // TODO: Add new validations to evaluation plan
        validationOutput = getIssuesInProject(currentActivity);
      } catch (error) {
        // console.error(error);
      }

      const hasObjectives = isParentProgramActivity(
        currentActivity.activityPlanTypeId
      )
        ? currentActivity.parentOutcomes &&
          currentActivity.parentOutcomes.length > 0
        : currentActivity.objective && currentActivity.objective.length > 0;

      let panes = [
        {
          menuItem: (
            <Menu.Item
              /*
               * Note:  activity-goal class name used for help tip showing class name (don't change it or remove it)
               */
              className="tab-item activity-goal"
              key="project.edit.start"
              disabled={state.isEvaluationPageOpen}
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                statusList={completionProgress}
                statusId={statusId}
                hasObjectives={hasObjectives}
                title="GOAL"
                popupState={get(props, 'user.options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupContentObject={{
                  data: helpTips,
                  key: '10',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="GOAL"
                children={
                  <CustomSegment
                    className="organization-goal-to-be-addressed"
                    title={getGrantTrialDefinedTexts(
                      get(currentActivity, 'parentId', null),
                      'Organisation Goal to be addressed',
                      1
                    )}
                    rightHeader={
                      <Loader
                        active={
                          activity.actPut.goal ? activity.actPut.goal : false
                        }
                        inline
                      />
                    }
                    children={
                      <ActivityGoal currentState="project.edit.start" />
                    }
                  />
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.objective"
              className="tab-item"
              disabled={state.isEvaluationPageOpen}
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                title="OBJECTIVE"
                popupState={get(user, 'options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupContentObject={{
                  data: helpTips,
                  key: '11',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={selectors.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="OBJECTIVE"
                children={
                  <CustomSegment
                    className="set-objective"
                    title="Set Objective"
                    children={
                      isParentProgramActivity(
                        currentActivity.activityPlanTypeId
                      ) ? (
                        <ActivityParentObjective
                          statusId={statusId}
                          currentState="project.edit.objective"
                        />
                      ) : (
                        <ActivityObjective
                          statusId={statusId}
                          currentState="project.edit.objective"
                        />
                      )
                    }
                    rightHeader={
                      <Loader
                        active={
                          activity.actPut.objective
                            ? activity.actPut.objective
                            : false
                        }
                        inline
                      />
                    }
                  />
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.evidence"
              className="tab-item"
              disabled={
                state.isEvaluationPageOpen ||
                (!Boolean(hasObjectives) &&
                  !isParentProgramActivity(currentActivity.activityPlanTypeId))
              }
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                statusList={completionProgress}
                statusId={statusId}
                title="EVIDENCE"
                popupState={get(props, 'user.options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupContentObject={{
                  data: helpTips,
                  key: '12',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="EVIDENCE"
                children={
                  <CustomSegment
                    className="evidence-about-object"
                    title={getEvidenceTitle(currentActivity)}
                    children={
                      isParentProgramActivity(
                        currentActivity.activityPlanTypeId
                      ) ? (
                        <ActivityParentEvidence
                          currentState="project.edit.evidence"
                          {...props}
                        />
                      ) : (
                        <ActivityEvidence
                          currentState="project.edit.evidence"
                          {...props}
                        />
                      )
                    }
                    rightHeader={
                      <div className="export-dropdown-btn">
                        <Button
                          size="tiny"
                          disabled={
                            getReportsLoading['evidenceExport'] ? true : false
                          }
                          loading={
                            getReportsLoading['evidenceExport'] ? true : false
                          }
                          className="Primary-Button"
                        >
                          <Dropdown
                            disabled={getReportsLoading['evidenceExport']}
                            text={'Export'}
                            direction="left"
                            selectOnBlur={false}
                            icon="angle down"
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text="Download Evidence"
                                className="dropdown-customItem"
                                onClick={() =>
                                  dispatch(
                                    getReports(
                                      `/project/${currentActivity.id}/evidence/export`,
                                      'evidenceExport'
                                    )
                                  )
                                }
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Button>
                      </div>
                    }
                  />
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.theoryOfChange"
              className="tab-item"
              disabled={
                state.isEvaluationPageOpen ||
                (!Boolean(hasObjectives) &&
                  !isParentProgramActivity(currentActivity.activityPlanTypeId))
              }
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                parentId={currentActivity.parentId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                title="THEORY OF CHANGE"
                popupState={get(props, 'user.options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupContentObject={{
                  data: helpTips,
                  key: '13',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="Theory of Change"
                children={
                  isParentProgramActivity(
                    currentActivity.activityPlanTypeId
                  ) ? (
                    <ActivityParentTheory />
                  ) : (
                    <ActivityTheory />
                  )
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.activityPlan"
              className="tab-item"
              disabled={state.isEvaluationPageOpen}
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                linkedActivities={currentActivity.linkedActivities}
                title="ACTIVITY PLAN"
                popupState={get(props, 'user.options.tips')}
                popupContentObject={{
                  data: helpTips,
                  key: '14',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="ACTIVITY PLAN"
                children={<ActivityPlan {...props} />}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.evaluationPlan"
              className="tab-item"
              disabled={!Boolean(hasObjectives)}
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                title="EVALUATION"
                linkedActivities={currentActivity.linkedActivities}
                popupState={get(props, 'user.options.tips')}
                popupContentObject={{
                  data: helpTips,
                  key: isParentProgramActivity(
                    currentActivity.activityPlanTypeId
                  )
                    ? '115'
                    : '15',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="EVALUATION"
                isEvaluationPageOpen={state.isEvaluationPageOpen}
                children={
                  <>
                    {!isParentProgramActivity(
                      currentActivity.activityPlanTypeId
                    ) &&
                      statusId === 1 && (
                        <Fragment>
                          <MainEvalPlan
                            currentState="project.edit.evaluationPlan"
                            isEvaluationPageOpen={state.isEvaluationPageOpen}
                            getEvaluationPage={(value) =>
                              getEvaluationPage(value)
                            }
                            {...props}
                          />
                        </Fragment>
                      )}
                    {!state.isEvaluationPageOpen && (
                      <div className="my-4">
                        <GreenLightComponent
                          currentTab={state.currentTab}
                          loading={activity.actGetLoading}
                          next={() => nextTab()}
                          previous={() => previousTab()}
                          title="GREEN LIGHT"
                          evidenceTypes={evidenceTypes ? evidenceTypes : null}
                          objective={
                            currentActivity.objective
                              ? currentActivity.objective
                              : []
                          }
                          currentState="project.edit.greenLight"
                          // approvalView={state.approvalView}
                          // renderApproved={renderApproved()}
                        />
                      </div>
                    )}
                    {statusId > 1 &&
                      !isParentProgramActivity(
                        currentActivity.activityPlanTypeId
                      ) && (
                        <CustomSegment
                          title="Evaluation Plans"
                          children={<EvaluationPlanSection {...props} />}
                          extraMargin
                        />
                      )}
                    {size(currentActivity.evaluationPlans) >= 1 &&
                      statusId > 1 && (
                        <CustomSegment
                          title="Outcome Summary"
                          className="outcome-Summary"
                          children={
                            <OutcomeSummarySections
                              {...props}
                              isSeries={isSeries}
                            />
                          }
                          rightHeader={
                            <div className="two-step-radio-container m-0">
                              <div className="d-flex align-items-end">
                                <div>
                                  <label className="mb-0">
                                    Multi stage evaluation only
                                  </label>
                                </div>
                                <div className="d-flex">
                                  <Radio
                                    className="multi-stage-evaluation-toggle"
                                    toggle
                                    checked={isSeries}
                                    onChange={() => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        isSeries: !prevState.isSeries,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          extraMargin
                        />
                      )}
                  </>
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.documentation"
              className="tab-item"
              disabled={statusId === 1}
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                title="DOCUMENTATION"
                popupState={get(props, 'user.options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupContentObject={{
                  data: helpTips,
                  key: isParentProgramActivity(
                    currentActivity.activityPlanTypeId
                  )
                    ? '116'
                    : '18',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                title="DOCUMENTATION"
                children={
                  <Documentation
                    {...props}
                    currentActivity={currentActivity}
                    approvalView={state.approvalView}
                    renderApproved={() => renderApproved()}
                  />
                }
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: (
            <Menu.Item
              key="project.edit.report"
              /*
               * Note:  activity-report class name used for help tip showing class name (don't change it or remove it)
               */
              className="tab-item activity-report"
              disabled={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? // isEvaluationPageOpen is added if the user inside the evaluation plan create or update screen to block the other tabs
                    state.isEvaluationPageOpen || !Boolean(hasObjectives)
                  : (currentActivity.objective &&
                        currentActivity.objective.length > 0) ||
                      statusId >= 2
                    ? state.isEvaluationPageOpen
                    : true
              }
            >
              <CustomItem
                currentTab={state.currentTab}
                activityPlanTypeId={currentActivity.activityPlanTypeId}
                isLinkedActivity={currentActivity.isLinkedActivity}
                statusList={completionProgress}
                statusId={statusId}
                hasObjectives={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? currentActivity.parentOutcomes &&
                      currentActivity.parentOutcomes.length
                    : currentActivity.objective &&
                      currentActivity.objective.length > 0
                }
                title="REPORT"
                popupState={get(props, 'user.options.tips')}
                linkedActivities={currentActivity.linkedActivities}
                popupPosition="bottom right"
                popupContentObject={{
                  data: helpTips,
                  key: isParentProgramActivity(
                    currentActivity.activityPlanTypeId
                  )
                    ? '117'
                    : '19',
                }}
                currentActivity={currentActivity}
              />
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                closable={currentActivity.statusId}
                next={() => nextTab()}
                previous={() => previousTab()}
                currentTab={state.currentTab}
                downloadLink={`/project/${currentActivity.id}/report/export`}
                exportJsonLink={`/activity/${currentActivity.id}/export-data`}
                title="REPORT"
                reportAllImagesLoaded={reportAllImagesLoaded}
                validationOutput={validationOutput.isValid}
                children={
                  <div>
                    {validationOutput.isValid
                      ? null
                      : // NOTE: Some old activities archived before implementing some validations
                        currentActivity.statusId !== 3 && (
                          <div className="report-outstanding-info">
                            <IssuesBox
                              title="Outstanding information"
                              messages={validationOutput.validationMessages}
                              isInfo={true}
                            />
                          </div>
                        )}

                    <CustomSegment
                      title="Activity"
                      className="activity"
                      extraMargin
                      children={
                        <ReportActivity
                          name={
                            currentActivity.name ? currentActivity.name : '-'
                          }
                          owner={
                            currentActivity.owner
                              ? currentActivity.owner.name
                                ? currentActivity.owner.name
                                : '-'
                              : '-'
                          }
                          type={
                            isParentProgramActivity(
                              currentActivity.activityPlanTypeId
                            )
                              ? getActivityType(currentActivity).title || '-'
                              : get(currentActivity, 'eventDetail.detail', '-')
                          }
                          periodGreenLight={
                            currentActivity.greenLightNotifyDate
                              ? currentActivity.greenLightNotifyDate
                              : '-'
                          }
                          periodStart={currentActivity.eventStartDate}
                          periodEnd={currentActivity.eventEndDate}
                          goal={
                            currentActivity.goal ? currentActivity.goal : '-'
                          }
                          goalDescription={
                            currentActivity.goalDescription
                              ? currentActivity.goalDescription
                              : '-'
                          }
                          summary={
                            currentActivity.summary
                              ? currentActivity.summary
                              : currentActivity.description
                                ? currentActivity.description
                                : null
                          }
                          activityPlanTypeId={
                            currentActivity.activityPlanTypeId
                          }
                          parentFestivals={currentActivity.parentFestivals}
                          festivalTypes={festivalTypes}
                        />
                      }
                      toggle={false}
                    />
                    {isParentProgramActivity(
                      currentActivity.activityPlanTypeId
                    ) ? (
                      <CustomSegment
                        className="theory-of-change"
                        title="Theory of Change"
                        children={
                          <TheoryOfChange
                            grant
                            currentActivity={
                              currentActivity ? currentActivity : {}
                            }
                            outcomes={parentOutcomes ? parentOutcomes : []}
                            aim={`${
                              currentActivity.primaryPolicy
                                ? `${get(
                                    currentActivity,
                                    'primaryPolicy.policyName',
                                    ''
                                  )}`
                                : ''
                            }${
                              currentActivity.secondaryPolicy
                                ? ` and ${get(
                                    currentActivity,
                                    'secondaryPolicy.policyName',
                                    ''
                                  )}`
                                : !isEmpty(domains)
                                  ? ', '
                                  : ''
                            }${!isEmpty(domains) ? domains.join(', ') : ''}`}
                            knowing={get(
                              currentActivity,
                              'projectTocKnown',
                              '-'
                            )}
                            willDo={get(currentActivity, 'projectTocDo', '-')}
                            description={null}
                            evidenceTypes={evidenceTypes ? evidenceTypes : null}
                            evidence={currentActivity.evidence || []}
                          />
                        }
                        extraMargin
                        toggle={false}
                      />
                    ) : (
                      <Fragment>
                        <CustomSegment
                          title={
                            currentActivity.primaryPolicy
                              ? currentActivity.primaryPolicy.policyName
                                ? `Objective - Intended Outcomes 
                                (${currentActivity.primaryPolicy.policyName})`
                                : 'Objective - Intended Outcomes'
                              : 'Objective - Intended Outcomes'
                          }
                          className="objective_"
                          children={
                            <TheoryOfChange
                              aim={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .title
                                        ? currentActivity.objective[0].outcome
                                            .title
                                        : '-'
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              knowing={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].tocKnown
                                      ? currentActivity.objective[0].tocKnown
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              willDo={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].tocDo
                                      ? currentActivity.objective[0].tocDo
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              description={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .title
                                        ? currentActivity.objective[0].outcome
                                            .title
                                        : '-'
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              evidenceTypes={
                                evidenceTypes ? evidenceTypes : null
                              }
                              evidence={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].evidence
                                      ? currentActivity.objective[0].evidence
                                      : []
                                    : []
                                  : []
                              }
                            />
                          }
                          extraMargin
                          toggle={false}
                        />
                        {currentActivity.secondaryPolicyId ? (
                          <CustomSegment
                            title={
                              currentActivity.secondaryPolicy
                                ? currentActivity.secondaryPolicy.policyName
                                  ? `Objective - Intended Outcomes 
                                  (${currentActivity.secondaryPolicy.policyName})`
                                  : 'Objective - Intended Outcomes'
                                : 'Objective - Intended Outcomes'
                            }
                            className="objective_"
                            children={
                              <TheoryOfChange
                                aim={
                                  currentActivity.objective
                                    ? currentActivity.objective.length > 1
                                      ? currentActivity.objective[1]
                                        ? currentActivity.objective[1].outcome
                                          ? currentActivity.objective[1].outcome
                                              .title
                                            ? currentActivity.objective[1]
                                                .outcome.title
                                            : '-'
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                knowing={
                                  currentActivity.objective
                                    ? currentActivity.objective.length > 1
                                      ? currentActivity.objective[1]
                                        ? currentActivity.objective[1].tocKnown
                                          ? currentActivity.objective[1]
                                              .tocKnown
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                willDo={
                                  currentActivity.objective
                                    ? currentActivity.objective.length > 1
                                      ? currentActivity.objective[1]
                                        ? currentActivity.objective[1].tocDo
                                          ? currentActivity.objective[1].tocDo
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                description={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].outcome
                                        ? currentActivity.objective[1].outcome
                                            .title
                                          ? currentActivity.objective[1].outcome
                                              .title
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                evidenceTypes={
                                  evidenceTypes ? evidenceTypes : null
                                }
                                evidence={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].evidence
                                        ? currentActivity.objective[1].evidence
                                        : []
                                      : []
                                    : []
                                }
                              />
                            }
                            extraMargin
                            toggle={false}
                          />
                        ) : null}
                      </Fragment>
                    )}
                    <CustomSegment
                      className="key-activity-data"
                      title="Key Activity Data"
                      children={
                        <ActualOutput
                          outputs={
                            currentActivity.eventDetail
                              ? currentActivity.eventDetail.outputFormat
                                ? currentActivity.eventDetail.outputFormat
                                    .format
                                  ? currentActivity.eventDetail.outputFormat
                                      .format
                                  : []
                                : []
                              : []
                          }
                          allData={currentActivity ? currentActivity : {}}
                          volunteers={
                            currentActivity.actualVolunteers
                              ? currentActivity.actualVolunteers
                              : '-'
                          }
                          venue={
                            currentActivity.venues
                              ? currentActivity.venues.length > 0
                                ? currentActivity.venues
                                : []
                              : []
                          }
                        />
                      }
                      extraMargin
                      toggle={false}
                    />
                    {isParentProgramActivity(
                      currentActivity.activityPlanTypeId
                    ) ? (
                      <></>
                    ) : (
                      // <ParentOutcomeCharts
                      //   getAllImages={value => getAllImages(value)}
                      // />
                      <ActualOutcomes
                        getAllImages={(value) => getAllImages(value)}
                      />
                    )}
                    <CustomSegment
                      className="analysis-and-reflection"
                      title="Analysis and Reflection"
                      children={
                        <Notes
                          reflection={
                            currentActivity.reflection
                              ? currentActivity.reflection
                              : ''
                          }
                          isAnalysisField={currentActivity.isAnalysisField}
                          analysis={
                            currentActivity.analysis
                              ? currentActivity.analysis
                              : ''
                          }
                          evaluationPlans={
                            currentActivity.evaluationPlans
                              ? currentActivity.evaluationPlans
                              : []
                          }
                          // no need to change isParent
                          isParent={isParentProgramActivity(
                            currentActivity.activityPlanTypeId
                          )}
                        />
                      }
                      extraMargin
                      toggle={false}
                    />
                  </div>
                }
              />
            </Tab.Pane>
          ),
        },
      ];

      if (!isEmpty(member)) {
        panes = remove(panes, (delPane) => {
          if (isString(member.permissions)) {
            member.permissions = JSON.parse(member.permissions);
          }
          const getPermission = find(member.permissions, (permission) => {
            return permission.state === delPane.menuItem.key;
          });
          if (getPermission.permission.read) {
            return true;
          }
          return false;
        });
      }

      // check tab order changes and fix (parent activity)
      panes = compact(
        map(completionProgress, (step) => {
          return find(
            panes,
            (tab) =>
              step.section.toUpperCase() ===
              get(tab, 'menuItem.props.children.props.title', null)
          );
        })
      );

      return panes;
    } catch (error) {
      return [
        {
          menuItem: (
            <Menu.Item className="no-print" key="ERROR" disabled={true} />
          ),
          render: () => (
            <Tab.Pane
              attached={false}
              className="custom-tab-act"
              loading={activity.actGetLoading}
            >
              <ActivitiesContainer
                next={() => nextTab()}
                previous={() => previousTab()}
                title="ERROR"
                children={
                  <CustomSegment
                    title="ERROR OCCURRED"
                    children={
                      <EmptyContainer msg="Please contact Takso admin for support..." />
                    }
                  />
                }
              />
            </Tab.Pane>
          ),
        },
      ];
    }
  }

  const nextTab = () => {
    const panes = generateTabs();

    const isTabDeactivatedResult = isTabDeactivated(
      'NEXT',
      currentActivity,
      panes,
      currentTab
    );

    const currentKey = panes[currentTab];

    if (!isTabDeactivatedResult) {
      completeGoalTab(currentKey.menuItem.key, currentTab + 1);
    } else {
      return false;
    }
  };

  const previousTab = () => {
    const panes = generateTabs();

    const isTabDeactivatedResult = isTabDeactivated(
      'BACK',
      currentActivity,
      panes,
      currentTab
    );

    if (!isTabDeactivatedResult) {
      changeRoute(currentTab - 1);
      // Assuming '.act-content-layer' is a DOM element
      const actContentLayer = document.querySelector('.act-content-layer');
      if (actContentLayer) {
        actContentLayer.scrollTop = 0;
      }
    } else {
      return false;
    }
  };

  const currentTabNavigation = () => {
    const panes = generateTabs();

    const isTabDeactivatedResult = isTabDeactivated(
      'CURRENT',
      currentActivity,
      panes,
      currentTab
    );

    if (!isTabDeactivatedResult) {
      changeRoute(0);
      // Assuming '.act-content-layer' is a DOM element
      const actContentLayer = document.querySelector('.act-content-layer');
      if (actContentLayer) {
        actContentLayer.scrollTop = 0;
      }
    } else {
      return false;
    }
  };

  const onTabClick = (data, currentTab) => {
    const current = data.panes[currentTab];
    completeGoalTab(current.menuItem.key, data.activeIndex);
  };

  const completeGoalTab = (key, activeIndex) => {
    const pageDisable = getPageDisable(
      currentActivity,
      user,
      'project.edit.goal'
    );

    if (key === 'project.edit.start') {
      if (
        currentActivity.completionProgress &&
        currentActivity.completionProgress.length &&
        currentActivity.completionProgress[0].status === 0
      ) {
        if (currentActivity.goal !== '' && !currentActivity.secondaryPolicyId) {
          // Check other conditions and update the state accordingly
          setState((state) => ({
            ...state,
            confirmModal: pageDisable ? false : true,
          }));
        } else if (
          currentActivity.goal !== '' &&
          currentActivity.secondaryPolicyId
        ) {
          // Check other conditions and update the state accordingly
          setState((state) => ({
            ...state,
            confirmModal: pageDisable ? false : true,
          }));
        } else {
          changeRoute(activeIndex);
          // Scroll logic here
        }
      } else {
        changeRoute(activeIndex);
        // Scroll logic here
      }
    } else {
      changeRoute(activeIndex);
      // Scroll logic here
    }
  };

  const close = () => {
    setState((prevState) => ({ ...prevState, confirmModal: false }));
  };

  const renderApproved = () => {
    const { approvalMethod, authorisationCode } = state;
    return (
      <div className="approved-content-div-documentation">
        {state.authorisationCode ? (
          <>
            <div className="approval-active-data">
              <label>
                <strong>
                  {currentActivity.codeLabel || authorisationCode}
                </strong>{' '}
                - {currentActivity.ppmsCode}
              </label>
            </div>
            {approvalMethod !== 'allowSelf' ? (
              <div className="approval-active-data">
                <Icon name="check circle" color="green" />
                <label>
                  <strong>Approved by</strong> -{' '}
                  {currentActivity.approvalUserName}
                </label>
              </div>
            ) : null}
          </>
        ) : approvalMethod !== 'allowSelf' ? (
          <div className="approval-active-data">
            <Icon name="check circle" color="green" size="large" />
            <label>
              <strong>Approved by</strong> - {currentActivity.approvalUserName}
            </label>
          </div>
        ) : null}
        {currentActivity.referenceNumber ? (
          <div className="approval-active-data-1">
            <label>
              <strong>{currentActivity.referenceCodeLabel}</strong> -{' '}
              {currentActivity.referenceNumber}
            </label>
          </div>
        ) : null}
      </div>
    );
  };

  const onConfirm = (activeIndex) => {
    setState((state) => ({ ...state, confirmModal: false }));
    changeRoute(activeIndex);
    window.scrollTo(0, 0); // Scroll to the top of the page

    const data = {
      completionProgress: currentActivity.completionProgress,
    };

    data.completionProgress[0].status = 2;
    dispatch(putActivityStatus(currentActivity.id, data));
  };

  const getEvaluationPage = (value) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isEvaluationPageOpen: value,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const getJoyRide = (data) => {
    const { type } = data;

    if (type === 'tour:end') {
      setState((prevState) => ({
        ...prevState,
        infoSteps: false,
      }));

      if (!user.onboardingOption.activityFormTips) {
        // only trigger for new user/ users not toured whole trip
        user.onboardingOption.activityFormTips = true;
        dispatch(setUserOnboardingOption(user));
      }
      props.togglerOnboardingTips(false); // hide help panel and off the onboarding flow in sidebar
    }
  };

  const { currentTab, confirmModal, infoSteps } = state;

  if (checkPermissionDenied(user, currentActivity)) return <PermissionDenied />;

  let _panes = generateTabs();

  return (
    <div className="steps-container">
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={_panes}
        className={`${
          isMobile ? 'custom-tabs-mobile-act' : 'custom-tabs1-act act-tab'
        }${
          currentActivity &&
          isParentProgramActivity(currentActivity.activityPlanTypeId)
            ? ' parent-act-tab'
            : ' single-act-tab'
        }`}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data, currentTab)}
      />
      <Modal open={confirmModal} size="tiny">
        <Modal.Header className="goal-confirm-modal-header">
          <div className="modal-heading">Activity Confirmation</div>
          <div className="slim-close-icon" onClick={() => close()}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Content className="goal-confirm-modal-body">
          You are about to confirm the goal for your activity. From this point
          the goal will be locked and unable to change. Do you want to proceed
          with this activity addressing this goal?
        </Modal.Content>
        <Modal.Actions className="goal-confirm-modal-actions">
          <div className="model-buttons">
            <Button className="Secondary-Button" onClick={() => close()}>
              Cancel
            </Button>
            <Button
              positive
              className="Primary-Button"
              onClick={() => onConfirm(currentTab + 1)}
            >
              OK
            </Button>
          </div>

          {/* <Button className='goal-cancel-button' onClick={() => close()}>
            Cancel
          </Button>
          <Button
            className='goal-confirm-button'
            onClick={() => onConfirm(activeIndex)}>
            OK
          </Button> */}
        </Modal.Actions>
      </Modal>
      {/* <Confirm
        open={confirmModal}
        onCancel={() => close()}
        onConfirm={() => onConfirm(activeIndex)}
        header={
          <div className='goal-confirm-modal-header'>
            Activity Confirmation
          </div>
        }
        content={
          <div className='goal-confirm-modal-body'>
            You are about to confirm the goal for your activity. From this
            point the goal will be locked and unable to change. Do you want to
            proceed with this activity addressing this goal?
          </div>
        }
        size='tiny'
      /> */}
      <Joyride
        autoStart
        callback={(data) => getJoyRide(data)}
        run={infoSteps}
        steps={steps}
        continuous
        disableOverlayClose={true} // disable overlay close <- need to go whole trip
        disableCloseOnEsc={true} // disable overlay close [ESC btn]<- need to go whole trip
        tooltipComponent={Tooltip}
        beaconComponent={BeaconComponent}
        scrollToFirstStep
        disableScrolling={!isMobile} // disable scroll in web view <- to resolve wrong target at help panel in web view
      />
    </div>
  );
};

// const mapStateToProps = state => {
//   return {
//     ...state.activity,
//     user: state.authentication.user,
//     evidenceTypes: state.extra.evidenceTypes,
//     helpTips: state.extra.activityHelpTips,
//     parentOutcomes: state.extra.outcomes,
//     festivalTypes: state.extra.festivalTypes,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators(
//     {
//       getAllActivityData,
//       putActivityStatus,
//       getReports,
//       putActivity,
//       postGroupSelected,
//       getSelectedGroup,
//       updateGroupSelected,
//       deleteGroupSelected,
//       updateProjectInputs,
//       setUserOnboardingOption,
//     },
//     dispatch
//   );
// };

export default ActivitySteps;

import React, { Component, Fragment } from 'react';
import {
  Button,
  Icon,
  Modal,
  Tab,
  Dropdown,
  Grid,
  Divider,
} from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { authMsg } from '../../../../utils';
import { OutputFormatForm } from './outputFormatForm';
import { get } from '../../../../utils/lodash';
import {
  getOutputFormatGroups,
  getOutputFormats,
  postOutputFormatGroup,
  postOutputFormat,
  updateOutputFormatGroup,
  updateOutputFormat,
  deleteOutputFormatter,
} from '../../../../store/actions';
import { CustomTable } from '../../../../components';
import { AvField, AvForm } from 'availity-reactstrap-validation';

class FormatGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fields: [],
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async componentDidMount() {
    const { getOutputFormatGroups } = this.props;
    getOutputFormatGroups();
  }

  render() {
    const { modal } = this.state;

    return (
      <Fragment>
        <div className="outcome-admin p-4">
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="large"
              trigger={
                <button className="submit-button">
                  <Icon name="plus circle" />
                  Add Format Groups
                </button>
              }
            >
              <Modal.Header>Create Output Format</Modal.Header>
              <Modal.Content>
                <OutputFormatGroupForm
                  toggle={() => this.toggle()}
                  {...this.props}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>

        <OutputFormatGroupList {...this.props} />
      </Fragment>
    );
  }
}
class Format extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fields: [],
      currentPage: 1,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    const { getOutputFormats } = this.props;
    getOutputFormats();
  }

  pageChange = (page) => {
    const { getOutputFormats } = this.props;
    getOutputFormats(page);
    this.setState({ currentPage: page });
  };

  render() {
    const { modal, currentPage } = this.state;

    return (
      <Fragment>
        <div className="outcome-admin">
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="small"
              trigger={
                <button className="submit-button">
                  <Icon name="plus circle" />
                  Add Output Format
                </button>
              }
            >
              <Modal.Header>Create Output Format</Modal.Header>
              <Modal.Content>
                <OutputFormatForm
                  toggle={() => this.toggle()}
                  pageChange={this.pageChange}
                  {...this.props}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>

        <OutputFormatList
          currentPage={currentPage}
          pageChange={this.pageChange}
          {...this.props}
        />
      </Fragment>
    );
  }
}
class OutputFormatGroupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      outputFormatOptions: [],
      outputFormatDropdownValues: [],
      outputFormats: [],
      isOutputformtsInvalid: false,
    };
  }

  componentDidMount() {
    const { outputFormatsList, outputFormatGroup } = this.props;

    const options = outputFormatsList.docs.map((outputFormat) => ({
      key: outputFormat.id,
      text: outputFormat.label,
      value: outputFormat.id,
    }));

    const newState = {
      outputFormatOptions: options,
    };

    if (outputFormatGroup) {
      const outputFormatDropdownValues = [];
      const outputFormats = [];

      for (let i = 0; i < outputFormatGroup.outputFormat.length; i++) {
        const format = outputFormatGroup.outputFormat.find(
          (format) => format.order === i + 1
        );
        if (format) {
          outputFormats.push({
            ...format.formatDetails,
            formula: format.formula ? format.formula : '',
          });
          outputFormatDropdownValues.push(format.formatDetails.id);
        }
      }

      newState.outputFormats = outputFormats;
      newState.outputFormatDropdownValues = outputFormatDropdownValues;
    }

    this.setState(newState);
  }

  handleValidSubmit(event, values) {
    const {
      postOutputFormatGroup,
      updateOutputFormatGroup,
      outputFormatGroup,
      toggle,
    } = this.props;
    const { outputFormats } = this.state;

    if (outputFormats.length === 0) {
      this.setState({ isOutputformtsInvalid: true });
      return;
    }

    values.outputFormats = outputFormats.map((outputFormat, index) => {
      delete values[`formula${index}`];
      return outputFormat.formula
        ? { id: outputFormat.id, formula: outputFormat.formula }
        : { id: outputFormat.id };
    });

    if (outputFormatGroup) {
      values.id = outputFormatGroup.id;
      updateOutputFormatGroup(values);
    } else {
      postOutputFormatGroup(values);
    }

    toggle();
  }

  outputFormatDropdownOnChange(values) {
    const { outputFormats, outputFormatDropdownValues } = this.state;
    const { outputFormatsList, outputFormatGroup } = this.props;

    let newOutputFormats = [...outputFormats];
    let newOutputFormatDropdownValues = [...outputFormatDropdownValues];

    values.forEach((value) => {
      const isExist = outputFormats.find(
        (outputFormat) => outputFormat.id === value
      );

      if (isExist) return;

      const formatObject = outputFormatsList.docs.find(
        (outputFormat) => outputFormat.id === value
      );
      newOutputFormatDropdownValues.push(value);
      newOutputFormats.push(formatObject);
    });

    outputFormats.forEach((outputFormat) => {
      const isExist = values.find((value) => outputFormat.id === value);

      if (isExist) return;

      if (outputFormatGroup) {
        const isAllradySaved = outputFormatGroup.outputFormat.find(
          (format) => format.outputFormatId === outputFormat.id
        );
        if (isAllradySaved) {
          authMsg(
            'error',
            'It is not possible to delete previously saved output formats.'
          );
          return;
        }
      }
      newOutputFormatDropdownValues = newOutputFormatDropdownValues.filter(
        (value) => outputFormat.id !== value
      );
      newOutputFormats = newOutputFormats.filter(
        (item) => outputFormat.id !== item.id
      );
    });

    this.setState({
      outputFormats: newOutputFormats,
      outputFormatDropdownValues: newOutputFormatDropdownValues,
      isOutputformtsInvalid: false,
    });
  }

  handleOnDragEnd(result) {
    if (!result.destination || this.props.view) return;

    const { outputFormats } = this.state;

    const items = Array.from(outputFormats);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const dropdownItems = items.map((item) => item.id);

    this.setState({
      outputFormats: items,
      outputFormatDropdownValues: dropdownItems,
    });
  }

  formulaOnChange(value, index) {
    const { outputFormats } = this.state;

    const newOutputFormats = [...outputFormats];
    newOutputFormats[index].formula = value;
    this.setState({ outputFormats: newOutputFormats });
  }

  renderOutputFormatCard(outputFormat, index) {
    return (
      <Grid className="output-format-drag-contianer">
        <Grid.Row columns={3}>
          <Grid.Column className="label-contianer">
            <label>Label</label>
            <label className="draggable-card-title mb-0">
              {outputFormat.label}
            </label>
          </Grid.Column>
          <Grid.Column className="label-contianer">
            <label>Field Name</label>
            <label className="draggable-card-title mb-0">
              {outputFormat.name}
            </label>
          </Grid.Column>
          <Grid.Column className="label-contianer">
            <label>Type</label>
            <label className="draggable-card-title mb-0">
              {get(outputFormat, 'typeData.name', '-')}
            </label>
          </Grid.Column>
        </Grid.Row>
        {outputFormat.isFormula && (
          <Grid.Row columns={1}>
            <Grid.Column className="label-contianer">
              <AvField
                label="Formula"
                name={`formula${index}`}
                disabled={this.props.view}
                value={outputFormat.formula}
                onChange={(event, value) => this.formulaOnChange(value, index)}
                rows="1"
                type="textarea"
                placeholder="Enter Formula here..."
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Formula is required',
                  },
                  pattern: {
                    value: '^\\S*$',
                    errorMessage:
                      'The formula should not include any white spaces.',
                  },
                }}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }

  render() {
    const { outputFormatGroup, view } = this.props;
    const {
      outputFormatOptions,
      outputFormats,
      outputFormatDropdownValues,
      isOutputformtsInvalid,
    } = this.state;
    let form = {};
    if (outputFormatGroup) {
      form = {
        name: outputFormatGroup.name,
      };
    }

    return (
      <AvForm
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        autoComplete="off"
        model={form}
      >
        <AvField
          label="Group Name"
          name="name"
          type="text"
          disabled={view}
          validate={{
            required: {
              value: true,
              errorMessage: 'Group Name is required',
            },
          }}
        />
        {!view && (
          <div>
            <label>Output Format</label>
            <Dropdown
              placeholder="Select Output Format"
              className="output-format-dropdown"
              fluid
              multiple
              value={outputFormatDropdownValues}
              search
              selection
              options={outputFormatOptions}
              onChange={(event, { value }) =>
                this.outputFormatDropdownOnChange(value)
              }
            />
            {isOutputformtsInvalid && (
              <label className="custom-error-label">
                Output Format is required
              </label>
            )}
          </div>
        )}

        {view ? (
          outputFormats.map((outputFormat, index) => (
            <div key={index}>
              <Divider />
              {this.renderOutputFormatCard(outputFormat, index)}
            </div>
          ))
        ) : outputFormats.length === 0 ? (
          <div className="output-format-drag-contianer empty">
            <label>Please Select an Output Format</label>
          </div>
        ) : (
          <div style={{ minHeight: '200px' }}>
            <DragDropContext onDragEnd={(value) => this.handleOnDragEnd(value)}>
              <Droppable
                droppableId="outputFormats"
                renderClone={(provided, snapshot, rubric) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {this.renderOutputFormatCard(
                      outputFormats[rubric.source.index],
                      rubric.source.index
                    )}
                  </div>
                )}
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {outputFormats.map((outputFormat, index) => (
                      <Draggable
                        key={outputFormat.id}
                        draggableId={`${outputFormat.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Divider />
                            <div>
                              <label className="draggable-card-title">
                                {index + 1}) {outputFormat.label}
                              </label>
                            </div>
                            {this.renderOutputFormatCard(outputFormat, index)}
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        <div className="model-button mt-4">
          {view ? (
            <Button
              content="Close"
              type="submit"
              className="modal-proceed mr-0"
              onClick={() => this.props.toggle()}
            />
          ) : (
            <Button
              content="Save"
              type="submit"
              className="modal-proceed mr-0"
              // loading={outLoading}
              // disabled={outLoading}
            />
          )}
        </div>
      </AvForm>
    );
  }
}
class OutputFormatGroupList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
    this.columns = [
      {
        width: 4,
        title: 'Group Name',
        render: (data) => {
          return data.name;
        },
      },
      {
        width: 4,
        title: 'Date Created',
        render: (data) => {
          return moment(data.createdAt).format('ll hh:mm A');
        },
      },
      {
        width: 4,
        title: 'Last Update',
        render: (data) => {
          return moment(data.updatedAt).format('ll hh:mm A');
        },
      },
      {
        width: 4,
        title: '',
        render: (data) => (
          <div className="output-format-action">
            <ViewOutputFormatGroup {...this.props} outputFormatGroup={data} />
            <EditOutputFormatGroup {...this.props} outputFormatGroup={data} />
            <RemoveOutputFormatter {...this.props} item={data} group />
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    const { getOutputFormats } = this.props;
    getOutputFormats('all');
  }

  pageChange = (page) => {
    const { getOutputFormatGroups } = this.props;
    getOutputFormatGroups(page);
    this.setState({ currentPage: page });
  };

  render() {
    const { outputFormatGroupsList } = this.props;
    return (
      <CustomTable
        header
        columns={this.columns}
        data={outputFormatGroupsList.docs}
        emptyMessage="You have not created any Output Formats yet…"
        customClass="output-format-table"
        // pagination
        handlePaginationChange={this.pageChange}
        // page={this.state.currentPage}
        // noOfPages={outputFormatGroupsList.pages}
      />
    );
  }
}
class OutputFormatList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.columns = [
      {
        width: 5,
        title: 'Label',
        render: (data) => {
          return data.label;
        },
      },
      {
        width: 5,
        title: 'Field Name',
        render: (data) => {
          return data.name;
        },
      },
      {
        width: 3,
        title: 'Type',
        render: (data) => {
          return get(data, 'typeData.name', '-');
        },
      },
      {
        width: 3,
        title: '',
        render: (data) => (
          <div className="output-format-action">
            <EditOutputFormat
              pageChange={this.pageChange}
              {...this.props}
              outputFormat={data}
            />
            <RemoveOutputFormatter
              pageChange={this.pageChange}
              {...this.props}
              item={data}
            />
          </div>
        ),
      },
    ];
  }

  render() {
    const { outputFormatsList, pageChange, currentPage } = this.props;
    return (
      <CustomTable
        header
        columns={this.columns}
        data={outputFormatsList.docs}
        emptyMessage="You have not created any Output Formats yet…"
        customClass="output-format-table"
        pagination
        handlePaginationChange={pageChange}
        page={currentPage}
        noOfPages={outputFormatsList.pages}
      />
    );
  }
}
class RemoveOutputFormatter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleOutputFormatRemove() {
    this.setState({ loading: true });
    const { deleteOutputFormatter, item, group } = this.props;
    await deleteOutputFormatter(item.id, `${group ? 'group' : 'format'}`);
    this.toggle();
    this.setState({ loading: false });
  }

  render() {
    const { modal, loading } = this.state;
    const { group } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        closeIcon={<Icon name="close" className="closeicon" />}
        size="mini"
        trigger={
          <label className="activity-actions-error first-down ">Delete</label>
        }
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete this Output Format
            {group ? ' Group' : ''}?
          </p>

          <div className="model-button">
            <Button onClick={() => this.toggle()} className="modal-cancel">
              No
            </Button>
            <Button
              onClick={() => {
                this.handleOutputFormatRemove();
                if (!group) {
                  this.props.pageChange(1);
                }
              }}
              content="Delete"
              type="submit"
              className="modal-proceed"
              loading={loading}
              disabled={loading}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
class EditOutputFormat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    const { outputFormat } = this.props;
    this.setState({ form: outputFormat });
  }

  handleValidSubmit(event, values) {}

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        closeIcon={<Icon name="close" className="closeicon" />}
        size="small"
        trigger={<label className="activity-actions-warn mb-0">Edit</label>}
      >
        <Modal.Header>Edit Output format</Modal.Header>
        <Modal.Content>
          <OutputFormatForm
            isEdit={true}
            toggle={() => this.toggle()}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
class EditOutputFormatGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="large"
        trigger={<label className="activity-actions-warn mb-0">Edit</label>}
      >
        <Modal.Header>Edit Format Group</Modal.Header>
        <Modal.Content>
          <OutputFormatGroupForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}
class ViewOutputFormatGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        closeIcon={<Icon name="close" className="closeicon" />}
        size="small"
        trigger={<label className="activity-actions-warn mb-0">View</label>}
      >
        <Modal.Header>View Format Groups</Modal.Header>
        <Modal.Content>
          <OutputFormatGroupForm
            toggle={() => this.toggle()}
            view
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
class OutputFormatter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.panes = [
      {
        menuItem: 'Format Group',
        render: () => <FormatGroup {...this.props} />,
      },
      {
        menuItem: 'Format',
        render: () => <Format {...this.props} />,
      },
    ];
  }

  render() {
    return (
      <Tab
        menu={{ color: 'orange', secondary: true, pointing: true }}
        panes={this.panes}
        className="custom-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outputFormatsList: state.admin.outputFormatsList,
    outputFormatGroupsList: state.admin.outputFormatGroupsList,
    outputFormatterUpdateLoading: state.admin.outputFormatterUpdateLoading,
    outLoading: state.admin.outLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOutputFormatGroups,
      getOutputFormats,
      postOutputFormatGroup,
      postOutputFormat,
      updateOutputFormatGroup,
      updateOutputFormat,
      deleteOutputFormatter,
    },
    dispatch
  );
};

OutputFormatter = connect(mapStateToProps, mapDispatchToProps)(OutputFormatter);
export { OutputFormatter };

import './organization.scss';
import React from 'react';

import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import ShowMoreText from 'react-show-more-text';
import { bindActionCreators } from 'redux';
import { getOrgCoreDataInitialValues } from '../../../validations/orgCoreDataValidation';
import { profileValidationSchemaWithDifferentNotes } from '../../../validations/orgCoreDataValidation';
import { coreDataValidationSchema } from '../../../validations/orgCoreDataValidation';
import {
  CustomDropdown,
  CustomTextArea,
  CustomFileUpload,
} from '../../../components/form';
import { Formik, Form } from 'formik';
import {
  Button,
  Grid,
  Dropdown,
  Icon,
  Input,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';

import {
  CustomSegment,
  CustomTable,
  EmptyContainer,
} from '../../../components';
import {
  deleteOrganizationCoreData,
  getCoreDataInfo,
  updateCoreDataInfo,
  updateOrganizationInfo,
  uploadOrgAnnualReport,
} from '../../../store/actions';
import { getReportYears, setValue } from '../../../utils';
import { find, get, isEmpty, map } from '../../../utils/lodash';
//import { getOrgProfileInitialValues } from '../../../validations/orgProfileValidation';

class UpdateData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      value: props.record.data ? props.record.data.value : '',
      valueError: '',
    };
  }

  changeEditState = async () => {
    let { edit, value } = this.state;
    let {
      coreData,
      index,
      reportYear,
      reportStart,
      reportEnd,
      organization,
      updateCoreDataInfo,
    } = this.props;

    if (!edit) {
      this.setState({
        edit: !edit,
        value: this.props.record.data.value,
      });
    } else {
      if (!value) {
        this.setState({
          valueError: 'Expenditure value is required',
        });
      } else {
        value = `${value}`.replace(/,/g, '');
        var isNum = /^\d+$/.test(value);
        if (isNum) {
          coreData[index].data.value = parseInt(value);
          try {
            await updateCoreDataInfo({
              coreData: {
                dateRecorded: moment().format('YYYY-MM-DD'),
                inputs: coreData,
              },
              reportYear,
              reportStart,
              reportEnd,
            });
          } catch (error) {
            NotificationManager.error('Oops, Something went wrong!');
          }

          this.setState({
            edit: !edit,
          });
        } else {
          this.setState({
            valueError: 'Only numbers can be entered',
          });
        }
      }
    }
  };

  async onChange(value) {
    value = value.replace(/,/g, '');
    let isNum = /^\d+$/.test(value);
    this.setState({
      valueError: !isNum ? 'Only numbers can be entered' : '',
      value: value,
    });
  }

  render() {
    let { edit, value, valueError } = this.state;
    const { record } = this.props;

    return (
      <div className="coredata-edit-container">
        <div className="coredata-edit-area-container">
          {edit ? (
            <div className="coredata-edit-input-container">
              <Input
                type="text"
                className={`input ${valueError ? 'input-err' : ''}`}
                onChange={(e) => {
                  this.onChange(e.target.value);
                }}
                value={value}
              />
              <div
                className={valueError ? 'error-msg-div' : 'error-msg-hidden'}
              >
                {valueError}
              </div>
            </div>
          ) : (
            setValue(record.data)
          )}
        </div>
        <div className="coredata-edit-action-container">
          {edit ? (
            <div className="actions">
              <label
                className="coredata-update-text"
                onClick={this.changeEditState}
              >
                Update
              </label>

              <label
                className="coredata-cancel-text"
                onClick={() => this.setState({ edit: !edit })}
              >
                Cancel
              </label>
            </div>
          ) : (
            <label
              className="coredata-edit-text"
              onClick={this.changeEditState}
            >
              Edit
            </label>
          )}
        </div>
      </div>
    );
  }
}
//model changes
class OrganizationCoreData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.year = new Date().getFullYear();
    this.state = {
      tmpCoreData: [],
      attachedReport: [],
      reportingYearsOptions: [],
      reportingYear: null,
      reportStart: null,
      reportEnd: null,
      modal: false,
      loading: false,
      removeModal: false,
      organization: [],
      orgForm: {},
      modalShow: false,
      file: null,
      imgPreView: null,
    };
  }
  setFile = async (file) => {
    this.setState({ file });
  };

  async componentWillMount() {
    try {
      await this.getReportingYears();

      const { organisationId } = this.props.auth.user;
      const { reportingYear } = this.state;

      this.props.getCoreDataInfo(reportingYear);
    } catch (error) {
      // handle error
    }
  }

  async getReportingYears() {
    const { organisation } = this.props.auth.user;
    const { financialYear } = get(organisation, 'settings[0]');

    const isFinancial = financialYear || financialYear === undefined;

    const {
      auth: {
        user: {
          organisation: {
            createdDateTime: organisationStartDate = '2018-01-01',
          },
        },
      },
    } = this.props;

    const reportingYears = getReportYears(
      isFinancial,
      this.year,
      organisationStartDate
    );

    this.setState({
      reportingYearsOptions: reportingYears,
      reportingYear: reportingYears[0].value,
      reportStart: reportingYears[0].yearStart,
      reportEnd: reportingYears[0].yearEnd,
    });
  }

  setTableColumns(dataset) {
    if (isEmpty(dataset)) return null;
    return map(dataset, (item, i) => (
      <Table.Row key={i}>
        <Table.Cell>{item.input}</Table.Cell>
        <Table.Cell>
          <UpdateData
            coreData={dataset}
            record={item}
            index={i}
            reportYear={this.state.reportingYear}
            reportStart={this.state.reportStart}
            reportEnd={this.state.reportEnd}
            {...this.props}
          />
        </Table.Cell>
        <Table.Cell>{item.dataToCollect}</Table.Cell>
      </Table.Row>
    ));
  }

  setFile = async (file) => {
    this.setState({ file });
  };

  optionYears() {
    const { reportingYearsOptions } = this.state;
    const data = reportingYearsOptions.map((item, key) => {
      return {
        key,
        value: item.value,
        text: item.text,
        rest: { key: item.value },
      };
    });
    return data;
  }
  onChange(type, value) {
    if (type === 'logo' && !!value.target.files[0]) {
      this.setState({
        file: value.target.files[0],
        imgPreView: value.target.files[0]
          ? window.URL.createObjectURL(value.target.files[0])
          : null,
      });
    }

    this.setState(
      (prevState) => ({
        orgForm: {
          ...prevState.orgForm,
          [type]: isNaN(value) ? value : Number(value),
        },
      }),
      () => {
        this.isSaved();
      }
    );
  }

  onChangeYearSelection(value) {
    const yearObj = find(this.state.reportingYearsOptions, {
      value,
    });
    const {
      updateOrganizationInfo,
      organization,
      getCoreDataInfo,
      auth: { user = null },
    } = this.props;

    this.setState(
      {
        reportingYear: value,
        reportStart: yearObj.yearStart,
        reportEnd: yearObj.yearEnd,
      },
      async () => {
        try {
          await updateOrganizationInfo(
            {
              settings: [
                {
                  ...organization.settings[0],
                  defaultYear: value,
                },
              ],
            },
            false
          );
          getCoreDataInfo(this.state.reportingYear);
        } catch (error) {
          console.error(
            'OrganizationCoreData ~ onChangeYearSelection ~ error:',
            error
          );
        }
      }
    );
  }

  changeYearModal(value) {
    const yearObj = find(this.state.reportingYearsOptions, {
      value: value,
    });

    this.setState({
      reportingYear: value,
      reportStart: yearObj.yearStart,
      reportEnd: yearObj.yearEnd,
    });
  }

  openModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async onHandleSubmit(values) {
    try {
      this.setState({ loading: true });
      const { file, reportingYear } = this.state;
      const { uploadOrgAnnualReport, getCoreDataInfo } = this.props;
      const { user } = this.props.auth;

      let formData = new FormData();
      formData.set('document', file.file);
      formData.set('reportingYear', reportingYear);
      formData.set('notes', values.notes);
      await uploadOrgAnnualReport(formData);

      await getCoreDataInfo(reportingYear);
      this.setState({ loading: false });
      this.openModal();
    } catch (error) {
      console.error(
        'OrganizationCoreData -> handleValidSubmit -> error :',
        error
      );
    }
  }

  columns() {
    return [
      {
        title: 'Report Name',
        width: 4,
        render: (data) => {
          let reportName = get(data.document, 'name', '');
          if (!isEmpty(reportName)) {
            reportName = reportName.split('.');
          }
          return (
            <div className="evidence-container">
              <label className="evidence-mainText">
                {' '}
                {!isEmpty(reportName) ? reportName[0].toUpperCase() : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Notes',
        width: 6,
        render: (data) => {
          return (
            <div className="evidence-container">
              <label className="core-data-label">
                <ShowMoreText
                  lines={2}
                  more="Read More"
                  less="Read Less"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={'... '}
                  className="pre-line"
                >
                  <p> {!isEmpty(data.notes) ? data.notes : '-'}</p>
                </ShowMoreText>
              </label>
            </div>
          );
        },
      },
      {
        title: 'Format',
        width: 2,
        render: (data) => {
          let format = get(data.document, 'name', '');
          if (!isEmpty(format)) {
            format = format.split('.');
          }
          return (
            <div className="evidence-container">
              <label className="core-data-label">
                {!isEmpty(format[1]) ? String(format[1]).toUpperCase() : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Date Added',
        width: 2,
        render: (data) => {
          let date = get(data.document, 'uploadDateTime', '');
          date = moment(date).format('YYYY-MM-DD');
          return (
            <div className="evidence-container">
              <label className="core-data-label">
                {!isEmpty(date) ? date : '-'}
              </label>
            </div>
          );
        },
        with: '500',
      },
      {
        title: '',
        width: 1,
        render: (data) => {
          return (
            <div className="actions-core">
              <label
                onClick={() => window.open(data.document.documentUrl, '_blank')}
                className="core-download"
              >
                Download
              </label>
              <DeleteCoreData
                reportYear={this.state.reportingYear}
                data={data}
                {...this.props}
              />
            </div>
          );
        },
      },
    ];
  }

  render() {
    const { isChecked } = this.state;
    const {
      organization: { organization },
    } = this.props;
    if (isEmpty(this.props.orgCoreData)) return null;
    const {
      orgLoading,
      orgCoreData: { coreData = {}, attachedDocuments = [] },
    } = this.props;
    const { modal, loading } = this.state;

    return (
      <div>
        <CustomSegment
          tooltip={{
            user: this.props.user,
            helpTips: this.props.helpTips,
            id: '173',
          }}
          title="Set Cultural Assets and Budgeted Recurrent Expenditure"
          className="set-cultural-assets-and-budgeted-recurrent-expenditure"
          rightHeader={
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              textAlign="right"
              floated="right"
            >
              <div className="dropdown-selection">
                <Dropdown
                  className="archive-dropdown"
                  name="reportYear"
                  id="reportYear"
                  labelText="Reporting Year"
                  options={this.optionYears()}
                  value={this.state.reportingYear}
                  placeholder="Select Reporting Year"
                  onChange={(event, data) => {
                    this.onChangeYearSelection(data.value);
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Reporting Year is required',
                    },
                  }}
                  selection
                  requiredStar
                  search
                />
              </div>
            </Grid.Column>
          }
          children={
            <div>
              <Segment className="content-segment" loading={orgLoading}>
                <Grid.Row className="report-section">
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    textAlign="left"
                  >
                    <div className="document-media-div">
                      <Button
                        className="Primary-Button"
                        onClick={() => this.openModal()}
                      >
                        Upload Report
                      </Button>
                    </div>
                    <div className="add-user">
                      <Modal
                        size="small"
                        open={modal}
                        closeIcon={
                          <Icon
                            onClick={() => this.openModal()}
                            name="close"
                            className="closeicon"
                          />
                        }
                        className="upload-report-model"
                      >
                        <Modal.Header>
                          <label className="upload-media-header">
                            Upload Report
                          </label>
                        </Modal.Header>
                        <Modal.Content>
                          <Segment
                            className="content-segment"
                            loading={loading}
                          >
                            <Formik
                              initialValues={getOrgCoreDataInitialValues(
                                organization
                              )}
                              onSubmit={(values, { resetForm }) => {
                                this.onHandleSubmit(values);
                                resetForm({ values: '' });
                              }}
                              validationSchema={
                                isChecked
                                  ? profileValidationSchemaWithDifferentNotes
                                  : coreDataValidationSchema
                              }
                            >
                              {({
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <CustomFileUpload
                                    id="documentFile"
                                    name="documentFile"
                                    labelText="Document"
                                    accept=".pdf, .docx"
                                    buttonText="Browse File"
                                    mediaUpload={true}
                                    infoMessage="Max file size - 50 MB"
                                    setFile={this.setFile}
                                    showMsg={true}
                                    placeholder="Accepted file types: .pdf, .docx"
                                    restrictedSize={2}
                                    setFieldValue={setFieldValue}
                                    loading={loading}
                                    disabled={loading}
                                    requiredStar
                                  />

                                  <CustomDropdown
                                    name="reportYear"
                                    id="reportYear"
                                    labelText="Reporting Year"
                                    options={this.optionYears()}
                                    value={this.state.reportingYear}
                                    placeholder="Select Reporting Year"
                                    getValue={(value) => {
                                      this.changeYearModal(value);
                                    }}
                                    selection
                                    requiredStar
                                    search
                                  />
                                  <CustomTextArea
                                    name="notes"
                                    id="notes"
                                    className="org-inputs-textarea-notes"
                                    placeholder="Enter Notes"
                                    labelText="Notes"
                                    getValue={(e) => this.onChange('notes', e)}
                                    requiredStar
                                  />
                                  <div className="model-buttons">
                                    <Button
                                      className="Secondary-Button"
                                      type="button"
                                      onClick={() => this.openModal()}
                                    >
                                      <div>Cancel</div>
                                    </Button>

                                    <Button
                                      disabled={loading}
                                      loading={isSubmitting}
                                      type="submit"
                                      className="Primary-Button"
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </Segment>
                        </Modal.Content>
                      </Modal>
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid className="core-data-grid" padded>
                  {/* <Grid.Row className="evidence-sector"> */}
                  <div className="core-text">Core data reference documents</div>
                  <CustomTable
                    header
                    columns={this.columns()}
                    data={attachedDocuments}
                    height={true}
                    emptyMessage="No reference documents"
                    customClass="core-data-reference-table"
                    keyExtractor={(item, index) => item.id}
                  />
                  {/* </Grid.Row>*/}
                </Grid>

                <Grid className="core-data-grid" padded>
                  {/* Inputs Table */}
                  <div className="table-holder">
                    <Table stackable basic="very" className="inputs-table">
                      <Table.Header className="inputs-table-head">
                        <Table.Row>
                          <Table.HeaderCell width={7}>Inputs</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Data</Table.HeaderCell>
                          <Table.HeaderCell width={6}>
                            Data to Collect
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {coreData && isEmpty(coreData.inputs) ? (
                          <Table.Row>
                            <Table.Cell colSpan={3}>
                              <EmptyContainer msg="No Inputs found..." />
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          this.setTableColumns(coreData.inputs)
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid>
              </Segment>
            </div>
          }
        />
      </div>
    );
  }
}

class DeleteCoreData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      removeModal: false,
      isChecked: false,
      organization: [],
      orgForm: {},
      modalShow: false,
      file: null,
      imgPreView: null,
    };
    this.fileInputRef = React.createRef();
  }

  removeToggle() {
    this.setState((prevState) => ({
      removeModal: !prevState.removeModal,
    }));
  }

  async handleCoreDataRemove(data) {
    const { deleteOrganizationCoreData, organization, reportYear } = this.props;
    await deleteOrganizationCoreData(
      organization.id,
      data.id,
      data.documentId,
      reportYear
    );
    this.removeToggle();
  }

  render() {
    const { coreDataDelete, data } = this.props;
    const { removeModal } = this.state;
    return (
      <Modal
        open={removeModal}
        onOpen={() => this.removeToggle()}
        onClose={() => this.removeToggle()}
        size="tiny"
        trigger={<label className="core-remove">Remove</label>}
      >
        <Modal.Header className="modal-header-font">
          Delete document in core data
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this document in core data?</p>
        </Modal.Content>
        <Modal.Actions>
          <div className="model-buttons">
            <Button
              onClick={() => this.removeToggle()}
              className="Primary-Button"
            >
              No
            </Button>
            <Button
              onClick={() => this.handleCoreDataRemove(data)}
              className="Secondary-Button"
              content="Yes"
              loading={coreDataDelete}
              disabled={coreDataDelete}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization.organization,
    orgLoading: state.organization.loading,
    orgCoreData: state.organization.orgCoreData,
    coreDataDelete: state.organization.coreDataDelete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateOrganizationInfo,
      uploadOrgAnnualReport,
      updateCoreDataInfo,
      getCoreDataInfo,
      deleteOrganizationCoreData,
    },
    dispatch
  );
};

OrganizationCoreData = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationCoreData);

export { OrganizationCoreData };

import { combineReducers } from 'redux';
import authentication from './modules/auth';
import survey from './modules/survey';
import report from './modules/report';
import organization from './modules/organization';
import activity from './modules/activity';
import extra from './modules/extra';
import user from './modules/user';
import admin from './modules/admin';

const reducers = combineReducers({
  authentication,
  survey,
  report,
  organization,
  activity,
  extra,
  user,
  admin,
});

export default reducers;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Button } from 'semantic-ui-react';
import { max } from 'lodash';

export const EvaluationSelfAssessedChart = ({
  response,
  objectiveId,
  checked,
  actualParticipantsEvaluated,
  totalParticipants,
  isDisabled,
  setChartToReport,
}) => {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [dataset, setDataset] = useState({ labels: [], datasets: [] });

  const average = (data, yMax) => {
    var totalParticipants = data.reduce(
      (total, value) => total + parseFloat(value),
      0
    );
    var denominator = 10 * totalParticipants;
    var total = data.reduce(
      (sum, value, index) => sum + parseFloat(value) * (index + 1),
      0
    );
    var average = (total / denominator) * 10;
    average = isNaN(average) ? 0 : parseFloat(average.toFixed(2));

    return [
      { x: average, y: 0 },
      { x: average, y: yMax },
    ];
  };

  const options = {
    animation: false,
    responsive: true,
    elements: {
      line: {
        tension: 0.1,
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        ticks: {
          max: 10,
          min: 1,
          stepSize: 1,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
          callback: function (value, index, values) {
            if (value === 1) {
              return ['1', 'Not at All'];
            } else if (value === 10) {
              return ['10', 'Most Imaginable'];
            }
            return value;
          },
        },
        title: {
          display: true,
          // text: 'Evaluated Score',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      y: {
        display: true,
        ticks: {
          min: 0,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
        title: {
          display: true,
          text: 'Average number of participants evaluated',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
    },
  };

  useEffect(() => {
    createLineChart();
  }, [response]);

  const createLineChart = () => {
    const maxResponse = Math.max(...response.map((res) => max(res)));
    options.scales.y.ticks.max = maxResponse + 1;
    try {
      let dataSets = [
        {
          label: 'Average',
          data: average(response[0], maxResponse),
          fill: true,
          backgroundColor: '#d16401',
          borderColor: '#d16401',
          type: 'line',
        },
        {
          label: 'Response',
          data: response[0],
          backgroundColor: 'rgba(253, 143, 88, 0.5)',
          borderColor: '#F8CCA4',
          fill: true,
          pointBackgroundColor: '#fff',
          pointBorderColor: 'rgba(253, 143, 88, 0.5)',
          pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
          tension: 0.2,
        },
      ];

      if (response.length === 2) {
        dataSets = [
          {
            label: 'Average',
            data: average(response[0], maxResponse),
            fill: true,
            backgroundColor: '#d16401',
            borderColor: '#d16401',
            type: 'line',
          },
          {
            label: 'Average',
            data: average(response[1], maxResponse),
            fill: true,
            backgroundColor: '#2F80ED',
            borderColor: '#2F80ED',
            type: 'line',
          },
          {
            label: 'After Response',
            data: response[0],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(253, 143, 88, 0.5)',
            borderColor: '#F8CCA4',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(253, 143, 88, 0.5)',
            pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
            tension: 0.2,
          },
          {
            label: 'Before Response',
            data: response[1],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(47, 128, 237, 0.5)',
            borderColor: '#97BFF6',
            fill: true,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgba(47, 128, 237, 0.5)',
            pointHoverBackgroundColor: 'rgba(47, 128, 237, 0.5)',
            tension: 0.2,
          },
        ];
      }
      setDataset({
        labels: labels,
        datasets: dataSets,
      });
    } catch (error) {
      console.error(
        '🚀 ~ file: selfAssessedSeparateChart.js:277 ~ createLineChart ~ error:',
        error
      );
    }
  };

  return (
    <>
      <div style={{ display: 'flex', margin: '0px 0px 20px 24px' }}>
        <div style={{ flex: 2 }}>
          {/* <b>Results : XXXXXXXX</b>
              <br/>
              <ul style={{ margin: '5px 0 0 10px', padding: 0 }}>
                <li>Participants considered were estimated to have a post participation mean rating of XXX</li>
              </ul> */}
        </div>
        <div
          className="eval-plan-objectiveChartToggle"
          style={{ marginRight: 24 }}
        >
          <div className="checkbox-container-include-in-report">
            <div className="include-in-report-label">Include in report</div>
            <div className="button-include">
              <Button.Group
                color="#F2F2F2"
                onClick={() => {
                  !isDisabled && setChartToReport(!checked, objectiveId);
                }}
              >
                <Button active={checked} disabled={isDisabled}>
                  Yes
                </Button>
                <Button active={!checked} disabled={isDisabled}>
                  No
                </Button>
              </Button.Group>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div id={`self-assessed-post-pre-trend-chart`}>
          <div className="outcomes-summary-chart-div">
            <div
              style={{
                backgroundColor: '#FAFAFA',
                display: 'flex',
                border: '1px solid #e0e0e0',
                // borderBottom: 'none',
                padding: 12,
              }}
            >
              <div style={{ flex: 1 }}>Participants : {totalParticipants}</div>
              <div style={{ flex: 1 }}>
                Respondents : {actualParticipantsEvaluated}
              </div>
              <div style={{ flex: 2 }}>
                Respondent Percentage :{' '}
                {(
                  (actualParticipantsEvaluated / totalParticipants) *
                  100
                ).toFixed(2)}
                %
              </div>
            </div>

            {/* TODO: Need to create header */}
            {/* <CustomReportHeader
            isSelfAssessedMethod={true}
            date={date}
            type={type}
            chartTypeLabel={chartTypeLabel}
            outcome={outcome}
            evalPlan={evalPlan}
            da={{ summary }}
            evalPlansCount={evalPlansCount}
            {...props}
          /> */}
            <div className="report-border-container">
              <Line options={options} data={dataset} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import '../../../activity.scss';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { CustomInput } from '../../../../../../components/form';

export default function EmailResponseFormGuestUser({
  isView = false,
  disabled = false, // TODO: need to remove
  isActive = false,
  changeEmail = null,
  isShowEntityName = false, // TODO: need to remove
  projected = false,
  activity = null,
}) {
  return (
    <div>
      <Grid>
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="firstName"
            name="firstName"
            placeholder="Enter First Name"
            labelText="First Name"
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="surname"
            name="surname"
            placeholder="Enter Last Name"
            labelText="Last Name"
            requiredStar
          />
        </Grid.Column>
      </Grid>
      <Grid>
        {/* <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="email"
            name="email"
            // getValue={e => changeEmail(e)}
            placeholder="Enter Email"
            labelText="Email"
            requiredStar
          />
        </Grid.Column> */}
        <Grid.Column
          className="linked-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="phone"
            name="phone"
            placeholder="Enter Phone"
            labelText="Phone"
            requiredStar
          />
        </Grid.Column>
      </Grid>
      <>
        <Grid>
          <Grid.Column>
            <CustomInput
              id="entityName"
              name="entityName"
              placeholder="Enter Business/ Organization Name"
              labelText="Business/ Organization Name"
              requiredStar
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <CustomInput
              id="address1"
              name="address1"
              placeholder="Enter Address Line 1"
              labelText="Address Line 1"
              requiredStar
            />
          </Grid.Column>
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={8}
            computer={8}
          >
            <CustomInput
              id="address2"
              name="address2"
              placeholder="Enter Address Line 2"
              labelText="Address Line 2"
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={6}
            computer={6}
          >
            <CustomInput
              id="suburb"
              name="suburb"
              placeholder="Enter Suburb"
              labelText="Suburb"
              requiredStar
            />
          </Grid.Column>
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={5}
            computer={5}
          >
            <CustomInput
              id="state"
              name="state"
              placeholder="Enter State"
              labelText="State"
              requiredStar
            />
          </Grid.Column>
          <Grid.Column
            className="linked-activity-model-column"
            mobile={16}
            tablet={5}
            computer={5}
          >
            <CustomInput
              id="postcode"
              name="postcode"
              placeholder="Enter Postcode"
              labelText="Postcode"
              requiredStar
            />
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
}

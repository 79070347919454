/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { find, get, sum } from '../../../utils/lodash';
import { map, isEmpty, max } from '../../../utils/lodash';
import { Line } from 'react-chartjs-2';
import { CustomReportHeader } from '../../customReportHeader';

export const SeparateChart = ({
  method,
  documentation = false,
  evalPlan,
  outcome,
  date,
  props,
  participants,
  avoidLabel = false,
}) => {
  const isIntercept = Number(method) === 12;
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [dataset, setDataset] = useState({ labels: [], datasets: [] });
  const [totalParticipant, setTotalParticipant] = useState(0);
  const [activityCount, setActivityCount] = useState(0);
  const [totalResponse, setTotalResponse] = useState(0);
  const [evalCount, setEvalCount] = useState(0);
  const [programsActivityCount, setProgramsActivityCount] = useState(0);
  const [linkedActivityCount, setLinkedActivityCount] = useState(0);

  useEffect(() => {
    createLineChart(participants);
  }, [participants]);

  const average = (data, yMax) => {
    if (sum(data) === 0) {
      return null;
    }

    var totalParticipants = data.reduce(
      (total, value) => total + parseFloat(value),
      0
    );
    var denominator = 10 * totalParticipants;
    var total = data.reduce(
      (sum, value, index) => sum + parseFloat(value) * (index + 1),
      0
    );
    var average = (total / denominator) * 10;
    average = isNaN(average) ? 0 : parseFloat(average.toFixed(2));

    return [
      { x: average, y: 0 },
      { x: average, y: yMax },
    ];
  };

  const options = {
    animation: false,
    responsive: true,
    elements: {
      line: {
        tension: 0.2,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        custom: function (tooltip) {
          if (!tooltip) return;
          tooltip.displayColors = false;
        },

        callbacks: {
          label: (context) => {
            const participants = get(context, 'formattedValue', 0);
            const isAverage =
              get(context, 'dataset.category', '') === 'AVERAGE';
            return isAverage
              ? `Average - ${context.formattedValue}`
              : `Post Result - ${context.label}  Count - ${participants} ${
                  evalPlan ? '  Evaluation Plan' : '  Activity'
                } Name - ${
                  context.chart.data.datasets[context.datasetIndex].label
                }`;
          },
          title: () => {
            return '';
          },
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        ticks: {
          max: 10,
          min: 1,
          stepSize: 1,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
          callback: function (value, index, values) {
            if (value === 1) {
              return ['1', 'Not at All'];
            } else if (value === 10) {
              return ['10', 'Most Imaginable'];
            }
            return value;
          },
        },
        title: {
          display: true,
          // text: '1 = not at all through to 10 = most imagined',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      y: {
        display: true,
        ticks: {
          min: 0,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
        title: {
          display: true,
          text: 'Number of participants evaluated',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
    },
  };

  const createLineChart = async (participants) => {
    let lines = [];
    setTotalParticipant(get(participants, 'totalParticipants', 0));
    setActivityCount(get(participants, 'activityCount', 0));
    setEvalCount(get(participants, 'evalCount', 0));
    setProgramsActivityCount(get(participants, 'programsActivityCount', 0));
    setLinkedActivityCount(get(participants, 'linkedActivityCount', 0));
    setTotalResponse(get(participants, 'totalRespondents', 0));

    if (!isEmpty(participants) && participants.dataAll) {
      const maxVal = max(participants.dataAll.flatMap((item) => item.data));
      const averageData = average(participants.data, maxVal);

      participants.dataAll.forEach((entry) => {
        let opacity = 0.5;
        if (documentation) {
          opacity =
            1 / participants.evalCount === 1 ? 0.5 : 1 / participants.evalCount;
        } else {
          opacity =
            1 / participants.activityCount === 1
              ? 0.5
              : 1 / participants.activityCount;
        }

        if (!isFinite(opacity)) {
          opacity = 0.5;
        }

        lines.push({
          category: 'DATA',
          label: entry.name,
          evalCount: participants.evalCount,
          activityCount: participants.activityCount,
          data: map(entry.data, (data, key) => {
            return { x: key + 1, y: sum(entry.data) === 0 ? null : data };
          }),
          fill: true,
          backgroundColor: isIntercept
            ? 'rgba(211, 211, 211,0.6)'
            : `rgba(253, 143, 88, ${opacity})`,
          borderColor: isIntercept ? 'rgba(211, 211, 211,0.6)' : '#fd8f581a',
          pointBorderColor: isIntercept
            ? 'rgba(211, 211, 211,0.6)'
            : '#fd8f5880',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: isIntercept
            ? 'rgba(211, 211, 211,0.6)'
            : '#fd8f5880',
          pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
          type: 'line',
        });
      });

      setDataset({
        datasets: [
          {
            category: 'AVERAGE',
            label: 'Average',
            data: averageData,
            activityCount: participants.activityCount,
            fill: true,
            backgroundColor: isIntercept ? '#424242' : '#d16401',
            borderColor: isIntercept ? '#424242' : '#d16401',
            type: 'line',
          },
          ...lines,
        ],
        labels,
      });
    }
  };

  let summary = {
    totalParticipant: totalParticipant, // FIXME: need to investigate this
    totalResponse: totalResponse,
    activityCount: activityCount,
    evalCount: evalCount,
    programsActivityCount: programsActivityCount,
    linkedActivityCount: linkedActivityCount,
  };
  let da = { summary };

  return (
    <div id={`self-assessed-post-pre-trend-chart`}>
      <div className="outcomes-summary-chart-div">
        {!avoidLabel ? <div className="chart-header">Separate</div> : null}
        <div id={`post-trend-chart`}>
          <CustomReportHeader
            method={method === '9' ? true : false} // <- FIXME: WHY USED LIKE THIS
            isIntercept={Number(method) === 12 ? true : false}
            showInterceptLegend={false}
            date={date}
            outcome={outcome}
            evalPlan={evalPlan}
            da={da}
            {...props}
          />
          <div className="report-border-container">
            <Line options={options} data={dataset} />
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { PARTNER_RELATIONSHIP } from '../../../../../../utils/constant';
import { isNetworkRelationship } from '../../../../../../utils/activity';
import {
  changeOwnershipValidationSchema,
  getOwnerDetailsInitialValues,
  // externalOwnerDetailsValidationSchema,
  // guestOwnerDetailsValidationSchema,
} from '../../../../../../validations/ownershipChangeValidation';

import {
  CustomRadioGroup,
  CustomInput,
  CustomTextArea,
} from '../../../../../../components/form';
import { stopPropagation } from '../../../../../../utils';
import {
  Grid,
  Dropdown,
  Modal,
  Icon,
  Button,
  Divider,
  Form,
} from 'semantic-ui-react';
import {
  changeLinkedActivityOwnership,
  findSystemUser,
} from '../../../../../../store/actions';
import { isEmpty, cloneDeep, get } from '../../../../../../utils/lodash';
import { getLinkActivityFeatureName } from '../../../../../../utils/activity/linkActivity';
import CheckUserEmail from '../forms/checkUserEmail';
// import './index.scss';

export default function ChangeOwnership(props) {
  const { activity, currentActivity } = props;

  const dispatch = useDispatch();
  let avForm = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [toggleVerify, setToggleVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [form, setForm] = useState({});
  const [isExpenditureValid, setIsExpenditureValid] = useState(true);

  const findUser = useSelector((state) => state.activity.findSystemUser);

  const resetFormState = () => {
    setForm({
      email,
      address1: '',
      address2: '',
      entityName: '',
      abn: '',
      organisationName: '',
      firstName: '',
      phone: '',
      postcode: '',
      state: '',
      suburb: '',
      surname: '',
    });
    setUserEmailResponse({
      firstName: '',
      organisation: '',
      isConfirmed: false,
      isVerified: false,
      isCleared: false,
      isExistingUser: false,
    });
  };

  const [userEmailResponse, setUserEmailResponse] = useState({
    firstName: '',
    organisation: '',
    isConfirmed: false,
    isVerified: false,
    isCleared: false,
    isExistingUser: false,
  });

  useEffect(() => {
    try {
      if (!isEmpty(findUser)) {
        const ownerDetails = { ...findUser };
        if (findUser.organisation !== null) {
          ownerDetails.entityName = findUser.organisation.name;
        }
        if (
          isEmpty(findUser.entityName) === false ||
          isEmpty(findUser.organisation) === false
        ) {
        }
        setForm(ownerDetails);
      } else {
        resetFormState();
      }
    } catch (error) {
      console.error(error);
    }
  }, [findUser]);

  useEffect(() => {
    try {
      avForm && avForm.reset && avForm.reset();
    } catch (error) {
      console.error(error);
    }
  }, [form]);

  useEffect(() => {
    resetFormState();
    if (email) {
      dispatch(findSystemUser(email));
    }
  }, [email]);
  useEffect(() => {
    try {
      setEmail('');
      setForm({});
      dispatch({ type: 'CLEAR_SYSTEM_USER' });
    } catch (error) {
      console.error(error);
    }
  }, [toggle]);

  const resetFieldsData = () => {
    try {
      setUserEmailResponse({
        firstName: '',
        organisation: '',
        isConfirmed: false,
        isVerified: false,
        isCleared: false,
        isExistingUser: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      resetFieldsData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleExpenditureIncome = (value) => {
    try {
      if (value === '') setIsExpenditureValid(false);
      else {
        if (!isExpenditureValid) setIsExpenditureValid(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidSubmit = async (values, resetForm) => {
    try {
      if (get(values, 'isChangeAllowed', false)) {
        setToggleVerify(true);

        //set the form for the notify modal
        delete values.acn;
        delete values.organisationName;
        setForm(values);
        resetForm();
      }
    } catch (error) {
      console.error('ContractorForm ~ handleValidSubmit ~ error: ', error);
    }
  };

  const notifyNewUser = async (values) => {
    try {
      let notifyGrantees = values.notifyGrantees;

      let isExistingGrantee = false,
        isNewGrantee = false;
      if (notifyGrantees.length === 2) {
        isExistingGrantee = true;
        isNewGrantee = true;
      } else {
        if (notifyGrantees.includes('newGrantee')) {
          isNewGrantee = true;
        } else {
          isExistingGrantee = true;
        }
      }

      let notifyData = {
        newUserEmail: userEmailResponse.email,
        isExistingGrantee,
        isNewGrantee,
      };

      onChangeLinkedActivityOwnership(notifyData);
    } catch (error) {
      console.error(error);
    }
  };

  const [notifyGrantees, setNotifyGrantees] = useState([]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (event) => {
    try {
      const { name, value, checked } = event.target;
      if (name === 'notifyGrantees') {
        const updatedValues = checked
          ? [...notifyGrantees, value]
          : notifyGrantees.filter((item) => item !== value);
        setNotifyGrantees({ ...notifyGrantees, [name]: updatedValues });
      } else {
        setNotifyGrantees({ ...notifyGrantees, [name]: value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    try {
      event.preventDefault();
      setErrors({});
      if (notifyGrantees.length === 0) {
        setErrors({ notifyGrantees: 'Notify option is required' });
      } else {
        setIsSubmitting(true);
        if (notifyGrantees) {
          notifyNewUser(notifyGrantees);
        }
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeLinkedActivityOwnership = async (notifyData) => {
    try {
      let formData = cloneDeep(form);

      if (formData.isChangeAllowed) {
        formData.activityName = activity.name;
        if (notifyData !== undefined) {
          formData.notify = { ...notifyData };
        }
        formData.address = formData.address1;

        delete formData.address1;
        delete formData.organisationId;
        delete formData.isExistingUser;
        delete formData.isConfirmedUser;
        delete formData.emailConfirmError;
        delete formData.isChangeAllowed;
        delete formData.isSameUser;
        delete formData.isSameUserError;

        dispatch(
          changeLinkedActivityOwnership(
            activity.parentId,
            activity.id,
            formData
          )
        );
        setToggleVerify(false);
        setToggle(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const parentActOwnerOrgId = currentActivity.owner
    ? currentActivity.owner.organisationId
    : null;

  const activityManager = {
    ...get(activity, 'owner', {}),
    isExistingUser: true,
  };

  const parentActivityOwnerOrgId = get(
    currentActivity,
    'owner.organisationId',
    null
  );

  const currencyType = get(currentActivity, 'currencyType', null);

  return (
    <>
      {/* --------------------------- notify modal --------------------------- */}
      <Modal
        size="small"
        open={toggleVerify}
        closeIcon={
          <Icon
            onClick={() => setToggleVerify(false)}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>{activity.name}</Modal.Header>
        <Modal.Content>
          <form onSubmit={handleSubmit}>
            <div className="form-title evidence-search-checkbox">
              <label>Notify of changes</label>
              <div>
                <input
                  type="checkbox"
                  name="notifyGrantees"
                  value="previousGrantee"
                  id="previousGrantee"
                  checked={notifyGrantees.includes('previousGrantee')}
                  onChange={handleInputChange}
                />
                <label className="notify-styles" htmlFor="previousGrantee">
                  Notify existing user
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="notifyGrantees"
                  value="newGrantee"
                  id="newGrantee"
                  checked={notifyGrantees.includes('newGrantee')}
                  onChange={handleInputChange}
                />
                <label className="notify-styles" htmlFor="newGrantee">
                  Notify new user
                </label>
              </div>
              {errors.notifyGrantees && (
                <div className="error-message" style={{ color: 'red' }}>
                  {errors.notifyGrantees}
                </div>
              )}
            </div>

            <div className="activity-model-buttons">
              <Button
                type="button"
                className="activity-reinvite-button"
                onClick={() => onChangeLinkedActivityOwnership()}
              >
                Skip
              </Button>
              <Button
                type="submit"
                className="activity-confirm-button"
                disabled={isSubmitting}
              >
                Notify
              </Button>
            </div>
          </form>
        </Modal.Content>
      </Modal>

      {/* --------------------------- change ownership modal --------------------------- */}
      <Modal
        open={toggle}
        onOpen={() => setToggle(true)}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => setToggle(false)}
          >
            &times;
          </div>
        }
        size="small"
        trigger={
          <Dropdown.Item className="activity-actions-dropdown-view">
            <label className="activity-actions-text">Change Ownership</label>
          </Dropdown.Item>
        }
        className="change-ownership-model"
      >
        <Modal.Header>
          <div className="small-modal-header">{activity.name}</div>
        </Modal.Header>
        <Modal.Content>
          <Formik
            onSubmit={(values, { resetForm }) => {
              handleValidSubmit(values, resetForm);
            }}
            initialValues={getOwnerDetailsInitialValues(activity)}
            validationSchema={changeOwnershipValidationSchema()}
          >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="change-ownership-form">
                  <div className="form-spacing">
                    <label className="title">
                      Enter details of the new activity manager
                    </label>
                    <div className="email-verification-two">
                      <CheckUserEmail
                        checkChangeAllow
                        changeOwnershipPayload={{
                          fromOrganisationId: get(
                            activityManager,
                            'organisation.id',
                            null
                          ),
                          fromUserId: get(activityManager, 'id', null),
                          parentActOwnerOrgId,
                        }}
                        id="email"
                        activityFeatureRequired={getLinkActivityFeatureName(
                          activity.activityPlanTypeId
                        )}
                        // activityManager={activityManager}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {/* existing user */}

                    {/* Relationship and Support Description */}
                    {
                      // isManagedByAnotherUser &&
                      values.isChangeAllowed &&
                      (!values.organisationId ||
                        (values.organisationId &&
                          values.organisationId !==
                            parentActivityOwnerOrgId)) ? (
                        <div>
                          <div className="radio-padding">
                            <Grid>
                              <Grid.Column className="linked-activity-model-column">
                                <div className="formik-custom-field act-inputs-new">
                                  <label>Relationship</label>
                                </div>
                                <CustomRadioGroup
                                  options={PARTNER_RELATIONSHIP}
                                  id="relationship"
                                  name="relationship"
                                  type="radio"
                                  requiredStar
                                />
                              </Grid.Column>
                            </Grid>
                          </div>
                        </div>
                      ) : null
                    }

                    {values.isChangeAllowed &&
                      // get(activityManager, 'id', null) !==
                      //   get(findUser, 'id', null) &&
                      // values.organisationId !== parentActivityOwnerOrgId &&
                      !isNetworkRelationship(values.relationship) && (
                        <Grid>
                          <Grid.Column
                            className="income-div linked-activity-model-column"
                            mobile={16}
                            tablet={8}
                            computer={8}
                          >
                            <CustomInput
                              id="projectedGrantAmount"
                              name="projectedGrantAmount"
                              type="number"
                              placeholder="0.00"
                              labelText="Program Contribution"
                              icon={
                                <Icon
                                  circular
                                  inverted
                                  name={get(
                                    currencyType,
                                    'iconName',
                                    'dollar sign'
                                  )}
                                  color={isExpenditureValid ? 'grey' : 'red'}
                                />
                              }
                              iconPosition="left"
                              getValue={(e) => handleExpenditureIncome(e)}
                              requiredStar
                            />
                          </Grid.Column>
                        </Grid>
                      )}

                    {/*
                     * values.organisationId -> to check guest user
                     *   values.organisationId !== parentActivityOwnerOrgId -> to check parent activity owner org ne activity owner org
                     */}
                    {
                      // isManagedByAnotherUser &&
                      values.isChangeAllowed &&
                      (!values.organisationId ||
                        (values.organisationId &&
                          values.organisationId !==
                            parentActivityOwnerOrgId)) ? (
                        <CustomTextArea
                          id="description"
                          name="description"
                          placeholder="Enter support description"
                          labelText="Support Description"
                        />
                      ) : null
                    }
                    <Divider></Divider>
                    <div className="activity-model-buttons">
                      <Button
                        //disabled={findUserLoading}
                        content="Save"
                        type="submit"
                        className="Primary-Button"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { max, isEmpty } from 'lodash';

export const SelfAssessedSeparateChart = ({
  participants,
  evaluations,
  activityCount = 0,
  totalParticipants = 0,
  totalRespondents = 0,
  ...props
}) => {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [dataset, setDataset] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    createLineChart(participants);
  }, [participants]);

  const average = (data, yMax) => {
    if (!Array.isArray(data)) {
      return [
        { x: 0, y: 0 },
        { x: 0, y: yMax },
      ];
    }

    if (data.length === 0) {
      return [
        { x: 0, y: 0 },
        { x: 0, y: yMax },
      ];
    }
    var totalParticipants = data.reduce(
      (total, value) => total + parseFloat(value),
      0
    );
    var denominator = 10 * totalParticipants;
    var total = data.reduce(
      (sum, value, index) => sum + parseFloat(value) * (index + 1),
      0
    );
    var average = (total / denominator) * 10;
    average = isNaN(average) ? 0 : parseFloat(average.toFixed(2));

    return [
      { x: average, y: 0 },
      { x: average, y: yMax },
    ];
  };

  const options = {
    animation: false,
    responsive: true,
    elements: {
      line: {
        tension: 0.1,
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        // custom: function(tooltip) {
        //   if (!tooltip) return;
        //   tooltip.displayColors = false;
        // },
        // callbacks: {
        //   label: context => {
        //     if (
        //       isEmpty(context.chart.data.datasets[context.datasetIndex].label)
        //     )
        //       return;
        //     return `EvalPlan - ${
        //       context.chart.data.datasets[context.datasetIndex].evalPlan
        //     }   ${'Activity'} Name - ${
        //       context.chart.data.datasets[context.datasetIndex].label
        //     }`;
        //   },
        //   title: () => {
        //     return '';
        //   },
        // },
        // displayColors: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        ticks: {
          max: 10,
          min: 1,
          stepSize: 1,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
          callback: function (value, index, values) {
            if (value === 1) {
              return ['1', 'Not at All'];
            } else if (value === 10) {
              return ['10', 'Most Imaginable'];
            }
            return value;
          },
        },
        title: {
          display: true,
          // text: 'Evaluated Score',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
      y: {
        display: true,
        ticks: {
          min: 0,
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
        title: {
          display: true,
          text: 'Average number of participants evaluated',
          font: {
            size: 14,
            family: 'Inter',
          },
        },
      },
    },
  };

  // useEffect(() => {
  //   createLineChart();
  // }, [evaluations]);

  // useEffect(() => {
  //   createLineChart();
  // }, [dataset]);

  const createLineChart = () => {
    try {
      const maxVal = max(participants.dataAll.flatMap((item) => item.data));
      let dataSets = [
        {
          label: 'Average',
          data: average(participants.data, maxVal),
          fill: true,
          backgroundColor: '#d16401',
          borderColor: '#d16401',
          type: 'line',
        },
      ];
      // let evaluationDataset = [];

      // const maxResponse = Math.max(
      //   ...evaluations.flatMap(evaluation =>
      //     evaluation.participantResponse.flatMap(response => response)
      //   )
      // );
      // const allResponses = evaluations.flatMap(
      //   evaluation => evaluation.participantResponse[0]
      // );
      if (!isEmpty(evaluations)) {
        evaluations.forEach((evaluation) => {
          const { name } = evaluation;
          const response = evaluation.participantResponse;

          dataSets = [
            ...dataSets,
            // {
            //   label: 'Average',
            //   data: average(response[0], maxResponse),
            //   fill: true,
            //   backgroundColor: '#d16401',
            //   borderColor: '#d16401',
            //   type: 'line',
            // },
            {
              label: `${name} Response`,
              data: response[0],
              backgroundColor: 'rgba(253, 143, 88, 0.5)',
              borderColor: '#F8CCA4',
              fill: true,
              pointBackgroundColor: '#fff',
              pointBorderColor: 'rgba(253, 143, 88, 0.5)',
              pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
              tension: 0.2,
            },
          ];

          // dataSets.push({
          //   label: 'Average',
          //   data: average(evaluation.participantResponse[0], max(evaluation.participantResponse[0])),
          //   fill: true,
          //   backgroundColor: '#d16401',
          //   borderColor: '#d16401',
          //   type: 'line',
          // });

          if (response.length === 2) {
            dataSets = [
              ...dataSets,
              // {
              //   label: 'Average',
              //   data: average(response[0], maxResponse),
              //   fill: true,
              //   backgroundColor: '#d16401',
              //   borderColor: '#d16401',
              //   type: 'line',
              // },
              // {
              //   label: 'Average',
              //   data: average(response[1], maxResponse),
              //   fill: true,
              //   backgroundColor: '#d16401',
              //   borderColor: '#d16401',
              //   type: 'line',
              // },

              {
                label: `${name} After Response`,
                data: response[0],
                pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                type: 'line',
                backgroundColor: 'rgba(253, 143, 88, 0.5)',
                borderColor: '#F8CCA4',
                fill: true,
                pointBackgroundColor: '#fff',
                pointBorderColor: 'rgba(253, 143, 88, 0.5)',
                pointHoverBackgroundColor: 'rgba(253, 143, 88, 0.5)',
                tension: 0.2,
              },
              {
                label: `${name} Before Response`,
                data: response[1],
                pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                type: 'line',
                backgroundColor: 'rgba(47, 128, 237, 0.5)',
                borderColor: '#97BFF6',
                fill: true,
                pointBackgroundColor: '#fff',
                pointBorderColor: 'rgba(47, 128, 237, 0.5)',
                pointHoverBackgroundColor: 'rgba(47, 128, 237, 0.5)',
                tension: 0.2,
              },
            ];
          }
        });
      }

      setDataset({
        labels: labels,
        datasets: dataSets,
      });
    } catch (error) {
      console.error(
        '🚀 ~ file: selfAssessedSeparateChart.js:277 ~ createLineChart ~ error:',
        error
      );
    }
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          display: 'flex',
          border: '1px solid #e0e0e0',
          borderBottom: 'none',
          padding: 12,
        }}
      >
        <div style={{ flex: 1 }}>Activity Count : {activityCount}</div>
        <div style={{ flex: 1 }}>Participants : {totalParticipants}</div>
        <div style={{ flex: 2 }}>Respondents :{totalRespondents}</div>
        <div style={{ flex: 2 }}>
          Respondent Percentage :{' '}
          {((totalRespondents / totalParticipants) * 100).toFixed(2)}%
        </div>
      </div>
      <div id={`self-assessed-post-pre-trend-chart`}>
        <div className="outcomes-summary-chart-div">
          <div className="chart-header">Self Assessed Separate</div>

          {/* TODO: Need to create header */}
          {/* <CustomReportHeader
            isSelfAssessedMethod={true}
            date={date}
            type={type}
            chartTypeLabel={chartTypeLabel}
            outcome={outcome}
            evalPlan={evalPlan}
            da={{ summary }}
            evalPlansCount={evalPlansCount}
            {...props}
          /> */}
          <div className="report-border-container">
            <Line options={options} data={dataset} />
          </div>
        </div>
      </div>
    </div>
  );
};

import '../activity.scss';

import React, { Fragment } from 'react';

import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
  AvInput,
} from 'availity-reactstrap-validation';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import {
  Button,
  Confirm,
  Dropdown,
  Grid,
  Icon,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import addCustom from '../../../../assets/add-icon-white-bg.svg';
import {
  CustomAccordion,
  CustomSegment,
  CustomTable,
  ShowEvaluationQuestion,
  CustomTooltip,
} from '../../../../components';
// import NavigationPrompt from 'react-router-navigation-prompt';
import {
  cloneDeep,
  compact,
  filter,
  find,
  get,
  isEmpty,
  isEqual,
  map,
  pick,
  size,
  sum,
  unionBy,
  uniq,
  sortBy,
  omit,
  orderBy,
} from '../../../../utils/lodash';
import {
  clearMethodQuestions,
  deleteEvaluationPlan,
  getEvaluationGroups,
  getMethodQuestions,
  postEvaluationPlan,
  updateEvaluationPlan,
  updateSeriesSubtitle,
  deleteSeriesSubtitle,
  getEvaluationPlans,
} from '../../../../store/actions';
import {
  getCollectionMethod,
  getPageDisable,
  getPreviewSurveyLink,
  noteReplace,
  stopPropagation,
} from '../../../../utils';
import { SeeMore } from '../../../../components/customToggle';

class CustomSuccessMessage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: get(props.form, 'successMessage', ''),
    };
  }

  getCustomSuccessMessage() {
    return this.state.text;
  }

  render() {
    const { text } = this.state;
    const { helpTips, user } = this.props;

    return (
      <Grid.Row className="evalPlan-question">
        <CustomTooltip
          state={get(user, 'options.tips')}
          contentObject={{
            data: helpTips,
            key: '215',
          }}
          position="top left"
          wide="very"
        >
          <div className="question-text">Survey Completion Message</div>
        </CustomTooltip>
        <div className="outputs-col-div">
          <div>
            <CKEditor
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'bulletedList',
                  'numberedList',
                ],
              }}
              className="custom-question-input"
              editor={ClassicEditor}
              data={text}
              onInit={(editor) => {}}
              onChange={(event, editor) => {
                const text = editor.getData();
                this.setState({
                  text,
                });
              }}
            />
          </div>
        </div>
      </Grid.Row>
    );
  }
}

class EvaluationPlanRespondent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMethod: null,
      selectedResponse: null,
      selectedCollectionMethod: null,
      selectedCollectionButton: false,
      confirm: false,
      isSeries: false,
      seriesCount: null,
      modal: false,
      checkBoxDisabled: false,
      popupShown: false,
    };
  }

  componentDidMount() {
    const { form } = this.props;
    this.setState({
      selectedMethod: get(form, 'method', null),
      selectedResponse: get(form, 'response', null),
      selectedCollectionMethod: get(form, 'collectionMethod', null),
    });
  }

  getQuestions() {
    return this.customQuestions.getQuestions();
  }
  getCustomSuccessMessage() {
    return this.customMessage.getCustomSuccessMessage();
  }

  getEvaluationQuestion() {
    const { form, surveyQuestions } = this.props;
    let getQuestions = {
      questions: [],
      facilitatorNote: '',
    };

    if (!isEmpty(surveyQuestions)) {
      getQuestions = surveyQuestions;
    } else {
      if (!isEmpty(form.evaluationQuestions)) {
        getQuestions = {
          questions: form.evaluationQuestions,
          facilitatorNote: form.facilitatorNote,
        };
      }
    }

    return map(getQuestions.questions, (question, i) =>
      this[`showQuestions${i}`].getEvaluationQuestion()
    );
  }

  close = () => {
    this.setState({ confirm: false }, () => {
      this.props.forceFormReset();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCollectionMethod !== null) {
      if (
        prevState.selectedCollectionMethod !==
        this.state.selectedCollectionMethod
      ) {
        this.methodQuestions();
      }
    }

    if (prevProps.selectedObjective !== this.props.selectedObjective) {
      this.methodQuestions();
    }
    if (prevState.selectedResponse !== this.state.selectedResponse) {
      this.props.getSelfAssessedResponse(this.state.selectedResponse);
    }
    if (
      prevState.selectedCollectionButton !== this.state.selectedCollectionButton
    ) {
      this.props.getCollectionMethodForButton(
        this.state.selectedCollectionButton
      );
    }
  }

  methodQuestions = async () => {
    try {
      const { selectedResponse, selectedMethod } = this.state;
      if (selectedResponse && selectedMethod) {
        const {
          getMethodQuestions,
          evaluationMethod,
          currentActivity,
          selectedObjective,
        } = this.props;
        const method = find(evaluationMethod, {
          response: selectedResponse,
          method: selectedMethod,
        });
        const outcomes = compact(
          map(currentActivity.objective, (objective) => {
            if (selectedObjective.includes(objective.id)) {
              return objective.outcomeId;
            }
          })
        );

        await getMethodQuestions(method.id, outcomes);
      }
    } catch (error) {
      NotificationManager.error('Something went wrong');
    }
  };

  handleChangeResponse = (selectedResponse) => {
    const { selectedMethod, selectedCollectionMethod } = this.state;
    if (selectedResponse !== 'Participant') {
      this.setState(
        {
          selectedMethod:
            selectedMethod === 'Intercept' ? null : selectedMethod,
          selectedCollectionMethod:
            selectedCollectionMethod === 'CULTURECOUNTS'
              ? null
              : selectedCollectionMethod,
          selectedResponse,
        },
        () => {
          if (selectedResponse !== 'Self-assessed') {
            this.methodQuestions();
          }
        }
      );
    } else {
      this.setState(
        {
          selectedMethod: selectedMethod,
          selectedCollectionMethod: selectedCollectionMethod,
          selectedResponse,
        },
        () => {
          if (selectedResponse !== 'Self-assessed') {
            this.methodQuestions();
          }
        }
      );
    }
  };

  handleChangeMethod = (selectedMethod) => {
    const { selectedCollectionMethod } = this.state;
    this.setState(
      {
        selectedMethod,
        selectedCollectionMethod:
          selectedMethod === 'Intercept'
            ? 'CULTURECOUNTS'
            : selectedCollectionMethod,
        selectedCollectionButton: selectedMethod === 'Intercept' ? true : null,
      },
      () => {
        this.methodQuestions();
      }
    );
  };

  handleCollectionMethod = (selectedCollectionMethod) => {
    this.setState({
      selectedCollectionMethod,
      selectedCollectionButton: true,
    });
  };

  handleEvaluationSeries(status) {
    this.props.handleEvaluationSeries(status);
  }

  renderBasedCollectionMethod(selectedCollectionMethod) {
    switch (selectedCollectionMethod) {
      case 'CULTURECOUNTS':
        return (
          <div className="culture-count-container">
            <a
              href={'https://culturecounts.cc/sign-in'}
              rel="noopener noreferrer"
              target="_blank"
              className="culture-count-a"
            >
              <div className="culture-count">
                <span>
                  Go to Culture Counts
                  <Icon name="external alternate" className="ml-3 mb-1" />
                </span>
              </div>
            </a>
          </div>
        );
      case 'LINK':
        return (
          <Fragment>
            <CustomQuestion
              ref={(c) => (this.customQuestions = c)}
              {...this.props}
            />
            <div className="sep-line-eval" />

            <CustomSuccessMessage
              ref={(c) => (this.customMessage = c)}
              {...this.props}
            />
          </Fragment>
        );
      default:
        return null;
    }
  }

  getMethodKey(response) {
    let key = '';
    switch (response) {
      case 'Interview':
        key = '147';
        break;
      case 'Questionnaire':
        key = '148';
        break;
      case 'Focus Group':
        key = '149';
        break;
      case 'Intercept':
        key = '216';
        break;
      default:
        break;
    }

    return key;
  }

  getResponsesKey(response) {
    let key = '';
    switch (response) {
      case 'Participant':
        key = '143';
        break;
      case 'Proxy':
        key = '144';
        break;
      case 'Expert':
        key = '145';
        break;
      case 'Self-assessed':
        key = '146';
        break;
      default:
        break;
    }

    return key;
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  evaluationSeriesHandler(status) {
    this.setState({
      isSeries: status,
    });
    if (!status && !this.state.popupShown) {
      NotificationManager.info(
        'You are not able to proceed with this method. If you are considering doing multiple evaluations that will include different people, check the help page to see how this can be done. ',
        '',
        10000 // 10S delay [to read whole message]
      );
      this.setState({ checkBoxDisabled: true });
    }
    this.props.handleEvaluationSeries(status);
  }

  render() {
    const {
      selectedResponse,
      selectedMethod,
      confirm,
      selectedCollectionMethod,
      modal,
    } = this.state;

    const {
      evaluationMethod,
      currentActivity,
      surveyQuestions,
      form,
      selectedObjective,
      helpTips,
      user,
      isSeries,
      setEvalSeriesCount,
      editMode,
    } = this.props;

    const isCultureCount = currentActivity.isCultureCount;
    const onlyCultureCount =
      selectedResponse === 'Participant' && selectedMethod === 'Intercept';

    const response = unionBy(evaluationMethod, 'response');
    const methods =
      filter(evaluationMethod, { response: selectedResponse }) || [];

    let getQuestions = {
      questions: [],
      facilitatorNote: '',
    };

    if (!isEmpty(surveyQuestions)) {
      getQuestions = surveyQuestions;
    } else {
      if (!isEmpty(form.evaluationQuestions)) {
        getQuestions = {
          questions: form.evaluationQuestions,
          facilitatorNote: form.facilitatorNote,
        };
      }
    }

    const sortedObjectives = sortBy(selectedObjective);
    let questions = sortBy(getQuestions.questions, (i) => i.objectiveId);
    questions = map(questions, (q, i) => {
      if (!q.objectiveId) {
        q.objectiveId = sortedObjectives[i];
      }
      return q;
    });
    return (
      <div className="evalPlan-question">
        <div className="outputs-col-div">
          <Confirm
            open={confirm}
            onCancel={this.close}
            onConfirm={() => this.getQuestions()}
            header="Warning!"
            content="You are have made changes to the activity after adding documentation information.  Please review the documentation information to ensure all information is still correct and update any fields affected by changes."
            size="tiny"
          />
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column className="creation-eval-new">
                <div className="evalPlanType-question">
                  <div className="evalPlanTypes">
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '130',
                      }}
                      position="top left"
                      wide="very"
                    >
                      <div className="evaluations-method-label">
                        Select Evaluation Respondent Type
                      </div>
                    </CustomTooltip>

                    <AvRadioGroup
                      inline
                      name="response"
                      value={selectedResponse}
                      required
                    >
                      {response.length
                        ? response.map((item, i) => {
                            if (item.response) {
                              return (
                                <AvRadio
                                  key={i}
                                  label={
                                    <CustomTooltip
                                      state={get(user, 'options.tips')}
                                      contentObject={{
                                        data: helpTips,
                                        key: this.getResponsesKey(
                                          item.response
                                        ),
                                      }}
                                      position="top left"
                                      wide="very"
                                    >
                                      <div>{item.response}</div>
                                    </CustomTooltip>
                                  }
                                  value={item.response}
                                  onChange={(e) => {
                                    this.handleChangeResponse(e.target.value);
                                  }}
                                />
                              );
                            }
                            return null;
                          })
                        : '-'}
                    </AvRadioGroup>
                  </div>

                  {selectedResponse && selectedResponse !== 'Self-assessed' ? (
                    <Fragment>
                      <div className="evalPlanTypes">
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '129',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div className="evaluations-method-label">
                            Select Evaluation Method
                          </div>
                        </CustomTooltip>
                        <AvRadioGroup
                          inline
                          name="method"
                          required
                          errorMessage="Evaluation Method is required"
                          className="evaluation-method-radio"
                          value={selectedMethod}
                        >
                          {methods
                            ? methods.map((item, i) => {
                                return (
                                  <AvRadio
                                    key={i}
                                    label={
                                      <CustomTooltip
                                        state={get(user, 'options.tips')}
                                        contentObject={{
                                          data: helpTips,
                                          key: this.getMethodKey(item.method),
                                        }}
                                        position="top left"
                                        wide="very"
                                      >
                                        <div>{item.method}</div>
                                      </CustomTooltip>
                                    }
                                    value={item.method}
                                    onChange={(e) => {
                                      this.handleChangeMethod(e.target.value);
                                    }}
                                  />
                                );
                              })
                            : null}
                        </AvRadioGroup>
                      </div>
                      <div className="evalPlanTypes">
                        <CustomTooltip
                          state={get(user, 'options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '131',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <div className="evaluations-method-label">
                            Select Data Collection Method
                          </div>
                        </CustomTooltip>
                        <AvRadioGroup
                          inline
                          name="collectionMethod"
                          required
                          errorMessage="Data collection method is required"
                          className="evaluation-method-radio"
                          value={
                            selectedMethod === 'Intercept'
                              ? 'CULTURECOUNTS'
                              : ''
                          }
                        >
                          {!onlyCultureCount
                            ? [
                                <AvRadio
                                  label={
                                    <CustomTooltip
                                      state={get(user, 'options.tips')}
                                      contentObject={{
                                        data: helpTips,
                                        key: '150',
                                      }}
                                      position="top left"
                                      wide="very"
                                    >
                                      <div>Takso Link</div>
                                    </CustomTooltip>
                                  }
                                  value="LINK"
                                  key="LINK"
                                  onChange={(e) => {
                                    this.handleCollectionMethod(e.target.value);
                                  }}
                                />,
                                <AvRadio
                                  label={
                                    <CustomTooltip
                                      state={get(user, 'options.tips')}
                                      contentObject={{
                                        data: helpTips,
                                        key: '151',
                                      }}
                                      position="top left"
                                      wide="very"
                                    >
                                      <div>CSV Upload</div>
                                    </CustomTooltip>
                                  }
                                  value="CSV"
                                  key="CSV"
                                  onChange={(e) => {
                                    this.handleCollectionMethod(e.target.value);
                                  }}
                                />,
                              ]
                            : null}
                          {isCultureCount &&
                          selectedResponse === 'Participant' ? (
                            <AvRadio
                              label={
                                <CustomTooltip
                                  state={get(user, 'options.tips')}
                                  contentObject={{
                                    data: helpTips,
                                    key: '217',
                                  }}
                                  position="top left"
                                  wide="very"
                                >
                                  <div>Culture Counts</div>
                                </CustomTooltip>
                              }
                              value="CULTURECOUNTS"
                              key="CULTURECOUNTS"
                              onChange={(e) => {
                                this.handleCollectionMethod(e.target.value);
                              }}
                              checked={true}
                            />
                          ) : null}
                        </AvRadioGroup>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className="question-width">
          {selectedResponse === 'Self-assessed' ? (
            <div className="show-q">
              Self-assessed evaluation will be completed on the Documentation
              Page.
            </div>
          ) : isEmpty(getQuestions) ? null : (
            <Fragment>
              {selectedResponse && selectedMethod ? (
                <>
                  <div className="questions-eval-new">
                    <div className="border-line" />
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '213',
                      }}
                      position="top left"
                      wide="very"
                    >
                      <div className="question-text">Questions</div>
                    </CustomTooltip>
                  </div>
                  {map(questions, (question, i) => (
                    <ShowQuestion
                      key={i}
                      ref={(c) => (this[`showQuestions${i}`] = c)}
                      question={question}
                      index={i}
                      {...this.props}
                    />
                  ))}
                  <div className="questions-eval-padding">
                    <CustomAccordion
                      headerStyle={{ color: '#EF8123' }}
                      title="Facilitator Notes"
                      evalPlan={true}
                      children={
                        <FacilitatorNotes
                          getQuestions={getQuestions}
                          {...this.props}
                        />
                      }
                    />
                  </div>
                </>
              ) : null}
              {this.renderBasedCollectionMethod(selectedCollectionMethod)}
            </Fragment>
          )}
        </div>

        {this.state.selectedCollectionMethod ||
        selectedResponse === 'Self-assessed' ||
        (form.isSeries && editMode) ? (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className="question-width">
                  <div className="checkbox-title-eval">
                    <Grid.Column mobile={16} tablet={16} computer={7}>
                      <div className="checkbox-padding">
                        {/* {!checkBoxDisabled ? ( // hide series checkbox when user no need it */}
                        <AvCheckboxGroup
                          name="isAllOrganisation"
                          className="eval-checkBox"
                        >
                          <AvCheckbox
                            disabled={form.isSeries} // || checkBoxDisabled
                            label={
                              <CustomTooltip
                                state={get(user, 'options.tips')}
                                contentObject={{
                                  data: helpTips,
                                  key: '5002',
                                }}
                                position="top left"
                                wide="very"
                              >
                                <span>
                                  Create multi-stage evaluation series
                                </span>
                              </CustomTooltip>
                            }
                            checked={isSeries || (editMode && form.isSeries)}
                            onChange={() => {
                              this.state.isSeries
                                ? this.evaluationSeriesHandler(
                                    !this.state.isSeries
                                  )
                                : this.toggle();
                            }}
                          />
                        </AvCheckboxGroup>
                        {/* ) : (
                          '' */}
                        {/* )} */}
                      </div>

                      {isSeries || form.isSeries ? (
                        <AvField
                          placeholder="How many times will this evaluation be repeated?"
                          className="eval-inputs mt-8"
                          name="seriesCount"
                          type="number"
                          min="2"
                          max="15"
                          autoComplete="off"
                          value={editMode && this.props.form.seriesCount}
                          validate={{
                            required: {
                              value: isSeries,
                              errorMessage:
                                'Must be a minimum of 2 evaluations',
                            },
                            // minNumber: value => {
                            //   return parseInt(value) >= 2
                            //     ? ''
                            //     : 'Series must have a minimum of 2 evaluations';
                            // },
                          }}
                          onChange={(e) => setEvalSeriesCount(e.target.value)}
                        />
                      ) : null}
                    </Grid.Column>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}

        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          size="small"
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <p>Do you intend to survey the same people multiple times?</p>
          </Modal.Content>
          <Modal.Actions>
            <div className="button-flex">
              <Button
                onClick={() => {
                  this.setState({ popupShown: false }, () => {
                    this.evaluationSeriesHandler(false);
                    this.toggle();
                  });
                }}
                className="Secondary-Button"
              >
                No
              </Button>
              <Button
                onClick={() => {
                  this.setState({ popupShown: true });
                  this.evaluationSeriesHandler(true);
                  this.toggle();
                }}
                className="Primary-Button"
              >
                Yes
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

class FacilitatorNotes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayNotes: false,
    };
  }
  render() {
    const { getQuestions, currentActivity } = this.props;
    const note = noteReplace(
      currentActivity,
      get(getQuestions, 'facilitatorNote', null)
    );
    if (!note) return null;
    return (
      <Grid.Row className="evalPlan-question">
        <div className="show-facilitator">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: note,
              }}
            />
          </div>
        </div>
      </Grid.Row>
    );
  }
}

class ShowQuestion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      questionNew: {
        methodHeading: '',
        heading: '',
        question1: '',
        question2: '',
      },
    };
  }

  componentDidMount() {
    this.setQuestion();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.question, this.props.question)) {
      this.setQuestion();
    }
  }

  setQuestion() {
    const { question, currentActivity, index } = this.props;
    let questionNew = {
      ...question,
      methodHeading:
        index === 0
          ? noteReplace(currentActivity, question.methodHeading)
          : null,
      heading: noteReplace(currentActivity, question.heading),
    };

    this.setState({
      questionNew,
    });
  }

  getEvaluationQuestion() {
    return this.state.questionNew;
  }

  addQuestion() {
    const { question } = this.props;
    const methodHeading = document.getElementById(
      `methodHeading${question.outcomeId}`
    );
    const heading = document.getElementById(`heading${question.outcomeId}`);
    const question1 = document.getElementById(`question1${question.outcomeId}`);
    const question2 = document.getElementById(`question2${question.outcomeId}`);

    const questionNew = {
      ...question,
      methodHeading: methodHeading ? methodHeading.innerHTML : '',
      heading: heading ? heading.innerHTML : '',
      question1: question1 ? question1.innerHTML : '',
      question2: question2 ? question2.innerHTML : '',
    };

    this.setState({
      questionNew,
    });
  }

  render() {
    const { questionNew } = this.state;
    const { currentActivity, question } = this.props;

    const qualitativeQuestion = questionNew.question2
      ? `Can you tell us more about why you gave these two ratings?`
      : 'Can you tell us more about why you gave this rating?';

    let allQuestion = '';
    for (const key in questionNew) {
      if (questionNew.hasOwnProperty(key)) {
        if (
          ['methodHeading', 'heading', 'question1', 'question2'].includes(key)
        ) {
          let getQuestion = questionNew[key];

          if (getQuestion !== null) {
            getQuestion = getQuestion.replace(
              /\+\+\+/g,
              '<span class="content-highlight" contenteditable="true">'
            );
            getQuestion = getQuestion.replace(/\*\*\*/g, '</span>');
            allQuestion += `<br/><div id="${key}${question.outcomeId}" >${getQuestion}</div>`;
          }
        }
      }
    }

    const questioner = {
      __html: allQuestion,
    };

    const objective = find(
      currentActivity.objective,
      (o) => o.id === question.objectiveId
    );
    return (
      <div className="questions-eval-padding">
        <CustomAccordion
          showQuestion={true}
          title={objective ? objective.outcome.title : '-'}
          headerStyle={{ color: '#000000' }}
          children={
            <div className="show-qs">
              <div
                onBlur={() => this.addQuestion()}
                dangerouslySetInnerHTML={questioner}
              />
              <div className="rating-qs">{qualitativeQuestion}</div>
            </div>
          }
        />
      </div>
    );
  }
}

class CustomQuestionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { questions, createQuestion, removeQuestion, updateQuestion } =
      this.props;
    return (
      <Fragment>
        {map(questions, (ques, i) => {
          return (
            <div className="custom-ques" key={i}>
              <div className="right-trash">
                <div className="remove-icon-eval">
                  {i === 0 ? null : (
                    <Icon
                      className="eval-qs-remove"
                      name="trash"
                      size="large"
                      onClick={() => removeQuestion(ques.index)}
                    />
                  )}
                </div>
              </div>

              <AvField
                name="Question"
                className="custom-question-input"
                placeholder="Enter your question"
                value={ques.label}
                onBlur={(e) =>
                  updateQuestion(ques.index, ques.type, e.target.value)
                }
              />
            </div>
          );
        })}

        <div className="add-icon" onClick={() => createQuestion()}>
          <img className="icons-add" src={addCustom} alt="icon" />
          <div className="add-more-ques">Add more questions</div>
        </div>
        <div className="sep-line-eval" />
      </Fragment>
    );
  }
}

class CustomQuestion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      showEmailInput: false,
      labelEmail: false,
      email: '',
      showFreeQuestion: false,
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  getQuestions() {
    return this.state.questions;
  }

  componentDidMount() {
    this.setState({
      questions: get(this.props, 'form.customQuestions', []),
      showEmailInput: get(this.props, 'form.additionalEmail', false),
      showFreeQuestion: get(this.props, 'form.additionalQuestion', false),
    });
  }

  addFreeQuestion() {
    let questions = cloneDeep(this.state.questions) || [];
    questions.push({
      type: 'FREE',
      label: '',
    });
    this.setState({
      questions,
    });
  }

  createQuestion() {
    const customQuestions = filter(
      this.state.questions,
      (e) => e.type === 'FREE'
    );
    if (!isEmpty(customQuestions) && size(customQuestions) === 1) {
      this.setState({
        modal: true,
      });
    } else {
      this.addFreeQuestion();
    }
  }

  removeQuestion(index) {
    let questions = cloneDeep(this.state.questions) || [];
    questions.splice(index, 1);
    this.setState({
      questions,
    });
  }

  updateQuestion(index, type, value) {
    let questions = cloneDeep(this.state.questions) || [];
    if (questions[index].type === type) {
      questions[index].label = value;
    }
    this.setState({
      questions,
    });
  }

  setQuestion(type) {
    switch (type) {
      case 'FREE':
        return '';
      case 'AGE':
        return 'What is your Age?';
      case 'POSTCODE':
        return 'What is your Postcode?';
      default:
        return '';
    }
  }

  onChange(type, check) {
    let { questions: ques } = this.state;

    if (check) {
      if (type === 'FREE')
        this.setState({
          showFreeQuestion: true,
        });
      else {
        if (type === 'EMAIL') {
          this.setState({ showEmailInput: true });
        }
      }
      ques.push({
        type: type,
        label: this.setQuestion(type),
      });
    } else {
      if (type === 'FREE')
        this.setState({
          showFreeQuestion: false,
        });
      else {
        if (type === 'EMAIL') {
          this.setState({ showEmailInput: false });
        }
      }

      ques = filter(ques, (o) => {
        return o.type !== type;
      });
    }

    this.setState({
      questions: ques,
    });
  }

  onEmail(value) {
    let { questions = [] } = this.state;
    let email = find(questions, (question) => question.type === 'EMAIL');
    if (email) {
      email.label = value;
      this.setState({
        questions,
      });
    }
  }

  render() {
    const {
      questions = [],
      modal,
      showFreeQuestion,
      showEmailInput,
    } = this.state;

    const questionDefaultValue = find(
      questions,
      (question) => question.type === 'EMAIL'
    );

    const { helpTips, user } = this.props;
    return (
      <>
        <div className="evalPlan-question">
          <CustomTooltip
            state={get(user, 'options.tips')}
            contentObject={{
              data: helpTips,
              key: '214',
            }}
            position="top left"
            wide="very"
          >
            <div className="question-text">Custom Questions</div>
          </CustomTooltip>
          <div>Select Predefined Questions</div>
          <Fragment>
            <div className="av-padding">
              <div className="additional-group">
                <div className="custom-ques">
                  <AvInput
                    type="checkbox"
                    name="additionalAge"
                    onChange={(e) => this.onChange('AGE', e.target.checked)}
                  />{' '}
                  <span className="move-right">What is your Age?</span>
                </div>
                <div className="custom-ques">
                  <AvInput
                    type="checkbox"
                    name="additionalPostcode"
                    onChange={(e) =>
                      this.onChange('POSTCODE', e.target.checked)
                    }
                  />{' '}
                  <span className="move-right">What is your Postcode?</span>
                </div>
                <div className="custom-ques">
                  <AvInput
                    type="checkbox"
                    name="additionalQuestion"
                    onChange={(e) => this.onChange('FREE', e.target.checked)}
                    multiple
                  />{' '}
                  <span className="move-right">Custom Question</span>
                </div>

                {showFreeQuestion ? (
                  <CustomQuestionForm
                    questions={compact(
                      map(questions, (ques, index) => {
                        if (ques.type === 'FREE') {
                          return {
                            ...ques,
                            index,
                          };
                        }
                      })
                    )}
                    createQuestion={() => this.createQuestion()}
                    removeQuestion={(index) => this.removeQuestion(index)}
                    updateQuestion={(index, type, value) =>
                      this.updateQuestion(index, type, value)
                    }
                    {...this.props}
                  />
                ) : null}

                <div className="custom-ques">
                  <AvInput
                    type="checkbox"
                    name="additionalEmail"
                    onChange={(e) => this.onChange('EMAIL', e.target.checked)}
                  />
                  <span className="move-right">Request Email</span>
                </div>
              </div>

              {showEmailInput && (
                <div className="label-email">
                  <AvField
                    validate={{
                      required: {
                        value: '^[A-Za-z0-9]+$',
                        errorMessage: 'Respondent Email Message is Required',
                      },
                    }}
                    name="EmailField"
                    label=""
                    defaultValue={
                      !isEmpty(questionDefaultValue) &&
                      questionDefaultValue.label
                    }
                    placeholder="Enter request for respondent's email"
                    className="email-active"
                    onBlur={(e) => this.onEmail(e.target.value)}
                  />
                </div>
              )}
            </div>
          </Fragment>

          <Modal
            open={modal}
            onOpen={() => this.toggle()}
            closeIcon={
              <Icon
                onClick={() => this.toggle()}
                name="close"
                className="closeicon"
              />
            }
            size="small"
          >
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
              <p>
                When deciding to include additional questions, ensure that the
                information requested is needed and will be used. This will
                avoid the collection of unnecessary information and ensure your
                survey is succinct
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.toggle()} className="modal-cancel">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.addFreeQuestion();
                  this.toggle();
                }}
                className="modal-proceed"
              >
                Confirm
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </>
    );
  }
}

class EvaluationPlanForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedObj: null,
      totalParticipants: 0,
      selectedResponse: null,
      groupSelected: false,
      sampleSize: 0,
      loading: false,
      selectedObjective: [],
      collectionMethodButton: false,
      getSelectResponseValue: false,
      selectedGroups: [],
      groupType: null,
      isSeries: false,
      seriesCount: 0,
    };
  }

  getEvaluationQuestion() {
    return this.evaluationPlanRespond.getEvaluationQuestion();
  }
  getTotalParticipant() {
    return this.evaluationPlanRespond.getTotalParticipant();
  }
  getQuestions() {
    return this.evaluationPlanRespond.getQuestions();
  }
  getCustomSuccessMessage() {
    return this.evaluationPlanRespond.getCustomSuccessMessage();
  }
  getSampleSize() {
    return this.state.sampleSize;
  }

  getTotalParticipants() {
    return this.state.totalParticipants;
  }

  getMultiStageEvalCount() {
    return this.state.seriesCount;
  }

  getIsMultiStageEval() {
    return this.state.isSeries;
  }

  componentDidMount() {
    try {
      const { getEvaluationGroups, currentActivity } = this.props;
      this.setState(
        {
          selectedObjective: this.props.form.objectives,
          selectedGroups: this.props.form.participantGroups,
        },
        () => {
          const participants = get(this.props, 'form.participants', []);
          if (!isEmpty(participants)) {
            const [one] = participants;
            this.setState({
              groupType: get(one, 'targetGroup.type', null),
            });
          }
        }
      );
      this.setSizes();
      getEvaluationGroups(currentActivity.id);
    } catch (error) {
      NotificationManager.error('Something went wrong');
    }
  }

  setSizes() {
    const { form } = this.props;
    if (!isEmpty(form.participants)) {
      let sampleSize = 0;
      let sampleSizeLabel = 0;
      let confidenceLevel = 1.96;
      let confidenceInterval = 5;
      sampleSize =
        (confidenceLevel * confidenceLevel * 0.25) /
        ((confidenceInterval / 100) * (confidenceInterval / 100));

      const totalParticipants = sum(
        map(form.participants, (group) =>
          parseInt(get(group, 'targetGroup.size', 0))
        )
      );

      let population = Math.floor(totalParticipants);
      if (population !== 0) {
        sampleSize = sampleSize / (1 + (sampleSize - 1) / population);
        sampleSizeLabel = Math.floor(sampleSize);
      }
      this.setState({
        totalParticipants,
        sampleSize: sampleSizeLabel,
      });
    }
  }

  handleChangeObjective(value, check) {
    const newValue = parseInt(value);
    const selectedObjective = [...this.state.selectedObjective];

    var index = selectedObjective.indexOf(newValue);

    if (check === true) {
      selectedObjective.push(newValue);
      this.setState({
        selectedObjective: uniq(selectedObjective),
      });
    } else {
      const selectedObjective = [...this.state.selectedObjective];
      if (index > -1) {
        selectedObjective.splice(index, 1);
      }

      this.setState({
        selectedObjective: uniq(selectedObjective),
      });
    }
  }

  async handleChangeGroups(value, check, groupType) {
    const newValue = parseInt(value);
    const selectedGroups = [...this.state.selectedGroups];

    var index = selectedGroups.indexOf(newValue);

    if (check === true) {
      selectedGroups.push(newValue);
      this.setState({
        selectedGroups: uniq(selectedGroups),
        groupType,
      });
    } else {
      const selectedGroups = [...this.state.selectedGroups];
      if (index > -1) {
        selectedGroups.splice(index, 1);
      }

      this.setState({
        selectedGroups: uniq(selectedGroups),
        groupType,
      });
    }
  }

  handleEvaluationSeries = (status) => {
    this.setState({
      isSeries: status,
    });
  };

  setEvalSeriesCount = (count) => {
    this.setState({
      seriesCount: count,
    });
  };

  async evaluationGroup(value, size, check, groupType) {
    await this.handleChangeGroups(value, check, groupType);

    let sampleSize = 0;
    let sampleSizeLabel = 0;
    let confidenceLevel = 1.96;
    let confidenceInterval = 5;
    sampleSize =
      (confidenceLevel * confidenceLevel * 0.25) /
      ((confidenceInterval / 100) * (confidenceInterval / 100));

    const totalParticipants = check
      ? this.state.totalParticipants + parseInt(size)
      : this.state.totalParticipants - parseInt(size);

    let population = Math.floor(totalParticipants);

    if (population !== 0) {
      sampleSize = sampleSize / (1 + (sampleSize - 1) / population);
      sampleSizeLabel = Math.floor(sampleSize);
    }

    this.setState({
      totalParticipants,
      sampleSize: sampleSizeLabel,
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 500);
  }

  getCollectionMethodForButton = (value) => {
    if (value === true) {
      this.setState({
        collectionMethodButton: true,
      });
    } else {
      return null;
    }
  };

  getSelfAssessedResponse = (value) => {
    if (value === 'Self-assessed') {
      this.setState({
        getSelectResponseValue: true,
      });
    } else {
      this.setState({
        getSelectResponseValue: false,
      });
    }
  };

  render() {
    const {
      currentActivity,
      editMode,
      methods,
      evaluationGroupSelected,
      groupParticipantLoading,
      goBack,
      helpTips,
      user,
    } = this.props;

    const {
      sampleSize,
      totalParticipants,
      loading,
      selectedObjective,
      selectedGroups,
      groupType,
      collectionMethodButton,
      getSelectResponseValue,
    } = this.state;

    return (
      <div className="create-evaluation-plan">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <AvField
                label="Evaluation Plan Name"
                className="eval-inputs"
                name="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Evaluation Plan Name is required',
                  },
                }}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="participant-group-div">
                <div className="checkbox-title-eval">
                  <AvCheckboxGroup
                    name="objectives"
                    className="eval-checkBox"
                    label={
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '210',
                        }}
                        position="top left"
                        wide="very"
                      >
                        <div>
                          {size(currentActivity.objective) === 1
                            ? 'Objective'
                            : 'Objective/s'}
                        </div>
                      </CustomTooltip>
                    }
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Linked objectives are required',
                      },
                    }}
                  >
                    {!isEmpty(currentActivity.objective) ? (
                      <Grid className="eval-check">
                        {map(currentActivity.objective, (objective, i) => {
                          return (
                            <Grid.Column
                              key={i}
                              mobile={16}
                              tablet={16}
                              computer={6}
                            >
                              <div className="checkbox-padding">
                                <AvCheckbox
                                  name="groups"
                                  label={get(objective, 'outcome.title', '-')}
                                  value={objective.id}
                                  onChange={(e) => {
                                    this.handleChangeObjective(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                            </Grid.Column>
                          );
                        })}
                      </Grid>
                    ) : (
                      <em>There is no objective selected</em>
                    )}
                  </AvCheckboxGroup>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>{' '}
          <Grid.Row>
            <Grid.Column>
              <div className="participant-group-div">
                <div className="checkbox-title-eval">
                  <AvCheckboxGroup
                    className="eval-checkBox"
                    name="participantGroups"
                    label={
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '211',
                        }}
                        position="top left"
                        wide="very"
                      >
                        <div>Select Evaluation Group</div>
                      </CustomTooltip>
                    }
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Linked evaluation groups are required',
                      },
                    }}
                  >
                    {!isEmpty(evaluationGroupSelected) ? (
                      <Grid className="eval-check">
                        {compact(
                          map(evaluationGroupSelected, (group, i) => {
                            let disabled = false;
                            if (
                              groupType === 'FIELD' &&
                              group.type === 'GROUP'
                            ) {
                              disabled = true;
                            }
                            if (
                              groupType === 'GROUP' &&
                              group.type === 'FIELD'
                            ) {
                              disabled = true;
                            }
                            if (isEmpty(selectedGroups)) {
                              disabled = false;
                            }
                            return (
                              <Grid.Column
                                key={i}
                                mobile={16}
                                tablet={16}
                                computer={6}
                              >
                                <div className="checkbox-padding">
                                  <AvCheckbox
                                    disabled={disabled}
                                    label={get(group, 'name', '-')}
                                    value={group.id}
                                    onChange={(e) => {
                                      this.evaluationGroup(
                                        e.target.value,
                                        group.size,
                                        e.target.checked,
                                        group.type
                                      );
                                    }}
                                  />
                                </div>
                              </Grid.Column>
                            );
                          })
                        )}
                      </Grid>
                    ) : (
                      <em>There is no evaluation group to select</em>
                    )}
                  </AvCheckboxGroup>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="eval-mtd-div">
                <div className="desc-div">
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '128',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <div className="sample-text">Recommended Sample Size</div>
                  </CustomTooltip>
                  {loading ? (
                    <Loader active inline className="evaluation-div-loader" />
                  ) : (
                    <div>
                      <label className="description">
                        Based on <b>{totalParticipants} total participants</b>{' '}
                        in the selected groups and a{' '}
                        <b>confidence level of 95%</b> and a{' '}
                        <b>confidence interval of +/-5</b> the recommended
                        sample size is <b>{sampleSize}</b>.
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {isEmpty(selectedObjective) ? null : (
          <EvaluationPlanRespondent
            ref={(c) => (this.evaluationPlanRespond = c)}
            selectedObjective={selectedObjective}
            evaluationMethod={methods}
            isSeries={this.state.isSeries}
            getCollectionMethodForButton={this.getCollectionMethodForButton}
            getSelfAssessedResponse={this.getSelfAssessedResponse}
            setEvalSeriesCount={this.setEvalSeriesCount}
            handleEvaluationSeries={this.handleEvaluationSeries}
            {...this.props}
          />
        )}

        <div className="participant-group-div">
          {editMode ? null : (
            <div>
              {collectionMethodButton || getSelectResponseValue ? (
                <div className="button-survey-new">
                  <Button
                    className="Secondary-Button"
                    onClick={(type) => goBack()}
                  >
                    Back
                  </Button>
                  <div>
                    <Button
                      type="submit"
                      className="Primary-Button"
                      loading={groupParticipantLoading}
                      disabled={groupParticipantLoading}
                    >
                      Save Evaluation Plan
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {editMode ? (
          <div className="button-survey-new">
            <div
              className="Secondary-Button"
              type="button"
              onClick={() => goBack()}
            >
              <div className="back-text">Back</div>
            </div>
            <div>
              <Button
                type="submit"
                className="Primary-Button"
                loading={groupParticipantLoading}
                disabled={groupParticipantLoading}
              >
                Update Evaluation Plan
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export class DeleteEvalPlan extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleGroupDelete() {
    const {
      evalPlanId,
      currentActivity,
      deleteEvaluationPlan,
      resetForm,
      seriesParentId,
    } = this.props;
    let isSeries = false;

    if (seriesParentId) {
      isSeries = true;
    } else {
      isSeries = false;
    }

    deleteEvaluationPlan(currentActivity.id, evalPlanId, isSeries);
    this.toggle();
    resetForm();
  }

  render() {
    const { modal } = this.state;
    const { evaluationLoader, trigger, seriesParentId } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={trigger}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete this{' '}
            {seriesParentId ? 'survey series' : 'survey'}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="modal-cancel">
            Cancel
          </Button>
          <Button
            className="modal-proceed"
            onClick={() => this.handleGroupDelete()}
            content="Delete "
            type="submit"
            loading={evaluationLoader}
            disabled={evaluationLoader}
            negative
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class ShowEvaluationModelQuestion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { evalPlans } = this.props;
    const evaluationQuestions =
      orderBy(evalPlans.evaluationQuestions, (i) => i.objectiveId) || [];
    return (
      <Fragment>
        <CustomAccordion
          showQuestion={true}
          title="Evaluation Questions"
          headerStyle={{ color: '#000000' }}
          children={
            <div className="show-qs-model">
              <div className="eval-groups-qs">
                {map(evaluationQuestions, (question, i) => {
                  return (
                    <ShowEvaluationQuestion
                      key={i}
                      question={question}
                      index={i}
                    />
                  );
                })}
              </div>
            </div>
          }
        />
      </Fragment>
    );
  }
}
class ViewPlan extends React.PureComponent {
  calculateSampleSize(evalPlans = []) {
    let sampleSize = 0;
    let sampleSizeLabel = 0;
    let confidenceLevel = 1.96;
    let confidenceInterval = 5;
    sampleSize =
      (confidenceLevel * confidenceLevel * 0.25) /
      ((confidenceInterval / 100) * (confidenceInterval / 100));

    const totalParticipants = sum(
      map(evalPlans?.participantGroups, (group) =>
        parseInt(get(group, 'targetGroup.size', 0))
      )
    );
    let population = Math.floor(totalParticipants);

    if (population !== 0) {
      sampleSize = sampleSize / (1 + (sampleSize - 1) / population);
      sampleSizeLabel = Math.floor(sampleSize);
    }
    return {
      totalParticipants,
      sampleSize: sampleSizeLabel,
    };
  }

  renderQuestions(customQs) {
    switch (customQs.type) {
      case 'AGE':
        return 'What is your age?';
      case 'POSTCODE':
        return 'What is your postcode?';
      default:
        return customQs.label;
    }
  }

  render() {
    const { evalPlans, getObjective } = this.props;
    const { totalParticipants, sampleSize } =
      this.calculateSampleSize(evalPlans);
    return (
      <Fragment>
        <div className="model-view-evaluation">
          <div className="eval-name-model">{evalPlans.name}</div>
          <div className="eval-objective">OBJECTIVE </div>
          <div className="eval-objective-sub ">{getObjective}</div>
          <div className="eval-objective">EVALUATION GROUPS</div>

          <div className="eval-groups">
            {map(evalPlans.participantGroups, (group, i) => {
              return <p key={i}>{group.targetGroup.name}</p>;
            })}
          </div>

          <div className="eval-objective">RECOMMENDED SAMPLE SIZE</div>

          <div className="eval-objective-sub">
            Based on <b>{totalParticipants} total participants</b> in the
            selected groups and a <b>confidence level of 95%</b> and a{' '}
            <b>confidence interval of +/-5</b> the recommended sample size is{' '}
            <b>{sampleSize}</b>.
          </div>

          <div className="eval-participant-type">EVALUATION METHOD</div>
          <div className="eval-groups">{evalPlans.method.response}</div>

          {evalPlans.method.response === 'Self-assessed' ? null : (
            <Fragment>
              <div className="eval-participant-type">
                EVALUATION RESPONDENT TYPE{' '}
              </div>
              <div className="eval-groups">{evalPlans.method.method}</div>

              <div className="eval-participant-type">
                DATA COLLECTION METHOD
              </div>
              <div className="eval-groups">
                {getCollectionMethod(evalPlans.collectionMethod)}
              </div>

              {/* Start of Question for model */}

              <ShowEvaluationModelQuestion evalPlans={evalPlans} />

              {/* End of Question for model */}

              {/* Start of Custom Question for model */}

              {evalPlans.collectionMethod === 'LINK' ? (
                <Fragment>
                  {!isEmpty(evalPlans.customQuestions) ? (
                    <Fragment>
                      <CustomAccordion
                        extraMargin
                        headerStyle={{
                          color: '#EF8123',
                        }}
                        title="Additional Questions"
                        evalPlan={true}
                        children={
                          <div className="eval-groups-custom-title">
                            {map(evalPlans.customQuestions, (customQs, i) => {
                              return (
                                <div key={i}>
                                  <div className="eval-group-custom">
                                    {i + 1}. {this.renderQuestions(customQs)}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        }
                      />
                    </Fragment>
                  ) : null}
                  {/* End of Custom Question for model */}

                  {/* Start of Custom Message for model */}

                  <CustomAccordion
                    extraMargin
                    headerStyle={{ color: '#EF8123' }}
                    title="Custom Success Message"
                    evalPlan={true}
                    children={
                      <div className="show-qs-model">
                        <div className="eval-groups-qs-custom">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: evalPlans.successMessage,
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                </Fragment>
              ) : null}

              {/* End of Custom Message for model */}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

class ViewEvaluationPlanModel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  objectives(item) {
    const { currentActivity } = this.props;
    return compact(
      map(currentActivity.objective, (objective, i) => {
        if (
          !find(item.evaluationQuestions, (e) => e.objectiveId === objective.id)
        ) {
          return null;
        }
        return (
          <div key={i}>
            <div className="eval-groups">
              {get(objective, 'outcome.title', null)}
            </div>
          </div>
        );
      })
    );
  }

  render() {
    const { item, triggerTitle } = this.props;

    const { modal } = this.state;
    return (
      <div>
        <Modal
          key={item.id}
          trigger={triggerTitle}
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Evaluation Plan </Modal.Header>

          <Modal.Content>
            <ViewPlan
              key={item.id}
              evalPlans={item}
              getObjective={this.objectives(item)}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const EvaluationGroup = (props) => {
  const { item } = props;
  return <div className="objective-eval">{item.name}</div>;
};

class EvaluationPlanList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      planPerPage: 5,
      currentPlans: [],
      modal: false,
      item: '',
      columns: [
        {
          width: 4,
          title: <div className="lib-header">Evaluation Plan Name</div>,
          render: (data) => {
            let namePostFix = data.seriesParentId
              ? `(${data.seriesPosition})`
              : '';
            return (
              <div className="eval-name-plan">
                <label className="desc-title">{`${data.name} ${namePostFix}`}</label>
                <div>
                  {get(data, 'isDisabled', true) ? (
                    <div className="isDisabled-eval">
                      <div className="text-dis">
                        {' '}
                        {get(data, 'haveResponses', false)
                          ? 'Closed'
                          : 'Disabled'}
                      </div>
                    </div>
                  ) : get(data, 'seriesParentId', null) &&
                    !getPageDisable(
                      props.currentActivity,
                      props.user,
                      props.currentState
                    ) ? ( // <- need to check visibility of add subtitle
                    <Button
                      onClick={() => {
                        this.props.toggleSubtitleModal(true, data);
                      }}
                      className="btn-add-subtitle"
                    >
                      {get(data, 'seriesSubtitle', null) ? (
                        <>
                          <Icon name="edit" />{' '}
                          {get(data, 'seriesSubtitle', null)}
                        </>
                      ) : (
                        <>
                          <Icon name="plus" /> Add Subtitle
                        </>
                      )}
                    </Button>
                  ) : get(data, 'seriesSubtitle', null) ? (
                    <span
                      style={{
                        position: 'static',
                        height: '1.25rem',
                        fontStyle: 'normal',
                        fontSize: '0.625rem',
                        fontWeight: '400',
                        align: 'center',
                        background: '#F5F3FF',
                        color: '#6D28D9',
                        padding: ' 0.313rem 0.75rem',
                        // marginLeft: '1.563rem',
                        size: '0.625rem',
                        textTransform: 'capitalize',
                        borderRadius: '0.25rem',
                      }}
                    >
                      {get(data, 'seriesSubtitle', null)}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          width: 5,
          title: 'Objective',
          render: (data) => {
            return <div>{this.objectives(data)}</div>;
          },
        },
        {
          width: 3,
          title: 'Evaluation group/s',
          render: (data) => {
            return size(this.evaluationGroups(data)) > 3 ? (
              <SeeMore
                minCount={3}
                dataSet={this.evaluationGroups(data)}
                CustomComponent={EvaluationGroup}
              />
            ) : (
              <div>
                {map(this.evaluationGroups(data), (g, i) => {
                  return (
                    <div key={i} className="objective-eval">
                      {g.name}
                    </div>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            const seriesParentId = get(data, 'seriesParentId', null);
            const evaluationId = get(data, 'id', null);
            return (
              <div>
                <ViewEvaluationPlanModel
                  triggerTitle={
                    <div className="view-plan">
                      {' '}
                      {seriesParentId
                        ? seriesParentId === evaluationId // only visible view for parent series
                          ? 'View Series'
                          : ''
                        : ' View the plan'}
                    </div>
                  }
                  item={data}
                  {...this.props}
                />
              </div>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            const { editPlan, currentActivity, user, currentState } =
              this.props;
            const preview = true;
            const surveyLink = getPreviewSurveyLink(
              currentActivity.id,
              data.id,
              map(
                get(data, 'evaluationQuestions', []),
                (evaluation) => evaluation.objectiveId
              ),
              preview
            );
            const seriesParentId = get(data, 'seriesParentId', null);
            const evaluationId = get(data, 'id', null);
            return (
              <div>
                {getPageDisable(currentActivity, user, currentState) ? (
                  <div>
                    {(data.collectionMethod === 'LINK' ||
                      data.collectionMethod === 'CSV') &&
                    !seriesParentId ? (
                      <a
                        href={surveyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <div className="preview-the-plan">
                          Preview Survey
                        </div>{' '}
                      </a>
                    ) : seriesParentId && seriesParentId === evaluationId ? (
                      <a
                        href={surveyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <div className="preview-the-plan">
                          Preview Survey
                        </div>{' '}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div>
                    {get(data, 'isDisabled', true) &&
                    // (get(data, 'haveResponses', false) <- future changes
                    seriesParentId === evaluationId ? (
                      <div className="disabled-eval-edit-plan">
                        {seriesParentId ? 'Edit Series' : 'Edit the plan'}
                      </div>
                    ) : (
                      <div
                        className="edit-the-plan"
                        onClick={() => editPlan(data)}
                      >
                        {seriesParentId
                          ? seriesParentId === evaluationId // only visible view for parent series
                            ? 'Edit Series'
                            : ''
                          : 'Edit the plan'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          },
        },
        {
          title: '',
          render: (data) => {
            const { resetForm, currentActivity, user, currentState } =
              this.props;
            const preview = true;
            const surveyLink = getPreviewSurveyLink(
              currentActivity.id,
              data.id,
              map(
                get(data, 'evaluationQuestions', []),
                (evaluation) => evaluation.objectiveId
              ),
              preview
            );
            const seriesParentId = get(data, 'seriesParentId', null);
            const evaluationId = get(data, 'id', null);
            return (
              <div className="dropdown-eval-plans">
                {getPageDisable(currentActivity, user, currentState) ||
                (seriesParentId && seriesParentId !== evaluationId) ? null : (
                  <Dropdown
                    icon="ellipsis vertical"
                    direction="left"
                    inline
                    selectOnBlur={false}
                  >
                    <Dropdown.Menu>
                      {data.collectionMethod === 'LINK' ||
                      data.collectionMethod === 'CSV' ? (
                        <Dropdown.Item
                          as="a"
                          href={surveyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div>Preview Survey</div>
                        </Dropdown.Item>
                      ) : null}

                      {(seriesParentId && evaluationId === seriesParentId) ||
                      !seriesParentId ? (
                        <DeleteEvalPlan
                          evalPlanId={data.id}
                          {...this.props}
                          resetForm={resetForm}
                          seriesParentId={seriesParentId}
                          trigger={
                            <Dropdown.Item
                              // text="Remove"
                              className="dropdown-customItem"
                            >
                              <div className="remove-activity">
                                {evaluationId === seriesParentId &&
                                seriesParentId ? (
                                  <span>Remove Series</span>
                                ) : (
                                  <span>Remove</span>
                                )}
                              </div>
                            </Dropdown.Item>
                          }
                        />
                      ) : (
                        ''
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { currentActivity } = this.props;
    const { planPerPage } = this.state;

    this.setState({
      currentPlans: !isEmpty(currentActivity.evaluationPlans)
        ? currentActivity.evaluationPlans.slice(0, planPerPage)
        : 0,
    });
  }

  componentDidUpdate(prevProps) {
    const { currentActivity } = this.props;
    if (
      prevProps.currentActivity.evaluationPlans !==
      currentActivity.evaluationPlans
    ) {
      const { planPerPage } = this.state;
      currentActivity.evaluationPlans &&
        this.setState({
          currentPlans: !isEmpty(currentActivity.evaluationPlans)
            ? currentActivity.evaluationPlans.slice(0, planPerPage)
            : 0,
        });
    }

    if (prevProps.seeMore !== this.state.seeMore) {
      this.evaluationGroups(this.state.item);
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  objectives(item) {
    const { currentActivity } = this.props;
    return compact(
      map(currentActivity.objective, (objective, i) => {
        if (
          !find(item.evaluationQuestions, (e) => e.objectiveId === objective.id)
        ) {
          return null;
        }
        return (
          <div className="objective-eval" key={i}>
            {get(objective, 'outcome.title', null)}
          </div>
        );
      })
    );
  }

  evaluationGroups(item) {
    let groups = [];
    for (const evalPlan of item.participantGroups) {
      let groupName = get(evalPlan, 'targetGroup.name', null);
      groups.push({ name: groupName });
    }

    return groups;
  }

  showOtherGroups(groups) {
    return compact(
      map(groups, (group, i) => {
        return (
          <div key={i}>
            <div className="objective-bg">{get(group, 'name', null)}</div>
          </div>
        );
      })
    );
  }

  pageChange(page) {
    const { currentActivity } = this.props;
    const { planPerPage } = this.state;

    const lastIndex = page * planPerPage;
    const firstIndex = lastIndex - planPerPage;
    const currentPlans = currentActivity.evaluationPlans.slice(
      firstIndex,
      lastIndex
    );

    this.setState({
      currentPage: page,
      currentPlans,
    });
  }

  render() {
    const {
      currentActivity,
      changeEvaluationPlanView,
      actPut,
      user,
      currentState,
    } = this.props;
    const { columns, currentPage, currentPlans, planPerPage } = this.state;
    const noOfPages = Math.ceil(
      currentActivity.evaluationPlans
        ? currentActivity.evaluationPlans.length / planPerPage
        : 0
    );

    return (
      <>
        <CustomSegment
          className="evaluation-plan"
          title="Evaluation Plan"
          rightHeader={
            <Segment
              loading={actPut['evaluationPlans']}
              disabled={getPageDisable(currentActivity, user, currentState)}
              className="segment-eval-plans"
            >
              <div
                onClick={(type) => changeEvaluationPlanView('Add')}
                className="Secondary-Button"
              >
                <Icon name="plus" />
                Add New Evaluation Plan
              </div>
            </Segment>
          }
          children={
            <Fragment>
              <div className="eval-bg-new">
                <CustomTable
                  header
                  data={currentPlans}
                  columns={columns}
                  pagination
                  handlePaginationChange={(page) => this.pageChange(page)}
                  page={currentPage}
                  noOfPages={noOfPages}
                  customClass="evaluation-table"
                  keyExtractor={(item, index) => item.id}
                />
              </div>
            </Fragment>
          }
        />
      </>
    );
  }
}

class MainEvalPlan extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCreatePlan: false,
      showCreatWarring: false,
      form: {
        id: null,
        name: 'Evaluation Plan',
        objectives: [],
        participantGroups: [],
        participants: [],
        customQuestions: [],
        collectionMethod: '',
        response: '',
        facilitatorNote: '',
        method: '',
        evaluationQuestions: [],
        successMessage:
          'Thank you for completing the survey, your responses have been submitted.',
        additionalAge: false,
        additionalQuestion: false,
        additionalPostcode: false,
        additionalEmail: false,
        additionalEmailLabel: '',
      },
      checkForm: {
        id: null,
        name: 'Evaluation Plan',
        objectives: [],
        participantGroups: [],
        participants: [],
        customQuestions: [],
        collectionMethod: '',
        response: '',
        facilitatorNote: '',
        method: '',
        evaluationQuestions: [],
        successMessage:
          'Thank you for completing the survey, your responses have been submitted.',
        additionalAge: false,
        additionalQuestion: false,
        additionalPostcode: false,
        additionalEmail: false,
        additionalEmailLabel: '',
      },
      editMode: false,
      loadEditing: false,
      modalShow: false,
      navigationModal: false,
      editNewForm: {},
      enterKey: false,
      subtitleModal: false,
      subtitleDeleteModal: false,
      subtitleDeleteLoading: false,
      seriesSubtitle: '',
      currentEvaluation: {},
    };
  }

  componentWillUnmount() {
    this.resetForm();
  }

  componentDidMount() {
    try {
      const { getEvaluationGroups, currentActivity, getEvaluationPlans } =
        this.props;
      getEvaluationPlans(currentActivity.id);
      getEvaluationGroups(currentActivity.id);
    } catch (error) {
      console.error(error);
    }
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  componentDidUpdate() {
    try {
      const { getEvaluationPage, isEvaluationPageOpen } = this.props;

      if (!this.state.showCreatePlan) {
        getEvaluationPage && isEvaluationPageOpen && getEvaluationPage(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async handleValidSubmit(event, values) {
    const {
      currentActivity,
      methods,
      postEvaluationPlan,
      updateEvaluationPlan,
    } = this.props;
    const { editMode, form } = this.state;

    const sampleSize = this.evaluationPlanForm.getSampleSize();
    const totalParticipants = this.evaluationPlanForm.getTotalParticipants();

    const method = find(methods, {
      response: get(values, 'response', ''),
      method: get(values, 'method', ''),
    });

    let allValue = {
      name: get(values, 'name', ''),
      collectionMethod: get(values, 'collectionMethod', 'LINK'),
      objectives: get(values, 'objectives', []),
      participantGroups: get(values, 'participantGroups', []),
      methodId: method.id,
      sampleSize,
      totalParticipants,
    };

    if (editMode) {
      allValue.isSeries = Boolean(Number(get(values, 'seriesCount', 0)));
      allValue.seriesCount = Number(get(values, 'seriesCount', 0));
    } else {
      allValue.isSeries = Boolean(
        this.evaluationPlanForm.getIsMultiStageEval()
      );
      allValue.seriesCount = Number(
        this.evaluationPlanForm.getMultiStageEvalCount()
      );
    }

    if (values.collectionMethod === 'LINK') {
      allValue.customQuestions = this.evaluationPlanForm.getQuestions();
      allValue.successMessage =
        this.evaluationPlanForm.getCustomSuccessMessage();
    }

    if (values.response === 'Self-assessed') {
      allValue.evaluationQuestions = map(
        get(values, 'objectives', []),
        (objective) => {
          const outcome = find(
            currentActivity.objective,
            (e) => e.id === objective
          );
          return {
            objectiveId: objective,
            outcomeId: outcome.outcomeId,
            methodId: method.id,
          };
        }
      );
    } else {
      const evaluationQuestions =
        this.evaluationPlanForm.getEvaluationQuestion();
      allValue.evaluationQuestions =
        this.formatEvalQuestions(evaluationQuestions);
    }

    if (editMode) {
      updateEvaluationPlan(currentActivity.id, form.id, allValue, this);
    } else {
      postEvaluationPlan(currentActivity.id, allValue, this);
    }
  }

  success() {
    this.resetForm();
    this.setState({
      showCreatePlan: false,
    });
  }

  errorFound() {
    this.setState({
      showCreatePlan: true,
    });
  }

  forceFormReset() {
    this.setState(
      {
        loadEditing: true,
      },
      () => {
        this.form && this.form.reset();
        this.setState({ loadEditing: false });
      }
    );
  }

  resetForm() {
    this.setState(
      {
        form: {
          id: null,
          name: 'Evaluation Plan',
          objectives: [],
          participantGroups: [],
          participants: [],
          customQuestions: [],
          collectionMethod: '',
          response: '',
          facilitatorNote: '',
          method: '',
          evaluationQuestions: [],
          successMessage:
            'Thank you for completing the survey, your responses have been submitted.',
          additionalAge: false,
          additionalQuestion: false,
          additionalPostcode: false,
          additionalEmail: false,
          additionalEmailLabel: '',
        },
        editMode: false,
      },
      () => {
        this.forceFormReset();
      }
    );
  }

  async editPlan(form) {
    Promise.all([this.editEvalPlan(form), this.changeEvaluationPlanView()]);
  }

  async editEvalPlan(form) {
    const { clearMethodQuestions } = this.props;
    await clearMethodQuestions();
    const additionalEmail = find(
      form.customQuestions,
      (e) => e.type === 'EMAIL'
    );

    let name;
    // create name without order format
    Boolean(form.seriesParentId)
      ? (name = form.name.replace(' (1)', ''))
      : (name = form.name);

    const setForm = {
      id: form.id,
      name: name,
      objectives: map(form.evaluationQuestions, (e) => e.objectiveId),
      participantGroups: map(form.participantGroups, (e) => e.targetGroupId),
      participants: form.participantGroups,
      customQuestions: form.customQuestions,
      collectionMethod: form.collectionMethod,
      response: form.method.response,
      facilitatorNote: form.method.facilitatorNote,
      method: form.method.method,
      evaluationQuestions: form.evaluationQuestions,
      successMessage: form.successMessage,
      additionalAge: find(form.customQuestions, (e) => e.type === 'AGE')
        ? true
        : false,
      additionalQuestion: find(form.customQuestions, (e) => e.type === 'FREE')
        ? true
        : false,
      additionalPostcode: find(
        form.customQuestions,
        (e) => e.type === 'POSTCODE'
      )
        ? true
        : false,
      additionalEmail: additionalEmail ? true : false,
      additionalEmailLabel: get(additionalEmail, 'label', ''),
      isSeries: Boolean(form.seriesParentId),
      seriesCount: Number(form.seriesCount),
    };

    this.setState(
      {
        form: setForm,
        editMode: true,
        editNewForm: setForm,
      },
      () => {
        this.forceFormReset();
      }
    );
  }

  toggle() {
    this.setState((prevState) => ({
      modalShow: !prevState.modalShow,
    }));
  }

  getFormChanges(checkForm, editMode, editNewForm) {
    let formChanges = this.form && this.form.getValues();
    formChanges = omit(formChanges, ['EmailField', 'Question']);

    formChanges = {
      ...checkForm,
      ...formChanges,
    };

    //order by ids
    editNewForm.customQuestions = orderBy(
      editNewForm.customQuestions,
      ['id'],
      ['desc']
    );
    editNewForm.evaluationQuestions = orderBy(
      editNewForm.evaluationQuestions,
      ['objectiveId'],
      ['desc']
    );

    if (editMode) {
      formChanges.customQuestions = editNewForm.customQuestions;
      formChanges.additionalEmailLabel = editNewForm.additionalEmailLabel;
      formChanges.evaluationQuestions = editNewForm.evaluationQuestions;
      formChanges.facilitatorNote = editNewForm.facilitatorNote;
      formChanges.id = editNewForm.id;
      formChanges.participants = editNewForm.participants;
      formChanges.successMessage = editNewForm.successMessage;
      formChanges.collectionMethod = editNewForm.collectionMethod;
      if (formChanges.response !== 'Self-assessed') {
        if (formChanges.collectionMethod === 'LINK') {
          formChanges.customQuestions = this.evaluationPlanForm.getQuestions();
          formChanges.successMessage =
            this.evaluationPlanForm.getCustomSuccessMessage();
        }
        if (formChanges.method !== '') {
          const evaluationQuestions =
            this.evaluationPlanForm.getEvaluationQuestion();
          formChanges.evaluationQuestions =
            this.formatEvalQuestions(evaluationQuestions);
        }
      }
      if (formChanges.response === 'Self-assessed') {
        delete formChanges.evaluationQuestions;
        delete editNewForm.evaluationQuestions;
      }
      //order by ids
      formChanges.customQuestions = orderBy(
        formChanges.customQuestions,
        ['id'],
        ['desc']
      );
      formChanges.evaluationQuestions = orderBy(
        formChanges.evaluationQuestions,
        ['objectiveId'],
        ['desc']
      );
    }
    return formChanges;
  }

  formatEvalQuestions(evaluationQuestions) {
    // pick important date from evaluation questions
    let data = map(evaluationQuestions, (e) =>
      pick(e, [
        'id',
        'objectiveId',
        'methodHeading',
        'heading',
        'question1',
        'question2',
        'outcomeId',
        'methodId',
      ])
    );
    // remove empty strings from question object
    data = map(data, (e) => {
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          if (e[key] === '') {
            e[key] = null;
          }
        }
      }
      return e;
    });
    return data;
  }

  goBack() {
    const { checkForm, editMode, editNewForm } = this.state;
    const { currentActivity } = this.props;

    // get form changes
    const formChanges = this.getFormChanges(checkForm, editMode, editNewForm);
    // get initial form values
    let beforeData = editMode ? editNewForm : checkForm;

    // format evaluation questions
    beforeData.evaluationQuestions = this.formatEvalQuestions(
      beforeData.evaluationQuestions
    );

    //add the old edit value to the state editNewFrom
    let getParticipantGroups = find(currentActivity.evaluationPlans, {
      id: editNewForm.id,
    });
    let oldParticipantGroups = [];

    if (getParticipantGroups) {
      for (const item of getParticipantGroups.participantGroups) {
        oldParticipantGroups.push(item.targetGroupId);
      }
    }
    editNewForm.participantGroups = oldParticipantGroups;

    // check changes in form
    if (!isEqual(beforeData, formChanges)) {
      this.setState({
        modalShow: true,
      });
    } else {
      this.setState((prevState) => ({
        showCreatePlan: !prevState.showCreatePlan,
        editMode: false,
      }));
    }
  }

  navigationBack() {
    const { checkForm, editMode, editNewForm } = this.state;
    // get form changes
    const formChanges = this.getFormChanges(checkForm, editMode, editNewForm);
    // get initial form values
    let beforeData = editMode ? editNewForm : checkForm;
    // format evaluation questions
    beforeData.evaluationQuestions = this.formatEvalQuestions(
      beforeData.evaluationQuestions
    );

    // check changes in form
    if (!isEqual(beforeData, formChanges)) {
      return true;
    }
    return false;
  }

  leaveThePage() {
    this.setState((prevState) => ({
      modalShow: !prevState.modalShow,
      showCreatePlan: !prevState.showCreatePlan,
      editMode: false,
    }));
  }

  setShowCreatWarring(value) {
    this.setState({
      showCreatWarring: value,
    });
  }

  changeEvaluationPlanView(type) {
    const { getEvaluationPage, currentActivity, evaluationGroupSelected } =
      this.props;
    if (
      !isEmpty(currentActivity.objective) &&
      !isEmpty(evaluationGroupSelected)
    ) {
      getEvaluationPage(true);

      if (type === 'Add') {
        this.resetForm();
      }

      this.setState((prevState) => ({
        editMode: false,
        showCreatePlan: !prevState.showCreatePlan,
      }));
    } else {
      this.setShowCreatWarring(true);
    }
  }
  // set current activity evaluation
  setCurrentActivity = (currentEvaluation) => {
    this.setState({ currentEvaluation: currentEvaluation.data });
  };

  toggleSubtitleModal = (state, data = {}) => {
    this.setState({
      subtitleModal: state,
    });
    if (data) {
      this.setCurrentActivity({ data });
    }
  };
  //update or add series subtitle
  updateSeriesSubtitle = (event, values) => {
    const { updateSeriesSubtitle } = this.props;
    const { currentEvaluation } = this.state;
    const subtitle = get(values, 'series-subtitle', '');

    updateSeriesSubtitle(
      currentEvaluation.projectId,
      currentEvaluation.id,
      subtitle
    );
    this.setState({
      subtitleModal: false,
    });
  };

  toggleSubtitleDeleteModal = (state) => {
    this.setState({
      subtitleModal: false,
      subtitleDeleteModal: state,
      subtitleDeleteLoading: false,
    });
  };

  handleDeleteSubtitle = () => {
    try {
      const { deleteSeriesSubtitle } = this.props;
      this.setState(
        {
          subtitleDeleteLoading: true,
        },
        async () => {
          const { currentEvaluation } = this.state;

          deleteSeriesSubtitle(
            currentEvaluation.projectId,
            currentEvaluation.id
          );

          this.setState({ subtitleDeleteLoading: false }, () => {
            NotificationManager.success(
              'Subtitle has been successfully deleted'
            );
            this.toggleSubtitleDeleteModal(false);
          });
        }
      );
    } catch (error) {
      console.error(error);
      NotificationManager.error(
        `We couldn't delete the subtitle`,
        'Something went wrong'
      );
    }
  };

  render() {
    const {
      showCreatePlan,
      form,
      loadEditing,
      editMode,
      modalShow,
      showCreatWarring,
      subtitleModal,
      currentEvaluation,
      subtitleDeleteModal,
      subtitleDeleteLoading,
    } = this.state;

    const { currentActivity, user, currentState, actPut } = this.props;

    return (
      <div>
        {/* <NavigationPrompt when={() => this.navigationBack() || false}> */}
        {({ onConfirm, onCancel }) => (
          <div className="msg-leave">
            <Modal
              onOpen={() => this.toggle()}
              size="tiny"
              open={true}
              closeIcon={
                <Icon onClick={onCancel} name="close" className="closeicon" />
              }
            >
              <Modal.Header className="warning-header-text">
                Are you sure you want to leave this page?
              </Modal.Header>
              <Modal.Content className="warning-content-text">
                You have unsaved changes. Do you want to leave ?
              </Modal.Content>
              <div className="buttons-stay-leave">
                <Button onClick={onCancel} className="stay">
                  Stay
                </Button>
                <Button onClick={onConfirm} content="Leave" className="leave" />
              </div>
            </Modal>
          </div>
        )}
        {/* </NavigationPrompt> */}

        <div className="msg-leave">
          <UnSavedModal
            modalShow={modalShow}
            toggle={() => this.toggle()}
            leaveThePage={() => this.leaveThePage()}
          />
        </div>
        {showCreatePlan ? (
          <Segment
            loading={actPut['evaluationPlans']}
            disabled={getPageDisable(currentActivity, user, currentState)}
            className="segment-eval-plans"
          >
            <AvForm
              ref={(c) => (this.form = c)}
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              autoComplete="off"
              model={form}
              onKeyPress={this.onKeyPress}
            >
              {loadEditing ? null : (
                <div>
                  <Segment.Group raised>
                    <Segment className="custom-segment">
                      <Fragment>
                        <div className="new-title">
                          <Icon
                            name="arrow left"
                            className="segment-icon-cursor"
                            onClick={() => this.goBack()}
                          />
                          {editMode
                            ? 'Edit Evaluation Plan'
                            : 'Create new Evaluation Plan'
                                .toLowerCase()
                                .split(' ')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(' ')}
                          <br></br>
                        </div>
                      </Fragment>
                    </Segment>
                    <EvaluationPlanForm
                      ref={(c) => (this.evaluationPlanForm = c)}
                      resetForm={() => this.resetForm()}
                      forceFormReset={() => this.forceFormReset()}
                      editMode={editMode}
                      changeEvaluationPlanView={(type) =>
                        this.changeEvaluationPlanView(type)
                      }
                      goBack={() => this.goBack()}
                      {...this.state}
                      {...this.props}
                    />
                  </Segment.Group>
                </div>
              )}
            </AvForm>
          </Segment>
        ) : (
          <div>
            <Modal
              open={subtitleModal}
              // onOpen={() => this.toggleSubtitleModal()}
              size="tiny"
              closeIcon={
                <Icon
                  onClick={() => this.toggleSubtitleModal(false)}
                  name="close"
                  className="closeicon"
                />
              }
            >
              <Modal.Header>
                {currentEvaluation.seriesSubtitle
                  ? 'Edit Subtitle'
                  : 'Add Subtitle to Evaluation Series'}
              </Modal.Header>
              <Modal.Content>
                <AvForm
                  ref={(c) => (this.form = c)}
                  onValidSubmit={(event, values) =>
                    this.updateSeriesSubtitle(event, values)
                  }
                  autoComplete="off"
                  model={form}
                  onKeyPress={this.onKeyPress}
                >
                  <AvField
                    label="Subtitle"
                    className="subtitle-inputs"
                    name="series-subtitle"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Subtitle',
                      },
                    }}
                    value={
                      currentEvaluation.seriesSubtitle
                        ? currentEvaluation.seriesSubtitle
                        : ''
                    }
                    onChange={(e) => {
                      this.setState({ seriesSubtitle: e.target.value });
                    }}
                    autoComplete="off"
                  />
                  <div className="d-flex mt-2">
                    <div className="">
                      {currentEvaluation.seriesSubtitle && (
                        <Button
                          className="modal-delete"
                          onClick={() => this.toggleSubtitleDeleteModal(true)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                    <div className="ml-auto">
                      <Button
                        className="modal-cancel mr-2"
                        onClick={() => this.toggleSubtitleModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        // onClick={this.updateSeriesSubtitle}
                        className="modal-proceed"
                      >
                        {currentEvaluation.seriesSubtitle ? 'Save' : 'Add'}
                      </Button>
                    </div>
                  </div>
                </AvForm>
              </Modal.Content>
            </Modal>
            <EvaluationPlanList
              editPlan={(form) => this.editPlan(form)}
              resetForm={() => this.resetForm()}
              toggleSubtitleModal={this.toggleSubtitleModal}
              changeEvaluationPlanView={(type) =>
                this.changeEvaluationPlanView(type)
              }
              {...this.props}
            />
          </div>
        )}

        <SubtitleDeleteModal
          subtitleDeleteModal={subtitleDeleteModal}
          handleDeleteSubtitle={this.handleDeleteSubtitle}
          toggleSubtitleDeleteModal={this.toggleSubtitleDeleteModal}
          subtitleDeleteLoading={subtitleDeleteLoading}
        />
        <Modal
          centered
          open={showCreatWarring}
          onClose={() => this.setShowCreatWarring(false)}
          size="tiny"
        >
          <Modal.Header>Warning!</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              To construct an Evaluation Plan, you must first select project
              objective/s and at least one Projected Participant Group from
              activity plan page.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="warning"
              onClick={() => this.setShowCreatWarring(false)}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

class UnSavedModal extends React.PureComponent {
  render() {
    const { toggle, leaveThePage, modalShow } = this.props;
    return (
      <Modal
        size="tiny"
        open={modalShow}
        closeIcon={
          <Icon onClick={() => toggle()} name="close" className="closeicon" />
        }
      >
        <Modal.Header>Are you sure you want to leave this page?</Modal.Header>
        <Modal.Content>
          You have unsaved changes. Do you want to leave ?
        </Modal.Content>
        <div className="buttons-stay-leave">
          <Button onClick={() => toggle()} className="stay">
            Stay
          </Button>
          <Button
            onClick={() => leaveThePage()}
            content="Leave"
            className="leave"
          />
        </div>
      </Modal>
    );
  }
}

export class SubtitleDeleteModal extends React.PureComponent {
  render() {
    const {
      subtitleDeleteModal,
      handleDeleteSubtitle,
      toggleSubtitleDeleteModal,
      deleteSeriesSubtitleLoading,
    } = this.props;
    return (
      <Modal
        open={subtitleDeleteModal}
        closeIcon={
          <Icon
            onClick={() => toggleSubtitleDeleteModal(false)}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        // trigger={triggerComp}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this subtitle?</p>

          <div className="participant-delete-button-container">
            <Button
              onClick={() => toggleSubtitleDeleteModal(false)}
              className="modal-cancel"
            >
              Cancel
            </Button>
            <Button
              className="modal-proceed"
              onClick={() => handleDeleteSubtitle()}
              content="Delete "
              type="submit"
              loading={deleteSeriesSubtitleLoading}
              negative
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    surveyQuestions: state.extra.surveyQuestions,
    methods: state.extra.methods,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMethodQuestions,
      clearMethodQuestions,
      getEvaluationGroups,
      postEvaluationPlan,
      deleteEvaluationPlan,
      updateEvaluationPlan,
      updateSeriesSubtitle,
      deleteSeriesSubtitle,
      getEvaluationPlans,
    },
    dispatch
  );
};

MainEvalPlan = connect(mapStateToProps, mapDispatchToProps)(MainEvalPlan);

export { MainEvalPlan };

import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { get, isEmpty } from 'lodash';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChartComp = ({
  options,
  labels,
  datasets,
  dataLabels,
  reportType,
  changeOutcomeInterface,
  props,
}) => {
  const outcomesAddressedHandleClick = (elements) => {
    // redirect to outcomes-reported chart in organisation report
    if (elements.length > 0 && reportType === 'outcomes-addressed') {
      const selectedOutcomes = elements[0];
      const selectedOutcome = dataLabels[selectedOutcomes.index];
      const reportType = 'outcomes-reported';

      if (get(props, 'activityId', null)) {
        props.setOutcomeValue(
          reportType,
          get(props, 'activityId', null),
          selectedOutcome
        );
        props.onTypeChange(reportType);
      } else {
        changeOutcomeInterface(reportType, selectedOutcome);
      }
    }
  };

  if (reportType === 'outcomes-addressed') {
    options = {
      ...options,
      onClick: (e, elements) => outcomesAddressedHandleClick(elements),
    };
  }

  return (
    <Bar
      options={{
        ...options,
        responsive: true,
        maintainAspectRatio: false,
      }}
      data={{
        labels: labels,
        datasets: datasets,
      }}
      className="bar-chart"
    />
  );
};

export const barOptions = (textColor, textBold, highlight, size = 14) => {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
        font: {
          size,
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        anchor: 'start',
        align: 'start',
        font: {
          size: 14,
          family: 'Inter',
          weight: '400',
        },
        padding: {
          top: 0,
          bottom: 0,
          left: 16,
          right: 16,
        },
      },
    },
    layout: {
      padding: {
        top: 8,
        bottom: 8,
        left: 32,
        right: 32,
      },
    },
    scales: {
      x: {
        position: 'top',
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
      },
      y: {
        ticks: {
          backdropColor: highlight,
          // showLabelBackdrop: true,
          padding: 30,
          crossAlign: 'far',
          color: textColor,
          font: {
            size,
            family: 'Inter',
            weight: textBold,
          },
        },
      },
    },
  };
};

export const stackedOptions = (textColor, textBold, highlight, size = 14) => {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
        font: {
          size,
        },
      },
      subtitle: {
        display: true,
        text: 'Custom Chart Subtitle',
      },
      datalabels: {
        anchor: 'start',
        align: 'start',
        font: {
          size: 14,
          family: 'Inter',
          weight: '400',
        },
        color: 'black',
        formatter: function (value, context) {
          const datasetArray = [];
          context.chart.data.datasets.forEach((dataset) => {
            if (dataset.data[context.dataIndex] !== null) {
              datasetArray.push(dataset.data[context.dataIndex]);
            }
          });

          // check array is empty [if array is empty mean no need to calculate sum and return]
          if (isEmpty(datasetArray)) {
            return '';
          }

          function totalSum(total, dataPoint) {
            return total + dataPoint;
          }

          let sum = datasetArray.reduce(totalSum, 0);

          // 0th index means chart left side label
          if (context.datasetIndex === 0) {
            return sum;
          } else {
            return '';
          }
        },
        padding: {
          top: 0,
          bottom: 0,
          left: 16,
          right: 16,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        position: 'top',
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Inter',
            weight: '400',
          },
        },
      },
      y: {
        ticks: {
          backdropColor: highlight,
          showLabelBackdrop: false,

          padding: 30,
          crossAlign: 'far',
          color: textColor,
          font: {
            size,
            family: 'Inter',
            weight: textBold,
          },
        },
        stacked: true,
      },
    },

    layout: {
      padding: {
        top: 8,
        bottom: 8,
        left: 32,
        right: 32,
      },
    },
  };
};

import { isEmpty, get, map } from '../../utils/lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Grid, Accordion, Table } from 'semantic-ui-react';

import { CustomSegment, EmptyContainer } from '../../components';
import { getOrganisationAdmins } from '../../store/actions';

import './auth.scss';

class Help extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { user, getOrganisationAdmins } = this.props;
    if (get(user, 'organisationId')) {
      getOrganisationAdmins();
    }
  }

  panels = () => {
    return [
      {
        key: 1,
        title: {
          content: <span>Q1. What is Takso?</span>,
        },
        content: {
          content: (
            <div>
              <p>
                <strong>Answer:</strong>
              </p>
              <p>
                Takso Outcomes Planning Platform® is a cloud-based service
                provided by Cultural Development Network to enable producers,
                presenters, planners and artists to plan, build, record and
                evaluate their activities.
                <a
                  href={'https://culturaldevelopment.net.au/whitebox/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  More information
                </a>
              </p>
            </div>
          ),
        },
      },
      {
        key: 2,
        title: {
          content: (
            <span>Q2. Where can I find out more about how Takso works?</span>
          ),
        },
        content: {
          content: (
            <div>
              <p>
                <strong>Answer:</strong>
              </p>
              <p>There are four sources of assistance.</p>
              <ol>
                <li>
                  When you first log in to WB each page is introduced with
                  onboarding tips. These tips can be set on or off through your
                  Profile Settings under you name.
                </li>
                <li>
                  Page Help sliders can be activated by selecting the orange ‘i’
                  information icon on the top title area of the page.
                </li>
                <li>
                  Hover-over tips are available. When you hold my mouse pointer
                  over a field in the body of a page, you will see a short
                  help-text pop-up.
                </li>
                <li>
                  You can contact your Takso Administrator/s through the contact
                  details above.
                </li>
              </ol>
            </div>
          ),
        },
      },
      {
        key: 3,
        title: {
          content: <span>Q3. Is my work private?</span>,
        },
        content: {
          content: (
            <div>
              <p>
                <strong>Answer:</strong>
              </p>
              <p>
                Only you and those you invite to collaborate on the activity can
                see your work and this can be limited or expanded by the
                permissions you give them. When you complete an activity and it
                is archived, the public <strong>‘Report’</strong> is available
                to other Takso users on the system.
              </p>
            </div>
          ),
        },
      },
      {
        key: 4,
        title: {
          content: (
            <span>Q4. Why does Takso share my final report with others?</span>
          ),
        },
        content: {
          content: (
            <div>
              <p>
                <strong>Answer:</strong>
              </p>
              <p>
                Takso captures the entire narrative of an activity including
                your own reflection on what it achieved. Sharing this summary
                information enables others to learn from our experience, and
                over time builds our collective knowledge across the sector.
              </p>
            </div>
          ),
        },
      },
      {
        key: 5,
        title: {
          content: <span>Q5. Is there a ‘Save’ button to save my work</span>,
        },
        content: {
          content: (
            <div>
              <p>
                <strong>Answer:</strong>
              </p>
              <p>
                The entries you make in Takso are saved automatically as you
                progress.
              </p>
            </div>
          ),
        },
      },
    ];
  };

  renderAdminList() {
    const { adminList } = this.props;

    const withoutCdnAdmins = adminList.filter((user) => {
      const userEmail = get(user, 'email', '');
      const isCdnAdmin = userEmail.includes('cdn-admin');
      return !isCdnAdmin;
    });

    return (
      <div>
        {isEmpty(withoutCdnAdmins) ? (
          <EmptyContainer msg="No Takso administrators found…" />
        ) : (
          <Table className="info-table" stackable structured={false}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {map(withoutCdnAdmins, (item, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {get(item, 'name', '-')}
                      {item.positionTitle ? `, ${item.positionTitle}` : null}
                    </Table.Cell>
                    <Table.Cell>
                      <a href={`mailto:${item.email}`}>{item.email}</a>
                    </Table.Cell>
                    <Table.Cell>{item.phone ? item.phone : '-'}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {user.role !== 'guest' && (
            <CustomSegment title="Takso Administrators">
              <Segment className="content-segment">
                {this.renderAdminList()}
              </Segment>
            </CustomSegment>
          )}

          <CustomSegment
            title="Frequently asked questions"
            extraMargin
            className="frequently-asked-questions"
          >
            <Segment className="content-segment">
              <Grid.Row>
                <Grid.Column>
                  <Accordion fluid exclusive={true} panels={this.panels()} />
                </Grid.Column>
              </Grid.Row>
            </Segment>
          </CustomSegment>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    adminList: state.organization.orgAdmins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrganisationAdmins }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);

import LinkedActivityForm from '../forms/linkedActivityForm';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Dropdown, Modal } from 'semantic-ui-react';

export default function CreateLinkedActivity(props) {
  const {
    currentState,
    reSetFilters,
    textLabel,
    modalLabel,
    activityPlanTypeId,
    parentActivityPlanTypeId,
  } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal
      trigger={
        <Dropdown.Item
          onClick={() => {
            toggle();
          }}
        >
          {textLabel}
        </Dropdown.Item>
      }
      size="small"
      open={modal}
      className="evaluation-model"
    >
      <Modal.Header className="goal-confirm-modal-header">
        <div className="modal-heading">Create New {modalLabel}</div>
        <div className="slim-close-icon" onClick={() => toggle()}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Content>
        <LinkedActivityForm
          reSetFilters={reSetFilters}
          toggle={toggle}
          currentState={currentState}
          activityPlanTypeId={activityPlanTypeId}
          parentActivityPlanTypeId={parentActivityPlanTypeId}
        />
      </Modal.Content>
    </Modal>
  );
}

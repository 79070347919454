import React from 'react';

import { Grid, Dropdown, Icon, Popup, Segment } from 'semantic-ui-react';
import './styles.scss';
import { get, isEmpty, map, size, compact } from '../utils/lodash';
import {
  getGoalStatus,
  getCriticalStatus,
  getScaleStatus,
  getActivityStatus,
} from '../utils';
import { CustomSegment, EmptyContainer } from '../components';
import { useSelector } from 'react-redux';
import { CustomTable } from '../components/customTable';
import { ViewMore } from '../containers/reports/reportHome';
import { isParentProgramActivity } from '../utils/activity';
import { ORGANISATION_REPORT_LOADING } from '../utils/constant';
const status_options = [
  {
    key: 'Ascending',
    text: <b>Ascending</b>,
    value: 'asc',
  },
  {
    key: 'Descending',
    text: <b>Descending</b>,
    value: 'desc',
  },
];

const heading_options = [
  {
    key: 'Goal',
    text: <b>Goal</b>,
    value: 'goal',
  },
  {
    key: 'ActivityName',
    text: <b>Activity Name</b>,
    value: 'activityName',
  },
  {
    key: 'Status',
    text: <b>Status</b>,
    value: 'status',
  },
];

export const ManagementSummary = ({
  order,
  sort = 'goal',
  onChangeOrder,
  onChangeSort,
  paginationAPI,
  activityType,
}) => {
  const managementSummaryReport = useSelector(
    (state) => state.report.managementSummaryReport
  );

  const reportInitialState = useSelector((state) => state.report);
  const reportFilterOptionsPage = get(
    reportInitialState,
    'reportFilterOptions.page',
    1
  );

  const loading = get(
    reportInitialState,
    `managementSummaryLoading.${ORGANISATION_REPORT_LOADING.MANAGEMENT_SUMMARY_LOADING}`,
    false
  );

  const handlePagination = (page) => {
    paginationAPI(page);
  };

  const getScale = (data) => {
    const getScale = get(data, 'scale', []);
    if (isEmpty(getScale)) return '-';
    let [first, second] = getScale;
    let scale = '';
    map(first, (item, i) => {
      if (i !== 'outcomeId') scale += `${getScaleStatus(i)} ${item} `;
    });
    let scaleTwo = '';
    map(second, (item, i) => {
      if (i !== 'outcomeId') scaleTwo += `${getScaleStatus(i)} ${item} `;
    });
    return (
      <div className="mt-1">
        {!isEmpty(scale) ? <div style={{ width: '125px' }}>{scale}</div> : '-'}
        {!isEmpty(scaleTwo) && <div style={{ width: '125px' }}>{scaleTwo}</div>}
      </div>
    );
  };

  const getColumns = () => {
    let columns = [
      {
        width: 4,
        title: (
          <div className="organisation-summary-activity-name">
            Activity Name
          </div>
        ),
        render: (data) => {
          return (
            <div className="report-goal">
              <div
                className={`circle circle-right activity-${getGoalStatus(
                  data.status.type
                )}`}
              />
              <div className={'goal-description'}>
                <label className={'goal-info'}>
                  {data.activityName ? data.activityName : null}
                </label>
                {data.dates !== '-' ? (
                  <label className={'reportDate'}>{data.dates}</label>
                ) : null}
              </div>
            </div>
          );
        },
      },
      {
        width: 3,
        title: <div>Type</div>,
        render: (data) => {
          return data.activityType;
        },
      },
      {
        width: 3,
        title: <div className="organisation-summary-objective">Objectives</div>,
        render: (data) => {
          const outcomes = map(data.outcomes, (item, index) => {
            return (
              <div className="objective-table-div" key={index}>
                <ul>
                  <li>{item}</li>
                </ul>
                <hr className="intense-outcome-hr" />
              </div>
            );
          });

          return size(outcomes) > 2 ? (
            //  TODO: Add the reusable see more component after re-skin merge into develop.
            <div>
              <ViewMore
                minCount={2}
                dataSet={outcomes}
                // CustomComponent={<div className="evaluation-group-bg">{outcomes}</div>}
              />
            </div>
          ) : (
            <div>{outcomes.length > 0 ? outcomes : '-'}</div>
          );
        },
      },
      {
        width: 3,
        title: 'Program',

        render: (data) => {
          return (
            <div className="report-goal">
              {get(data, 'parentProjectData.status', '') !== '' && (
                <div
                  className={`circle circle-right activity-${getActivityStatus(
                    data?.parentProjectData?.statusId
                  )}`}
                />
              )}

              <div>
                <label className={'goal-info'}>
                  {get(data, 'parentProjectData.name', '-')}
                </label>
              </div>
            </div>
          );
        },
        textAlign: 'left',
      },
      {
        width: 2,
        title: 'Contact',
        render: (data) => {
          return (
            <div className="report-goal">
              <div className={'goal-description'}>
                <label className={'goal-info'}>
                  {data.owner.name ? data.owner.name : null}
                </label>
              </div>
            </div>
          );
        },
      },
      {
        width: 1,
        title: <div className="organisation-summary-number-status">Status</div>,
        render: (data) => {
          return (
            <div>
              {data.status.type === 'COMPLETED' &&
              !isParentProgramActivity(data.activityPlantTypeId) ? (
                <div className="scale">{getScale(data)}</div>
              ) : (
                <div className="percentage">
                  <div>{`${get(data, 'status.percentage', 0)}%`}</div>
                  {data.status.note && (
                    <div className="per-right">
                      <Popup
                        content={
                          data.status.note.label ? data.status.note.label : null
                        }
                        trigger={
                          <Icon
                            name="warning circle"
                            color={getCriticalStatus(
                              data.status.note.criticality
                            )}
                            className="icon-org-summary"
                          ></Icon>
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
        textAlign: 'left',
      },
    ];

    return compact(columns);
  };

  return (
    <div>
      <>
        <CustomSegment title="Summary management Overview">
          <div className="activity-count-info">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Grid
                    className="summary-management-grid"
                    padded
                    loading={loading}
                  >
                    <div className="org-down">
                      <Grid.Row>
                        <label>Strategic Plan Document</label>
                      </Grid.Row>
                      <Grid.Row>
                        <h6>
                          {' '}
                          <div className="content">
                            {!isEmpty(managementSummaryReport.referenceDocument)
                              ? managementSummaryReport.referenceDocument
                              : '-'}
                          </div>
                        </h6>
                      </Grid.Row>
                    </div>
                    {/* {5 === 1 ? (
                      <Loader active size="medium" indeterminate />
                    ) : ( */}
                    <div className="left-align-sm">
                      <div className="activity-name">
                        <div
                          className={
                            managementSummaryReport.totalDevelopmentCount ==
                            null
                              ? 'circle circle-bottom-null activity-warning'
                              : 'circle circle-bottom activity-warning'
                          }
                        />
                        <div className="col-wise">
                          <div className="management-summary-act-status">
                            <label>In-development</label>
                          </div>
                          <div className="number-centre">
                            {get(managementSummaryReport, 'development', 0)}
                          </div>
                        </div>
                      </div>
                      <div className="activity-name">
                        <div
                          className={
                            managementSummaryReport.totalActiveCount == null
                              ? 'circle circle-bottom-null activity-success'
                              : 'circle circle-bottom activity-success'
                          }
                        />
                        <div className="col-wise">
                          <div className="management-summary-act-status">
                            <label>Active</label>
                          </div>
                          <div className="number-centre">
                            {get(managementSummaryReport, 'active', 0)}
                          </div>
                        </div>
                      </div>

                      <div className="activity-name">
                        <div
                          className={
                            managementSummaryReport.totalCompletedCount == null
                              ? 'circle circle-bottom-null activity-completed'
                              : 'circle circle-bottom activity-completed'
                          }
                        />
                        <div className="col-wise">
                          <div className="management-summary-act-status">
                            <label>Completed</label>
                          </div>
                          <div className="number-centre">
                            {get(managementSummaryReport, 'completed', 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    {/* )} */}
                    <Grid.Row>
                      <div className="org-down-act-type">
                        <Grid.Row>
                          <label>Activity Types</label>
                        </Grid.Row>
                        <Grid.Row>
                          <h6>
                            {' '}
                            <div className="content">
                              {!isEmpty(managementSummaryReport.activityTypes)
                                ? managementSummaryReport.activityTypes.join(
                                    ', '
                                  )
                                : '-'}
                            </div>
                          </h6>
                        </Grid.Row>
                      </div>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </CustomSegment>
      </>
      <CustomSegment className="management-summary">
        <Segment loading={loading}>
          <div className="header-container">
            <div className="sub-header-container">
              <Grid divided="vertically">
                <Grid.Row className="subheader-margin">
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="m-0 p-0"
                  >
                    <span className="sort-order-style">
                      Sort By: &nbsp;
                      <Dropdown
                        direction="left"
                        inline
                        selectOnBlur={false}
                        defaultValue={sort}
                        options={heading_options}
                        onChange={(e, { value }) => onChangeSort(value)}
                        className="mr-16"
                      />
                      Order by: &nbsp;
                      <Dropdown
                        direction="left"
                        inline
                        defaultValue={order}
                        selectOnBlur={false}
                        options={status_options}
                        onChange={(e, { value }) => onChangeOrder(value)}
                      />
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>

          {!isEmpty(get(managementSummaryReport, 'projects', [])) ? (
            <div className="organisation-table-horizontal">
              <CustomTable
                header
                columns={getColumns()}
                data={managementSummaryReport.projects}
                customHeaderClass="org-report-table-header"
                pagination
                customClass="organisation-summary-table"
                handlePaginationChange={(page) => handlePagination(page)}
                page={reportFilterOptionsPage}
                noOfPages={managementSummaryReport.pages}
                keyExtractor={(item, index) => item.id}
              />
            </div>
          ) : (
            <EmptyContainer msg="No Data Available" />
          )}
        </Segment>
      </CustomSegment>
    </div>
  );
};

import React, { Component } from 'react';
import { isEmpty, map, get } from '../../../../utils/lodash';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import {
  Button,
  Grid,
  Icon,
  Label,
  Modal,
  Radio,
  Segment,
} from 'semantic-ui-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import {
  getAllQuestions,
  getOutcomes,
  getDomains,
  updateQuestions,
} from '../../../../store/actions';
import { CustomTable } from '../../../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isBrowser, isTablet } from 'react-device-detect';
import moment from 'moment';

class SelectOutcome extends React.PureComponent {
  componentDidMount() {
    const { getOutcomes, getDomains } = this.props;
    getOutcomes();
    getDomains();
  }

  renderOutcomeChild(id) {
    const { outcomes } = this.props;
    return map(outcomes, (item, i) => {
      if (id !== item.policyId) return null;
      if (item.isEnable === 0) return null;
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  }

  renderOutcome(domains) {
    return map(domains, (domain, i) => {
      return (
        <optgroup key={i} label={domain.policyName} value={domain.id}>
          {this.renderOutcomeChild(domain.id)}
        </optgroup>
      );
    });
  }
  render() {
    const { domains, setOutcome } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <AvField
              className="org-inputs"
              type="select"
              name="select"
              label="Select an Outcome"
              onChange={(e) => setOutcome(e)}
            >
              <option value="" disabled defaultValue>
                Select an Outcome
              </option>
              {this.renderOutcome(domains)}
            </AvField>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
class EvaluationQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outputId: null,
    };
  }

  setOutcome({ target }) {
    this.setState({
      outputId: target.value,
    });
  }

  render() {
    return (
      <div className="eval-qs-admin">
        <div>
          <div>
            <AvForm
              ref={(c) => (this.form = c)}
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              className="venue-form"
            >
              <SelectOutcome
                setOutcome={(e) => this.setOutcome(e)}
                {...this.props}
              />
            </AvForm>
          </div>
        </div>
        <EvaluationQuestionList
          {...this.props}
          outputId={this.state.outputId}
        />
      </div>
    );
  }
}

class EvaluationQuestionList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEnable: false,
      columns: [
        {
          title: 'Evaluation Question',
          render: (data) => {
            return (
              <div className="blocked-badge-container">
                <div className={!data.isEnable && 'disabled'}>
                  {data.method.details}
                </div>
                {!data.isEnable && (
                  <Label className="blocked-badge" color="red" size="tiny">
                    Disabled
                  </Label>
                )}
              </div>
            );
          },
          width: 8,
        },
        {
          title: <div>Created Date</div>,
          render: (data) => {
            return (
              <div className={!data.isEnable && 'disabled'}>
                {moment(data.createdAt).format('ll hh:mm A')}
              </div>
            );
          },
          width: 3,
        },
        {
          title: <div>Updated Date</div>,
          render: (data) => {
            return (
              <div className={!data.isEnable && 'disabled'}>
                {moment(data.updatedAt).format('ll hh:mm A')}
              </div>
            );
          },
          width: 3,
        },
        {
          title: <div></div>,
          render: (data) => {
            return (
              <EditQuestionModal
                item={data}
                {...this.props}
                currentPage={this.state.currentPage}
              />
            );
          },
          width: 1,
          textAlign: isBrowser || isTablet ? 'center' : null,
        },
      ],
      currentPage: 1,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { outputId, getAllQuestions } = this.props;
    const { currentPage } = this.state;
    try {
      if (prevProps.outputId !== outputId) {
        await getAllQuestions(currentPage, outputId);
      }
    } catch (error) {}
  }

  async componentDidMount() {
    try {
      const { currentPage } = this.state;
      const { getAllQuestions } = this.props;
      await getAllQuestions(currentPage);
    } catch (error) {}
  }

  pageChange(page) {
    const { getAllQuestions } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getAllQuestions(page);
      }
    );
  }

  render() {
    const { questions, listLoading } = this.props;

    const { columns, currentPage } = this.state;

    return (
      <Segment
        className="super-admin-content-segment"
        loading={listLoading}
        disabled={listLoading}
      >
        {questions.docs && questions.docs.length ? (
          <CustomTable
            header
            columns={columns}
            data={questions.docs}
            pagination
            customClass="super-admin-table tbody-hover"
            handlePaginationChange={(page) => this.pageChange(page)}
            page={currentPage}
            noOfPages={questions.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <div className="no-ques">Please Select an Outcome</div>
        )}
      </Segment>
    );
  }
}
class EditQuestionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  editSelected(item) {
    this.setState({
      form: item,
    });
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelected(item)}
          >
            Edit
          </label>
        }
        className="edit-interview"
      >
        <Modal.Header>Edit {get(item.method, 'details', '')}</Modal.Header>
        <Modal.Content>
          <EditQuestionForm
            form={item}
            toggle={() => this.toggle()}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class EditQuestionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preamble: '',
      question1: '',
      question2: '',
      cumulative: false,
      loading: false,
      preambleError: false,
      question2Error: false,
      question1Error: false,
      isEnable: false,
    };
  }

  componentWillMount() {
    const { form } = this.props;
    this.setState({
      preamble: form.preamble,
      question1: form.question1,
      question2: form.question2,
      cumulative: form.cumulative,
      isEnable: form.isEnable,
    });
  }

  enableToggle() {
    this.setState((prevState) => ({
      isEnable: !prevState.isEnable,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { updateQuestions, form, toggle, currentPage } = this.props;
      const { preamble, question1, question2, isEnable } = this.state;

      if (values.cumulative && isEmpty(question2)) {
        this.setState({
          question2Error: true,
        });
      } else if (isEmpty(question1)) {
        this.setState({
          question1Error: true,
        });
      } else if (isEmpty(preamble)) {
        this.setState({
          preambleError: true,
        });
      } else {
        const data = {
          preamble,
          question1,
          question2,
          isEnable,
          ...values,
        };
        await updateQuestions(form.outcomeId, form.methodId, data, currentPage);
        toggle();
      }
    } catch (error) {
      error.log('Update Question submit~', error);
    }
  }

  onChangeFilter(check) {
    if (check) {
      this.setState({
        cumulative: true,
        question2: '',
      });
    } else {
      this.setState({
        cumulative: false,
        question2: '',
      });
    }
  }
  onChangeQuestion1(event, editor) {
    const question1 = editor.getData();
    if (!isEmpty(question1)) {
      this.setState({
        question1Error: false,
      });
    }
  }

  onChange(event, editor, type) {
    let question1Error, question2Error, preambleError;
    const value = editor.getData();
    if (isEmpty(value)) return null;
    if (type === 'preamble') {
      preambleError = false;
    }
    if (type === 'question1') {
      question1Error = false;
    }
    if (type === 'question2') {
      question2Error = false;
    }

    this.setState({
      preambleError,
      question2Error,
      question1Error,
    });
  }

  render() {
    const {
      preamble,
      question1,
      question2,
      cumulative,
      preambleError,
      question2Error,
      question1Error,
      isEnable,
    } = this.state;

    const { toggle, questionLoading, form } = this.props;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <label>Introduction of Outcome Measure</label>
        <CKEditor
          editor={ClassicEditor}
          data={preamble}
          onBlur={(event, editor) => {
            const preamble = editor.getData();
            this.setState({
              preamble,
            });
          }}
          onChange={(event, editor, type) =>
            this.onChange(event, editor, 'preamble')
          }
        />
        {preambleError ? (
          <div className="custom-error-label">Preamble is required</div>
        ) : null}

        <div className="gap-between-ck">
          <label>Question 1</label>
          <CKEditor
            editor={ClassicEditor}
            data={question1}
            onBlur={(event, editor) => {
              const question1 = editor.getData();
              this.setState({
                question1,
              });
            }}
            onChange={(event, editor, type) =>
              this.onChange(event, editor, 'question1')
            }
          />
        </div>
        {question1Error ? (
          <div className="custom-error-label">Question 1 is required</div>
        ) : null}

        {cumulative ? (
          <div>
            <div className="gap-between-ck">
              <label>Question 2</label>
              <CKEditor
                className={question2Error ? 'custom-error-input .prompt ' : ''}
                editor={ClassicEditor}
                data={question2}
                onBlur={(event, editor) => {
                  const question2 = editor.getData();
                  this.setState({
                    question2,
                  });
                }}
                onChange={(event, editor, type) =>
                  this.onChange(event, editor, 'question2')
                }
              />
            </div>
            {question2Error ? (
              <div className="custom-error-label">Question 2 is required</div>
            ) : null}
          </div>
        ) : null}
        <div className="gap-between-ck">
          <AvGroup className="grantee-checkbox-outcome">
            <AvInput
              type="checkbox"
              name="cumulative"
              value={cumulative === 1 ? true : false}
              onChange={(e) => this.onChangeFilter(e.target.checked)}
            />{' '}
            This outcome has a post-pre test question
          </AvGroup>
        </div>

        <div className="edit-outcome-status-bar enable-question">
          <div className="two-step-radio-container-admin status-right">
            <Radio
              toggle
              className="modal-toggle-qs-participant"
              checked={isEnable}
              onChange={() => this.enableToggle()}
            />
          </div>
          <label>Enable</label>
        </div>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={questionLoading}
            disabled={questionLoading}
          />
        </div>
      </AvForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outcomes: state.extra.outcomes,
    questions: state.admin.questions,
    domains: state.admin.domains,
    questionLoading: state.admin.questionLoading,
    listLoading: state.admin.listLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllQuestions,
      getOutcomes,
      getDomains,
      updateQuestions,
    },
    dispatch
  );
};

EvaluationQuestions = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationQuestions);
export default EvaluationQuestions;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, PureComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AvForm, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Grid, Segment, Tab, Loader, Button } from 'semantic-ui-react';
import { EmptyContainer } from '../../../../components';
import {
  evalPlanManage,
  evalManage,
  updateEvaluationPlan,
  getProjectOutcomeSummary,
  updateOutcomeChart,
} from '../../../../store/actions';
import { isEmpty, map, get, find, size } from '../../../../utils/lodash';
import { getChartType, getPageDisable } from '../../../../utils';
import {
  SINGLE_POST_TYPES, // OK
  SEPARATE_POST_TYPE, // MULTIPLE_POST_TYPES,
  MULTIPLE_POST_TYPES,
  SINGLE_PREPOST_TYPES,
  SINGLE_MULTIPLE_POST_TYPES,
  MULTIPLE_INDIVIDUAL_TYPES, // MULTIPLE_PREPOST_TYPES,
  MULTIPLE_PREPOST_TYPES,
  INTERCEPT_SINGLE_POST_TYPES, // OK
  INTERCEPT_SEPARATE_POST_TYPES, // INTERCEPT_MULTIPLE_POST_TYPES,
  INTERCEPT_MULTIPLE_POST_TYPES,
} from '../../../../utils/constant';
import '../activity.scss';
import { concat } from 'lodash';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import {
  AggregateChart,
  SeparateChart,
  TrendRateOfChangeChart,
  IndividualRateOfChangeChart,
} from '../../../../components/chartsWidgets';

export const SummaryChart = (props) => {
  const getChart = () => {
    let {
      chartType,
      chartMethod,
      method,
      outcomeId,
      participants,
      participantsChange,
      participantsChangePrePost,
    } = props;

    let _participants =
      participants && participants.length > 0
        ? participants.find((item) => Number(item.id) === Number(outcomeId))
        : null;

    let _participantsChange = !isEmpty(participantsChange)
      ? participantsChange
      : null;

    let _participantsChangePrePost =
      participantsChangePrePost && participantsChangePrePost.length > 0
        ? participantsChangePrePost.find(
            (item) => Number(item.id) === Number(outcomeId)
          )
        : null;

    if (method === 'all') {
      _participants =
        participants && participants.length > 0
          ? participants.find((item) => Number(item.id) === Number(outcomeId))
          : null;

      _participantsChange = !isEmpty(participantsChange)
        ? participantsChange
        : null;

      _participantsChangePrePost =
        participantsChangePrePost && participantsChangePrePost.length > 0
          ? participantsChangePrePost.find(
              (item) => Number(item.id) === Number(outcomeId)
            )
          : null;
    } else {
      _participants =
        _participants && _participants.byMethod
          ? _participants.byMethod.find((item) => item.methodId === method)
          : null;
    }

    if (Number(method) === 12) {
      chartType = 'postChart';
    }

    switch (chartType) {
      case 'postChart':
        const aggregate = [
          'SINGLE',
          'INTERCEPT_SINGLE',
          'AGGREGATE',
          'INTERCEPT_AGGREGATE',
        ];
        const seperate = ['SEPERATE', 'INTERCEPT_SEPERATE'];
        // const selfAssessedPost = ['SELF-ASSESSED-POST'];

        // if (selfAssessedPost.includes(chartMethod)) {
        //   return (
        //     <div className="pre-post-chart-div">
        //       <SelfAssessedPostTrendChart
        //         {...props}
        //         chartTypeLabel={chartTypeLabel}
        //         documentationSelfAssessed={true}
        //       />
        //     </div>
        //   );
        // }

        if (aggregate.includes(chartMethod)) {
          return (
            <div className="pre-post-chart-div">
              <AggregateChart
                {...props}
                method={method}
                participants={_participants}
              />
            </div>
          );
        }
        if (seperate.includes(chartMethod)) {
          return (
            <div className="pre-post-chart-div">
              <SeparateChart
                {...props}
                method={method}
                participants={_participants}
              />
            </div>
          );
        }
        return null;
      case 'prePostChart':
        const trend = ['SINGLE_TREND', 'TREND'];
        const individual = ['SINGLE_INDIVIDUAL', 'INDIVIDUAL'];
        // const selfAssessedPre = ['SELF-ASSESSED-PRE'];

        if (trend.includes(chartMethod)) {
          return (
            <div className="pre-post-chart-div">
              <TrendRateOfChangeChart
                {...props}
                method={method}
                participantsChangePrePost={_participantsChangePrePost}
              />
            </div>
          );
        }
        if (individual.includes(chartMethod)) {
          return (
            <div className="pre-post-chart-div">
              <IndividualRateOfChangeChart
                {...props}
                method={method}
                participantsChange={_participantsChange}
              />
            </div>
          );
        }
        // if (selfAssessedPre.includes(chartMethod)) {
        //   return (
        //     <div className="pre-post-chart-div">
        //       <SelfAssessedPostPreTrend
        //         {...props}
        //         documentationSelfAssessed={true}
        //       />
        //     </div>
        //   );
        // }
        return null;
      default:
        return (
          <div className="pre-post-chart-div">
            <EmptyContainer msg="No data in selected outcome." />
          </div>
        );
    }
  };

  return <div className="outcomes-summary-chart-div">{getChart()}</div>;
};

const OutcomeSummaryCharts = (props) => {
  const {
    evalPlanSize,
    title,
    updateOutcomeChart,
    currentActivity,
    outcomeId,
    isSeries,
    user,
  } = props;
  const [viewIntercept, setViewIntercept] = useState(false);
  const [inReport, setInReport] = useState(false);
  const [viewAllNoData, setviewAllNoDataNoData] = useState(false);
  // const [viewSelfAsseseedView, setSelfAsseseedView] = useState(false);

  const [selfAssessedInReport, setSelfAssessedInReport] = useState(false);

  const [selfAssessedType, setselfAssessedType] = useState('');

  const [isIntercept, setInterceptToggle] = useState(false);
  const [chartType, setChartType] = useState(undefined);
  const [radioSet, setRadioSet] = useState(SINGLE_POST_TYPES);
  const [radio, setRadio] = useState(null);
  const [methodType, setMethodType] = useState('all');

  const isDisabled = getPageDisable(
    currentActivity,
    user,
    'project.edit.evaluationPlan'
  );

  useEffect(() => {
    const type = getChartType(props);
    setChartType(type);
    checkIfIntercept();
  }, []);

  useEffect(() => {
    if (chartType === 'postChart') {
      setRadioData(
        evalPlanSize
          ? isIntercept
            ? INTERCEPT_SINGLE_POST_TYPES
            : SINGLE_POST_TYPES
          : isIntercept
            ? isSeries
              ? INTERCEPT_SEPARATE_POST_TYPES
              : INTERCEPT_MULTIPLE_POST_TYPES
            : isSeries
              ? SEPARATE_POST_TYPE
              : MULTIPLE_POST_TYPES
      );
      setselfAssessedType('SELF-ASSESSED-POST');
    }
    if (chartType === 'prePostChart') {
      setRadioData(
        methodType === 'selfAssessed'
          ? SINGLE_MULTIPLE_POST_TYPES
          : evalPlanSize
            ? SINGLE_PREPOST_TYPES
            : isSeries
              ? MULTIPLE_INDIVIDUAL_TYPES
              : MULTIPLE_PREPOST_TYPES
      );
      setselfAssessedType('SELF-ASSESSED-PRE');
    }
  }, [chartType, methodType]);

  useEffect(() => {
    setRadioData(
      evalPlanSize
        ? isIntercept
          ? INTERCEPT_SINGLE_POST_TYPES
          : SINGLE_POST_TYPES
        : isIntercept
          ? isSeries
            ? INTERCEPT_SEPARATE_POST_TYPES
            : INTERCEPT_MULTIPLE_POST_TYPES
          : isSeries
            ? SEPARATE_POST_TYPE
            : MULTIPLE_POST_TYPES
    );
    if (isIntercept) {
      setChartType('postChart');
    } else {
      const type = getChartType(props);
      setChartType(type);
    }
  }, [isIntercept]);

  useEffect(() => {
    checkInReport();
  }, [radio]);

  useEffect(() => {
    methodAllData();
    // checkIfSelfAssessed();
  });

  const methodAllData = () => {
    if (chartType !== undefined) {
      switch (chartType) {
        case 'postChart':
          let ac = props.participants[0];

          if (methodType !== 'all') {
            ac = get(ac, 'byMethod', '');
            ac = ac[0];
          }

          if (!isEmpty(ac)) {
            if (ac.activityCount === 0) {
              setviewAllNoDataNoData(true);
            } else {
              setviewAllNoDataNoData(false);
            }
          }
          break;
        case 'prePostChart':
          let pcp = props.participantsChangePrePost[0];

          if (methodType !== 'all') {
            pcp = get(pcp, 'byMethod', '');
            pcp = pcp[0];
          }

          if (!isEmpty(pcp)) {
            if (pcp.activityCount === 0) {
              setviewAllNoDataNoData(true);
            } else {
              setviewAllNoDataNoData(false);
            }
          }
          break;

        default:
          break;
      }
    }
  };

  const checkIfIntercept = () => {
    const participants = find(
      get(props, 'participants', []),
      (d) => d.name === title
    );
    if (!isEmpty(participants)) {
      const getIntercept = find(
        participants.byMethod,
        (m) => m.method === 'Intercept'
      );
      if (!isEmpty(getIntercept)) {
        setViewIntercept(true);
      }
    }
  };

  const checkInReport = () => {
    const outcomeCharts = get(currentActivity, 'outcomeCharts', []);

    if (radio) {
      const exist = find(
        outcomeCharts,
        (c) => c.type === radio && c.outcomeId === outcomeId
      );

      const getSelfAssessed = find(
        outcomeCharts,
        (c) => c.type === selfAssessedType && c.outcomeId === outcomeId
      );

      if (getSelfAssessed) {
        setSelfAssessedInReport(true);
      } else {
        setSelfAssessedInReport(false);
      }

      if (exist) {
        setInReport(true);
      } else {
        setInReport(false);
      }
    }
  };

  const setRadioData = (type) => {
    const [one] = type;
    setRadioSet(type);
    setRadio(one.value);
    checkInReport();
  };

  const setTypeChange = (type) => {
    setMethodType(type);

    if (type === 'interceptOnly') {
      setInterceptToggle(true);
    } else {
      setInterceptToggle(false);
    }
  };

  const toggleInReport = (status, methodType) => {
    updateOutcomeChart(currentActivity.id, {
      status,
      outcome: outcomeId,
      type: methodType === 'selfAssessed' ? selfAssessedType : radio,
      imageData: '',
    });
    if (methodType === 'selfAssessed') {
      setSelfAssessedInReport(status);
    } else {
      setInReport(status);
    }
  };

  return (
    <Grid className="actions-charts-container">
      <Grid.Row>
        <AvForm>
          <div className="outcome-summary-selector">
            <AvField
              type="select"
              name="methodType"
              className="method-selector"
              required
              onChange={(e) => setTypeChange(e.target.value)}
              value={methodType}
            >
              <option value="all" defaultValue>
                All Methods
              </option>
              {/* {viewSelfAsseseedView ? (
                <option value="selfAssessed">Self Assessed-Only</option>
              ) : null} */}

              {viewIntercept ? (
                <option value="interceptOnly">Intercept Only</option>
              ) : null}
            </AvField>

            {methodType !== 'selfAssessed' ? (
              <AvRadioGroup
                inline
                name="chartMethod"
                required
                errorMessage="Data collection method is required"
                className="outcome-eval-plan-radio"
                value={radio}
              >
                {map(radioSet, (data, i) => (
                  <AvRadio
                    key={i}
                    label={data.label}
                    value={data.value}
                    onChange={(e) => setRadio(data.value)}
                  />
                ))}
              </AvRadioGroup>
            ) : (
              <AvRadioGroup
                inline
                name="chartMethod"
                required
                errorMessage="Data collection method is required"
                className="outcome-eval-plan-radio"
                value={radio}
              >
                {map(radioSet, (data, i) => (
                  <AvRadio
                    key={i}
                    label={data.label}
                    value={data.value}
                    onChange={(e) => setRadio(data.value)}
                  />
                ))}
              </AvRadioGroup>
            )}
          </div>
          {/* <div className="radiobutton-container-intercept-only">
            <div className="outcome-summary-radiobutton-flex-item">
              <AvForm>
                <AvRadioGroup
                  inline
                  name="chartMethod"
                  required
                  errorMessage="Data collection method is required"
                  className="documentation-eval-plan-radio"
                  value={radio}
                >
                  {map(radioSet, (data, i) => (
                    <AvRadio
                      key={i}
                      label={data.label}
                      value={data.value}
                      onChange={e => setRadio(data.value)}
                    />
                  ))}
                </AvRadioGroup>
              </AvForm>
            </div>
          </div> */}
        </AvForm>
        {methodType === 'selfAssessed' && !isSeries ? (
          <div className="outcome-summary-intercept-flex-item">
            <div className="checkbox-container-include-in-report">
              <div className="include-in-report-label">Include in report</div>
              <div className="button-include">
                <Button.Group
                  className="btn-include-in-report"
                  onClick={() =>
                    !isDisabled &&
                    toggleInReport(!selfAssessedInReport, methodType)
                  }
                  disabled={isDisabled}
                >
                  <Button
                    active={selfAssessedInReport && true}
                    disabled={isDisabled}
                  >
                    Yes
                  </Button>
                  <Button
                    active={!selfAssessedInReport && true}
                    disabled={isDisabled}
                  >
                    No
                  </Button>
                </Button.Group>
              </div>
            </div>
          </div>
        ) : viewAllNoData ? null : (
          !isSeries && (
            <div className="outcome-summary-intercept-flex-item">
              <div className="checkbox-container-include-in-report">
                <div className="include-in-report-label">Include in report</div>
                <div className="button-include">
                  <Button.Group
                    className="btn-include-in-report"
                    onClick={() =>
                      !isDisabled && toggleInReport(!inReport, methodType)
                    }
                  >
                    <Button active={inReport && true} disabled={isDisabled}>
                      Yes
                    </Button>
                    <Button active={!inReport && true} disabled={isDisabled}>
                      No
                    </Button>
                  </Button.Group>
                </div>
              </div>
            </div>
          )
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="removed-margin">
          {methodType === 'all' ? (
            viewAllNoData ? (
              <EmptyContainer msg="No data in all methods." />
            ) : (
              <SummaryChart
                evalPlan
                outcomeId={outcomeId}
                chartType={chartType}
                chartMethod={radio}
                type={title}
                outcome={title}
                method={isIntercept ? 12 : 'all'}
                isIntercept={isIntercept}
                documentation={true}
                {...props}
              />
            )
          ) : methodType === 'interceptOnly' ? (
            <SummaryChart
              evalPlan
              outcomeId={outcomeId}
              chartType={chartType}
              chartMethod={radio}
              type={title}
              outcome={title}
              method={12}
              isIntercept={isIntercept}
              documentation={true}
              {...props}
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

class OutcomeSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getProjectOutcomeSummary, currentActivity, isSeries } = this.props;
    getProjectOutcomeSummary(currentActivity.id, isSeries);
  }

  componentDidUpdate(prevProps) {
    const { getProjectOutcomeSummary, currentActivity, isSeries } = this.props;
    if (prevProps.isSeries !== isSeries) {
      getProjectOutcomeSummary(currentActivity.id, isSeries);
    }
  }

  renderTabs() {
    const { currentActivity, projectLoading } = this.props;
    const evalPlanSize = size(get(currentActivity, 'evaluationPlans', []));

    if (isEmpty(currentActivity.objective)) return [];

    const objectives = map(currentActivity.objective, (objective, i) => {
      return {
        menuItem: get(objective, 'outcome.title', '-'),
        render: () => (
          <Tab.Pane key={i} menu={{ secondary: true, pointing: true }}>
            <div>
              {projectLoading ? (
                <div className="outcome-summary-loading">
                  <Loader active inline="centered" />
                </div>
              ) : (
                <div className="evaluation-outcome-summary-charts">
                  <OutcomeSummaryCharts
                    title={get(objective, 'outcome.title', null)}
                    outcomeId={get(objective, 'outcome.id', null)}
                    evalPlanSize={evalPlanSize === 1}
                    {...this.props}
                  />
                </div>
              )}
            </div>
          </Tab.Pane>
        ),
      };
    });
    // const others = [
    //   {
    //     menuItem: 'Tab ',
    //     render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
    //   }
    // ];
    const newArray = concat(objectives);
    return newArray;
  }

  render() {
    return (
      <Fragment>
        <Fragment>
          <Tab
            className="tabs-graph"
            menu={{ secondary: true, pointing: true }}
            panes={this.renderTabs()}
          />
        </Fragment>
      </Fragment>
    );
  }
}

class OutcomeSummarySections extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Segment className="survey-seg">
        <div className="survey-div">
          <OutcomeSummary {...this.props} />
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    participantsChange: state.report.participantsChange,
    participantsChangePrePost: state.report.participantsChangePrePost,
    participants: state.report.participants,
    projectLoading: state.report.projectLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      evalPlanManage,
      evalManage,
      updateEvaluationPlan,
      getProjectOutcomeSummary,
      updateOutcomeChart,
    },
    dispatch
  );
};

OutcomeSummarySections = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeSummarySections);

export { OutcomeSummarySections };

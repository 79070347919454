import React, { useState } from 'react';

import { SelfAssessedSeparateChart } from '../activityCharts/programOutcome/selfAssessedSeparateChart';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';
import { advancedFilterOpen } from '../../../store/actions';
import { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import {
  ORGANISATION_REPORT_LOADING,
  PROGRAM_REPORT_LOADING,
} from '../../../utils/constant';
import { EmptyContainer } from '../../genericComponents';
import { CustomSegment } from '../../customSegment';
import { SelfAssessedTrendChart } from '../activityCharts/programOutcome/selfAssessedTrendChart';
import { AggregateChart } from '../postCharts/aggregateChart';
import { SeparateChart } from '../postCharts//separateChart';
import { IndividualRateOfChangeChart } from '../postPreCharts/individualRateChart';
import { TrendRateOfChangeChart } from '../postPreCharts/trendRatesOfChart';

export const OutcomeChart = (props) => {
  const [outcomeSelected, setOutcomeSelected] = useState({});
  const [isParticipantsEmpty, setIsParticipantsEmpty] = useState(false);
  const [isParticipantsChangeEmpty, setParticipantsChangeEmpty] =
    useState(false);
  const [
    isParticipantsPrePostChangeEmpty,
    setIsParticipantsPrePostChangeEmpty,
  ] = useState(false);

  const dispatch = useDispatch();
  const reportFilter = useSelector((state) => state.report.reportFilterOptions);
  const participants = useSelector((state) => state.report.participants);
  const reportInitialState = useSelector((state) => state.report);

  //check is postPre or post
  const outcomes = useSelector((state) => state.extra.outcomes);
  const participantsChangePrePost = useSelector(
    (state) => state.report.participantsChangePrePost
  );

  const participantsChange = useSelector(
    (state) => state.report.participantsChange
  );

  const item = outcomes.find((item) => item.id === reportFilter.outcome);
  const loaderValue = props.parent
    ? PROGRAM_REPORT_LOADING
    : ORGANISATION_REPORT_LOADING;
  const getTypeOfReport = () => {
    return item && item.prePost
      ? `postPreReportLoading.${loaderValue['POST_PRE_REPORT_LOADING']}`
      : `postReportLoading.${loaderValue['POST_REPORT_LOADING']}`;
  };

  const individualRate = props.parent
    ? PROGRAM_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING
    : ORGANISATION_REPORT_LOADING.INDIVIDUAL_RATE_REPORT_LOADING;
  const individualRateLoading = get(
    reportInitialState,
    `individualRateReportLoading.${individualRate}`,
    false
  );
  const type = getTypeOfReport();
  const loading = get(reportInitialState, type);

  useEffect(() => {
    setIsParticipantsEmpty(isEmpty(participants));
  }, [participants]);

  useEffect(() => {
    setIsParticipantsPrePostChangeEmpty(
      participantsChangePrePost.activityCount === 0 ||
        participantsChangePrePost.activityCount === undefined
        ? true
        : false
    );
  }, [participantsChangePrePost]);

  useEffect(() => {
    setParticipantsChangeEmpty(isEmpty(participantsChange));
  }, [participantsChange]);

  useEffect(() => {
    setOutcomeSelected(reportFilter.outcome);
  }, [reportFilter.outcome]);

  const OUTCOME_ID = item && item.prePost;

  const METHOD_ID = reportFilter.method;

  const renderGraphs = () => {
    if (outcomeSelected === null) {
      return (
        <EmptyContainer
          msg="No outcome selected"
          childrenComponent={
            <Button
              className="Secondary-Button"
              type="button"
              onClick={() =>
                props.setVisible
                  ? props.setVisible(true)
                  : dispatch(advancedFilterOpen(true))
              }
            >
              Select an outcome
            </Button>
          }
        />
      );
    } else {
      if (METHOD_ID === 12) {
        return (
          <>
            {isParticipantsEmpty ? (
              <EmptyContainer msg="No data in that method." />
            ) : (
              <>
                <AggregateChart
                  method={METHOD_ID}
                  participants={participants}
                  {...props}
                />
                <SeparateChart
                  method={METHOD_ID}
                  participants={participants}
                  {...props}
                />
              </>
            )}
          </>
        );
      } else if (METHOD_ID === 9) {
        if (OUTCOME_ID === 0) {
          if (isParticipantsEmpty) {
            return <EmptyContainer msg="No data in that method." />;
          } else if (participants) {
            const formattedSelfPostParticipants =
              get(participants, 'dataAll', []).length > 0 &&
              participants.dataAll.map((item) => {
                return {
                  name: item.name,
                  participantResponse: [item.data],
                };
              });
            return (
              <>
                <SelfAssessedTrendChart
                  {...props}
                  activityCount={participants.activityCount}
                  totalParticipants={participants.totalParticipants}
                  totalRespondents={participants.totalRespondents}
                  response={[participants.data]}
                />
                <SelfAssessedSeparateChart
                  {...props}
                  participants={participants}
                  activityCount={participants.activityCount}
                  totalParticipants={participants.totalParticipants}
                  totalRespondents={participants.totalRespondents}
                  evaluations={formattedSelfPostParticipants}
                />
              </>
            );
          }
        } else {
          if (isParticipantsPrePostChangeEmpty) {
            return <EmptyContainer msg="No data in that method." />;
          } else if (participantsChangePrePost) {
            return (
              <>
                <SelfAssessedTrendChart
                  {...props}
                  activityCount={participantsChangePrePost.activityCount}
                  totalParticipants={
                    participantsChangePrePost.totalParticipants
                  }
                  totalRespondents={participantsChangePrePost.totalRespondents}
                  response={participantsChangePrePost.data}
                />
                <IndividualRateOfChangeChart
                  {...props}
                  participantsChange={participantsChange}
                />
              </>
            );
          }
        }
      } else {
        if (OUTCOME_ID === 0) {
          return (
            <>
              {isParticipantsEmpty ? (
                <EmptyContainer msg="No data in that method." />
              ) : (
                <>
                  <AggregateChart {...props} participants={participants} />
                  <SeparateChart {...props} participants={participants} />
                </>
              )}
            </>
          );
        } else {
          if (
            !loading &&
            isParticipantsChangeEmpty &&
            isParticipantsPrePostChangeEmpty
          ) {
            return <EmptyContainer msg="No data in that method." />;
          } else {
            if (participantsChangePrePost || participantsChange) {
              return (
                <>
                  <TrendRateOfChangeChart
                    {...props}
                    participantsChangePrePost={participantsChangePrePost}
                  />
                  <IndividualRateOfChangeChart
                    {...props}
                    participantsChange={participantsChange}
                  />
                </>
              );
            }
          }
        }
      }
    }
  };

  return (
    <CustomSegment
      title={`Outcomes Reported ${item && item.title ? `: ${item.title}` : ''}`}
      className="outcomes-reported"
    >
      {/* if prepost then check postpre and individual and make load */}
      <Segment
        loading={
          item && item.prePost ? loading || individualRateLoading : loading
        }
      >
        {renderGraphs()}
      </Segment>
    </CustomSegment>
  );
};

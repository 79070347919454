import React, { Fragment, useEffect, useState } from 'react';
import Joyride, { ACTIONS, LIFECYCLE } from 'react-joyride';
import { bindActionCreators } from 'redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { Grid, Button, Message, Modal } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import {
  CustomSegment,
  Tooltip,
  BeaconComponent,
  NotificationMessage,
} from '../../components';
import { APP_NAME, SUBSCRIPTIONS } from '../../utils/constant';
import history from '../../utils/history';
import moment from 'moment';
import './dashboard.scss';
import {
  getAllDashboardData,
  updateNotificationReadStatus,
  getUserReminders,
  getNotifications,
  getSubscriptions,
  upgradeGuestUser,
  getNotificationsAll,
  getPaymentPreferences,
  deleteUserNotification,
  setUserOnboardingOption,
} from '../../store/actions';
import ActivityStateSegment from './ActivityStateSegment';
import RecentActivitiesSegment from './RecentActivitiesSegment';
import TodoSegment from './TodoSegment';
import { get, map, find } from '../../utils/lodash';
import './dashboard.scss';
import { isEmpty } from 'lodash';
import { isIndividualUser } from '../../utils/activity/userPermission';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const userContainer = isMobile ? '.user-profile-drop' : '.user-pro-panel';

const steps = [
  {
    target: userContainer,
    content:
      'User settings. From here you can update your details and password, find FAQs and log out.',
    placement: 'bottom-end',
    disableBeacon: true,
  },
  {
    target: '.nav-routes-container',
    content:
      'This is the main menu. From here you can navigate to different sections of Takso.',
    placement: 'right',
  },
  {
    target: '.dash-panel',
    content:
      'Click here to go to the dashboard to see a list of things to do, notifications from Admin, and a summary of activities.',
    placement: 'right',
  },
  {
    target: '.activity-panel',
    content:
      'Click here to see and enter all the activities you own or are invited to.',
    placement: 'right',
  },
  {
    target: '.report-panel',
    content:
      'View reports for your account generated from aggregate data from each activity in Takso.',
    placement: 'right',
  },
  {
    target: '.all-activities-panel',
    content:
      'Takso Library. View the public reports of all completed activities across Takso.',
    placement: 'right',
  },
  {
    target: '.organisation-panel',
    content:
      'Click here to manage your corporate information and organizational Takso settings.',
    placement: 'right',
  },
  {
    target: '.activities',
    content: (
      <div>
        <label>
          This is an overview of the activities running in your organisation.
        </label>
        <br />
        <br />
        <label className="amber-color">
          <b>Amber</b>
        </label>{' '}
        - activities in development
        <br />
        <label className="green-color">
          <b>Green</b>
        </label>{' '}
        - active activities
        <br />
        <label className="blue-color">
          <b>Blue</b>
        </label>{' '}
        - completed activities
        <br />
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.to-do',
    content:
      'These are the tasks which you are due to complete and will disappear as you finish each one.',
    placement: 'right',
  },
  {
    target: '.notifications',
    content:
      'Communication from your organisation’s Takso admins will appear here.',
    placement: 'top',
  },
  {
    target: '.help-page',
    content:
      'Page Help. You can access information and help for each page by clicking the help tab.',
    placement: 'left',
  },
  {}, // passed an empty array: to hold steps until right side bar loaded (right help panel)
  {
    target: '.action-container',
    content: `Click “Show me around” anytime you want to take this tour again.`,
    placement: 'left',
  },
];

const Dashboard = (props) => {
  const { visibleTips, togglerOnboardingTips, togglerHelpPanelDrawer } = props;
  const [infoSteps, setInfoSteps] = useState(false);
  const [visible, setVisible] = useState(true);
  const [modal, setModal] = useState(false);
  const [isOpenNotificationMsg, setIsOpenNotificationMsg] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);

  const latestOrgInvoice = get(auth.user, 'organisation.invoices[0]', null);

  const { organisation } = auth.user;
  const paymentPreferenceId = get(organisation, 'paymentPreferenceId', null);
  const {
    notifications,
    userReminders,
    loadingReminders,
    statUser,
    loadingStat,
    notificationsLoading,
    notificationsAll,
  } = user;

  const navigate = useNavigate();

  const btCustomer = get(auth.user, 'organisation.btCustomer', null);
  const cardEdit = btCustomer && btCustomer.btCardHolderName ? true : false;

  useEffect(() => {
    document.title = `Dashboard | ${APP_NAME}`;

    try {
      dispatch(getAllDashboardData());
      if (!auth.user.onboardingOption.dashboardTips) {
        setInfoSteps(true);
      }
    } catch (error) {
      // console.error('Dashboard -> componentDidMount -> error', error);
    }
  }, []);

  useEffect(() => {
    if (visibleTips) {
      // check help panel "show me around" btn clicked <- block in other updating statuses
      setInfoSteps(visibleTips);
      togglerOnboardingTips(false); // hide parent component (help panel)
    }
  }, [visibleTips]);

  const goToRoute = (route) => {
    navigate(route);
  };

  const getJoyRide = (data) => {
    const { step, action, type, lifecycle } = data;

    window.scrollTo(0, 0); // to overcome react joyride scrolling issue (joyride used wrong class position while scrolling)
    if (
      // to open left help panel
      step.target === '.help-page' &&
      action === ACTIONS.NEXT &&
      lifecycle === LIFECYCLE.COMPLETE
    ) {
      // trigger only before reach to next location
      togglerHelpPanelDrawer(true);
      setInfoSteps(false);
      setTimeout(() => {
        setInfoSteps(true);
      }, 400);
    }

    if (type === 'tour:end') {
      setInfoSteps(false);

      if (!auth.user.onboardingOption.dashboardTips) {
        // only trigger for new user/ users not toured whole trip
        auth.user.onboardingOption.dashboardTips = true;
        dispatch(setUserOnboardingOption(auth.user)); // save in db
      }
      togglerOnboardingTips(false); // hide help panel and off the onboarding flow in sidebar
    }
  };

  async function repeatInfo() {
    try {
      setInfoSteps(true);
    } catch (error) {
      setInfoSteps(false);
    }
  }

  const handleDismiss = () => {
    setVisible(false);

    setTimeout(() => {
      setVisible(true);
    }, 2000);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const changeToBillingPage = () => {
    navigate('/account/billing');
  };

  const daysRemaining = (trialEndDate) => {
    let eventDate = moment(trialEndDate);
    let todaysDate = moment();
    if (moment().isSame(eventDate, 'day')) return -1;
    return eventDate.diff(todaysDate, 'days');
  };

  const msg = find(notificationsAll.docs, { isRead: false });
  if (msg && !msg.isRead && !isOpenNotificationMsg)
    setIsOpenNotificationMsg(true);

  let passedDate = false;
  if (!isEmpty(latestOrgInvoice)) {
    if (
      latestOrgInvoice.nextBillingDate &&
      moment().diff(latestOrgInvoice.nextBillingDate, 'days') !== 0
    ) {
      passedDate = true;
    }
  }

  let guestIndividual = false;
  if (!isEmpty(organisation)) {
    if (
      organisation.trialExpiredAt === null &&
      !passedDate &&
      organisation.type === 'INDIVIDUAL'
    ) {
      guestIndividual = true;
    }
  }

  const duration = isIndividualUser(auth.user)
    ? daysRemaining(organisation.trialExpiredAt)
    : 0;

  return (
    <Fragment>
      <div className="add-goal">
        <Modal size="tiny" open={modal}>
          <Modal.Header>UPGRADE ACCOUNT</Modal.Header>
          <Modal.Content>
            <UpgradeSubscription toggle={() => toggle()} {...props} />
          </Modal.Content>
        </Modal>
      </div>

      <Grid.Row>
        <Grid.Column width={16}>
          {visible && guestIndividual ? (
            <Message className="upgrade-notification">
              <div className="txt-button-align">
                <div className="upg-text-outline">
                  <div className="upgrade-text">Account Upgrade</div>
                  <div className="upgrade-text-sub">
                    Click the upgrade button to start your subscription.
                  </div>
                </div>

                <div className=" btn-outline">
                  <Button
                    type="submit"
                    className="btn btn-primary verify-button upgrade-button"
                    content="Upgrade"
                    onClick={() => changeToBillingPage()}
                  />
                </div>
              </div>
            </Message>
          ) : null}

          {isIndividualUser(auth.user) && duration >= 0 ? (
            <Message className="upgrade-notification">
              <div className="txt-button-align">
                <div className="upg-text-outline">
                  <div className="upgrade-text">Account Upgrade</div>
                  <div className="upgrade-text-sub">
                    {paymentPreferenceId === 1
                      ? `${
                          duration === 0
                            ? 'Your free trial ends today.'
                            : `You have ${duration} days left on your free trial.`
                        } ${
                          !cardEdit
                            ? 'Add a card to start your subscription.'
                            : ''
                        }`
                      : `${
                          duration === 0
                            ? 'Your free trial ends today.'
                            : `You have ${duration} days left on your free trial.`
                        }`}
                  </div>
                </div>
                {paymentPreferenceId === 1 && !cardEdit ? (
                  <div className=" btn-outline">
                    <Button
                      type="submit"
                      className="btn btn-primary verify-button upgrade-button"
                      content="Add Card"
                      onClick={() => history.push('/account/billing')}
                    />
                  </div>
                ) : null}
              </div>
            </Message>
          ) : null}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column
          width={isMobile ? (!infoSteps && isMobile ? 16 : 15) : 10}
          className="dashboard-column"
        >
          {msg && !msg.isRead && isOpenNotificationMsg ? (
            <NotificationMessage
              classNames="notification-segment"
              message={get(msg, 'notification.message', '')}
              title={get(msg, 'notification.title', '')}
              updateNotificationReadStatus={() => {
                dispatch(updateNotificationReadStatus(msg.id, true));
                setIsOpenNotificationMsg(false);
              }}
            />
          ) : null}

          <CustomSegment
            title="TO DO"
            /*
             * Note:  to-do class name used for help tip showing class name (don't change it or remove it)
             */
            className="to-do"
            headerStyle={{ marginLeft: 12 }}
          >
            <TodoSegment
              todoList={userReminders}
              loading={loadingReminders}
              {...props}
              style={{ paddingRight: '1.714rem' }}
            />
          </CustomSegment>
          {isMobile ? (
            <div>
              <CustomSegment
                title="ACTIVITIES"
                /*
                 * Note: activities class name used for help tip showing class name (don't change it or remove it)
                 */
                className="activities"
                extraMargin
                headerStyle={{ marginLeft: 35 }}
              >
                <ActivityStateSegment
                  user={auth.user}
                  statUser={statUser}
                  loading={loadingStat}
                />
              </CustomSegment>
              <CustomSegment
                title="NOTIFICATIONS"
                icon="bell"
                /*
                 * Note: notifications class name used for help tip showing class name (don't change it or remove it)
                 */
                className="notifications"
              >
                <RecentActivitiesSegment
                  userNotifications={notifications}
                  userNotificationsAll={notificationsAll}
                  loading={notificationsLoading.notification}
                  updateNotificationReadStatus={(id) =>
                    dispatch(updateNotificationReadStatus(id, true))
                  }
                  deleteUserNotification={(id) =>
                    dispatch(updateNotificationReadStatus(id))
                  }
                  {...props}
                />
                {/* } */}
              </CustomSegment>
            </div>
          ) : null}
        </Grid.Column>
        {isMobile ? null : (
          <Grid.Column width={6} className="pr-0">
            <CustomSegment title="ACTIVITIES" className="activities">
              <ActivityStateSegment
                user={auth.user}
                statUser={statUser}
                loading={loadingStat}
              />
            </CustomSegment>

            <CustomSegment
              title="NOTIFICATIONS"
              className="notifications"
              icon="bell"
              style={{ marginTop: '1.714rem' }}
            >
              <RecentActivitiesSegment
                userNotifications={notifications}
                userNotificationsAll={notificationsAll}
                loading={notificationsLoading.notification}
                updateNotificationReadStatus={(id) =>
                  dispatch(updateNotificationReadStatus(id, true))
                }
                {...props}
              />
            </CustomSegment>
          </Grid.Column>
        )}
        <Joyride
          autoStart
          callback={(data) => getJoyRide(data)}
          run={infoSteps}
          steps={steps}
          continuous
          disableOverlayClose={true} // disable overlay close <- need to go whole trip
          disableCloseOnEsc={true} // disable overlay close [ESC btn]<- need to go whole trip
          tooltipComponent={Tooltip}
          beaconComponent={BeaconComponent}
          scrollToFirstStep
          disableScrolling={!isMobile} // disable scroll in web view <- to resolve wrong target at help panel in web view
        />
      </Grid.Row>
    </Fragment>
  );
};

const UpgradeSubscription = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    subscription: '',
    paymentMethod: '1',
  });

  const subscriptions = useSelector((state) => state.extra.subscriptions);
  const paymentPreferences = useSelector(
    (state) => state.extra.paymentPreferences
  );

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(getPaymentPreferences());
  }, []); // Empty dependency array ensures this effect runs once on mount

  const handleValidSubmit = async (event, values) => {
    try {
      const { toggle } = props;
      dispatch(upgradeGuestUser(values));
      toggle();
    } catch (error) {}
  };

  const onChangeSubs = (subscription) => {
    setState((prevState) => ({
      ...prevState,
      subscription,
    }));
  };

  const onChangeMethod = (paymentMethod) => {
    setState((prevState) => ({
      ...prevState,
      paymentMethod,
    }));
  };

  const renderGroups = () => {
    return map(subscriptions, (item, i) => {
      const isIndividual = item.accountTypes.includes('INDIVIDUAL');
      if (!isIndividual) return null;
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  };

  const renderPaymentPreference = () => {
    return map(paymentPreferences, (item, i) => {
      // Manual Transaction-id 2 removed from guest user upgrade modal
      if (item.id === 2) return null;
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  };

  const { subscription } = state;
  const { toggle } = props;

  const user = useSelector((state) => state.user);
  const subDetails = find(
    SUBSCRIPTIONS,
    (sub) => sub.id === Number(subscription)
  );

  return (
    <div className="container-upgradeSubscription">
      <AvForm
        onValidSubmit={(event, values) => handleValidSubmit(event, values)}
      >
        <div className="your-plan-subscription">YOUR PLAN</div>

        <AvField
          type="select"
          name="subscription"
          label="SUBSCRIPTION"
          className="sub-inputs"
          validate={{
            required: {
              value: true,
              errorMessage: 'Subscription type is required',
            },
          }}
          onChange={(e) => onChangeSubs(e.target.value)}
        >
          <option value="" defaultValue disabled>
            Select Subscription Type...
          </option>
          {renderGroups()}
        </AvField>
        {subDetails ? (
          <div className="sub-detail-container-verification">
            <label className="plan-include-text">Your plan includes</label>

            <Grid columns={1} stackable>
              <Grid.Row className="detail">
                {map(subDetails.includes, (inc, i) => (
                  <div key={i}>
                    <label className={'orangeCorrectIcon'}>
                      &#10004;&nbsp;&nbsp;
                    </label>
                    <label className={'planList'}>{inc}</label>
                  </div>
                ))}
              </Grid.Row>
            </Grid>
          </div>
        ) : null}

        <div className="payment-text">PAYMENT METHOD</div>

        <AvField
          type="select"
          name="paymentMethod"
          className="sub-inputs"
          validate={{
            required: {
              value: true,
              errorMessage: 'Payment method is required',
            },
          }}
          onChange={(e) => onChangeMethod(e.target.value)}
          value={state.paymentMethod}
        >
          <option value="" disabled defaultValue>
            Select Payment Method...
          </option>
          {renderPaymentPreference()}
        </AvField>

        <div className="model-buttons">
          <Button
            className="goal-cancel-button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            loading={user.guestUserLoading}
            disabled={user.guestUserLoading}
            content="Save"
            type="submit"
            className="goal-create-button"
          />
        </div>
      </AvForm>
    </div>
  );
};

export default Dashboard;

/* eslint-disable eqeqeq */
import { isEmpty } from 'lodash';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { Label } from 'semantic-ui-react';
export class PostalCodeSearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { suburb: null, errorMessage: false };
  }

  async sendLocation(data) {
    const { getLocation } = this.props;

    if (data.address_components && data.address_components.length) {
      const postalCodes = data.address_components
        .filter((it) => {
          return it.types.indexOf('postal_code') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const suburb = data.address_components
        .filter((it) => {
          return it.types.indexOf('locality') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      const suburbExtra = data.address_components
        .filter((it) => {
          return it.types.indexOf('political') !== -1;
        })
        .map((it) => {
          return it.long_name;
        });
      if (postalCodes[0]) {
        data.postcode = postalCodes[0];
      }
      if (suburb[0] || suburbExtra[0]) {
        this.setState({ suburb: suburb[0] || suburbExtra[0] });
      }
      if (isEmpty(postalCodes)) {
        data.postcode = '';
      }
    }

    this.setState({
      errorMessage: isEmpty(data.postcode) ? true : false,
    });

    getLocation(data);
  }

  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  };

  render() {
    if (!window.google) return null;
    const { suburb, errorMessage } = this.state;
    const { value, onChange } = this.props;
    return (
      <div className="form-group">
        <label className="">What is your postcode?</label>
        <div className="input-group input-group">
          <Autocomplete
            onKeyPress={this.handleKeyPress}
            onChange={onChange}
            onPlaceSelected={(data) => {
              this.sendLocation(data);
            }}
            value={value}
            className={`org-inputs is-touched is-pristine av-invalid form-control ${
              errorMessage ? 'invalid-city' : ''
            } `}
            types={['(regions)']}
            componentRestrictions={{ country: 'AU' }}
          />
          {errorMessage ? (
            <div className="invalid-message">Post code unavailable</div>
          ) : null}
        </div>
        {suburb !== null &&
        Number.isInteger(parseInt(value)) &&
        !errorMessage ? (
          <Label style={{ marginTop: '0.625rem' }} horizontal>
            {suburb}
          </Label>
        ) : null}
      </div>
    );
  }
}

import 'core-js/stable';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-notifications/lib/notifications.css';
import 'semantic-ui-css/semantic.min.css';
import React, { Fragment, PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import qs from 'qs';
import FullStory from 'react-fullstory';
import ReactGA from 'react-ga4';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as Sentry from '@sentry/react';

import {
  getRefreshToken,
  setAuthenticatedToken,
  setRefreshToken,
} from './utils/cacheStore';
import { BASE_URL } from './utils/constant';
// import SentryBoundary from './utils/errorBoundary';
import { get } from './utils/lodash';
import AppRouting from './routing';
import { unregister } from './serviceWorker';
import { store, persistor } from './store';
import { initializedApp } from './store/actions';
import { userAuth } from './utils';
import NetworkDetectorHoc from './lib/networkDetectorHoc';
import { BrowserRouter, useNavigate } from 'react-router-dom';
store.dispatch(initializedApp());
// to save request type [get, post, put, ...]
let requestMethod = null;
axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 60000;
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.interceptors.request.use((request) => {
  requestMethod = get(request, 'method', 'get');
  request.ts = performance.now(); // to find the performance
  if (
    request.data &&
    request.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    request.data = qs.stringify(request.data);
  }
  return request;
});
let isRefreshing = false;
let requestQueue = [];
// network error vars
let isShownNetworkError = false;
let networkErrorPathname = '';
const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  function trackApiResponse(response, ts) {
    if (response.config && response.config.url) {
      ReactGA.event({
        category: 'Axios',
        action: response.config.url,
        value: ts, // in milliseconds
        label: response.status,
      });
    }
  }
  /*
   * this function execute all the hold function in request queue array
   */
  const processQueue = (error, token = null) => {
    requestQueue.forEach(async (promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    requestQueue = [];
  };
  // log out user
  const userLogOut = async () => {
    await userAuth.signout(() => {
      persistor.purge();
    });
    const pathname = window.location.pathname;
    navigate('/', {
      state: {
        redirectRoute: pathname,
        logoutReason: 'Session Expired',
      },
    });
  };
  axios.interceptors.response.use(
    (response) => {
      const ts = Number(performance.now() - response.config.ts);
      trackApiResponse(response, ts);
      return response;
    },
    async (error) => {
      // network error check
      if (error.message === 'Network Error') {
        if (
          (!isShownNetworkError && networkErrorPathname === '') ||
          (networkErrorPathname !== '' &&
            networkErrorPathname !== window.location.pathname)
        ) {
          isShownNetworkError = true;
          networkErrorPathname = window.location.pathname;
          return Promise.reject('NETWORK_ERROR');
        }
        // discard API calls due to network error; no need to call this again
        if (requestMethod && requestMethod.toUpperCase() === 'GET') {
          return Promise.reject('NETWORK_DISCARD');
        } else {
          return Promise.reject('NETWORK_ERROR');
        }
      }
      const ts = Number(performance.now() - error.config.ts);
      trackApiResponse(error, ts);
      const status = get(error, 'response.status', 500);
      const unauthorized = get(error, 'response.data.message', null);
      const originalRequest = error.config;
      if (status === 500 && unauthorized === 'DISABLED') {
        await userAuth.signout(() => {
          persistor.purge();
        });
        navigate('/account-disabled');
        return Promise.reject(error);
      }
      if (
        (status === 401 || unauthorized === 'Unauthorized') &&
        originalRequest.url === `${BASE_URL}/auth/refresh`
      ) {
        await userLogOut();
        return Promise.reject(error);
      }
      if (
        !originalRequest._retry &&
        status === 500 &&
        unauthorized === 'Session Expired.'
      ) {
        /*
         * while re-generating refresh token all the other request are held,
         * once refresh token generated send the request to API
         */
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            // add request to queue
            requestQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch(async (err) => {
              await userLogOut();
              return Promise.reject(error);
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        const refresh_token = await getRefreshToken();
        return new Promise((resolve, reject) => {
          axios
            .post('/auth/refresh', {
              refresh_token,
            })
            .then((res) => {
              const refreshToken = res.data.refreshToken;
              const token = res.data.token;
              setAuthenticatedToken(token);
              setRefreshToken(refreshToken);
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              // send token to run paused requests
              processQueue(null, token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              // finally release request que for call requests
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );
  return children;
};
class IndexApp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
    };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    unregister();
  }
  async componentDidMount() {
    try {
      unregister();
      Sentry.init({
        enabled: process.env.REACT_APP_ENV_VALUE === 'production',
        environment: 'production',
        debug: false,
        dsn: 'https://4536a50f286842c3bb708c1cbae2bcab@o261360.ingest.sentry.io/1459144',
        integrations: [
          new Sentry.BrowserTracing(),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
              window.location.origin,
              BASE_URL,
              /^\/(?!\/)/,
            ],
          }),
        ],
        beforeSend(event, hint) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        // integrations: [new Sentry.Replay()],
      });

      ReactGA.initialize('UA-127298113-6', {
        debug: false,
        // process.env.REACT_APP_ENV_VALUE === 'production' ? false : true,
        titleCase: false,
      });
      this.setState({ view: true });
    } catch (error) {
      this.setState({ view: true });
    }
  }
  render() {
    const { view } = this.state;
    if (view) {
      return (
        <Fragment>
          <FullStory
            org="o-19SSJE-na1"
            debug={process.env.REACT_APP_ENV_VALUE !== 'production'}
          />
          {this.props.children}
        </Fragment>
      );
    } else {
      return null;
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
const mapStateToProps = (state) => {
  return state;
};
let AppWrapper = connect(mapStateToProps, mapDispatchToProps)(IndexApp);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <SentryBoundary>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AxiosInterceptor>
          <AppWrapper>
            <NetworkDetectorHoc>
              <AppRouting />
            </NetworkDetectorHoc>
          </AppWrapper>
        </AxiosInterceptor>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // /* </SentryBoundary> */
);

// ReactDOM.render(
//   <SentryBoundary>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <AppWrapper>
//           <NetworkDetectorHoc>
//             <AppRouting />
//           </NetworkDetectorHoc>
//         </AppWrapper>
//       </PersistGate>
//     </Provider>
//   </SentryBoundary>,
//   document.getElementById('root')
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();

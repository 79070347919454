/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isPermanentlyDeleteActivity } from '../../../../../../utils/activity';

import { stopPropagation } from '../../../../../../utils';
import { Dropdown, Modal } from 'semantic-ui-react';
import { get } from '../../../../../../utils/lodash';
import DeactivateLinkedActivityForm from '../forms/deactivateLinkedActivityForm';

export default class DeactivateLinkedActivity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { activity, user } = this.props;
    const guestUserOrganisation = get(
      activity,
      'guestOwner.organisation',
      null
    );
    const permanentDelete = isPermanentlyDeleteActivity(
      guestUserOrganisation,
      user.organisationId
    );
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => this.toggle()}
          >
            &times;
          </div>
        }
        size="tiny"
        trigger={
          <Dropdown.Item className="activity-actions-dropdown-view">
            <label
              className={
                permanentDelete
                  ? 'activity-actions-text-warning'
                  : 'activity-actions-text'
              }
            >
              {activity.deleted ? 'Activate' : 'Deactivate'}
            </label>
          </Dropdown.Item>
        }
      >
        <Modal.Header>
          <div className="small-modal-header">
            {activity.deleted ? 'Activate' : 'Deactivate'} Activity -{' '}
            {activity.name}
          </div>
        </Modal.Header>
        <Modal.Content>
          <DeactivateLinkedActivityForm
            toggle={() => this.toggle()}
            guestUserOrganisation={guestUserOrganisation}
            permanentDelete={permanentDelete}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

import * as Yup from 'yup';
import { EMAIL_REG_EX, PHONE_REG_EX } from '../utils/regex';
import { get } from 'lodash';
import { PARTNER_RELATIONSHIP } from '../utils/constant';

export const getOwnerDetailsInitialValues = (activity) => {
  return {
    // firstName: get(activityOwner, 'firstName', ''),
    // email: get(activityOwner, 'email', ''),
    // surname: get(activityOwner, 'surname', ''),
    // entityName: get(activityOwner, 'organisation.name', ''),
    // address1: get(activityOwner, 'address1', ''),
    // address2: get(activityOwner, 'address2', ''),
    // suburb: get(activityOwner, 'suburb', ''),
    // state: get(activityOwner, 'state', ''),
    // postcode: get(activityOwner, 'postcode', ''),
    // phone: get(activityOwner, 'phone', ''),
    // abn: get(activityOwner, 'abn', ''),
    // acn: get(activityOwner, 'acn', ''),
    // organisationId: get(activityOwner, 'organisationId', null),
    // organisationName: get(activityOwner, 'organisation.name', ''),
    // // programContribution: get(partnerDetails, 'relationship', ''),
    // isExistingUser: true,
    // isConfirmedUser: true,
    projectedGrantAmount: get(activity, 'projectedGrantAmount', ''),
    relationship: PARTNER_RELATIONSHIP[0].value,
    // description: get(activity, 'description', ''),
    // emailConfirmError: activityOwner ? 'confirmedUser' : '',
  };
};

export const changeOwnershipValidationSchema = () =>
  Yup.lazy((values) => {
    let validationSchema = {
      email: Yup.string()
        .required('Email is required!')
        .matches(EMAIL_REG_EX, 'Email is not valid!'),
      emailConfirmError: !values.isConfirmedUser
        ? Yup.string().required('Confirmation is required!')
        : Yup.string().optional(),
    };

    if (!values.isExistingUser) {
      validationSchema = {
        ...validationSchema,
        firstName: Yup.string().required('First Name is required!'),
        surname: Yup.string().required('Last Name is required!'),
        phone: Yup.string()
          .required('Phone number is required!')
          .matches(PHONE_REG_EX, 'Phone number is not valid!')
          .min(10, 'Must be a minimum of 10 digits!'),

        entityName: Yup.string().required(
          'Business/ organisation name is required!'
        ),
        address1: Yup.string().required('Address Line 1 is required!'),
        suburb: Yup.string().required('Suburb is required!'),
        state: Yup.string().required('State is required!'),
        postcode: Yup.string().required('Postcode is required!'),
      };
    }

    return Yup.object().shape(validationSchema);
  });

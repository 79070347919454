import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Segment } from 'semantic-ui-react';
import { isEmpty, get } from '../../../../utils/lodash';
import '../activity.scss';
import { updateProjectEvaluationImage } from '../../../../store/actions';
// import {
//   EvaluationQuestionGraph,
//   SelfAssessedGraph,
//   SurveyResponcesChart,
//   EvaluationPostPreSurvey,
// } from '../evaluation/evaluationQuestion';
import { averageValue } from '../../../../utils';
import {
  AggregateChart,
  IndividualRateOfChangeChart,
  TrendRateOfChangeChart,
} from '../../../../components/chartsWidgets';

class MeasureEvaluation extends PureComponent {
  render() {
    const { evaluation, selfAssessed } = this.props;

    const _participantResponse = get(evaluation, 'participantResponse', null);
    const methodId = get(evaluation, 'methodId', null);

    const chartData = {
      name: get(evaluation, 'outcome.title', ''),
      totalParticipants: get(evaluation, 'totalParticipants', null),
      totalRespondents: get(evaluation, 'actualParticipantsEvaluated', null),
      evalCount: 1, // no need to show
      activityCount: 1, // no need to show
      data: Boolean(get(evaluation, 'cumulative', 0)) // for post pre chart
        ? _participantResponse
        : // for post  chart
          _participantResponse && _participantResponse.length > 0
          ? _participantResponse[0]
          : null,
      results: get(evaluation, 'prePostSurveyResponse', null),
    };

    if (isEmpty(evaluation)) return null;
    const [one = []] = get(evaluation, 'participantResponse', []);
    return (
      <Segment className="report-activity-seg" basic>
        <div className="report-activity-div">
          <Grid>
            <Grid.Row className="results-row">
              <Grid.Column>
                <label className="bold-text">
                  Results: {get(evaluation, 'outcome.title', '-')}
                </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="graph-div">
            {!selfAssessed && (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <ul className="align-left-avg-resp">
                      {evaluation.cumulative ? (
                        <li>
                          {`The evaluated participants had a pre average response response of ${get(
                            averageValue(evaluation.participantResponse),
                            'preAverage',
                            '0.00'
                          )}`}
                        </li>
                      ) : null}
                      <li>
                        {`The evaluated participants had a post average response of ${get(
                          averageValue(evaluation.participantResponse),
                          'postAverage',
                          '0.00'
                        )}`}
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {selfAssessed && evaluation.actualParticipantsEvaluated >= 2 ? (
              <div className="self-assessed-graph">
                {/* SelfAssessedGraph */}
                {Boolean(evaluation.cumulative) ? (
                  <TrendRateOfChangeChart
                    isDocumentationLegends
                    method={methodId}
                    participantsChangePrePost={chartData}
                    avoidLabel={true}
                  />
                ) : (
                  <AggregateChart
                    method={methodId}
                    isDocumentationLegends
                    participants={chartData}
                    avoidLabel={true}
                  />
                )}
              </div>
            ) : (
              <>
                {Boolean(evaluation.cumulative) ? (
                  <>
                    <div className="self-assessed-graph">
                      {/* EvaluationQuestionGraph */}
                      <TrendRateOfChangeChart
                        isDocumentationLegends
                        method={methodId}
                        participantsChangePrePost={chartData}
                        avoidLabel={false}
                      />
                    </div>

                    {/*  trend rate of change / heat map chart not available on self assess */}
                    {!selfAssessed && (
                      <div className="heat-map-report">
                        {/* EvaluationPostPreSurvey */}
                        <IndividualRateOfChangeChart
                          fromDocumentation
                          evaluationObject={evaluation}
                          participantsChange={chartData}
                          avoidLabel={false}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    {/* SurveyResponcesChart */}
                    <AggregateChart
                      method={methodId}
                      isDocumentationLegends
                      participants={chartData}
                      avoidLabel={true}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentActivity } = state.activity;
  return {
    currentActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProjectEvaluationImage,
    },
    dispatch
  );
};

MeasureEvaluation = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasureEvaluation);

export { MeasureEvaluation };

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import moment from 'moment';
import './account.scss';
import { CustomTable, EmptyContainer } from '../../../components';
import { get, map } from '../../../utils/lodash';
import { INVOICE_STATUS_OPTIONS } from '../../../utils/constant';
import { getInvoicesStatus } from '../../../utils';
import { getInvoices, retrySubscription } from '../../../store/actions';

class Invoices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 'all',
      page: 1,
    };
  }

  getColumns() {
    return [
      {
        width: 3,
        title: 'Date',
        render: (data) => {
          return moment(data.billingStartDate).format('DD-MMMM-YYYY');
        },
      },
      {
        width: 3,
        title: 'Subscription',
        render: (data) => {
          return data.subscriptionType.title;
        },
      },
      {
        width: 2,
        title: 'Amount',
        render: (data) => {
          return `${Number(data.amount)}$`;
        },
      },
      {
        width: 3,
        title: 'No. of Users',
        render: (data) => {
          return data.userCount;
        },
      },
      {
        width: 3,
        title: 'Status',
        render: (data) => {
          const paymentNote = get(data, 'payment[0].note', null);
          return (
            <label className={`subscription-status ${data.status}`}>
              {paymentNote === 'card_not_added'
                ? 'Card Not Added'
                : getInvoicesStatus(data.status)}
            </label>
          );
        },
      },
      {
        width: 2,
        title: '',
        render: (data) => {
          switch (data.status) {
            case 'paid':
              return (
                <Button
                  onClick={() => window.open(data.invoiceUrl, '_blank')}
                  className="invoice"
                  content="Download"
                />
              );
            default:
              if (data.archivedAt) return null;
              if (data.paymentPreferenceId === 2) return null;
              return (
                <Button
                  onClick={() => this.retryTransaction(data.id)}
                  className="invoice"
                  content="Pay Now"
                />
              );
          }
        },
      },
    ];
  }

  componentDidMount() {
    const { getInvoices, user } = this.props;
    const { status, page } = this.state;
    getInvoices(user.organisation.id, {
      status,
      page,
      trialCheck: true,
    });
  }

  async retryTransaction(transactionId) {
    try {
      const { user, retrySubscription, cardEdit, cardAlert } = this.props;
      if (!cardEdit) {
        cardAlert(true);
        return;
      }
      await retrySubscription(user.organisation.id, { transactionId });
    } catch (error) {
      console.error(error);
    }
  }

  onStatusChange(status) {
    const { getInvoices, user } = this.props;
    const { page } = this.state;
    this.setState(
      {
        status,
      },
      () => {
        getInvoices(user.organisation.id, {
          page,
          status,
          trialCheck: true,
        });
      }
    );
  }

  pageChange(page) {
    const { getInvoices, user } = this.props;
    const { status } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getInvoices(user.organisation.id, {
          page,
          status,
          trialCheck: true,
        });
      }
    );
  }

  render() {
    const { status, page } = this.state;
    const { orgInvoices, orgInvoicesLoading } = this.props;

    return (
      <Segment
        className="content-segment"
        loading={orgInvoicesLoading}
        disabled={orgInvoicesLoading}
      >
        <Grid>
          <Grid.Row className="org-row">
            <AvForm className="sub-control-selector">
              <AvField
                type="select"
                name="status"
                label="STATUS"
                className="report-org-summary-selector"
                required
                onChange={(e) => this.onStatusChange(e.target.value)}
                value={status}
              >
                {map(INVOICE_STATUS_OPTIONS, (e) => (
                  <option key={e.key} value={e.value}>
                    {e.option}
                  </option>
                ))}
              </AvField>
            </AvForm>
          </Grid.Row>
          <Grid.Row>
            {orgInvoices && orgInvoices.docs && orgInvoices.docs.length ? (
              <Grid.Column>
                <CustomTable
                  header
                  columns={this.getColumns()}
                  data={orgInvoices.docs}
                  customClass="invoice-table"
                  pagination
                  handlePaginationChange={(page) => this.pageChange(page)}
                  page={page}
                  noOfPages={orgInvoices.pages}
                  keyExtractor={(item, index) => item.id}
                />
              </Grid.Column>
            ) : (
              <EmptyContainer msg="No invoices have been added…" />
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, organization } = state;
  const btCustomer = get(authentication.user, 'organisation.btCustomer', null);
  return {
    cardEdit: btCustomer && btCustomer.btCardHolderName ? true : false,
    orgInvoices: organization.orgInvoices,
    orgInvoicesLoading: organization.orgInvoicesLoading,
    user: authentication.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInvoices,
      retrySubscription,
    },
    dispatch
  );
};

Invoices = connect(mapStateToProps, mapDispatchToProps)(Invoices);

export { Invoices };

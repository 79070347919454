/* eslint-disable eqeqeq */
/* eslint-disable no-eval */
import React from 'react';
import { NotificationManager } from 'react-notifications';
import thousands from 'thousands';
import moment from 'moment';
import {
  AUTH_TOKEN,
  IS_AUTHENTICATED,
  removeAuthenticatedToken,
  removeIsAuthenticated,
  removeRefreshToken,
  setIsAuthenticated,
} from './cacheStore';
import { ABN_LOOKUP_CONFIG } from './constant';
import {
  compact,
  difference,
  filter,
  find,
  findIndex,
  flatten,
  flattenDeep,
  forEach,
  get,
  isArray,
  isEmpty,
  isEqual,
  isNumber,
  isString,
  map,
  sortedUniq,
  sum,
  size,
  uniq,
  cloneDeep,
} from './lodash';
import { Popup } from 'semantic-ui-react';
import xss from 'xss';
import { grantTrialHelpTips, grantTrialProgramProjectIds } from './pageText';
import {
  isParentProgramActivity,
  isFestivalProgramActivity,
  isGrantProgramActivity,
  isMapProgramActivity,
  isGrantLinkedActivity,
} from './activity';
import WHITELIST_USERS from './activity/specialPermissionUsers';
import { Buffer } from 'buffer';

export function renderInfo(props) {
  const { helpPages, match, currentActivity } = props;
  const isParent = (currentActivity, page) => {
    if (isParentProgramActivity(currentActivity.activityPlanTypeId)) {
      switch (get(currentActivity, 'activityPlanTypeId', 1)) {
        case 4:
          page += '.grant';
          return page;
        default:
          return page;
      }
    } else {
      return page;
    }
  };

  /* TODO: remove below 2 lines [linked activities checking]
   * remove isHelpTipProject variable
   * New grant application trial
   */
  const projectId = get(currentActivity, 'parentId', null);
  const isHelpTipTrialProject = grantTrialProgramProjectIds.includes(projectId);

  switch (match.path) {
    case '/dashboard':
      return getInfo(helpPages, 'dashboard.overview');
    case '/activities':
      return getInfo(helpPages, 'activity.home');
    case '/library':
      return getInfo(helpPages, 'library');
    case '/user-profile':
      return getInfo(helpPages, 'user.profile');
    case '/settings':
      return getInfo(helpPages, 'user.settings');
    case '/help':
      return getInfo(helpPages, 'user.help');
    case '/organisation':
      return getInfo(helpPages, 'account.profile');
    case '/organisation/:type':
      switch (match.params.type) {
        case 'profile':
          return getInfo(helpPages, 'account.profile');
        case 'settings':
          return getInfo(helpPages, 'account.settings');
        case 'activity':
          return getInfo(helpPages, 'account.activities');
        case 'goals':
          return getInfo(helpPages, 'account.goals');
        case 'core-data':
          return getInfo(helpPages, 'account.core-data');
        case 'user-management':
          return getInfo(helpPages, 'account.user-management');
        case 'venues':
          return getInfo(helpPages, 'account.venues');
        case 'contractors':
          return getInfo(helpPages, 'account.contractors');
        case 'communication':
          return getInfo(helpPages, 'account.communication');
        default:
          return getInfo(helpPages, 'account.profile');
      }
    case '/account':
      return getInfo(helpPages, 'account.profile.individual');
    case '/account/:type':
      switch (match.params.type) {
        case 'profile':
          return getInfo(helpPages, 'account.profile.individual');
        case 'settings':
          return getInfo(helpPages, 'account.settings.individual');
        case 'billing':
          return getInfo(helpPages, 'account.billing.individual');
        case 'goals':
          return getInfo(helpPages, 'account.goals.individual');
        default:
          return getInfo(helpPages, 'account.profile.individual');
      }
    case '/activity/:id/preview':
      return getInfo(helpPages, 'activity.report');
    case '/activity/:id/participants':
      return getInfo(helpPages, 'activity.participants');
    case '/activities/:id':
      return getInfo(helpPages, 'activity.goal', isHelpTipTrialProject);
    case '/activities/:id/:type':
      switch (match.params.type) {
        case 'goal':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.goal'),
            isHelpTipTrialProject
          );
        case 'objective':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.objective'),
            isHelpTipTrialProject
          );
        case 'evidence':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.evidence'),
            isHelpTipTrialProject
          );
        case 'theory-of-change':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.theoryOfChange'),
            isHelpTipTrialProject
          );
        case 'activity-plan':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.activityPlan'),
            isHelpTipTrialProject
          );
        case 'activity':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.activityPlan'),
            isHelpTipTrialProject
          );
        case 'evaluation':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.evaluationPlan'),
            isHelpTipTrialProject
          );
        case 'green-light':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.greenLight')
          );
        case 'documentation':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.documentation'),
            isHelpTipTrialProject
          );
        case 'report':
          return getInfo(
            helpPages,
            isParent(currentActivity, 'activity.report'),
            isHelpTipTrialProject
          );
        default:
          return getInfo(helpPages, 'activity.goal', isHelpTipTrialProject);
      }
    case '/reports/activities':
      return getInfo(helpPages, 'reports.activity-summary');
    case '/reports/:type':
      switch (match.params.type) {
        case 'activity-summary':
          return getInfo(helpPages, 'reports.activity-summary');
        case 'outcome-summary':
          return getInfo(helpPages, 'reports.outcomes-summary');
        case 'input-output-data':
          return getInfo(helpPages, 'reports.headline-data');
        case 'organisation-summary':
          return getInfo(helpPages, 'reports.organisation-summary');
        default:
          return getInfo(helpPages, 'reports.activity-summary');
      }
    default:
      return null;
  }
}

export const getPolicyName = (policyId) => {
  switch (policyId) {
    case 1:
      return 'Cultural';
    case 2:
      return 'Social';
    case 3:
      return 'Economic';
    case 4:
      return 'Governance';
    case 5:
      return 'Environmental';
    default:
      break;
  }
};

/**
 * Short your text with pop up message
 * @param {string} name
 * @param {number} length
 * @param {string} prefix
 * @param {string} separator
 * @param {boolean} isPopUp
 * @returns string
 */
export const threeDotSeparator = (props) => {
  let {
    name = '',
    length = 0,
    prefix = '',
    separator = '',
    isPopUp = false,
  } = props;
  const originalText = prefix ? prefix.concat(' ', separator, ' ', name) : name;
  let convertText = originalText;
  const textLength = originalText ? originalText.length : 0;
  if (textLength > length) {
    convertText = originalText.substring(0, length) + '...';
  }
  return isPopUp ? (
    <Popup
      className="activity-header-popup-text"
      content={originalText}
      trigger={<p>{convertText}</p>}
    />
  ) : (
    convertText
  );
};

export function getInfo(helpPages, type, isHelpTipTrialProject = false) {
  let data = {};

  /* TODO: remove if condition
   * New grant application trial
   */
  if (isHelpTipTrialProject) {
    data = find(grantTrialHelpTips, (item) => item.helpTipType === type);
  } else {
    data = find(helpPages, (o) => {
      return o.title === type;
    });
  }

  if (isEmpty(data)) {
    return null;
  }

  return data.content;
}

export function toSqlDate(date) {
  // date has to passed on this format -> DD-MM-YYYY
  date = date.split('-');

  return `${date[2]}-${date[1]}-${date[0]}`;
}

export const userAuth = {
  isAuthenticated: Boolean(localStorage.getItem(IS_AUTHENTICATED)) || false,
  isAuthToken: Boolean(localStorage.getItem(AUTH_TOKEN)) || false,
  async authenticate(cb) {
    try {
      this.isAuthenticated = true;
      await setIsAuthenticated(true);
    } catch (error) {
      console.error('authenticate -> error', error);
    }
    cb();
  },
  async signout() {
    try {
      this.isAuthenticated = false;
      await removeIsAuthenticated();
      await removeAuthenticatedToken();
      await removeRefreshToken();
    } catch (error) {
      console.error('signout -> error', error);
    }
  },
};

// eslint-disable-next-line
const abnNameCallback = (nameData) => nameData;

export async function getABN(name) {
  try {
    const url = `${ABN_LOOKUP_CONFIG.endpoint}?name=${name}&maxResults=100&callback=abnNameCallback&guid=${ABN_LOOKUP_CONFIG.token}`;
    const options = {
      method: 'GET',
      // mode: 'no-cors'
    };
    const response = await fetch(url, options);
    const responseText = await response.text();
    const data = eval(responseText);
    return data;
  } catch (error) {
    throw error;
  }
}

export const authMsg = (type, data) => {
  // if error coming from network issue && need to discard error message (no need to  display error message)
  if (data === 'NETWORK_DISCARD') {
    return false;
  }

  let msg,
    title = null;
  if (isArray(data)) {
    const [message, heading] = data;
    msg = message;
    title = heading;
  } else {
    msg = data;
  }
  if (userAuth.isAuthenticated) {
    switch (type) {
      case 'info':
        NotificationManager.info(msg, title);
        break;
      case 'success':
        NotificationManager.success(msg, title);
        break;
      case 'warning':
        NotificationManager.warning(msg, title);
        break;
      case 'error':
        NotificationManager.error(msg, title);
        break;
      default:
        break;
    }
  } else {
    return false;
  }
};

export const getPageDisable = (
  currentActivity,
  user,
  currentState,
  actual = false
) => {
  try {
    // grant permissions to specific users
    if (
      currentActivity &&
      Number(currentActivity.statusId) !== 3 &&
      WHITELIST_USERS.includes(user.email)
    ) {
      return false;
    }

    const member = find(currentActivity.members, (member) => {
      return Number(member.userId) === Number(user.id);
    });

    if (isEmpty(member)) {
      return true;
    }
    if (isString(member.permissions)) {
      member.permissions = JSON.parse(member.permissions);
    }

    const getPermission = find(member.permissions, (permission) => {
      return permission.state === currentState;
    });

    if (!getPermission.permission.write) {
      return true;
    }

    if (actual) {
      if (currentActivity.statusId == 3) {
        return true;
      } else {
        return false;
      }
    }

    // if activity status -> completed
    if (currentActivity.statusId == 3) return true;

    // if program activity status -> active
    if (
      isParentProgramActivity(currentActivity.activityPlanTypeId) &&
      currentState !== 'project.edit.documentation' &&
      currentState !== 'project.edit.evaluationPlan' &&
      currentState !== 'project.edit.activityPlan' &&
      currentState !== 'project.edit.notes' &&
      currentActivity.statusId >= 2
    ) {
      return true;
    }

    // if single or linked activity status -> active
    if (
      !isParentProgramActivity(currentActivity.activityPlanTypeId) &&
      currentState !== 'project.edit.documentation' &&
      currentState !== 'project.edit.evaluationPlan' &&
      currentState !== 'project.edit.notes' &&
      currentActivity.statusId >= 2 &&
      !actual
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
// Able to remove only when in development
export function getDisableAndRemoveAbility(
  activityStatus,
  userId,
  ownerId,
  haveResponses
) {
  let disableAndRemoveStatus = {
    isAbleToDeactivate: false,
    isAbleToRemove: false,
  };
  // not able to deactivate and remove [completed activity]
  if (activityStatus === 3) {
    disableAndRemoveStatus.isAbleToDeactivate = false;
    disableAndRemoveStatus.isAbleToRemove = false;
  } else if (userId === ownerId) {
    // owned by user
    if (haveResponses) {
      // has responses
      if (activityStatus === 1) {
        // in development
        disableAndRemoveStatus.isAbleToDeactivate = false;
        disableAndRemoveStatus.isAbleToRemove = false;
      } else if (activityStatus === 2) {
        // in active
        disableAndRemoveStatus.isAbleToDeactivate = false;
        disableAndRemoveStatus.isAbleToRemove = false;
      }
    } else {
      // no responses
      if (activityStatus === 1) {
        // in development
        disableAndRemoveStatus.isAbleToDeactivate = true;
        disableAndRemoveStatus.isAbleToRemove = true;
      }
      if (activityStatus === 2) {
        // in development
        disableAndRemoveStatus.isAbleToDeactivate = true;
        disableAndRemoveStatus.isAbleToRemove = false;
      }
    }
  } else if (userId !== ownerId) {
    // owned by other user
    if (haveResponses) {
      // has responses
      disableAndRemoveStatus.isAbleToDeactivate = false;
      disableAndRemoveStatus.isAbleToRemove = false;
    } else {
      if (activityStatus === 1) {
        // in development
        disableAndRemoveStatus.isAbleToDeactivate = true;
        disableAndRemoveStatus.isAbleToRemove = false; // <- TODO: [co sign-in]
      } else if (activityStatus === 2) {
        // in active
        disableAndRemoveStatus.isAbleToDeactivate = true;
        disableAndRemoveStatus.isAbleToRemove = false;
      }
    }
  }

  return disableAndRemoveStatus;
}

export const getActivityStatus = (statusId) => {
  switch (statusId) {
    case 1:
      return 'warning';
    case 2:
      return 'success';
    case 3:
      return 'completed';
    default:
      return 'warning';
  }
};

export const getGoalStatus = (type) => {
  switch (type) {
    case 'DEVELOPMENT':
      return 'warning';
    case 'ACTIVE':
      return 'success';
    case 'COMPLETED':
      return 'completed';
    default:
      return 'warning';
  }
};

export const getScaleStatus = (scale) => {
  switch (scale) {
    case 'post':
      return 'Post :';
    case 'pre':
      return 'Pre :';
    default:
      return '';
  }
};

export const getCriticalStatus = (type) => {
  switch (type) {
    case 'HIGH':
      return 'red';
    case 'MEDIUM':
      return 'orange';
    default:
      return 'orange';
  }
};

export const getInvoicesStatus = (status) => {
  switch (status) {
    case 'paid':
      return 'Paid';
    case 'pending':
      return 'Pending';
    case 'overdue':
      return 'Over Due';
    case 'fail':
      return 'Fail';
    case 'closed':
      return 'Closed';
    default:
      return '';
  }
};

export const getCommonError = (errorResponse) => {
  try {
    // show network error (not discard network error)
    if (errorResponse === 'NETWORK_ERROR') {
      return 'Network Connection Error!';
    }

    // network error discard (to prevent show network error message)
    if (errorResponse === 'NETWORK_DISCARD') {
      return 'NETWORK_DISCARD';
    }

    const errorData = errorResponse.response;

    if (!errorData) {
      return 'Oops, Something went wrong!';
    }

    if (errorData.data.message) {
      return errorData.data.message;
    }

    if (errorData.status) {
      if (errorData.status === 400) {
        return 'Your entered value is not valid or required, Please check again!';
      } else if (errorData.status === 401) {
        return [
          "You were idle too long. You'll need to login again.",
          "You've Timed Out!",
        ];
      } else if (errorData.status === 403) {
        return (
          errorData.data.message ||
          "You don't have permission to make this call"
        );
      } else if (errorData.status === 404) {
        return 'Sorry, Record not found';
      } else if (errorData.status === 405) {
        return 'Request is not allowed';
      } else if (errorData.status === 500) {
        return 'Oops, Something went wrong!';
      } else {
        return 'Oops, Something went wrong!';
      }
    }

    return 'Oops, Something went wrong!';
  } catch (error) {
    console.error('getCommonError -> error', error);
    return 'Oops, Something went wrong!';
  }
};

export const getSurveyLink = (
  projectId,
  evalPlanId,
  objectives,
  ipLock = false
) => {
  const code = btoa(
    // encode a string to base64 format
    JSON.stringify({
      projectId,
      evalPlanId,
      objectives,
      ipLock,
    })
  );
  return `${window.location.origin}/survey/${code}`;
};

export const getPrintableSurveyToken = (
  projectId,
  evalPlanId,
  objectives,
  ipLock = false
) => {
  const token = btoa(
    // encode a string to base64 format
    JSON.stringify({
      projectId,
      evalPlanId,
      objectives,
      ipLock,
    })
  );
  return token;
};

export const getPreviewSurveyLink = (
  projectId,
  evalPlanId,
  objectives,
  preview = false
) => {
  const code = btoa(
    // encode a string to base64 format
    JSON.stringify({
      projectId,
      evalPlanId,
      objectives,
      ipLock: false,
      preview,
    })
  );
  return `${window.location.origin}/preview-survey/${code}`;
};

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

const sanitizeHTML = (HTML) => {
  return xss(HTML, {
    whiteList: {},
    stripIgnoreTag: true,
    // stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
  });
};

export const noteReplace = (currentActivity, note) => {
  if (note === null) return '';
  note = note.replace(
    /\[userName\]/gi,
    sanitizeHTML(get(currentActivity, 'owner.name', '-'))
  );

  note = note.replace(
    /\[interviewerName\]/gi,
    sanitizeHTML(get(currentActivity, 'owner.name', '-'))
  );

  note = note.replace(
    /\[activityName\]/gi,
    sanitizeHTML(get(currentActivity, 'name', '-'))
  );

  note = note.replace(
    /\[organisationName\]/gi,
    sanitizeHTML(get(currentActivity, 'owner.organisation.name', '-'))
  );

  return note;
};

export const removeEditable = (note) => {
  if (note === null) return '';
  note = replaceAll(
    note,
    '<span class="content-highlight" contenteditable="true">',
    '<span>'
  );

  return note;
};

export function projectValidation(activityProject) {
  try {
    const workingProject = cloneDeep(activityProject);

    let goalStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Goal'
    );

    let objectiveStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Objective'
    );
    let evidenceStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Evidence'
    );
    let theoryStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Theory of Change'
    );
    let activityPlanStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Activity Plan' || o.section === 'Activity Plan'
    );
    let evaluationStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Evaluation'
    );
    let documentationStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Documentation'
    );

    let reportStatus = find(
      workingProject.completionProgress,
      (o) => o.section === 'Report'
    );

    // need to have all status otherwise data is not loaded properly or data loading status
    if (
      !isEmpty(goalStatus) &&
      !isEmpty(objectiveStatus) &&
      !isEmpty(evidenceStatus) &&
      !isEmpty(theoryStatus) &&
      !isEmpty(activityPlanStatus) &&
      !isEmpty(evaluationStatus) &&
      !isEmpty(documentationStatus) &&
      !isEmpty(reportStatus)
    ) {
      objective();
      evidence();
      theoryOfChange();
      activityPlan();
      evaluation();
      documentation();
      report();
    }

    function objective() {
      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          if (!isEmpty(workingProject.parentOutcomes)) {
            const outcomesSelected = map(
              workingProject.parentOutcomes,
              (outcome) => outcome.policyValue
            );
            const domainsSelected = map(
              workingProject.parentDomains,
              (domain) => domain.policyId
            );
            if (workingProject.primaryPolicyId)
              domainsSelected.push(workingProject.primaryPolicyId);
            if (workingProject.secondaryPolicyId)
              domainsSelected.push(workingProject.secondaryPolicyId);

            const diff = difference(
              sortedUniq(domainsSelected),
              sortedUniq(outcomesSelected)
            );

            if (!isEmpty(diff)) {
              if (goalStatus.status == 2) {
                objectiveStatus.status = 1;
              } else {
                objectiveStatus.status = 0;
              }
            } else {
              objectiveStatus.status = 2;
            }
          } else {
            if (goalStatus.status == 2) {
              objectiveStatus.status = 1;
            } else {
              objectiveStatus.status = 0;
            }
          }
        } else {
          if (
            workingProject.objective &&
            workingProject.objective.length == 1 &&
            !workingProject.secondaryPolicyId
          ) {
            objectiveStatus.status = 2;
          } else if (
            workingProject.objective &&
            workingProject.objective.length == 2 &&
            workingProject.secondaryPolicyId
          ) {
            objectiveStatus.status = 2;
          } else {
            if (goalStatus.status == 2) {
              objectiveStatus.status = 1;
            } else {
              objectiveStatus.status = 0;
            }
          }
        }
      }
    }

    function evidence() {
      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          if (isEmpty(workingProject.evidence)) {
            if (goalStatus.status === 2) {
              evidenceStatus.status = 1;
            } else {
              evidenceStatus.status = 0;
            }
          } else {
            evidenceStatus.status = 2;
          }
        } else {
          if (workingProject.objective && workingProject.objective.length) {
            if (
              workingProject.objective.length == 1 &&
              !workingProject.secondaryPolicyId
            ) {
              if (workingProject.objective[0].evidence.length > 0) {
                evidenceStatus.status = 2;
              } else {
                if (goalStatus.status == 2) {
                  evidenceStatus.status = 1;
                } else {
                  evidenceStatus.status = 0;
                }
              }
            } else if (
              workingProject.objective.length == 2 &&
              workingProject.secondaryPolicyId
            ) {
              if (
                typeof workingProject.objective[0].evidence === 'undefined' ||
                typeof workingProject.objective[0].evidence === 'undefined'
              ) {
                if (goalStatus.status == 2) {
                  evidenceStatus.status = 1;
                } else {
                  evidenceStatus.status = 0;
                }
                return;
              }
              if (
                workingProject.objective[0].evidence.length > 0 &&
                workingProject.objective[1].evidence.length > 0
              ) {
                evidenceStatus.status = 2;
              } else {
                if (goalStatus.status == 2) {
                  evidenceStatus.status = 1;
                } else {
                  evidenceStatus.status = 0;
                }
              }
            } else {
              if (goalStatus.status == 2) {
                evidenceStatus.status = 1;
              } else {
                evidenceStatus.status = 0;
              }
            }
          }
        }
      }
    }

    function theoryOfChange() {
      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          if (
            isString(workingProject.projectTocDo) &&
            isString(workingProject.projectTocKnown) &&
            workingProject.projectTocDo != '' &&
            workingProject.projectTocKnown != ''
          ) {
            theoryStatus.status = 2;
          } else {
            if (goalStatus.status === 2) {
              theoryStatus.status = 1;
            } else {
              theoryStatus.status = 0;
            }
          }
        } else {
          if (workingProject.objective && workingProject.objective.length) {
            if (
              workingProject.objective.length == 1 &&
              !workingProject.secondaryPolicyId
            ) {
              if (
                workingProject.objective[0].tocDo != '' &&
                workingProject.objective[0].tocKnown != ''
              ) {
                theoryStatus.status = 2;
              } else {
                if (goalStatus.status == 2) {
                  theoryStatus.status = 1;
                } else {
                  theoryStatus.status = 0;
                }
              }
            } else if (
              workingProject.objective.length == 2 &&
              workingProject.secondaryPolicyId
            ) {
              if (workingProject.objective.length == 2) {
                if (
                  workingProject.objective[0].tocDo != '' &&
                  workingProject.objective[0].tocKnown != '' &&
                  workingProject.objective[1].tocDo != '' &&
                  workingProject.objective[1].tocKnown != ''
                ) {
                  theoryStatus.status = 2;
                } else {
                  if (goalStatus.status == 2) {
                    theoryStatus.status = 1;
                  } else {
                    theoryStatus.status = 0;
                  }
                }
              }
            } else {
              if (goalStatus.status == 2) {
                theoryStatus.status = 1;
              } else {
                theoryStatus.status = 0;
              }
            }
          }
        }
      }
    }

    function activityPlan() {
      var isValid = true;
      var fields = null;
      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        const isProjected = workingProject.statusId === 1;
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          // activity plan
          isValid = isActivityPlanValid(4);

          if (isValid) {
            if (workingProject.statusId === 2) {
              if (isEmpty(workingProject.linkedActivities)) {
                activityPlanStatus.status = 1;
              } else {
                const linkedStatus = compact(
                  map(workingProject.linkedActivities, (act) => {
                    if (act.hasOwnProperty('deleted')) {
                      if (act.deleted) return;
                      if (act.statusId === 3 && !act.childApproved) {
                        return 2;
                      }
                      return act.statusId;
                    }
                    return 1;
                  })
                );

                const diff = difference(sortedUniq(linkedStatus), [3]);

                if (!isEmpty(diff)) {
                  activityPlanStatus.status = 1;
                } else {
                  activityPlanStatus.status = 2;
                }
              }
            } else {
              activityPlanStatus.status = 2;
            }
          } else {
            if (goalStatus.status === 2) {
              activityPlanStatus.status = 1;
            } else {
              activityPlanStatus.status = 0;
            }
          }
        } else {
          const outputValidity = getFormatOutputValidity(
            workingProject,
            isProjected
          );

          if (!outputValidity) {
            isValid = false;
          }

          fields = loadValidators(4);
          fields.forEach(function (item) {
            var validator = eval('workingProject.' + item);
            if (item == 'name') {
              if (validator == 'Unnamed') {
                isValid = false;
              }
            }
            if (validator == '' || validator == null) {
              isValid = false;
            }
          });

          if (isValid) {
            activityPlanStatus.status = 2;
          } else {
            if (goalStatus.status == 2) {
              activityPlanStatus.status = 1;
            } else {
              activityPlanStatus.status = 0;
            }
          }
        }
      }
    }
    function evaluation() {
      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          if (
            workingProject.statusId === 1 &&
            workingProject.parentOutcomes.length === 0
          ) {
            evaluationStatus.status = 0;
          } else if (
            workingProject.statusId === 1 &&
            (objectiveStatus.status !== 2 ||
              evidenceStatus.status !== 2 ||
              theoryStatus.status !== 2 ||
              (activityPlanStatus.status !== 2 &&
                workingProject.statusId === 1))
          ) {
            evaluationStatus.status = 1;
          } else if (
            workingProject.statusId === 1 &&
            objectiveStatus.status === 2 &&
            evidenceStatus.status === 2 &&
            theoryStatus.status === 2 &&
            activityPlanStatus.status === 2
          ) {
            evaluationStatus.status = 2;
            if (workingProject.approvalMethod === 1) {
              if (workingProject.approvedDate) {
                evaluationStatus.status = 2;
              } else {
                evaluationStatus.status = 1;
              }
            }
          } else if (workingProject.statusId === 2) {
            // check management code enabled
            if (!isEmpty(workingProject.codeLabel)) {
              if (!isEmpty(workingProject.ppmsCode)) {
                evaluationStatus.status = 2;
              } else {
                evaluationStatus.status = 1;
              }
            } else {
              evaluationStatus.status = 2;
            }
          }
        } else {
          if (!isEmpty(workingProject.objective)) {
            if (!isEmpty(workingProject.evaluationPlans)) {
              let getEvalPlans = map(
                get(workingProject, 'evaluationPlans', []),
                (plan) => {
                  return map(plan.evaluationQuestions, (e) => e.objectiveId);
                }
              );
              if (!isEmpty(getEvalPlans)) {
                getEvalPlans = uniq(flattenDeep(getEvalPlans));
              }
              const objectiveIds = map(workingProject.objective, (e) => e.id);

              if (isEqual(objectiveIds.sort(), getEvalPlans.sort())) {
                let evalPlans = [];
                if (workingProject.statusId > 1) {
                  evalPlans = workingProject.evaluationPlans.find(
                    (plan) =>
                      (!plan.dateMeasured && plan.methodId !== 9) ||
                      (plan.methodId === 9 &&
                        plan.isNewCsvFeature &&
                        !plan.methodReason) ||
                      (plan.methodId === 9 &&
                        !plan.isNewCsvFeature &&
                        !isEmpty(plan.evaluationQuestions) &&
                        !plan.evaluationQuestions[0].methodReason)
                  );
                }
                // need to get approval from approver for green light
                if (workingProject.approvalMethod === 1) {
                  // green light approved activity
                  if (workingProject.approvedDate) {
                    if (
                      (workingProject.statusId > 1 && !isEmpty(evalPlans)) ||
                      objectiveStatus.status !== 2 ||
                      evidenceStatus.status !== 2 ||
                      theoryStatus.status !== 2 ||
                      (activityPlanStatus.status !== 2 &&
                        workingProject.statusId === 1)
                    ) {
                      evaluationStatus.status = 1;
                    } else {
                      // check management code enabled
                      if (
                        !isEmpty(workingProject.codeLabel) &&
                        workingProject.statusId > 1
                      ) {
                        if (!isEmpty(workingProject.ppmsCode)) {
                          evaluationStatus.status = 2;
                        } else {
                          evaluationStatus.status = 1;
                        }
                      } else {
                        evaluationStatus.status = 2;
                      }
                    }
                  } else {
                    if (goalStatus.status == 2) {
                      evaluationStatus.status = 1;
                    } else {
                      evaluationStatus.status = 0;
                    }
                  }
                } else {
                  if (
                    (workingProject.statusId > 1 && !isEmpty(evalPlans)) ||
                    objectiveStatus.status !== 2 ||
                    evidenceStatus.status !== 2 ||
                    theoryStatus.status !== 2 ||
                    (activityPlanStatus.status !== 2 &&
                      workingProject.statusId === 1)
                  ) {
                    evaluationStatus.status = 1;
                  } else {
                    if (
                      !isEmpty(workingProject.codeLabel) &&
                      workingProject.statusId > 1
                    ) {
                      if (!isEmpty(workingProject.ppmsCode)) {
                        evaluationStatus.status = 2;
                      } else {
                        evaluationStatus.status = 1;
                      }
                    } else {
                      evaluationStatus.status = 2;
                    }
                  }
                }
              } else {
                if (goalStatus.status == 2) {
                  evaluationStatus.status = 1;
                } else {
                  evaluationStatus.status = 0;
                }
              }
            } else {
              if (goalStatus.status == 2) {
                evaluationStatus.status = 1;
              } else {
                evaluationStatus.status = 0;
              }
            }
          } else {
            evaluationStatus.status = 0;
          }
        }
      }
    }

    async function documentation() {
      documentationStatus.status = 0;

      if (
        workingProject.completionProgress &&
        workingProject.completionProgress.length
      ) {
        if (workingProject.statusId > 1) {
          documentationStatus.status = 1;
          let isValid = true;
          if (!workingProject.summary || workingProject.summary == '') {
            isValid = false;
          }
          if (!workingProject.reflection || workingProject.reflection == '') {
            isValid = false;
          }
          if (isValid) {
            documentationStatus.status = 2;
          }
        } else {
          documentationStatus.status = 0;
        }
      }
    }

    function report() {
      reportStatus.status = 0;

      if (
        (goalStatus.status === 2 && workingProject.objective.length !== 0) ||
        (goalStatus.status === 2 &&
          isParentProgramActivity(workingProject.activityPlanTypeId) &&
          !isEmpty(workingProject.parentOutcomes))
      ) {
        reportStatus.status = 1;
      }

      if (
        goalStatus.status === 2 &&
        objectiveStatus.status === 2 &&
        evidenceStatus.status === 2 &&
        theoryStatus.status === 2 &&
        activityPlanStatus.status === 2 &&
        evaluationStatus.status === 2
      ) {
        if (workingProject.statusId === 1) {
          reportStatus.status = 2;
        } else if (
          workingProject.statusId > 1 &&
          documentationStatus.status === 2
        ) {
          reportStatus.status = 2;
        }
      }
    }

    function isActivityPlanValid(section) {
      let isValid = true;
      const fields = loadValidators(section);

      fields.forEach(function (item) {
        var validator = eval('workingProject.' + item);
        if (item == 'name' && validator == 'Unnamed') {
          isValid = false;
        }

        if (!validator) {
          const outputFormatGroup = get(
            workingProject,
            'eventDetail.outputFormat',
            null
          );
          if (outputFormatGroup) {
            let currentOutput;
            for (const formatItem of outputFormatGroup.format) {
              if (item === formatItem.name) {
                currentOutput = workingProject.output.find(
                  (outputItem) =>
                    outputItem.outputFormatId === formatItem.outputFormatId
                );
                break;
              }
            }
            if (currentOutput) validator = currentOutput.projectedValue;
          }

          if (
            item == 'projectedTotalGrantsPool' &&
            (validator == '0.00' || validator == '0')
          ) {
            isValid = false;
          }

          if (!validator) isValid = false;
        }
      });

      return isValid;
    }

    function loadValidators(section) {
      var fields = null;
      if (section == 4) {
        fields = [
          'name',
          'greenLightNotifyDate',
          'eventStartDate',
          'eventEndDate',
          'projectedBudget',
          'projectedCost',
          'description',
        ];

        if (isParentProgramActivity(workingProject.activityPlanTypeId)) {
          if (isFestivalProgramActivity(workingProject.activityPlanTypeId)) {
            fields.push('parentFestivals');
            fields.push('projectedNumberOfFestivalActivities');
            fields.push('projectedTotalNumberOfParticipants');
          }
          if (isGrantProgramActivity(workingProject.activityPlanTypeId)) {
            fields.push('projectedTotalGrantsPool');
            fields.push('grantApplicationClosingDate');
          }
          if (isMapProgramActivity(workingProject.activityPlanTypeId)) {
            fields.push('projectedNumberOfProgramActivities');
          }
        } else {
          // if liked activity push projectedGrantAmount field to validators
          if (
            workingProject.isLinkedActivity &&
            isGrantLinkedActivity(
              workingProject.parentActivity.activityPlanTypeId,
              workingProject.isLinkedActivity
            )
          ) {
            fields.push('projectedGrantAmount');
          }
          fields.push('eventDetailId');
        }
        return fields;
      }
      return null;
    }
    return workingProject;
  } catch (e) {
    console.error('🚀 ~ file: index.js:1341 ~ projectValidation ~ e:', e);
  }
}

/**
 * Project archive validations
 * @param {*} project
 */
export function getIssuesInProject(project, usingParent = false) {
  const validationMessages = [];
  let validationLink = [];
  let isValid = true;

  if (isParentProgramActivity(project.activityPlanTypeId)) {
    if (isEmpty(project.parentOutcomes)) {
      isValid = false;
      validationMessages.push('Objective: Please select the objectives');
    }
  } else {
    if (project.objective && !project.objective.length) {
      validationMessages.push('Objective: Please select the objectives');
      return {
        isValid: false,
        validationMessages,
      };
    }
  }

  const linkedActivities =
    isParentProgramActivity(project.activityPlanTypeId) &&
    project.linkedActivities
      ? project.linkedActivities
      : [];

  if (!project.eventStartDate || !project.eventEndDate) {
    isValid = false;
    validationMessages.push('Activity plan: Please enter your activity dates');
  }

  if (project.statusId === 1) {
    isValid = false;
    validationMessages.push('Evaluation: Please approve this activity');
    return {
      isValid,
      validationMessages,
    };
  }

  if (
    isParentProgramActivity(project.activityPlanTypeId) &&
    linkedActivities.length === 0
  ) {
    isValid = false;
    validationMessages.push(
      'Activity Plan: Please enter at least one linked activity'
    );
  }

  // check projected contractors available
  if (!isEmpty(project.contractors)) {
    let isProjectedContractorsAvailable = false;
    let isEmptyProjectContractorCostAvailable = false;

    map(project.contractors, (contractor) => {
      if (contractor.contractorCategory === 'projected') {
        isProjectedContractorsAvailable = true;
      } else {
        if (!contractor.cost) {
          isEmptyProjectContractorCostAvailable = true;
        }
      }
    });

    if (isProjectedContractorsAvailable) {
      isValid = false;
      validationMessages.push(
        'Activity Plan: Please remove any projected contractors or complete all required information'
      );
    }

    if (isEmptyProjectContractorCostAvailable) {
      isValid = false;
      validationMessages.push(
        'Activity Plan: Please enter contractor cost for contractors'
      );
    }
  }

  //check the venues available

  if (isEmpty(project.venues)) {
    validationMessages.push('Activity Plan: Please enter venue details');
    isValid = false;
  }

  // check projected partners available
  if (!isEmpty(project.partners)) {
    let isPartnerCostRequired = false;
    map(project.partners, (partner) => {
      if (
        partner.supportType !== 'NETWORK' &&
        !partner.cost
        //  ||
        // partner.cost === '0.00'
      ) {
        isPartnerCostRequired = true;
      }
    });

    if (isPartnerCostRequired) {
      isValid = false;
      validationMessages.push(
        'Activity Plan: Please enter contribution costs for all partners'
      );
    }
  }

  if (isParentProgramActivity(project.activityPlanTypeId)) {
    if (!usingParent) {
      // check evaluation
      // if (isEmpty(project.linkedActivities)) {
      //   isValid = false;
      //   validationMessages.push(
      //     'Evaluation: Please enter and accept at least one linked activity'
      //   );
      // }
      if (!isEmpty(project.linkedActivities)) {
        const linkedStatus = compact(
          map(project.linkedActivities, (act) => {
            if (act.hasOwnProperty('deleted')) {
              if (act.deleted) return;
              if (act.statusId === 3 && !act.childApproved) {
                return 2;
              }
              return act.statusId;
            }
            return 1;
          })
        );
        const diff = difference(sortedUniq(linkedStatus), [3]);
        if (!isEmpty(diff)) {
          isValid = false;
          validationMessages.push(
            'Activity Plan: Please ensure all linked activities are accepted'
          );
        }
      }
    }
  } else {
    if (!project.objective && !project.objective.length) {
      let isValid = false;
      validationMessages.push('Objective: Please select the objectives');
      return {
        isValid,
        validationMessages,
      };
    }

    const outputFormatGroup = get(project, 'eventDetail.outputFormat', null);

    if (outputFormatGroup) {
      const isProjected = project.statusId === 1;
      const outputFormatValid = getFormatOutputValidity(project, isProjected);

      if (isProjected && !outputFormatValid) {
        isValid = false;
        validationMessages.push(
          'Activity plan: Please enter the participant numbers for this activity'
        );
      } else if (!outputFormatValid) {
        isValid = false;
        validationMessages.push(
          'Activity plan: Please enter a figure in session field'
        );
      }
    }

    // validations for series evaluations
    if (!isEmpty(project.evaluationPlans)) {
      let series = [];
      map(project.evaluationPlans, (plan) => {
        if (plan.seriesParentId) {
          map(plan.evaluationQuestions, (evaluation) => {
            if (evaluation.methodId === 9) {
              if (
                plan.methodReason === null ||
                isEmpty(plan.assessmentTypes) ||
                plan.actualParticipantsEvaluated === null ||
                evaluation.selfAssessmentReason === null
              ) {
                if (
                  series.filter((e) => e.id === plan.seriesParentId).length ===
                    0 &&
                  series.filter((e) => e.name === plan.name).length === 0
                ) {
                  series.push({
                    id: plan.seriesParentId,
                    isValid: false,

                    message: `Evaluation: "${evaluation.outcome.title}" in "${plan.name}" requires a self-assessment evaluation to be completed. Click View Details and answer the self-assessment questions.`,
                  });
                }
              }
            } else if (
              !Boolean(evaluation.actualParticipantsEvaluated) &&
              plan.collectionMethod !== 'LINK'
            ) {
              if (
                series.filter((e) => e.id === plan.seriesParentId).length ===
                  0 &&
                series.filter((e) => e.name === plan.name).length === 0
              ) {
                series.push({
                  id: plan.seriesParentId,
                  isValid: false,
                  message: `Evaluation: "${plan.name}" has no responses recorded for “${evaluation.outcome.title}”. Please enter responses or complete a self-assessment for this plan.`,
                });
              }
            }
          });
        }
      });
      map(series, (seriesPlan) => {
        if (!seriesPlan.isValid) {
          validationMessages.push(seriesPlan.message);
        }
      });
    }

    if (!isEmpty(project.evaluationPlans)) {
      map(project.evaluationPlans, (plan) => {
        // if not self-assessed evaluation plan
        if (plan.methodId !== 9) {
          if (!plan.isDisabled) {
            if (!plan.dateMeasured || plan.dateMeasured == '') {
              isValid = false;
              let planName = plan.seriesParentId
                ? `${plan.name} (${plan.seriesPosition})`
                : `${plan.name}`;
              validationMessages.push(
                `Evaluation: Please confirm whether "${planName}" evaluation was completed.`
              );
            }
            if (isEmpty(plan.participantGroups)) {
              isValid = false;
              validationMessages.push(
                `Evaluation: Please select at least one evaluation participants group for "${plan.name}"`
              );
            }
          }
        }

        map(plan.evaluationQuestions, (evaluation) => {
          if (plan.isDisabled && !evaluation.haveResponses) {
            return;
          }

          if (evaluation.methodId === 9) {
            if (
              (plan.methodReason === null ||
                isEmpty(plan.assessmentTypes) ||
                plan.actualParticipantsEvaluated === null ||
                evaluation.selfAssessmentReason === null) &&
              !plan.seriesParentId
            ) {
              isValid = false;
              validationMessages.push(
                `Evaluation: "${evaluation.outcome.title}" in "${plan.name}" requires a self-assessment evaluation to be completed. Click View Details and answer the self-assessment questions.`
              );
            }
          } else if (
            !Boolean(evaluation.actualParticipantsEvaluated) &&
            plan.collectionMethod !== 'LINK' &&
            !plan.seriesParentId
          ) {
            isValid = false;
            validationMessages.push(
              `Evaluation: "${plan.name}" has no responses recorded for “${evaluation.outcome.title}”. Please enter responses or complete a self-assessment for this plan.`
            );
          } else if (
            !Boolean(evaluation.actualParticipantsEvaluated) &&
            plan.collectionMethod === 'LINK'
          ) {
            validationLink.push(plan.name);
          }

          if (Boolean(evaluation.actualParticipantsEvaluated)) {
            const [one, two] = evaluation.participantResponse;
            // check first (post) array responses
            if (isArray(one)) {
              const participantResponses = sum(one);

              // FIXME: Need to check this participant responses condition
              // if (evaluation.methodId === 9) {
              //   if (participantResponses < 1) {
              //     console.log('evaluation', one);
              //     console.log('participantResponses: ', participantResponses);
              //     isValid = false;
              //     validationMessages.push(
              //       `Evaluation: Number of participants who completed the evaluation does not match
              //       the number of participant (POST) responses considered for "${evaluation.outcome.title}" in "${plan.name}". Please contact Takso admin for more information`
              //     );
              //   }
              // } else {
              if (
                evaluation.methodId !== 9 &&
                participantResponses != evaluation.actualParticipantsEvaluated
              ) {
                isValid = false;
                validationMessages.push(
                  `Evaluation: Number of participants who completed the evaluation does not match 
                    the number of participant (POST) responses entered for "${evaluation.outcome.title}" in "${plan.name}". Please contact Takso admin for more information`
                );
              }
              // }
            }

            // check second (pre) array responses
            if (isArray(two)) {
              const participantResponses = sum(two);

              // FIXME: Need to check this participant responses condition
              // if (evaluation.methodId === 9) {
              //   if (participantResponses < 1) {
              //     console.log('evaluation', two);
              //     console.log('participantResponses: ', participantResponses);
              //     isValid = false;
              //     validationMessages.push(
              //       `Evaluation: Number of participants who completed the evaluation does not match
              //       the number of participant (PRE) responses considered for "${evaluation.outcome.title}" in "${plan.name}". Please contact Takso admin for more information`
              //     );
              //   }
              // } else {
              if (
                evaluation.methodId !== 9 &&
                participantResponses != evaluation.actualParticipantsEvaluated
              ) {
                isValid = false;
                validationMessages.push(
                  `Evaluation: Number of participants who completed the evaluation does not match 
                    the number of participant (PRE) responses entered for "${evaluation.outcome.title}" in "${plan.name}". Please contact Takso admin for more information`
                );
              }
              // }
            }
          }
        });
      });
    }

    validationLink = uniq(validationLink);

    if (!isEmpty(validationLink)) {
      for (const evalName of validationLink) {
        isValid = false;
        if (!validationLink.seriesParentId) {
          validationMessages.push(
            `Evaluation : "${evalName}" has no responses recorded. Please enter responses or complete a self-assessment for this plan.`
          );
        }
      }
    }
  }

  if (!project.summary || project.summary == '') {
    isValid = false;
    validationMessages.push(
      'Documentation: Please enter the final activity description'
    );
  }
  if (!project.reflection || project.reflection == '') {
    isValid = false;
    validationMessages.push(
      'Documentation: Please complete your reflection of this activity'
    );
  }

  return {
    isValid,
    validationMessages,
  };
}

/**
 * Project green-light validations
 * @param {*} project
 */
export function getIssuesInActivities(project) {
  const validationMessages = [];
  let isValid = true;

  if (!project.goal) {
    validationMessages.push('Goal: Please enter a goal');
    return {
      isValid: false,
      validationMessages,
    };
  }

  if (isParentProgramActivity(project.activityPlanTypeId)) {
    if (isEmpty(project.parentOutcomes)) {
      isValid = false;
      validationMessages.push('Objective: Please select the objectives');
    }

    if (!isEmpty(project.parentOutcomes)) {
      const outcomesSelected = map(
        project.parentOutcomes,
        (outcome) => outcome.policyValue
      );
      const domainsSelected = map(
        project.parentDomains,
        (domain) => domain.policyId
      );
      if (project.primaryPolicyId)
        domainsSelected.push(project.primaryPolicyId);

      if (project.secondaryPolicyId)
        domainsSelected.push(project.secondaryPolicyId);

      const diff = difference(
        sortedUniq(domainsSelected),
        sortedUniq(outcomesSelected)
      );

      if (!isEmpty(diff)) {
        isValid = false;
        validationMessages.push(
          'Objective: Please select at least one objective for each domain'
        );
      }
    }

    if (isEmpty(project.evidence)) {
      isValid = false;
      validationMessages.push('Evidence: Please enter evidence');
    }

    if (!project.projectTocKnown || !project.projectTocDo) {
      isValid = false;
      validationMessages.push(
        'Theory of change: Please enter or complete the theory of Change'
      );
    }

    if (project.name == '' || project.name == 'Unnamed') {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please give your activity a suitable name!'
      );
    }
    if (!project.description || project.description == '') {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please enter an activity summary'
      );
    }
    if (project.statusId < 2 && !project.greenLightNotifyDate) {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please select the green light date'
      );
    }
    if (!project.eventStartDate || !project.eventEndDate) {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please enter your activity dates'
      );
    }

    /** Program activity validations for projected outputs **/

    const outputFormatGroup = get(project, 'eventDetail.outputFormat', null);

    if (outputFormatGroup) {
      let currentOutput;
      for (const formatItem of outputFormatGroup.format) {
        currentOutput = project.output.find(
          (outputItem) =>
            outputItem.outputFormatId === formatItem.outputFormatId
        );
        const value = get(currentOutput, 'projectedValue', '');
        if (project.activityPlanTypeId !== 4 && !value) {
          switch (formatItem.name) {
            case 'projectedNumberOfFestivalActivities':
            case 'projectedTotalNumberOfParticipants':
            case 'projectedNumberOfProgramActivities':
              isValid = false;
              validationMessages.push(
                `Activity plan: Please enter your ${formatItem.label}`
              );
              break;
            default:
          }
        } else if (project.statusId < 2) {
          if (
            formatItem.name === 'projectedTotalGrantsPool' &&
            (!value || value === '0.00' || value === '0')
          ) {
            isValid = false;
            validationMessages.push(
              'Activity plan: Please enter your projected total grants pool'
            );
          }

          if (formatItem.name === 'grantApplicationClosingDate' && !value) {
            isValid = false;
            validationMessages.push(
              'Activity plan: Please select your grants application closing date'
            );
          }
        }
      }
    }

    // festival program
    if (project.activityPlanTypeId === 3 && isEmpty(project.parentFestivals)) {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please select at least one festival type'
      );
    }
  } else {
    if (project.objective && !project.objective.length) {
      validationMessages.push('Objective: Please select the objectives');
      return {
        isValid: false,
        validationMessages,
      };
    }
    if (project.objective.length == 1 && project.secondaryPolicyId) {
      isValid = false;
      validationMessages.push(
        'Objective: Please specify your secondary objective'
      );
    }

    if (project.objective.length == 1 && !project.secondaryPolicyId) {
      if (project.objective[0].evidence.length == 0) {
        isValid = false;
        validationMessages.push(
          'Evidence: Please enter evidence for ' +
            project.objective[0].outcome.title
        );
      }
    } else if (project.objective.length == 2 && project.secondaryPolicyId) {
      if (project.objective[0].evidence.length == 0) {
        isValid = false;
        validationMessages.push(
          'Evidence: Please enter evidence for ' +
            project.objective[0].outcome.title
        );
      }
      if (project.objective[1].evidence.length == 0) {
        isValid = false;
        validationMessages.push(
          'Evidence: Please enter evidence for ' +
            project.objective[1].outcome.title
        );
      }
    }

    if (!project.objective[0].tocKnown || !project.objective[0].tocDo) {
      isValid = false;
      validationMessages.push(
        'Theory of Change: Please enter or complete the theory of Change for ' +
          project.objective[0].outcome.title
      );
    }
    if (
      project.objective.length > 1 &&
      (!project.objective[1].tocKnown || !project.objective[1].tocDo)
    ) {
      isValid = false;
      validationMessages.push(
        'Theory of Change: Please enter or complete the theory of Change for ' +
          project.objective[1].outcome.title
      );
    }

    if (project.name === '' || project.name == 'Unnamed') {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please give your activity a suitable name'
      );
    }
    if (!project.eventDetailId) {
      isValid = false;
      validationMessages.push('Activity plan: Please select the activity type');
    }
    if (!project.description || project.description == '') {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please enter an activity summary'
      );
    }
    if (project.statusId < 2 && !project.greenLightNotifyDate) {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please select the green light date'
      );
    }
    if (!project.eventStartDate || !project.eventEndDate) {
      isValid = false;
      validationMessages.push(
        'Activity plan: Please enter your activity dates'
      );
    }
    if (project.eventDetailId) {
      const isProjected = project.statusId === 1;
      const outputFormatValid = getFormatOutputValidity(project, isProjected);

      if (isProjected && !outputFormatValid) {
        isValid = false;
        validationMessages.push(
          'Activity plan: Please enter a figure in at least one projected participant field'
        );
      }
    }

    if (isEmpty(project.evaluationPlans)) {
      isValid = false;
      validationMessages.push('Evaluation: Please enter an evaluation plan');
    } else {
      let getEvalPlans = map(get(project, 'evaluationPlans', []), (plan) => {
        return map(plan.evaluationQuestions, (e) => e.objectiveId);
      });
      if (!isEmpty(getEvalPlans)) {
        getEvalPlans = uniq(flattenDeep(getEvalPlans));
      }
      const objectiveIds = map(project.objective, (e) => e.id);

      if (!isEqual(objectiveIds.sort(), getEvalPlans.sort())) {
        isValid = false;
        validationMessages.push(
          'Evaluation tab: Please enter at least one evaluation plan for all objectives'
        );
      }
    }
  }

  return {
    isValid,
    validationMessages,
  };
}

// FIXME: for now using old validation
export function getFormatOutputValidity(project, isProjected = true) {
  let isValidProjectedOutput = false;
  let isValidSessionOutput = false;
  const outputFormatGroup = get(project, 'eventDetail.outputFormat', null);

  if (outputFormatGroup) {
    for (const formatItem of outputFormatGroup.format) {
      const currentOutput = project.output.find(
        (outputItem) => outputItem.outputFormatId === formatItem.outputFormatId
      );

      if (currentOutput) {
        if (isProjected) {
          const isValueValid =
            currentOutput.projectedValue &&
            currentOutput.projectedValue !== '0' &&
            currentOutput.projectedValue !== '0.00';

          if (isValueValid && formatItem.type == 'participant-group') {
            isValidProjectedOutput = true;
          }
        } else {
          const isValueValid =
            currentOutput.actualValue &&
            currentOutput.actualValue !== '0' &&
            currentOutput.actualValue !== '0.00';

          if (isValueValid && formatItem.type == 'participant-group') {
            isValidSessionOutput = true;
          }
        }
      }
    }
  }
  if (isProjected) {
    return isValidProjectedOutput;
  } else {
    return isValidSessionOutput;
  }
}

export function checkEvaluationMethod(evaluation) {
  try {
    if (isArray(evaluation)) {
      const data = map(evaluation, (o) => {
        return o.participantResponse;
      });
      evaluation.participantResponse = flatten(data);
    }
    let count = 0;
    forEach(evaluation.participantResponse, (value) => {
      count += isNumber(sum(value)) ? sum(value) : 0;
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
}

export function decodeBase64String(base64String) {
  try {
    const b64Decoded =
      typeof Buffer !== 'undefined'
        ? Buffer.from(base64String, 'base64').toString()
        : atob(base64String);
    const data = JSON.parse(b64Decoded);
    return data;
  } catch (error) {
    console.error('error from decodeBase64String : ', error);
  }
}

export function getActivityType(activity) {
  //Check only Single/Linked/Grant for now
  if (!activity) return '-';

  const parentActivity = get(activity, 'parentActivity', null);
  const activityPlanTypeId = parentActivity
    ? parentActivity.activityPlanTypeId
    : activity.activityPlanTypeId;
  switch (activityPlanTypeId) {
    case 1:
      if (activity.isLinkedActivity) {
        return {
          type: 'Linked',
          title: 'Linked Activity',
          text: 'Activity',
          organisation: get(activity, 'owner.organisation.name', null),
          parentActivity: get(activity, 'parentActivity.name', null),
        };
      } else {
        return {
          type: 'Single',
          title: 'Single Activity',
          text: 'Activity',
        };
      }
    // case 2:
    //   return 'Series';
    // case 3:
    //   return 'Festival';
    case 3:
      if (activity.isLinkedActivity) {
        return {
          type: 'Linked',
          title: 'Linked Activity',
          text: 'Activity',
          organisation: get(activity, 'owner.organisation.name', null),
          parentActivity: get(activity, 'parentActivity.name', null),
        };
      } else {
        return {
          type: 'Festival',
          title: 'Festival Program',
          text: 'Festival',
        };
      }
    case 4:
      if (activity.isLinkedActivity) {
        return {
          type: 'Linked',
          title: 'Linked Activity',
          text: 'Activity',
          organisation: get(activity, 'owner.organisation.name', null),
          parentActivity: get(activity, 'parentActivity.name', null),
        };
      } else {
        return {
          type: 'Grant',
          title: 'Grants Program',
          text: 'Program',
        };
      }
    // case 5:
    //   return 'Cross Sectoral';
    case 6:
      if (activity.isLinkedActivity) {
        return {
          type: 'Linked',
          title: 'Linked Activity',
          text: 'Activity',
          organisation: get(activity, 'owner.organisation.name', null),
          parentActivity: get(activity, 'parentActivity.name', null),
        };
      } else {
        return {
          type: 'Multi Activity',
          title: 'Multi-Activity Program',
          text: 'Activity',
        };
      }
    default:
      return '-';
  }
}

export function getEvidenceTitle(activity) {
  if (isGrantProgramActivity(activity.activityPlanTypeId)) {
    return 'Evidence supporting the grants program';
  } else {
    return 'Evidence about Objectives';
  }
}

export function setValue(item, currencySymbol = '$') {
  if (item.value === 0) return '-';
  switch (item.type) {
    case 'currency':
      return `${currencySymbol}${thousands(item.value)}`;
    default:
      return thousands(item.value);
  }
}

export function getActivityTypeToc(currentActivity) {
  if (!isParentProgramActivity(currentActivity.activityPlanTypeId)) return ' ';
  switch (currentActivity.activityPlanTypeId) {
    case 4:
      return 'grant';
    case 3:
      return 'festival';
    case 6:
      return 'multi-activity';

    default:
      return 'grant';
  }
}

export function getValuesFormat(value, type, currencySymbol = '$') {
  value = value ? thousands(value) : 0;

  if (type === 'currency') {
    value = `${currencySymbol} ` + value;
  } else if (type === 'percentage') {
    value = value + ' %';
  }

  return value;
}

export function averageValue(participantResponse) {
  let denominatorPost = 0,
    denominatorPre = 0;
  let postAverage = 0,
    preAverage = 0;
  let totalPost = 0,
    totalPre = 0;

  let results = {};
  if (!isEmpty(participantResponse)) {
    forEach(participantResponse[0], (value, index) => {
      totalPost += value * (index + 1);
      denominatorPost += value;
    });

    denominatorPost = denominatorPost * 10;
    if (denominatorPost != 0) {
      postAverage = (totalPost / denominatorPost) * 10;
      postAverage = Math.round(postAverage * 100) / 100;
    }
    results.postAverage = Number(postAverage).toFixed(2);

    if (size(participantResponse) > 1) {
      forEach(participantResponse[1], (value, index) => {
        totalPre += value * (index + 1);
        denominatorPre += value;
      });

      denominatorPre = denominatorPre * 10;
      if (denominatorPost != 0) {
        preAverage = (totalPre / denominatorPre) * 10;
        preAverage = Math.round(preAverage * 100) / 100;
      }
      results.preAverage = Number(preAverage).toFixed(2);
    }

    return results;
  }
  return results;
}

export function setParentDomains(currentActivity) {
  const getSubDomains = filter(
    currentActivity.parentDomains,
    (domain) => domain.policyId !== currentActivity.primaryPolicy.id
  );
  const parentDomains = [
    {
      policyId: currentActivity.primaryPolicy.id,
      policy: currentActivity.primaryPolicy,
    },
    ...getSubDomains,
  ];
  if (currentActivity.secondaryPolicyId) {
    parentDomains.push({
      policyId: currentActivity.secondaryPolicy.id,
      policy: currentActivity.secondaryPolicy,
    });
  }
  return parentDomains;
}

export const ContentAndHeader = ({ title, children }) => [
  <h3 key={1} className="c-h3">
    {title}
  </h3>,
  <div key={2} className="approval-request-data">
    {children}
  </div>,
];

export const ContentAndHeaderEvaluation = ({ title, children }) => [
  title ? (
    <span key={`content_and_header_evaluation_1`} className="c-h3">
      {title}
    </span>
  ) : null,
  <span key={`content_and_header_evaluation_2`} className="c-content-eval">
    {children}
  </span>,
];

export const getCollectionMethod = (method) => {
  switch (method) {
    case 'LINK':
      return 'Takso Link';
    case 'CSV':
      return 'CSV Upload';
    case 'CULTURECOUNTS':
      return 'Culture Counts';
    default:
      break;
  }
};

export const getChartType = (props) => {
  let {
    title,
    participants,
    participantsChange,
    participantsChangePrePost,
    method,
  } = props;
  try {
    let arrayType;
    if (title) {
      participantsChange = findIndex(participantsChange, (o) => {
        return title === o.name;
      });
      participants = findIndex(participants, (o) => {
        return title === o.name;
      });
      participantsChangePrePost = findIndex(participantsChangePrePost, (o) => {
        return title === o.name;
      });

      if (parseInt(method) === 12) {
        arrayType = 'participants';
      } else if (participantsChange >= 0 || participantsChangePrePost >= 0) {
        arrayType = 'participantsChange';
      } else if (participants >= 0) {
        arrayType = 'participants';
      }

      switch (arrayType) {
        case 'participantsChange':
          return 'prePostChart';
        case 'participants':
          return 'postChart';
        default:
          return 'noData';
      }
    }
  } catch (error) {
    return 'noData';
  }
};

/**
 * Check activity method name
 * @param {string} method
 * @returns
 */
export const checkMethodName = (method) => {
  switch (method) {
    case '2':
      return 'Interview - participant';
    case '3':
      return 'Questionnaire - participant';
    case '4':
      return 'Focus Group - participants';
    case '5':
      return 'Interview - proxy';
    case '6':
      return 'Interview - expert';
    case '7':
      return 'Questionnaire - proxy';
    case '8':
      return 'Questionnaire - expert';
    case '9':
      return 'Self-assessed';
    case '10':
      return 'Focus Group - proxies';
    case '11':
      return 'Focus Group - experts';
    case '12':
      return 'Intercept';
    case '13':
      return 'Quick Response';
    case 'all':
      return 'All';
    default:
      return '';
  }
};

export const dateRangePickerTitle = (currentActivity) => {
  switch (currentActivity.activityPlanTypeId) {
    case 3:
      return 'Festival Dates';
    case 4:
      return 'Activity Period';
    case 6:
      return 'Program Period';
    default:
      return 'Activity Period';
  }
};

export const datePickerStartTitle = (currentActivity) => {
  switch (currentActivity.activityPlanTypeId) {
    case 3:
      return 'Festival Start Date';
    case 4:
      return 'Activity Start Date';
    case 6:
      return 'Program Start Date';
    default:
      return 'Activity Start Date';
  }
};

export const datePickerEndTitle = (currentActivity) => {
  switch (currentActivity.activityPlanTypeId) {
    case 3:
      return 'Festival End Date';
    case 4:
      return 'Activity End Date';
    case 6:
      return 'Program End Date';
    default:
      return 'Activity End Date';
  }
};

export const getActivityDescription = (planType) => {
  switch (planType) {
    case '1':
      return 'An activity that aims to achieve a maximum of two objectives; has its own start and end dates and is not part of a defined larger program of activities.';
    case '3':
      return 'A program designed with multiple objectives that are delivered by multiple linked activities and publicly identified as a unified celebration or cultural event.';
    case '4':
      return 'An activity with multiple objectives that are achieved via a number of single activities; initiated through a process of pitching for and distribution of a pool of funds that occur between the Grant opening date and the final acquittal date.';
    case '6':
      return 'A program designed with multiple objectives that are delivered by multiple linked activities not necessarily identified publicly as a unified program.';
    default:
      break;
  }
};

export const stopPropagation = (event) => {
  event.stopPropagation();
};

export const isFeatureSelected = (newId, selectedFeaturs) => {
  const isExist = find(selectedFeaturs, (id) => id === newId);
  return isExist ? true : false;
};

// this function used for admin headline data reports
export const getReportYears = (
  isFinancial = false,
  startingYear,
  organisationRegDate = null
) => {
  const organisationRegYear = organisationRegDate
    ? moment(organisationRegDate).year()
    : 2018;
  const organisationRegMonth = organisationRegDate
    ? moment(organisationRegDate).month() + 1
    : 1; // 1 -> january
  const optionCount = getYearOptionCount(organisationRegYear, isFinancial);

  // for super admin not checking registered year

  let reportingYears = [];
  if (isFinancial) {
    const currentMonth = new Date().getMonth();
    let initYear = startingYear - 1;

    /*
     * To check organisation was created before july if it was need to show last year
     * for the begins to get that financial range
     */
    if (organisationRegDate && organisationRegMonth > 6) {
      initYear = startingYear;
    } else if (currentMonth > 6) {
      // if current month between july - december
      initYear = startingYear;
    }

    for (let year = initYear; year >= startingYear - optionCount; year--) {
      const yearStart = moment(`${year}-07-01`)
        .startOf('month')
        .format('YYYY-MM-DD');

      const yearEnd = moment(`${year + 1}-06-30`)
        .endOf('month')
        .format('YYYY-MM-DD');

      const yearStartTitle = moment(yearStart).format('YYYY');
      const yearEndTitle = moment(yearEnd).format('YYYY');

      reportingYears.push({
        title: `${yearStartTitle} - ${yearEndTitle}`,
        text: `${yearStartTitle} - ${yearEndTitle}`,
        value: `${year}-${year + 1}`,
        yearStart,
        yearEnd,
      });
    }
  } else {
    for (let year = startingYear; year >= startingYear - optionCount; year--) {
      reportingYears.push({
        title: `${year}`,
        text: `${year}`,
        value: `${year}`,
        yearStart: moment(`${year}-01-01`).format('YYYY-MM-DD'),
        yearEnd: moment(`${year}-12-31`).format('YYYY-MM-DD'),
      });
    }
  }
  return reportingYears;
};

export const isProgramActivity = (activityType) => {
  const types = ['grant_program', 'festival_program', 'multi_activity_program'];
  const _isProgramActivity = types.includes(activityType);
  return _isProgramActivity;
};

export const getProgramActivityTitle = (activityType) => {
  switch (activityType) {
    case 'grant_program':
      return 'Grant programs';
    case 'festival_program':
      return 'Festival programs';
    case 'multi_activity_program':
      return 'Multi-Activity programs';
    default:
      return 'Grant programs';
  }
};

export const trimText = (text, max_length = 20) => {
  if (text && text.length > max_length) {
    return text.substring(0, max_length).trimEnd() + '...';
  }

  return text;
};

export const getObjectiveErrors = (project) => {
  let isEmptyGoals = false;
  let isEmptyObjectives = false;
  const validationErrors = [];

  if (!project.goal || project.goal === '') {
    isEmptyGoals = true;
  }
  if (isParentProgramActivity(project.activityPlanTypeId)) {
    const projectParentOutcomesIds =
      project.parentOutcomes.length > 0
        ? project.parentOutcomes.map(
            (parentOutcome) => parentOutcome.policyValue
          )
        : [];

    /*
     * If Select Goal is cultural secondary policies will be optional fields
     */
    if (!isEmpty(project.parentDomains)) {
      // empty parent outcomes
      if (projectParentOutcomesIds.length === 0) {
        isEmptyObjectives = true;
      }

      let isEmptyParentDomainsAvailable = false;
      map(project.parentDomains, (domain) => {
        if (!projectParentOutcomesIds.includes(domain.policyId)) {
          isEmptyParentDomainsAvailable = true;
        }
      });

      if (isEmptyParentDomainsAvailable) {
        isEmptyObjectives = true;
      }
    } else {
      if (isEmpty(project.parentOutcomes)) {
        isEmptyObjectives = true;
      }
      if (project.primaryPolicyId) {
        if (!projectParentOutcomesIds.includes(project.primaryPolicyId))
          isEmptyObjectives = true;
      }

      if (project.secondaryPolicyId) {
        if (!projectParentOutcomesIds.includes(project.secondaryPolicyId))
          isEmptyObjectives = true;
      }
    }
  } else {
    if (project.objective && !project.objective.length) {
      isEmptyObjectives = true;
    }
  }

  if (isEmptyGoals) {
    validationErrors.push('Goal: Please select the Goals');
  }

  if (isEmptyObjectives) {
    validationErrors.push('Objective: Please select the objectives');
  }

  if (validationErrors.length > 0) {
    return validationErrors;
  } else {
    return null;
  }
};

export const getYearOptionCount = (startYear = null, isFinancial = false) => {
  startYear = startYear ? startYear : 2018;
  const endYear = moment().year();

  let addition = isFinancial ? 1 : 0;

  const yearDifference = parseInt(endYear) - parseInt(startYear) + addition;
  return yearDifference;
};

export const getNotePermission = (currentActivity, user, permissionType) => {
  if (isEmpty(currentActivity)) {
    return false;
  }

  const member = find(currentActivity.members, (member) => {
    return Number(member.userId) === Number(user.id);
  });

  if (isEmpty(member)) {
    return false;
  }

  if (isString(member.permissions)) {
    member.permissions = JSON.parse(member.permissions);
  }

  const getPermission = find(member.permissions, (permission) => {
    return permission.state === 'project.edit.notes';
  });

  const permission = get(getPermission, `permission[${permissionType}]`, false);

  return permission;
};
/**
 * @param  {} activityPlanTypeId=null
 * @param  {} isLinkedActivity=false
 * @description this function is used when showing linked activity details (single links, program links)
 * @note do not use this function for identifying activity type where to get single types (not linked)
 */
export const activityTypeName = (
  activityPlanTypeId = null,
  isLinkedActivity = false
) => {
  let activity_name = '';

  activityPlanTypeId = Number(activityPlanTypeId);
  if (activityPlanTypeId === 1 && isLinkedActivity) {
    activity_name = 'Single';
  } else if (isLinkedActivity) {
    switch (activityPlanTypeId) {
      case 3:
        activity_name = 'Festival Program';
        break;
      case 4:
        activity_name = 'Grant Program';
        break;
      case 6:
        activity_name = 'Multi-Activity Program';
        break;
      default:
        break;
    }
  }

  return activity_name.trim();
};

export const isTabDeactivated = (
  newTabPosition = 'NEXT', // ['NEXT', 'BACK', 'CURRENT']
  activity,
  panes,
  currentTab
) => {
  const { activityPlanTypeId, statusId, parentOutcomes, objective } = activity;

  const hasObjectives = isParentProgramActivity(activityPlanTypeId)
    ? parentOutcomes && parentOutcomes.length > 0
    : objective && objective.length > 0;

  let newTab = panes[currentTab + 1] || {}; // default next

  switch (newTabPosition) {
    case 'NEXT': {
      newTab = panes[currentTab + 1] || {};
      break;
    }
    case 'BACK': {
      newTab = panes[currentTab - 1] || {};
      break;
    }
    default: {
      // current
      newTab = panes[currentTab] || {};
      break;
    }
  }

  const newTabKey = get(newTab, 'menuItem.key', null);

  if (newTabKey === 'project.edit.start') {
    // Goal can be accessed at initial state and able to access
    return false;
  } else if (newTabKey === 'project.edit.objective') {
    // Objective can be accessed at initial state and able to access
    return false;
  } else if (newTabKey === 'project.edit.evidence') {
    /*
     * For Single Activities - Evidence can be accessed once objectives are assigned to activity
     * For Program Activities - Evidence can be accessed at initial state and able to access
     */
    const isEvidenceDisabled =
      !Boolean(hasObjectives) &&
      !isParentProgramActivity(activity.activityPlanTypeId);
    return isEvidenceDisabled;
  } else if (newTabKey === 'project.edit.theoryOfChange') {
    /*
     * For Single Activities - TOC can be accessed once objectives are assigned to activity
     * For Program Activities - TOC can be accessed at initial state and able to access
     */
    const isTocDisabled =
      !Boolean(hasObjectives) &&
      !isParentProgramActivity(activity.activityPlanTypeId);
    return isTocDisabled;
  } else if (newTabKey === 'project.edit.activityPlan') {
    // Activity Plan can be accessed at initial state and able to access
    return false;
  } else if (newTabKey === 'project.edit.evaluationPlan') {
    // Evaluation can be accessed once objectives are assigned to activity
    const isEvaluationDisabled = !Boolean(hasObjectives);
    return isEvaluationDisabled;
  } else if (newTabKey === 'project.edit.documentation') {
    // Documentation can be accessed after green light activity (status id must be 2 || 3)
    const isDocumentationDisabled = statusId === 1;
    return isDocumentationDisabled;
  } else if (newTabKey === 'project.edit.report') {
    // Report can be accessed once objectives are assigned to activity
    const isReportDisabled = !Boolean(hasObjectives);
    return isReportDisabled;
  } else {
    // Others cases -> disable access
    return true;
  }
};

export const getGestUserContractorNoteVenuePermission = (user, members) => {
  if (!user || isEmpty(members)) {
    return { search: false, add: false };
  }

  if (user.role !== 'guest') {
    return { search: true, add: true };
  }

  const member = find(members, (member) => {
    return Number(member.userId) === Number(user.id);
  });

  if (isEmpty(member)) {
    return { search: false, add: false };
  }

  if (member.isOwner) {
    return { search: false, add: true };
  } else {
    return { search: true, add: true };
  }
};

import React, { useState, useEffect } from 'react';
import { Grid, Loader, Radio, Segment } from 'semantic-ui-react';
import { ChartComponent, makeChartObject } from '../activitySummary';
import { useDispatch, useSelector } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

import { isEmpty, find, get } from '../../../../utils/lodash';
import '../report.scss';
import { CustomSegment, EmptyContainer } from '../../../../components';
import {
  filterValueIndexes,
  removeIndexesValues,
} from '../../../../utils/customFunctions';
import { PROGRAM_REPORT_LOADING } from '../../../../utils/constant';
import { getProgrammeReportData } from '../../../../store/actions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const ProgrammeOutcomeAddress = (props) => {
  const [participantsResponses, setParticipantsResponses] = useState({
    mainTitle: [],
    dataset: [],
    development: [],
    active: [],
    completed: [],
    styles: {
      color: [],
      weight: [],
      highlight: [],
      barColor: [],
      size: [],
    },
  });

  const dispatch = useDispatch();
  const [participantsResponsesOnly, setParticipantsResponsesOnly] =
    useState(true);

  const reportFilter = useSelector((state) => state.report.reportFilterOptions);

  const outcomeSummaryChart = useSelector(
    (state) => state.report.outcomeSummaryChart
  );
  const organization = useSelector((state) => state.organization.organization);

  const industrySectorTypes = useSelector(
    (state) => state.extra.industrySectorTypes
  );

  const reportInitialState = useSelector((state) => state.report);
  const outcomeReportLoader = get(
    reportInitialState,
    `outcomeSummaryReportLoading.${PROGRAM_REPORT_LOADING.OUTCOME_ADDRESS_LOADING}`,
    false
  );
  const getHeight = (data) => {
    return `${5 * data.length}rem`;
  };

  useEffect(() => {
    if (!isEmpty(outcomeSummaryChart)) {
      setParticipantsResponses({
        mainTitle: [],
        dataset: [],
        development: [],
        active: [],
        completed: [],
        styles: {
          color: [],
          weight: [],
          highlight: [],
          barColor: [],
          size: [],
        },
      });

      makeChartObject(
        outcomeSummaryChart,
        setParticipantsResponses,
        reportFilter
      );
    }
  }, [outcomeSummaryChart, participantsResponsesOnly, reportFilter]);

  const getActivityTotal = (chart, title) =>
    (chart.find((item) => item.mainTitle === title) || {}).totalActivities;

  const cultural = getActivityTotal(outcomeSummaryChart, 'Cultural');
  const social = getActivityTotal(outcomeSummaryChart, 'Social');
  const economic = getActivityTotal(outcomeSummaryChart, 'Economic');
  const governance = getActivityTotal(outcomeSummaryChart, 'Governance');
  const environmental = getActivityTotal(outcomeSummaryChart, 'Environmental');

  const getOrganizationSectorType = () => {
    try {
      const type = find(industrySectorTypes, (o) => {
        return o.id === organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  };

  const onChangeParticipantsResponsesOnly = () => {
    setParticipantsResponsesOnly(!participantsResponsesOnly);
    dispatch(
      getProgrammeReportData(
        'outcomes-addressed',
        get(props, 'currentActivity.id', null),
        {
          outcomeOnly: !participantsResponsesOnly,
        }
      )
    );
  };

  const { parent = false, superAdmin = false } = props;
  return (
    <Segment loading={outcomeReportLoader}>
      {/* <CustomSegment title="Outcome Overview"> */}
      <div className="activity-count-info">
        <Grid>
          <Grid.Row className="programme-activity">
            <Grid.Column width={16}>
              <Grid.Column className="activity-summary-grid">
                {parent || superAdmin ? null : (
                  <div className="org-down">
                    <Grid.Row>
                      <label>Organisation Type</label>
                    </Grid.Row>
                    <Grid.Row>
                      {outcomeReportLoader ? (
                        <Loader active size="medium" />
                      ) : (
                        <h6>{getOrganizationSectorType()}</h6>
                      )}
                    </Grid.Row>
                  </div>
                )}

                <div className="left-align">
                  <div className="activity-name">
                    <div className="report-circle circle-bottom reports-cultural" />
                    <div className="col-wise">
                      <div>
                        <label>Cultural</label>
                      </div>
                      <div className="number-centre">{cultural || 0}</div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="report-circle circle-bottom reports-economic" />
                    <div className="col-wise">
                      <div>
                        <label>Economic</label>
                      </div>
                      <div className="number-centre">{economic || 0}</div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="report-circle circle-bottom reports-social" />
                    <div className="col-wise">
                      <div>
                        <label>Social</label>
                      </div>
                      <div className="number-centre">{social || 0}</div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="report-circle circle-bottom reports-environmental" />
                    <div className="col-wise">
                      <div>
                        <label>Environment</label>
                      </div>
                      <div className="number-centre">{environmental || 0}</div>
                    </div>
                  </div>

                  <div className="activity-name">
                    <div className="report-circle circle-bottom reports-governance" />
                    <div className="col-wise">
                      <div>
                        <label>Governance</label>
                      </div>
                      <div className="number-centre">{governance || 0}</div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {/* </CustomSegment> */}
      <br />
      <CustomSegment
        title="Outcomes Addressed"
        rightHeader={
          <div style={{ display: ' flex', flexDirection: 'row' }}>
            Used Outcomes Only: &nbsp;
            <div className="two-step-radio-container-admin">
              <Radio
                toggle
                checked={participantsResponsesOnly}
                onChange={() => onChangeParticipantsResponsesOnly()}
              />
            </div>
          </div>
        }
      >
        <Segment className="chart-holder">
          {!isEmpty(get(participantsResponses, 'dataset', [])) ? (
            <div
              style={{
                height: getHeight(get(participantsResponses, 'dataset', 0)),
                width: 'auto',
                background: 'white',
              }}
            >
              <ChartComponent
                toggle={participantsResponsesOnly}
                data={participantsResponses}
                chartType={'activitiesOnly'}
                status={reportFilter.statuses}
                dynamicBarColor={true}
                props={props}
                reportType={props.reportType}
              />
            </div>
          ) : (
            <EmptyContainer msg="No Data Available" />
          )}
        </Segment>
      </CustomSegment>
    </Segment>
  );
};

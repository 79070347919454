import * as Yup from 'yup';
import {
  DOMAIN_REG_EX,
  EMAIL_REG_EX,
  LOWERCASE_REG_EX,
  NUMERIC_REG_EX,
  SPECIAL_CHARACTER_REG_EX,
  PHONE_REG_EX,
  UPPERCASE_REG_EX,
} from '../utils/regex';

export const getAdminOrgUserCreateInitialValues = admin => {
  return {
    orgIndustrySectorTypeId:
      admin && admin.orgIndustrySectorTypeId
        ? admin.orgIndustrySectorTypeId
        : '',
    organisationName:
      admin && admin.organisationName ? admin.organisationName : '',
    orgPhone: admin && admin.orgPhone ? admin.orgPhone : '',
    orgDomain: admin && admin.orgDomain ? admin.orgDomain : '',
    orgEmail: admin && admin.orgEmail ? admin.orgEmail : '',
    userFirstName: admin && admin.userFirstName ? admin.userFirstName : '',
    userSurname: admin && admin.userSurname ? admin.userSurname : '',
    isTaksoAdminEmail:
      admin && admin.isTaksoAdminEmail ? admin.isTaksoAdminEmail : '',
    userEmail: admin && admin.userEmail ? admin.userEmail : '',
    password: admin && admin.password ? admin.password : '',
    confirmPassword:
      admin && admin.confirmPassword ? admin.confirmPassword : '',
    passwordIndv: admin && admin.passwordIndv ? admin.passwordIndv : '',
    confirmPasswordIndv:
      admin && admin.confirmPasswordIndv ? admin.confirmPasswordIndv : '',
    individualPaymentPreferenceId:
      admin && admin.individualPaymentPreferenceId
        ? admin.individualPaymentPreferenceId
        : '',
    individualSubscription:
      admin && admin.individualSubscription ? admin.individualSubscription : '',
    userFirstNameIndv:
      admin && admin.userFirstNameIndv ? admin.userFirstNameIndv : '',
    userPhoneIndv: admin && admin.userPhoneIndv ? admin.userPhoneIndv : '',
    userSurnameIndv:
      admin && admin.userSurnameIndv ? admin.userSurnameIndv : '',
    userEmailIndv: admin && admin.userEmailIndv ? admin.userEmailIndv : '',
  };
};

//organisation
export const getAdminUserCreateValidationSchemaOrg = (
  isTaksoAdminEmail = false
) => {
  let validationObject = {
    orgPhone: Yup.string()
      .required('Number is required')
      .matches(PHONE_REG_EX, 'Phone number is not valid!')
      .min(10, 'Must be a minimum of 10 digits!'),
    orgIndustrySectorTypeId: Yup.string().required(
      'IndustrySectorType Id is required'
    ),
    organisationName: Yup.string().required('Organisation Name is required'),
    orgDomain: Yup.string()
      .required('Web Domain is required!')
      .matches(DOMAIN_REG_EX, 'Please enter the valid format'),
    orgEmail: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),
    userFirstName: Yup.string().required('first Name is required'),
    userSurname: Yup.string().required('last name is required'),
    userEmail: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),
  };

  if (isTaksoAdminEmail) {
    validationObject = {
      ...validationObject,
      password: Yup.string()
        .min(8, 'LENGTH_ERROR')
        .required('LENGTH_ERROR')
        .matches(LOWERCASE_REG_EX, 'LOWERCASE_ERROR')
        .matches(UPPERCASE_REG_EX, 'UPPERCASE_ERROR')
        .matches(NUMERIC_REG_EX, 'NUMERIC_ERROR')
        .matches(SPECIAL_CHARACTER_REG_EX, 'SPECIAL_CHARACTER_ERROR'),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], "Doesn't match the new password"),
    };
  }

  return Yup.object().shape(validationObject);
};

//individuals
export const getAdminUserCreateValidationSchemaIndv = (
  isTaksoAdminEmail = false
) => {
  let validationObject = {
    individualPaymentPreferenceId: Yup.string().required(
      'Payment Preference  is required'
    ),
    individualSubscription: Yup.string().required('Subscription is required'),
    userFirstNameIndv: Yup.string().required('first name is required'),
    userSurnameIndv: Yup.string().required('last name is required'),
    userEmailIndv: Yup.string()
      .required('Email is required')
      .matches(EMAIL_REG_EX, 'Please enter the valid format'),
    userPhoneIndv: Yup.string()
      .required('Number is required')
      .matches(PHONE_REG_EX, 'Phone number is not valid!')
      .min(10, 'Must be a minimum of 10 digits!'),
  };

  if (isTaksoAdminEmail) {
    validationObject = {
      ...validationObject,
      passwordIndv: Yup.string()
        .min(8, 'LENGTH_ERROR')
        .required('LENGTH_ERROR')
        .matches(LOWERCASE_REG_EX, 'LOWERCASE_ERROR')
        .matches(UPPERCASE_REG_EX, 'UPPERCASE_ERROR')
        .matches(NUMERIC_REG_EX, 'NUMERIC_ERROR')
        .matches(SPECIAL_CHARACTER_REG_EX, 'SPECIAL_CHARACTER_ERROR'),
      confirmPasswordIndv: Yup.string()
        .required('Confirm password is required')
        .oneOf(
          [Yup.ref('passwordIndv'), null],
          "Doesn't match the new password"
        ),
    };
  }
  return Yup.object().shape(validationObject);
};
